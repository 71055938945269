import React, { memo } from 'react';
import { isEqual } from 'lodash';
import useRoutes from 'routes/routes';
import { Switch, Route, Redirect } from 'react-router-dom';
import withAdminContainer from 'HOC/withAdminContainer';
import PropTypes from 'prop-types';

const fullScreenPaths = ['/admin/zones', '/admin/overview', '/admin/overview/', '/admin/equipment/map'];

const AdminContent = ({ isAdmin }) => {
    const routes = useRoutes();
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            // If the links has admin privileges and the user is not an admin
            if (prop.adminPrivilege && !isAdmin) {
                return null;
            }
            if (prop.collapse) {
                //If routes has a collapse
                return getRoutes(prop.views);
            }
            if (prop.layout === '/admin') {
                const path = prop.layout + prop.path;
                const isFullScreenPath = fullScreenPaths.includes(path);
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={withAdminContainer(prop.component, isFullScreenPath)}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    return (
        <Switch>
            {getRoutes(routes)}
            {isAdmin ? (
                <Redirect from="/admin" to="/admin/dashboard" />
            ) : (
                <Redirect from="/admin" to="/admin/overview" />
            )}
        </Switch>
    );
};

AdminContent.propTypes = { isAdmin: PropTypes.bool };

export default memo(AdminContent, isEqual);
