import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { stringify } from 'qs';
import { selectNotifications } from 'store/selectors/notificationSelectors';
import { webURL } from 'config';

interface Notification {
    id: string;
    label: string;
    owner: string;
    anomaly?: string;
    createdAt: number;
}

const useDesktopNotification = (): void => {
    const intl = useIntl();
    const notifications = useSelector(selectNotifications);

    const showNotification = async (notifications: Notification[]): Promise<void> => {
        // If there is no notification then terminate the function
        if (notifications.length < 1) return;

        if (Notification.permission === 'granted') {
            // If there is more than one notification
            const isMultiple = notifications.length > 1;

            // If there is more than one notification then show the notification count otherwise show the notification label
            const label = isMultiple
                ? intl.formatMessage(
                      { defaultMessage: 'You have {notificationCount, number} notifications' },
                      { notificationCount: notifications.length },
                  )
                : notifications[0].label;

            // If there is more than one notification then add the overview page link otherwise add the notification link
            const link = isMultiple
                ? `${webURL}/admin/overview`
                : `${webURL}/admin/overview?${stringify({ anomaly: notifications[0].anomaly })}`;

            // Create notification
            const notification = new Notification(label, {
                icon: '/favicon-32x32.png',
            });

            // Add notification events
            notification.onclick = function (event) {
                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                window.open(link, '_blank');
            };
        } else if (Notification.permission !== 'denied') {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') showNotification(notifications);
        }
    };

    useEffect(() => {
        if (notifications.length > 0) {
            // Get the old notifications
            const cacheNotifStr = localStorage.getItem('oldNotifications');
            let cacheNotif: string[] = [];
            if (typeof cacheNotifStr === 'string') {
                cacheNotif = JSON.parse(cacheNotifStr);
            }

            // Find the new notifications
            const newNotifications = notifications.filter(
                (notification: Notification) => !cacheNotif.includes(notification.id),
            );

            showNotification(newNotifications);
            // Set current notifications as old notifications
            localStorage.setItem(
                'oldNotifications',
                JSON.stringify(notifications.map((notification: Notification) => notification.id)),
            );
        }
    }, [notifications]);
};

export default useDesktopNotification;
