import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Translate from '@material-ui/icons/Translate';
import NavbarDropDown from 'components/Navbars/NavbarDropDown';

import { supportedLangs } from 'config';
import { switchLocal } from 'store/slices/localizationSlice';
import { selectLocale } from 'store/selectors/localizationSelectors';

const LocaleSwitcher = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const locale = useSelector(selectLocale);
    const localeChangeHandler = useCallback(
        (selectedLocale) => {
            dispatch(switchLocal(selectedLocale.code));
        },
        [dispatch],
    );

    const dropdownList = useMemo(
        () =>
            Object.entries(supportedLangs).map(([key, value]) => ({
                content: value.nativeName || value.name,
                code: key,
                menuItemProps: { selected: key === locale },
            })),
        [locale],
    );
    const buttonText = supportedLangs[locale].nativeName || supportedLangs[locale].name;

    return (
        <NavbarDropDown
            className={className}
            onClick={localeChangeHandler}
            buttonIcon={<Translate />}
            buttonText={buttonText}
            caret
            buttonProps={{ justIcon: false, size: 'sm', style: { padding: '10px 12px' } }}
            dropdownList={dropdownList}
            {...rest}
        />
    );
};

LocaleSwitcher.propTypes = { className: PropTypes.string };

export default memo(LocaleSwitcher, isEqual);
