/*eslint-disable*/
import React, { memo } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import withCatchError from 'contexts/CatchError/withCatchError';
// core components
// import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks';

import {
    drawerWidth,
    drawerMiniWidth,
    transition,
    boxShadow,
    defaultFont,
    primaryColor,
    primaryBoxShadow,
    // infoColor,
    successColor,
    warningColor,
    dangerColor,
    roseColor,
    whiteColor,
    blackColor,
    grayColor,
    hexToRgb,
} from 'assets/jss/material-dashboard-pro-react';

import Loader from 'components/Loader/Loader';

import { logOutHandler, changeOrg } from 'store/slices/authSlice';
import {
    selectIsAuthenticated,
    selectAuthUser,
    selectActiveOrg,
    selectAuthLoading,
} from 'store/selectors/authSelectors';

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
    sidebarWrapper = React.createRef();
    componentDidMount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(this.sidebarWrapper.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps.destroy();
        }
    }
    render() {
        const { className, user, headerLinks, links } = this.props;
        return (
            <div className={className} ref={this.sidebarWrapper}>
                {user}
                {headerLinks}
                {links}
            </div>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            miniActive: true,
            ...this.getCollapseStates(props.routes),
        };
    }

    onSidebarMouseOver = () => {
        if (this.props.miniActive) {
            this.setState({ miniActive: false });
        }
    };
    onSidebarMouseOut = () => {
        if (this.props.miniActive) {
            this.setState({ miniActive: true });
        }
    };
    mainPanel = React.createRef();
    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = (routes) => {
        let initialState = {};
        routes.map((prop) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }
    // verifies if routeName is the one active (in browser input)
    activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? 'active' : '';
    };
    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }
    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = (routes) => {
        const { classes, color, rtlActive, isAdmin } = this.props;

        return routes.map((prop, key) => {
            if (prop.redirect || prop.invisible) {
                return null;
            }
            // If the links has admin privileges and the user is not an admin
            if (prop.adminPrivilege && !isAdmin) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop['state']] = !this.state[prop.state];
                const navLinkClasses =
                    classes.itemLink +
                    ' ' +
                    cx({
                        [' ' + classes.collapseActive]: this.getCollapseInitialState(prop.views),
                    });
                const itemText =
                    classes.itemText +
                    ' ' +
                    cx({
                        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                        [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                        [classes.itemTextRTL]: rtlActive,
                    });
                const collapseItemText =
                    classes.collapseItemText +
                    ' ' +
                    cx({
                        [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
                        [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                        [classes.collapseItemTextRTL]: rtlActive,
                    });
                const itemIcon =
                    classes.itemIcon +
                    ' ' +
                    cx({
                        [classes.itemIconRTL]: rtlActive,
                    });
                const caret =
                    classes.caret +
                    ' ' +
                    cx({
                        [classes.caretRTL]: rtlActive,
                    });
                const collapseItemMini =
                    classes.collapseItemMini +
                    ' ' +
                    cx({
                        [classes.collapseItemMiniRTL]: rtlActive,
                    });
                return (
                    <ListItem
                        key={key}
                        className={cx(
                            { [classes.item]: prop.icon !== undefined },
                            { [classes.collapseItem]: prop.icon === undefined },
                        )}
                    >
                        <NavLink
                            to={'#'}
                            className={navLinkClasses}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState(st);
                            }}
                        >
                            {prop.icon !== undefined ? (
                                typeof prop.icon === 'string' ? (
                                    <Icon className={itemIcon}>{prop.icon}</Icon>
                                ) : (
                                    <prop.icon className={itemIcon} />
                                )
                            ) : (
                                <span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
                            )}
                            <ListItemText
                                primary={rtlActive ? prop.rtlName : prop.name}
                                secondary={
                                    <b className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')} />
                                }
                                disableTypography={true}
                                className={cx(
                                    { [itemText]: prop.icon !== undefined },
                                    { [collapseItemText]: prop.icon === undefined },
                                )}
                            />
                        </NavLink>
                        <Collapse in={this.state[prop.state]} unmountOnExit>
                            <List className={classes.list + ' ' + classes.collapseList}>
                                {this.createLinks(prop.views)}
                            </List>
                        </Collapse>
                    </ListItem>
                );
            }
            const innerNavLinkClasses =
                classes.collapseItemLink +
                ' ' +
                cx({
                    [' ' + classes[color]]: this.activeRoute(prop.path),
                });
            const collapseItemMini =
                classes.collapseItemMini +
                ' ' +
                cx({
                    [classes.collapseItemMiniRTL]: rtlActive,
                });
            const navLinkClasses =
                classes.itemLink +
                ' ' +
                cx({
                    [' ' + classes[color]]: this.activeRoute(prop.path),
                });
            const itemText =
                classes.itemText +
                ' ' +
                cx({
                    [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                    [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                    [classes.itemTextRTL]: rtlActive,
                });
            const collapseItemText =
                classes.collapseItemText +
                ' ' +
                cx({
                    [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
                    [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                    [classes.collapseItemTextRTL]: rtlActive,
                });
            const itemIcon =
                classes.itemIcon +
                ' ' +
                cx({
                    [classes.itemIconRTL]: rtlActive,
                });
            return (
                <ListItem
                    key={key}
                    className={cx(
                        { [classes.item]: prop.icon !== undefined },
                        { [classes.collapseItem]: prop.icon === undefined },
                    )}
                >
                    <NavLink
                        to={prop.layout + prop.path}
                        className={cx(
                            { [navLinkClasses]: prop.icon !== undefined },
                            { [innerNavLinkClasses]: prop.icon === undefined },
                        )}
                    >
                        {prop.icon !== undefined ? (
                            typeof prop.icon === 'string' ? (
                                <Icon className={itemIcon}>{prop.icon}</Icon>
                            ) : (
                                <prop.icon className={itemIcon} />
                            )
                        ) : (
                            <span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
                        )}
                        <ListItemText
                            primary={rtlActive ? prop.rtlName : prop.name}
                            disableTypography={true}
                            className={cx(
                                { [itemText]: prop.icon !== undefined },
                                { [collapseItemText]: prop.icon === undefined },
                            )}
                        />
                    </NavLink>
                </ListItem>
            );
        });
    };

    render() {
        const {
            isAuthenticated,
            authUser,
            activeOrg,
            loading,
            classes,
            logo,
            logoMini: logoMiniImg,
            image,
            routes,
            bgColor,
            rtlActive,
            isAdmin,
        } = this.props;

        const orgs = authUser?.orgs;
        const itemText =
            classes.itemText +
            ' ' +
            cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive,
            });
        const collapseItemText =
            classes.collapseItemText +
            ' ' +
            cx({
                [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive,
            });
        const userWrapperClass =
            classes.user +
            ' ' +
            cx({
                [classes.whiteAfter]: bgColor === 'white',
                [classes.userLoading]: loading,
            });
        const caret =
            classes.caret +
            ' ' +
            cx({
                [classes.caretRTL]: rtlActive,
            });
        const collapseItemMini =
            classes.collapseItemMini +
            ' ' +
            cx({
                [classes.collapseItemMiniRTL]: rtlActive,
            });
        const photo =
            classes.photo +
            ' ' +
            cx({
                [classes.photoRTL]: rtlActive,
            });

        var user = (
            <div className={userWrapperClass}>
                <Loader loading={loading} size="25" whiteBg />
                {!loading && (
                    <>
                        {/* Active org logo */}
                        {orgs?.length > 0 && (
                            <div className={photo}>
                                <img
                                    src={orgs.find((org) => org.id === activeOrg)?.logo}
                                    className={classes.avatarImg}
                                    alt="Organization logo"
                                />
                            </div>
                        )}
                        <List className={classes.list}>
                            <ListItem className={classes.item + ' ' + classes.userItem}>
                                <NavLink
                                    to={'#'}
                                    className={classes.itemLink + ' ' + classes.userCollapseButton}
                                    onClick={() => this.openCollapse('openAvatar')}
                                >
                                    <ListItemText
                                        primary={isAuthenticated && authUser?.name}
                                        secondary={
                                            <b
                                                className={
                                                    caret +
                                                    ' ' +
                                                    classes.userCaret +
                                                    ' ' +
                                                    (this.state.openAvatar ? classes.caretActive : '')
                                                }
                                            />
                                        }
                                        disableTypography={true}
                                        className={itemText + ' ' + classes.userItemText}
                                    />
                                </NavLink>
                                <Collapse in={this.state.openAvatar} unmountOnExit>
                                    <List className={classes.list + ' ' + classes.collapseList}>
                                        {!loading &&
                                            orgs &&
                                            orgs.map((org) => (
                                                <ListItem
                                                    className={classes.collapseItem}
                                                    key={org.id}
                                                    onClick={() =>
                                                        this.props.catchError(async () => {
                                                            await this.props.changeOrg(org.id);
                                                            this.openCollapse('openAvatar');
                                                        })
                                                    }
                                                >
                                                    <NavLink
                                                        to="#"
                                                        className={cx(classes.itemLink, {
                                                            [classes.itemLinkActive]: activeOrg === org.id,
                                                        })}
                                                    >
                                                        <span className={collapseItemMini}>
                                                            <img src={org.logo} />
                                                        </span>
                                                        <ListItemText
                                                            primary={org.name}
                                                            disableTypography={true}
                                                            className={collapseItemText}
                                                        />
                                                    </NavLink>
                                                </ListItem>
                                            ))}

                                        <ListItem className={classes.collapseItem}>
                                            <NavLink to="/admin/add-organization" className={classes.itemLink}>
                                                <span className={collapseItemMini}>
                                                    <AddCircleOutlineIcon />
                                                </span>
                                                <ListItemText
                                                    primary={<FormattedMessage defaultMessage="Add Organization" />}
                                                    disableTypography={true}
                                                    className={collapseItemText}
                                                />
                                            </NavLink>
                                        </ListItem>
                                        {/* <ListItem className={classes.collapseItem}>
                                            <NavLink
                                                to="#"
                                                className={classes.itemLink}
                                                onClick={this.props.logOutHandler}
                                            >
                                                <span className={collapseItemMini}>
                                                    <ExitToAppIcon />
                                                </span>
                                                <ListItemText
                                                    primary={rtlActive ? 'إعدادات' : 'LogOut'}
                                                    disableTypography={true}
                                                    className={collapseItemText}
                                                />
                                            </NavLink>
                                        </ListItem> */}
                                    </List>
                                </Collapse>
                            </ListItem>
                        </List>
                    </>
                )}
            </div>
        );

        var links = <List className={classes.list}>{this.createLinks(routes)}</List>;

        const logoNormal =
            classes.logoNormal +
            ' ' +
            cx({
                [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
                [classes.logoNormalSidebarMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.logoNormalRTL]: rtlActive,
            });
        const logoMini =
            classes.logoMini +
            ' ' +
            cx({
                [classes.logoMiniRTL]: rtlActive,
                [classes.logoMiniSidebarMiniActive]: this.props.miniActive && this.state.miniActive,
            });
        const logoClasses =
            classes.logo +
            ' ' +
            cx({
                [classes.whiteAfter]: bgColor === 'white',
            });
        var brand = (
            <div className={logoClasses}>
                <a href="/" target="_blank" className={logoMini}>
                    <img src={logoMiniImg} alt="logo" className={classes.img} />
                </a>
                <a href="/" target="_blank" className={logoNormal}>
                    <img src={logo} alt="logo" className={classes.img} />
                </a>
            </div>
        );
        const drawerPaper =
            classes.drawerPaper +
            ' ' +
            cx({
                [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
                [classes.drawerPaperRTL]: rtlActive,
            });
        const sidebarWrapper =
            classes.sidebarWrapper +
            ' ' +
            cx({
                [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
                [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
            });
        return (
            <div ref={this.mainPanel}>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={rtlActive ? 'right' : 'left'}
                        open={this.props.open}
                        classes={{
                            paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={user}
                            // headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />} //stop rendering the header links inside the sidebar
                            links={links}
                            isAdmin={isAdmin}
                        />
                        {image !== undefined ? (
                            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
                        ) : null}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        onMouseOver={this.onSidebarMouseOver}
                        onMouseOut={this.onSidebarMouseOut}
                        anchor={rtlActive ? 'right' : 'left'}
                        variant="permanent"
                        open
                        classes={{
                            paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
                        }}
                    >
                        {brand}
                        <SidebarWrapper className={sidebarWrapper} user={user} links={links} isAdmin={isAdmin} />
                        {image !== undefined ? (
                            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
                        ) : null}
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

Sidebar.defaultProps = {
    bgColor: 'blue',
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
    logo: PropTypes.string,
    logoMini: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    miniActive: PropTypes.bool,
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    isAdmin: PropTypes.bool,
};

SidebarWrapper.propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    headerLinks: PropTypes.object,
    links: PropTypes.object,
    isAdmin: PropTypes.bool,
};

const sidebarStyle = (theme) => ({
    drawerPaperRTL: {
        // [theme.breakpoints.up('md')]: {
        left: 'auto !important',
        right: '0 !important',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     left: '0  !important',
        //     right: 'auto !important',
        // },
    },
    drawerPaper: {
        border: 'none',
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        zIndex: '1032',
        transitionProperty: 'top, bottom, width',
        transitionDuration: '.2s, .2s, .35s',
        transitionTimingFunction: 'linear, linear, ease',
        // overflow: 'auto',
        ...boxShadow,
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            position: 'fixed',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: drawerWidth,
            ...boxShadow,
            position: 'fixed',
            display: 'block',
            top: '0',
            height: '100vh',
            // right: '0',
            // left: 'auto',
            zIndex: '1032',
            visibility: 'visible',
            overflowY: 'visible',
            borderTop: 'none',
            textAlign: 'left',
            paddingRight: '0px',
            paddingLeft: '0',
            transform: `translate3d(${drawerWidth}px, 0, 0)`,
            ...transition,
        },
        '&:before,&:after': {
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            content: '""',
            display: 'block',
            top: '0',
        },
    },
    blackBackground: {
        color: whiteColor,
        '&:after': {
            background: blackColor,
            opacity: '.8',
        },
    },
    blueBackground: {
        color: whiteColor,
        '&:after': {
            background: primaryColor[0],
            opacity: '.93',
        },
    },
    whiteBackground: {
        color: grayColor[2],
        '&:after': {
            background: whiteColor,
            opacity: '.93',
        },
    },
    whiteAfter: {
        '&:after': {
            backgroundColor: 'hsla(0,0%,71%,.3) !important',
        },
    },
    drawerPaperMini: {
        width: drawerMiniWidth + 'px!important',
        overflow: 'hidden !important',
    },
    logo: {
        // padding: '15px 0px',
        margin: '0',
        display: 'block',
        position: 'relative',
        zIndex: '4',
        height: '80px',
        overflow: 'hidden',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            height: '1px',
            right: '15px',
            width: 'calc(100% - 30px)',
            backgroundColor: 'hsla(0,0%,100%,.3)',
        },
        '& a': {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
        },
    },
    logoMini: {
        transition: 'all 300ms ',
        width: '80px',
        height: '100%',
        color: 'inherit',
        padding: 15,
        transform: 'translateX(-85px)',
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
    logoMiniRTL: {
        float: 'right',
        marginRight: '30px',
        marginLeft: '26px',
    },
    logoNormal: {
        ...defaultFont,
        transition: 'all 300ms ',
        display: 'block',
        opacity: '1',
        textTransform: 'uppercase',
        padding: '10px 0px',
        fontSize: '18px',
        whiteSpace: 'nowrap',
        fontWeight: '400',
        lineHeight: '30px',
        width: '100%',
        overflow: 'hidden',
        transform: 'translateX(0px)',
        '&,&:hover,&:focus': {
            color: 'inherit',
        },
        '& img': {
            width: 'auto',
            height: '100%',
            display: 'block',
            margin: '0 auto',
        },
    },
    logoNormalRTL: {
        textAlign: 'right',
    },
    logoNormalSidebarMini: {
        // opacity: '0',
        // visibility: 'hidden',
        transform: 'translateX(265px)',
    },
    logoNormalSidebarMiniRTL: {
        transform: 'translate3d(25px, 0, 0)',
    },
    logoMiniSidebarMiniActive: {
        // opacity: 1,
        // visibility: 'visible',
        transform: 'translateX(0)',
    },
    img: {
        width: '35px',
        verticalAlign: 'middle',
        border: '0',
    },
    background: {
        position: 'absolute',
        zIndex: '1',
        height: '100%',
        width: '100%',
        display: 'block',
        top: '0',
        left: '0',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        transition: 'all 300ms linear',
    },
    list: {
        // marginTop: '15px',
        paddingLeft: '0',
        paddingTop: '0',
        paddingBottom: '0',
        marginBottom: '0',
        listStyle: 'none',
        color: 'inherit',
        '&:before,&:after': {
            display: 'table',
            content: '" "',
        },
        '&:after': {
            clear: 'both',
        },
    },
    item: {
        color: 'inherit',
        position: 'relative',
        display: 'block',
        textDecoration: 'none',
        margin: '0',
        padding: '0',
    },
    userItem: {
        '&:last-child': {
            paddingBottom: '0px',
        },
    },
    itemLink: {
        paddingLeft: '10px',
        paddingRight: '10px',
        transition: 'all 300ms linear',
        margin: '10px 15px 0',
        borderRadius: '3px',
        position: 'relative',
        display: 'block',
        padding: '10px 15px',
        backgroundColor: 'transparent',
        ...defaultFont,
        width: 'auto',
        '&:hover': {
            outline: 'none',
            backgroundColor: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
            boxShadow: 'none',
        },
        '&,&:hover,&:focus': {
            color: 'inherit',
        },
    },
    itemLinkActive: {
        color: theme.palette.primary.main,
        position: 'relative',
        '&:after': {
            content: '""',
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },
    itemIcon: {
        color: 'inherit',
        width: '30px',
        height: '24px',
        float: 'left',
        position: 'inherit',
        top: '3px',
        marginRight: '15px',
        textAlign: 'center',
        verticalAlign: 'middle',
        opacity: '0.8',
    },
    itemIconRTL: {
        float: 'right',
        marginLeft: '15px',
        marginRight: '1px',
    },
    itemText: {
        color: 'inherit',
        ...defaultFont,
        margin: '0',
        lineHeight: '30px',
        fontSize: '14px',
        transform: 'translate3d(0px, 0, 0)',
        opacity: '1',
        transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
        position: 'relative',
        display: 'block',
        height: 'auto',
        whiteSpace: 'nowrap',
        padding: '0 16px !important',
    },
    userItemText: {
        lineHeight: '22px',
    },
    itemTextRTL: {
        marginRight: '45px',
        textAlign: 'right',
    },
    itemTextMini: {
        transform: 'translate3d(-25px, 0, 0)',
        opacity: '0',
    },
    itemTextMiniRTL: {
        transform: 'translate3d(25px, 0, 0) !important',
    },
    collapseList: {
        marginTop: '0',
        '& $caret': {
            marginTop: '8px',
        },
    },
    collapseItem: {
        position: 'relative',
        display: 'block',
        textDecoration: 'none',
        margin: '10px 0 0 0',
        padding: '0',
    },

    collapseActive: {
        outline: 'none',
        backgroundColor: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
        boxShadow: 'none',
    },
    collapseItemLink: {
        transition: 'all 300ms linear',
        margin: '0 15px',
        borderRadius: '3px',
        position: 'relative',
        display: 'block',
        padding: '10px',
        backgroundColor: 'transparent',
        ...defaultFont,
        width: 'auto',
        '&:hover': {
            outline: 'none',
            backgroundColor: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
            boxShadow: 'none',
        },
        '&,&:hover,&:focus': {
            color: 'inherit',
        },
    },
    collapseItemMini: {
        color: 'inherit',
        ...defaultFont,
        textTransform: 'uppercase',
        width: '30px',
        marginRight: '15px',
        textAlign: 'center',
        letterSpacing: '1px',
        position: 'relative',
        float: 'left',
        display: 'inherit',
        transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
        fontSize: '14px',
        '& svg': {
            fontSize: '1.5em !important',
        },
        '& img': {
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            marginTop: '-10px',
        },
    },
    collapseItemMiniRTL: {
        float: 'right',
        marginLeft: '30px',
        marginRight: '1px',
    },
    collapseItemText: {
        color: 'inherit',
        ...defaultFont,
        margin: '0',
        position: 'relative',
        transform: 'translateX(0px)',
        opacity: '1',
        whiteSpace: 'nowrap',
        display: 'block',
        transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
        fontSize: '14px',
    },
    collapseItemTextRTL: {
        textAlign: 'right',
    },
    collapseItemTextMiniRTL: {
        transform: 'translate3d(25px, 0, 0) !important',
    },
    collapseItemTextMini: {
        transform: 'translate3d(-25px, 0, 0)',
        opacity: '0',
    },
    caret: {
        marginTop: '13px',
        position: 'absolute',
        right: '18px',
        transition: 'all 150ms ease-in',
        display: 'inline-block',
        width: '0',
        height: '0',
        marginLeft: '2px',
        verticalAlign: 'middle',
        borderTop: '4px solid',
        borderRight: '4px solid transparent',
        borderLeft: '4px solid transparent',
    },
    userCaret: {
        marginTop: '10px',
    },
    caretRTL: {
        left: '11px',
        right: 'auto',
    },
    caretActive: {
        transform: 'rotate(180deg)',
    },
    purple: {
        '&,&:hover,&:focus': {
            color: whiteColor,
            backgroundColor: primaryColor[0],
            ...primaryBoxShadow,
        },
    },
    blue: {
        '&,&:hover,&:focus': {
            color: whiteColor,
            backgroundColor: primaryColor[0],
            boxShadow:
                '0 12px 20px -10px rgba(' +
                hexToRgb(primaryColor[0]) +
                ',.28), 0 4px 20px 0 rgba(' +
                hexToRgb(blackColor) +
                ',.12), 0 7px 8px -5px rgba(' +
                hexToRgb(primaryColor[0]) +
                ',.2)',
        },
    },
    green: {
        '&,&:hover,&:focus': {
            color: whiteColor,
            backgroundColor: successColor[0],
            boxShadow:
                '0 12px 20px -10px rgba(' +
                hexToRgb(successColor[0]) +
                ',.28), 0 4px 20px 0 rgba(' +
                hexToRgb(blackColor) +
                ',.12), 0 7px 8px -5px rgba(' +
                hexToRgb(successColor[0]) +
                ',.2)',
        },
    },
    orange: {
        '&,&:hover,&:focus': {
            color: whiteColor,
            backgroundColor: warningColor[0],
            boxShadow:
                '0 12px 20px -10px rgba(' +
                hexToRgb(warningColor[0]) +
                ',.28), 0 4px 20px 0 rgba(' +
                hexToRgb(blackColor) +
                ',.12), 0 7px 8px -5px rgba(' +
                hexToRgb(warningColor[0]) +
                ',.2)',
        },
    },
    red: {
        '&,&:hover,&:focus': {
            color: whiteColor,
            backgroundColor: dangerColor[0],
            boxShadow:
                '0 12px 20px -10px rgba(' +
                hexToRgb(dangerColor[0]) +
                ',.28), 0 4px 20px 0 rgba(' +
                hexToRgb(blackColor) +
                ',.12), 0 7px 8px -5px rgba(' +
                hexToRgb(dangerColor[0]) +
                ',.2)',
        },
    },
    white: {
        '&,&:hover,&:focus': {
            color: grayColor[2],
            backgroundColor: whiteColor,
            boxShadow:
                '0 4px 20px 0 rgba(' +
                hexToRgb(blackColor) +
                ',.14), 0 7px 10px -5px rgba(' +
                hexToRgb(grayColor[2]) +
                ',.4)',
        },
    },
    rose: {
        '&,&:hover,&:focus': {
            color: whiteColor,
            backgroundColor: roseColor[0],
            boxShadow:
                '0 4px 20px 0 rgba(' +
                hexToRgb(blackColor) +
                ',.14), 0 7px 10px -5px rgba(' +
                hexToRgb(roseColor[0]) +
                ',.4)',
        },
    },
    sidebarWrapper: {
        position: 'relative',
        height: 'calc(100vh - 75px)',
        overflow: 'auto',
        width: '260px',
        zIndex: '4',
        overflowScrolling: 'touch',
        transitionProperty: 'top, bottom, width',
        transitionDuration: '.2s, .2s, .35s',
        transitionTimingFunction: 'linear, linear, ease',
        color: 'inherit',
        paddingBottom: '30px',
    },
    sidebarWrapperWithPerfectScrollbar: {
        overflow: 'hidden !important',
    },
    user: {
        paddingBottom: '20px',
        paddingTop: '20px',
        // margin: '20px auto 0',
        position: 'relative',
        minHeight: '54px',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            right: '15px',
            height: '1px',
            width: 'calc(100% - 30px)',
            backgroundColor: 'hsla(0,0%,100%,.3)',
        },
    },
    userLoading: {
        '&:after': {
            opacity: 0,
        },
    },
    photo: {
        transition: 'all 300ms linear',
        width: '34px',
        height: '34px',
        overflow: 'hidden',
        float: 'left',
        zIndex: '5',
        marginRight: '11px',
        borderRadius: '50%',
        marginLeft: '23px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...boxShadow,
    },
    photoRTL: {
        float: 'right',
        marginLeft: '12px',
        marginRight: '24px',
    },
    avatarImg: {
        width: '100%',
        verticalAlign: 'middle',
        border: '0',
    },
    userCollapseButton: {
        margin: '0',
        minHeight: 35,
        padding: '6px 15px',
        '&:hover': {
            background: 'none',
        },
    },
});
const mapStateToProps = (state) => {
    return {
        isAuthenticated: selectIsAuthenticated(state),
        authUser: selectAuthUser(state),
        activeOrg: selectActiveOrg(state),
        loading: selectAuthLoading(state),
    };
};
const MemorizedSidebar = memo(withCatchError(Sidebar), isEqual);
const SidebarWithRedux = connect(mapStateToProps, { changeOrg, logOutHandler })(MemorizedSidebar);

export default withStyles(sidebarStyle)(SidebarWithRedux);
