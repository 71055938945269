import React, { useState, useEffect, memo } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { CircularProgress } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Skeleton } from '@material-ui/lab';
import ImageIcon from '@material-ui/icons/Image';
import IconButton from '@material-ui/core/IconButton';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import { stateColors, grayColor } from 'assets/jss/material-dashboard-pro-react';
import { getSingleAnomaly } from 'store/slices/anomalySlice';
import { closeNotification } from 'store/slices/notificationSlice';
import useIsMounted from 'hooks/useIsMounted';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import { useAppDispatch } from 'store';
import useCatchErrorCallback from 'hooks/useCatchErrorCallback';

const AdminNotificationListItem = ({ notification, onClick }) => {
    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();
    const { label, anomaly: anomalyId } = notification;
    const [loading, setLoading] = useState(true);
    const [anomaly, setAnomaly] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const closeHandler = useCatchErrorCallback(async (e) => {
        try {
            e.stopPropagation();
            setDisabled(true);
            await dispatch(closeNotification(notification.id));
        } catch (error) {
            setDisabled(false);
            throw error;
        }
    }, []);

    const fetchData = useCatchErrorCallback(async () => {
        try {
            setLoading(true);

            const anomaly = await getSingleAnomaly(anomalyId);
            if (!isMounted()) return false;
            setAnomaly(anomaly);
        } finally {
            if (isMounted()) {
                setLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const classes = useStyles({ state: anomaly?.state });
    // Create query
    const query = anomaly ? stringify({ lat: anomaly.latLng[0], lng: anomaly.latLng[1], anomaly: anomaly.id }) : '';

    // If loading show skelton if anomaly has data show data else  show empty string
    const secondaryContent = loading ? (
        <Skeleton animation="wave" />
    ) : anomaly ? (
        <>
            {anomaly?.description}
            <br />
            <TimeAgo seconds={anomaly?.createdAt * 1000} />
        </>
    ) : (
        ''
    );
    return (
        <ListItem
            button
            className={classes.listItem}
            classes={{ container: classes.listItemContainer }}
            component={Link}
            to={`/admin/overview?${query}`}
            disabled={!anomaly}
            onClick={onClick}
        >
            <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
                {loading ? (
                    <Avatar variant="rounded" classes={{ root: classes.avatarRoot }}>
                        <Skeleton animation="wave" variant="rect" height="100%" width="100%" />
                    </Avatar>
                ) : (
                    <Avatar variant="rounded" src={anomaly?.photos[0]} classes={{ root: classes.avatarRoot }}>
                        <ImageIcon />
                    </Avatar>
                )}
            </ListItemAvatar>
            <ListItemText
                classes={{ primary: classes.primaryText }}
                primaryTypographyProps={{ noWrap: true }}
                secondaryTypographyProps={{ noWrap: true, component: 'div' }}
                primary={anomaly ? anomaly?.label : label}
                secondary={secondaryContent}
            />
            {/* Close button */}
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    disableRipple
                    size="small"
                    onClick={closeHandler}
                    disabled={disabled}
                >
                    {disabled ? <CircularProgress size="20px" /> : <CloseIcon />}
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

AdminNotificationListItem.propTypes = {
    notification: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};
const useStyles = makeStyles({
    listItemAvatarRoot: {
        minWidth: '86px',
    },
    avatarRoot: {
        width: '76px',
        height: '76px',
    },
    primaryText: {
        lineHeight: 1,
        fontWeight: 500,
        color: (props) => (props.state !== undefined ? stateColors[props.state] : 'inherit'),
    },

    markAsRead: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    listItemContainer: {
        position: 'relative',
        '&:not(:last-child)': {
            borderBottom: '1px solid ' + grayColor[5],
        },
        '&::before, &::after': {
            top: '50%',
            left: 18,
            width: 15,
            height: 15,
            content: '""',
            zIndex: 99,
            position: 'absolute',
            borderRadius: '50%',
        },
        '&::before': {
            transform: 'translateY(-50%) translateX(-50%) scale(0)',
            animation: '$notificationEffect 3000ms infinite',
            background: (props) => (props.state !== undefined ? stateColors[props.state] : 'transparent'),
        },
        '&::after': {
            transform: 'translateY(-50%) translateX(-50%)',
            border: '2px solid',
            borderColor: (props) => (props.state !== undefined ? stateColors[props.state] : 'transparent'),
        },
    },

    listItem: {
        paddingLeft: '36px',
    },

    '@keyframes notificationEffect': {
        '0%': {
            opacity: 0,
            transform: 'translateY(-50%) translateX(-50%) scale(0)',
        },
        '50%': {
            opacity: 1,
            transform: 'translateY(-50%) translateX(-50%) scale(1)',
        },
        '100%': {
            opacity: 0,
            transform: 'translateY(-50%) translateX(-50%) scale(0)',
        },
    },
});

export default memo(AdminNotificationListItem, isEqual);
