/**
 * @fileoverview gRPC-Web generated client stub for v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js')
const proto = {};
proto.v1 = require('./v1_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AdminClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AdminPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Admin_Reset = new grpc.web.MethodDescriptor(
  '/v1.Admin/Reset',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.Empty>}
 */
const methodInfo_Admin_Reset = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminClient.prototype.reset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Admin/Reset',
      request,
      metadata || {},
      methodDescriptor_Admin_Reset,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AdminPromiseClient.prototype.reset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Admin/Reset',
      request,
      metadata || {},
      methodDescriptor_Admin_Reset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.OpsLogReq,
 *   !proto.v1.OpLogList>}
 */
const methodDescriptor_Admin_OpsLog = new grpc.web.MethodDescriptor(
  '/v1.Admin/OpsLog',
  grpc.web.MethodType.UNARY,
  proto.v1.OpsLogReq,
  proto.v1.OpLogList,
  /**
   * @param {!proto.v1.OpsLogReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.OpLogList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.OpsLogReq,
 *   !proto.v1.OpLogList>}
 */
const methodInfo_Admin_OpsLog = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.OpLogList,
  /**
   * @param {!proto.v1.OpsLogReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.OpLogList.deserializeBinary
);


/**
 * @param {!proto.v1.OpsLogReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.OpLogList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.OpLogList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminClient.prototype.opsLog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Admin/OpsLog',
      request,
      metadata || {},
      methodDescriptor_Admin_OpsLog,
      callback);
};


/**
 * @param {!proto.v1.OpsLogReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.OpLogList>}
 *     Promise that resolves to the response
 */
proto.v1.AdminPromiseClient.prototype.opsLog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Admin/OpsLog',
      request,
      metadata || {},
      methodDescriptor_Admin_OpsLog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.User,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Admin_UserChangeEmail = new grpc.web.MethodDescriptor(
  '/v1.Admin/UserChangeEmail',
  grpc.web.MethodType.UNARY,
  proto.v1.User,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.User,
 *   !proto.v1.Empty>}
 */
const methodInfo_Admin_UserChangeEmail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminClient.prototype.userChangeEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Admin/UserChangeEmail',
      request,
      metadata || {},
      methodDescriptor_Admin_UserChangeEmail,
      callback);
};


/**
 * @param {!proto.v1.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AdminPromiseClient.prototype.userChangeEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Admin/UserChangeEmail',
      request,
      metadata || {},
      methodDescriptor_Admin_UserChangeEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.UserName,
 *   !proto.v1.NewPassword>}
 */
const methodDescriptor_Admin_UserResetPassword = new grpc.web.MethodDescriptor(
  '/v1.Admin/UserResetPassword',
  grpc.web.MethodType.UNARY,
  proto.v1.UserName,
  proto.v1.NewPassword,
  /**
   * @param {!proto.v1.UserName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.NewPassword.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.UserName,
 *   !proto.v1.NewPassword>}
 */
const methodInfo_Admin_UserResetPassword = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.NewPassword,
  /**
   * @param {!proto.v1.UserName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.NewPassword.deserializeBinary
);


/**
 * @param {!proto.v1.UserName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.NewPassword)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.NewPassword>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminClient.prototype.userResetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Admin/UserResetPassword',
      request,
      metadata || {},
      methodDescriptor_Admin_UserResetPassword,
      callback);
};


/**
 * @param {!proto.v1.UserName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.NewPassword>}
 *     Promise that resolves to the response
 */
proto.v1.AdminPromiseClient.prototype.userResetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Admin/UserResetPassword',
      request,
      metadata || {},
      methodDescriptor_Admin_UserResetPassword);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AuthClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AuthPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RegisterReq,
 *   !proto.v1.Authorization>}
 */
const methodDescriptor_Auth_Register = new grpc.web.MethodDescriptor(
  '/v1.Auth/Register',
  grpc.web.MethodType.UNARY,
  proto.v1.RegisterReq,
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.RegisterReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RegisterReq,
 *   !proto.v1.Authorization>}
 */
const methodInfo_Auth_Register = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.RegisterReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @param {!proto.v1.RegisterReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Authorization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Authorization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AuthClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Auth/Register',
      request,
      metadata || {},
      methodDescriptor_Auth_Register,
      callback);
};


/**
 * @param {!proto.v1.RegisterReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Authorization>}
 *     Promise that resolves to the response
 */
proto.v1.AuthPromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Auth/Register',
      request,
      metadata || {},
      methodDescriptor_Auth_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.LoginReq,
 *   !proto.v1.Authorization>}
 */
const methodDescriptor_Auth_Login = new grpc.web.MethodDescriptor(
  '/v1.Auth/Login',
  grpc.web.MethodType.UNARY,
  proto.v1.LoginReq,
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.LoginReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.LoginReq,
 *   !proto.v1.Authorization>}
 */
const methodInfo_Auth_Login = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.LoginReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @param {!proto.v1.LoginReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Authorization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Authorization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AuthClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Auth/Login',
      request,
      metadata || {},
      methodDescriptor_Auth_Login,
      callback);
};


/**
 * @param {!proto.v1.LoginReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Authorization>}
 *     Promise that resolves to the response
 */
proto.v1.AuthPromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Auth/Login',
      request,
      metadata || {},
      methodDescriptor_Auth_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.OrgLoginReq,
 *   !proto.v1.Authorization>}
 */
const methodDescriptor_Auth_OrgLogin = new grpc.web.MethodDescriptor(
  '/v1.Auth/OrgLogin',
  grpc.web.MethodType.UNARY,
  proto.v1.OrgLoginReq,
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.OrgLoginReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.OrgLoginReq,
 *   !proto.v1.Authorization>}
 */
const methodInfo_Auth_OrgLogin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.OrgLoginReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @param {!proto.v1.OrgLoginReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Authorization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Authorization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AuthClient.prototype.orgLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Auth/OrgLogin',
      request,
      metadata || {},
      methodDescriptor_Auth_OrgLogin,
      callback);
};


/**
 * @param {!proto.v1.OrgLoginReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Authorization>}
 *     Promise that resolves to the response
 */
proto.v1.AuthPromiseClient.prototype.orgLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Auth/OrgLogin',
      request,
      metadata || {},
      methodDescriptor_Auth_OrgLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.Authorization>}
 */
const methodDescriptor_Auth_PublicLogin = new grpc.web.MethodDescriptor(
  '/v1.Auth/PublicLogin',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.Authorization>}
 */
const methodInfo_Auth_PublicLogin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Authorization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Authorization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AuthClient.prototype.publicLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Auth/PublicLogin',
      request,
      metadata || {},
      methodDescriptor_Auth_PublicLogin,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Authorization>}
 *     Promise that resolves to the response
 */
proto.v1.AuthPromiseClient.prototype.publicLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Auth/PublicLogin',
      request,
      metadata || {},
      methodDescriptor_Auth_PublicLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.SetPasswordReq,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Auth_SetPassword = new grpc.web.MethodDescriptor(
  '/v1.Auth/SetPassword',
  grpc.web.MethodType.UNARY,
  proto.v1.SetPasswordReq,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.SetPasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.SetPasswordReq,
 *   !proto.v1.Empty>}
 */
const methodInfo_Auth_SetPassword = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.SetPasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.SetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AuthClient.prototype.setPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Auth/SetPassword',
      request,
      metadata || {},
      methodDescriptor_Auth_SetPassword,
      callback);
};


/**
 * @param {!proto.v1.SetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AuthPromiseClient.prototype.setPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Auth/SetPassword',
      request,
      metadata || {},
      methodDescriptor_Auth_SetPassword);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.SearchClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.SearchPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.GPSPoint,
 *   !proto.v1.ZoneList>}
 */
const methodDescriptor_Search_Zones = new grpc.web.MethodDescriptor(
  '/v1.Search/Zones',
  grpc.web.MethodType.UNARY,
  proto.v1.GPSPoint,
  proto.v1.ZoneList,
  /**
   * @param {!proto.v1.GPSPoint} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ZoneList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.GPSPoint,
 *   !proto.v1.ZoneList>}
 */
const methodInfo_Search_Zones = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.ZoneList,
  /**
   * @param {!proto.v1.GPSPoint} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ZoneList.deserializeBinary
);


/**
 * @param {!proto.v1.GPSPoint} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.ZoneList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.ZoneList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.SearchClient.prototype.zones =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Search/Zones',
      request,
      metadata || {},
      methodDescriptor_Search_Zones,
      callback);
};


/**
 * @param {!proto.v1.GPSPoint} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.ZoneList>}
 *     Promise that resolves to the response
 */
proto.v1.SearchPromiseClient.prototype.zones =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Search/Zones',
      request,
      metadata || {},
      methodDescriptor_Search_Zones);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.HandlingFilter,
 *   !proto.v1.HandlingList>}
 */
const methodDescriptor_Search_Handlings = new grpc.web.MethodDescriptor(
  '/v1.Search/Handlings',
  grpc.web.MethodType.UNARY,
  proto.v1.HandlingFilter,
  proto.v1.HandlingList,
  /**
   * @param {!proto.v1.HandlingFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.HandlingList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.HandlingFilter,
 *   !proto.v1.HandlingList>}
 */
const methodInfo_Search_Handlings = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.HandlingList,
  /**
   * @param {!proto.v1.HandlingFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.HandlingList.deserializeBinary
);


/**
 * @param {!proto.v1.HandlingFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.HandlingList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.HandlingList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.SearchClient.prototype.handlings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Search/Handlings',
      request,
      metadata || {},
      methodDescriptor_Search_Handlings,
      callback);
};


/**
 * @param {!proto.v1.HandlingFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.HandlingList>}
 *     Promise that resolves to the response
 */
proto.v1.SearchPromiseClient.prototype.handlings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Search/Handlings',
      request,
      metadata || {},
      methodDescriptor_Search_Handlings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AnomalyFilter,
 *   !proto.v1.AnomalyList>}
 */
const methodDescriptor_Search_Anomalies = new grpc.web.MethodDescriptor(
  '/v1.Search/Anomalies',
  grpc.web.MethodType.UNARY,
  proto.v1.AnomalyFilter,
  proto.v1.AnomalyList,
  /**
   * @param {!proto.v1.AnomalyFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AnomalyList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AnomalyFilter,
 *   !proto.v1.AnomalyList>}
 */
const methodInfo_Search_Anomalies = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.AnomalyList,
  /**
   * @param {!proto.v1.AnomalyFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AnomalyList.deserializeBinary
);


/**
 * @param {!proto.v1.AnomalyFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.AnomalyList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.AnomalyList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.SearchClient.prototype.anomalies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Search/Anomalies',
      request,
      metadata || {},
      methodDescriptor_Search_Anomalies,
      callback);
};


/**
 * @param {!proto.v1.AnomalyFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.AnomalyList>}
 *     Promise that resolves to the response
 */
proto.v1.SearchPromiseClient.prototype.anomalies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Search/Anomalies',
      request,
      metadata || {},
      methodDescriptor_Search_Anomalies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AnomalyFilter,
 *   !proto.v1.AnalysisStatusList>}
 */
const methodDescriptor_Search_AnalysisStatuses = new grpc.web.MethodDescriptor(
  '/v1.Search/AnalysisStatuses',
  grpc.web.MethodType.UNARY,
  proto.v1.AnomalyFilter,
  proto.v1.AnalysisStatusList,
  /**
   * @param {!proto.v1.AnomalyFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AnalysisStatusList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AnomalyFilter,
 *   !proto.v1.AnalysisStatusList>}
 */
const methodInfo_Search_AnalysisStatuses = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.AnalysisStatusList,
  /**
   * @param {!proto.v1.AnomalyFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AnalysisStatusList.deserializeBinary
);


/**
 * @param {!proto.v1.AnomalyFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.AnalysisStatusList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.AnalysisStatusList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.SearchClient.prototype.analysisStatuses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Search/AnalysisStatuses',
      request,
      metadata || {},
      methodDescriptor_Search_AnalysisStatuses,
      callback);
};


/**
 * @param {!proto.v1.AnomalyFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.AnalysisStatusList>}
 *     Promise that resolves to the response
 */
proto.v1.SearchPromiseClient.prototype.analysisStatuses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Search/AnalysisStatuses',
      request,
      metadata || {},
      methodDescriptor_Search_AnalysisStatuses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.TaskFilter,
 *   !proto.v1.Tasks>}
 */
const methodDescriptor_Search_GetTasks = new grpc.web.MethodDescriptor(
  '/v1.Search/GetTasks',
  grpc.web.MethodType.UNARY,
  proto.v1.TaskFilter,
  proto.v1.Tasks,
  /**
   * @param {!proto.v1.TaskFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Tasks.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.TaskFilter,
 *   !proto.v1.Tasks>}
 */
const methodInfo_Search_GetTasks = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Tasks,
  /**
   * @param {!proto.v1.TaskFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Tasks.deserializeBinary
);


/**
 * @param {!proto.v1.TaskFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Tasks)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Tasks>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.SearchClient.prototype.getTasks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Search/GetTasks',
      request,
      metadata || {},
      methodDescriptor_Search_GetTasks,
      callback);
};


/**
 * @param {!proto.v1.TaskFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Tasks>}
 *     Promise that resolves to the response
 */
proto.v1.SearchPromiseClient.prototype.getTasks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Search/GetTasks',
      request,
      metadata || {},
      methodDescriptor_Search_GetTasks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AnomalyFilter,
 *   !proto.v1.CSV>}
 */
const methodDescriptor_Search_AnomaliesCSV = new grpc.web.MethodDescriptor(
  '/v1.Search/AnomaliesCSV',
  grpc.web.MethodType.UNARY,
  proto.v1.AnomalyFilter,
  proto.v1.CSV,
  /**
   * @param {!proto.v1.AnomalyFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CSV.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AnomalyFilter,
 *   !proto.v1.CSV>}
 */
const methodInfo_Search_AnomaliesCSV = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.CSV,
  /**
   * @param {!proto.v1.AnomalyFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CSV.deserializeBinary
);


/**
 * @param {!proto.v1.AnomalyFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.CSV)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.CSV>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.SearchClient.prototype.anomaliesCSV =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Search/AnomaliesCSV',
      request,
      metadata || {},
      methodDescriptor_Search_AnomaliesCSV,
      callback);
};


/**
 * @param {!proto.v1.AnomalyFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.CSV>}
 *     Promise that resolves to the response
 */
proto.v1.SearchPromiseClient.prototype.anomaliesCSV =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Search/AnomaliesCSV',
      request,
      metadata || {},
      methodDescriptor_Search_AnomaliesCSV);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.CheckFilter,
 *   !proto.v1.CSV>}
 */
const methodDescriptor_Search_ChecksCSV = new grpc.web.MethodDescriptor(
  '/v1.Search/ChecksCSV',
  grpc.web.MethodType.UNARY,
  proto.v1.CheckFilter,
  proto.v1.CSV,
  /**
   * @param {!proto.v1.CheckFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CSV.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.CheckFilter,
 *   !proto.v1.CSV>}
 */
const methodInfo_Search_ChecksCSV = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.CSV,
  /**
   * @param {!proto.v1.CheckFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CSV.deserializeBinary
);


/**
 * @param {!proto.v1.CheckFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.CSV)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.CSV>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.SearchClient.prototype.checksCSV =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Search/ChecksCSV',
      request,
      metadata || {},
      methodDescriptor_Search_ChecksCSV,
      callback);
};


/**
 * @param {!proto.v1.CheckFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.CSV>}
 *     Promise that resolves to the response
 */
proto.v1.SearchPromiseClient.prototype.checksCSV =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Search/ChecksCSV',
      request,
      metadata || {},
      methodDescriptor_Search_ChecksCSV);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.RondesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.RondesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RondeRef,
 *   !proto.v1.RondeExecRef>}
 */
const methodDescriptor_Rondes_Start = new grpc.web.MethodDescriptor(
  '/v1.Rondes/Start',
  grpc.web.MethodType.UNARY,
  proto.v1.RondeRef,
  proto.v1.RondeExecRef,
  /**
   * @param {!proto.v1.RondeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RondeExecRef.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RondeRef,
 *   !proto.v1.RondeExecRef>}
 */
const methodInfo_Rondes_Start = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.RondeExecRef,
  /**
   * @param {!proto.v1.RondeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RondeExecRef.deserializeBinary
);


/**
 * @param {!proto.v1.RondeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.RondeExecRef)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.RondeExecRef>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondesClient.prototype.start =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Rondes/Start',
      request,
      metadata || {},
      methodDescriptor_Rondes_Start,
      callback);
};


/**
 * @param {!proto.v1.RondeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.RondeExecRef>}
 *     Promise that resolves to the response
 */
proto.v1.RondesPromiseClient.prototype.start =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Rondes/Start',
      request,
      metadata || {},
      methodDescriptor_Rondes_Start);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ReportCheckAnomalyReq,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Rondes_ReportCheckAnomaly = new grpc.web.MethodDescriptor(
  '/v1.Rondes/ReportCheckAnomaly',
  grpc.web.MethodType.UNARY,
  proto.v1.ReportCheckAnomalyReq,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ReportCheckAnomalyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ReportCheckAnomalyReq,
 *   !proto.v1.Empty>}
 */
const methodInfo_Rondes_ReportCheckAnomaly = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ReportCheckAnomalyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.ReportCheckAnomalyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondesClient.prototype.reportCheckAnomaly =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Rondes/ReportCheckAnomaly',
      request,
      metadata || {},
      methodDescriptor_Rondes_ReportCheckAnomaly,
      callback);
};


/**
 * @param {!proto.v1.ReportCheckAnomalyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RondesPromiseClient.prototype.reportCheckAnomaly =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Rondes/ReportCheckAnomaly',
      request,
      metadata || {},
      methodDescriptor_Rondes_ReportCheckAnomaly);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ReorderReq,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Rondes_Reorder = new grpc.web.MethodDescriptor(
  '/v1.Rondes/Reorder',
  grpc.web.MethodType.UNARY,
  proto.v1.ReorderReq,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ReorderReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ReorderReq,
 *   !proto.v1.Empty>}
 */
const methodInfo_Rondes_Reorder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ReorderReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.ReorderReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondesClient.prototype.reorder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Rondes/Reorder',
      request,
      metadata || {},
      methodDescriptor_Rondes_Reorder,
      callback);
};


/**
 * @param {!proto.v1.ReorderReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RondesPromiseClient.prototype.reorder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Rondes/Reorder',
      request,
      metadata || {},
      methodDescriptor_Rondes_Reorder);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.UploadsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.UploadsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.UploadReq,
 *   !proto.v1.Authorization>}
 */
const methodDescriptor_Uploads_Add = new grpc.web.MethodDescriptor(
  '/v1.Uploads/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.UploadReq,
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.UploadReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.UploadReq,
 *   !proto.v1.Authorization>}
 */
const methodInfo_Uploads_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Authorization,
  /**
   * @param {!proto.v1.UploadReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Authorization.deserializeBinary
);


/**
 * @param {!proto.v1.UploadReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Authorization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Authorization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.UploadsClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Uploads/Add',
      request,
      metadata || {},
      methodDescriptor_Uploads_Add,
      callback);
};


/**
 * @param {!proto.v1.UploadReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Authorization>}
 *     Promise that resolves to the response
 */
proto.v1.UploadsPromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Uploads/Add',
      request,
      metadata || {},
      methodDescriptor_Uploads_Add);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.ZonesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.ZonesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.ZoneList>}
 */
const methodDescriptor_ZonesService_List = new grpc.web.MethodDescriptor(
  '/v1.ZonesService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.ZoneList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ZoneList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.ZoneList>}
 */
const methodInfo_ZonesService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.ZoneList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ZoneList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.ZoneList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.ZoneList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ZonesServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ZonesService/List',
      request,
      metadata || {},
      methodDescriptor_ZonesService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.ZoneList>}
 *     Promise that resolves to the response
 */
proto.v1.ZonesServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ZonesService/List',
      request,
      metadata || {},
      methodDescriptor_ZonesService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ZoneRef,
 *   !proto.v1.Zone>}
 */
const methodDescriptor_ZonesService_Get = new grpc.web.MethodDescriptor(
  '/v1.ZonesService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.ZoneRef,
  proto.v1.Zone,
  /**
   * @param {!proto.v1.ZoneRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Zone.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ZoneRef,
 *   !proto.v1.Zone>}
 */
const methodInfo_ZonesService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Zone,
  /**
   * @param {!proto.v1.ZoneRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Zone.deserializeBinary
);


/**
 * @param {!proto.v1.ZoneRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Zone)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Zone>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ZonesServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ZonesService/Get',
      request,
      metadata || {},
      methodDescriptor_ZonesService_Get,
      callback);
};


/**
 * @param {!proto.v1.ZoneRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Zone>}
 *     Promise that resolves to the response
 */
proto.v1.ZonesServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ZonesService/Get',
      request,
      metadata || {},
      methodDescriptor_ZonesService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Zone,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_ZonesService_Set = new grpc.web.MethodDescriptor(
  '/v1.ZonesService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Zone,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Zone} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Zone,
 *   !proto.v1.Empty>}
 */
const methodInfo_ZonesService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Zone} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Zone} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ZonesServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ZonesService/Set',
      request,
      metadata || {},
      methodDescriptor_ZonesService_Set,
      callback);
};


/**
 * @param {!proto.v1.Zone} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.ZonesServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ZonesService/Set',
      request,
      metadata || {},
      methodDescriptor_ZonesService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Zone,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_ZonesService_Add = new grpc.web.MethodDescriptor(
  '/v1.ZonesService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Zone,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Zone} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Zone,
 *   !proto.v1.Empty>}
 */
const methodInfo_ZonesService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Zone} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Zone} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ZonesServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ZonesService/Add',
      request,
      metadata || {},
      methodDescriptor_ZonesService_Add,
      callback);
};


/**
 * @param {!proto.v1.Zone} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.ZonesServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ZonesService/Add',
      request,
      metadata || {},
      methodDescriptor_ZonesService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ZoneRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_ZonesService_Delete = new grpc.web.MethodDescriptor(
  '/v1.ZonesService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.ZoneRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ZoneRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ZoneRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_ZonesService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ZoneRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.ZoneRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ZonesServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ZonesService/Delete',
      request,
      metadata || {},
      methodDescriptor_ZonesService_Delete,
      callback);
};


/**
 * @param {!proto.v1.ZoneRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.ZonesServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ZonesService/Delete',
      request,
      metadata || {},
      methodDescriptor_ZonesService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AnomaliesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AnomaliesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.AnomalyList>}
 */
const methodDescriptor_AnomaliesService_List = new grpc.web.MethodDescriptor(
  '/v1.AnomaliesService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.AnomalyList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AnomalyList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.AnomalyList>}
 */
const methodInfo_AnomaliesService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.AnomalyList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AnomalyList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.AnomalyList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.AnomalyList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AnomaliesServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AnomaliesService/List',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.AnomalyList>}
 *     Promise that resolves to the response
 */
proto.v1.AnomaliesServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AnomaliesService/List',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AnomalyRef,
 *   !proto.v1.Anomaly>}
 */
const methodDescriptor_AnomaliesService_Get = new grpc.web.MethodDescriptor(
  '/v1.AnomaliesService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.AnomalyRef,
  proto.v1.Anomaly,
  /**
   * @param {!proto.v1.AnomalyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Anomaly.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AnomalyRef,
 *   !proto.v1.Anomaly>}
 */
const methodInfo_AnomaliesService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Anomaly,
  /**
   * @param {!proto.v1.AnomalyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Anomaly.deserializeBinary
);


/**
 * @param {!proto.v1.AnomalyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Anomaly)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Anomaly>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AnomaliesServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AnomaliesService/Get',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_Get,
      callback);
};


/**
 * @param {!proto.v1.AnomalyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Anomaly>}
 *     Promise that resolves to the response
 */
proto.v1.AnomaliesServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AnomaliesService/Get',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Anomaly,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AnomaliesService_Set = new grpc.web.MethodDescriptor(
  '/v1.AnomaliesService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Anomaly,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Anomaly} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Anomaly,
 *   !proto.v1.Empty>}
 */
const methodInfo_AnomaliesService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Anomaly} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Anomaly} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AnomaliesServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AnomaliesService/Set',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_Set,
      callback);
};


/**
 * @param {!proto.v1.Anomaly} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AnomaliesServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AnomaliesService/Set',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Anomaly,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AnomaliesService_Add = new grpc.web.MethodDescriptor(
  '/v1.AnomaliesService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Anomaly,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Anomaly} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Anomaly,
 *   !proto.v1.Empty>}
 */
const methodInfo_AnomaliesService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Anomaly} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Anomaly} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AnomaliesServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AnomaliesService/Add',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_Add,
      callback);
};


/**
 * @param {!proto.v1.Anomaly} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AnomaliesServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AnomaliesService/Add',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AnomalyRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AnomaliesService_Delete = new grpc.web.MethodDescriptor(
  '/v1.AnomaliesService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.AnomalyRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AnomalyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AnomalyRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_AnomaliesService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AnomalyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.AnomalyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AnomaliesServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AnomaliesService/Delete',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_Delete,
      callback);
};


/**
 * @param {!proto.v1.AnomalyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AnomaliesServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AnomaliesService/Delete',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AnomalyRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AnomaliesService_UpVote = new grpc.web.MethodDescriptor(
  '/v1.AnomaliesService/UpVote',
  grpc.web.MethodType.UNARY,
  proto.v1.AnomalyRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AnomalyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AnomalyRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_AnomaliesService_UpVote = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AnomalyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.AnomalyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AnomaliesServiceClient.prototype.upVote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AnomaliesService/UpVote',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_UpVote,
      callback);
};


/**
 * @param {!proto.v1.AnomalyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AnomaliesServicePromiseClient.prototype.upVote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AnomaliesService/UpVote',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_UpVote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AnomalyRefList,
 *   !proto.v1.AnomalyList>}
 */
const methodDescriptor_AnomaliesService_GetMany = new grpc.web.MethodDescriptor(
  '/v1.AnomaliesService/GetMany',
  grpc.web.MethodType.UNARY,
  proto.v1.AnomalyRefList,
  proto.v1.AnomalyList,
  /**
   * @param {!proto.v1.AnomalyRefList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AnomalyList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AnomalyRefList,
 *   !proto.v1.AnomalyList>}
 */
const methodInfo_AnomaliesService_GetMany = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.AnomalyList,
  /**
   * @param {!proto.v1.AnomalyRefList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AnomalyList.deserializeBinary
);


/**
 * @param {!proto.v1.AnomalyRefList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.AnomalyList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.AnomalyList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AnomaliesServiceClient.prototype.getMany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AnomaliesService/GetMany',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_GetMany,
      callback);
};


/**
 * @param {!proto.v1.AnomalyRefList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.AnomalyList>}
 *     Promise that resolves to the response
 */
proto.v1.AnomaliesServicePromiseClient.prototype.getMany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AnomaliesService/GetMany',
      request,
      metadata || {},
      methodDescriptor_AnomaliesService_GetMany);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.UsersServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.UsersServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.UserList>}
 */
const methodDescriptor_UsersService_List = new grpc.web.MethodDescriptor(
  '/v1.UsersService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.UserList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.UserList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.UserList>}
 */
const methodInfo_UsersService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.UserList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.UserList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.UserList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.UserList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.UsersServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.UsersService/List',
      request,
      metadata || {},
      methodDescriptor_UsersService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.UserList>}
 *     Promise that resolves to the response
 */
proto.v1.UsersServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.UsersService/List',
      request,
      metadata || {},
      methodDescriptor_UsersService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.UserRef,
 *   !proto.v1.User>}
 */
const methodDescriptor_UsersService_Get = new grpc.web.MethodDescriptor(
  '/v1.UsersService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.UserRef,
  proto.v1.User,
  /**
   * @param {!proto.v1.UserRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.UserRef,
 *   !proto.v1.User>}
 */
const methodInfo_UsersService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.User,
  /**
   * @param {!proto.v1.UserRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.User.deserializeBinary
);


/**
 * @param {!proto.v1.UserRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.UsersServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.UsersService/Get',
      request,
      metadata || {},
      methodDescriptor_UsersService_Get,
      callback);
};


/**
 * @param {!proto.v1.UserRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.User>}
 *     Promise that resolves to the response
 */
proto.v1.UsersServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.UsersService/Get',
      request,
      metadata || {},
      methodDescriptor_UsersService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.User,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_UsersService_Set = new grpc.web.MethodDescriptor(
  '/v1.UsersService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.User,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.User,
 *   !proto.v1.Empty>}
 */
const methodInfo_UsersService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.UsersServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.UsersService/Set',
      request,
      metadata || {},
      methodDescriptor_UsersService_Set,
      callback);
};


/**
 * @param {!proto.v1.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.UsersServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.UsersService/Set',
      request,
      metadata || {},
      methodDescriptor_UsersService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.User,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_UsersService_Add = new grpc.web.MethodDescriptor(
  '/v1.UsersService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.User,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.User,
 *   !proto.v1.Empty>}
 */
const methodInfo_UsersService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.UsersServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.UsersService/Add',
      request,
      metadata || {},
      methodDescriptor_UsersService_Add,
      callback);
};


/**
 * @param {!proto.v1.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.UsersServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.UsersService/Add',
      request,
      metadata || {},
      methodDescriptor_UsersService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.UserRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_UsersService_Delete = new grpc.web.MethodDescriptor(
  '/v1.UsersService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.UserRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.UserRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.UserRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_UsersService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.UserRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.UserRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.UsersServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.UsersService/Delete',
      request,
      metadata || {},
      methodDescriptor_UsersService_Delete,
      callback);
};


/**
 * @param {!proto.v1.UserRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.UsersServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.UsersService/Delete',
      request,
      metadata || {},
      methodDescriptor_UsersService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.InviteUserReq,
 *   !proto.v1.UserRef>}
 */
const methodDescriptor_UsersService_Invite = new grpc.web.MethodDescriptor(
  '/v1.UsersService/Invite',
  grpc.web.MethodType.UNARY,
  proto.v1.InviteUserReq,
  proto.v1.UserRef,
  /**
   * @param {!proto.v1.InviteUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.UserRef.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.InviteUserReq,
 *   !proto.v1.UserRef>}
 */
const methodInfo_UsersService_Invite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.UserRef,
  /**
   * @param {!proto.v1.InviteUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.UserRef.deserializeBinary
);


/**
 * @param {!proto.v1.InviteUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.UserRef)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.UserRef>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.UsersServiceClient.prototype.invite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.UsersService/Invite',
      request,
      metadata || {},
      methodDescriptor_UsersService_Invite,
      callback);
};


/**
 * @param {!proto.v1.InviteUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.UserRef>}
 *     Promise that resolves to the response
 */
proto.v1.UsersServicePromiseClient.prototype.invite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.UsersService/Invite',
      request,
      metadata || {},
      methodDescriptor_UsersService_Invite);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EquipmentMetricsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EquipmentMetricsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MetricsQuery,
 *   !proto.v1.MetricValueList>}
 */
const methodDescriptor_EquipmentMetrics_Get = new grpc.web.MethodDescriptor(
  '/v1.EquipmentMetrics/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.MetricsQuery,
  proto.v1.MetricValueList,
  /**
   * @param {!proto.v1.MetricsQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MetricValueList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MetricsQuery,
 *   !proto.v1.MetricValueList>}
 */
const methodInfo_EquipmentMetrics_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.MetricValueList,
  /**
   * @param {!proto.v1.MetricsQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MetricValueList.deserializeBinary
);


/**
 * @param {!proto.v1.MetricsQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.MetricValueList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.MetricValueList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentMetricsClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentMetrics/Get',
      request,
      metadata || {},
      methodDescriptor_EquipmentMetrics_Get,
      callback);
};


/**
 * @param {!proto.v1.MetricsQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.MetricValueList>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentMetricsPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentMetrics/Get',
      request,
      metadata || {},
      methodDescriptor_EquipmentMetrics_Get);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.UserNotifsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.UserNotifsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.NextReq,
 *   !proto.v1.NextNotifList>}
 */
const methodDescriptor_UserNotifs_Watch = new grpc.web.MethodDescriptor(
  '/v1.UserNotifs/Watch',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.v1.NextReq,
  proto.v1.NextNotifList,
  /**
   * @param {!proto.v1.NextReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.NextNotifList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.NextReq,
 *   !proto.v1.NextNotifList>}
 */
const methodInfo_UserNotifs_Watch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.NextNotifList,
  /**
   * @param {!proto.v1.NextReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.NextNotifList.deserializeBinary
);


/**
 * @param {!proto.v1.NextReq} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.v1.NextNotifList>}
 *     The XHR Node Readable Stream
 */
proto.v1.UserNotifsClient.prototype.watch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/v1.UserNotifs/Watch',
      request,
      metadata || {},
      methodDescriptor_UserNotifs_Watch);
};


/**
 * @param {!proto.v1.NextReq} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.v1.NextNotifList>}
 *     The XHR Node Readable Stream
 */
proto.v1.UserNotifsPromiseClient.prototype.watch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/v1.UserNotifs/Watch',
      request,
      metadata || {},
      methodDescriptor_UserNotifs_Watch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.NextReq,
 *   !proto.v1.NextNotifList>}
 */
const methodDescriptor_UserNotifs_Next = new grpc.web.MethodDescriptor(
  '/v1.UserNotifs/Next',
  grpc.web.MethodType.UNARY,
  proto.v1.NextReq,
  proto.v1.NextNotifList,
  /**
   * @param {!proto.v1.NextReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.NextNotifList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.NextReq,
 *   !proto.v1.NextNotifList>}
 */
const methodInfo_UserNotifs_Next = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.NextNotifList,
  /**
   * @param {!proto.v1.NextReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.NextNotifList.deserializeBinary
);


/**
 * @param {!proto.v1.NextReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.NextNotifList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.NextNotifList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.UserNotifsClient.prototype.next =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.UserNotifs/Next',
      request,
      metadata || {},
      methodDescriptor_UserNotifs_Next,
      callback);
};


/**
 * @param {!proto.v1.NextReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.NextNotifList>}
 *     Promise that resolves to the response
 */
proto.v1.UserNotifsPromiseClient.prototype.next =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.UserNotifs/Next',
      request,
      metadata || {},
      methodDescriptor_UserNotifs_Next);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.NotifsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.NotifsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.NotifList>}
 */
const methodDescriptor_NotifsService_List = new grpc.web.MethodDescriptor(
  '/v1.NotifsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.NotifList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.NotifList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.NotifList>}
 */
const methodInfo_NotifsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.NotifList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.NotifList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.NotifList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.NotifList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.NotifsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.NotifsService/List',
      request,
      metadata || {},
      methodDescriptor_NotifsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.NotifList>}
 *     Promise that resolves to the response
 */
proto.v1.NotifsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.NotifsService/List',
      request,
      metadata || {},
      methodDescriptor_NotifsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.NotifRef,
 *   !proto.v1.Notif>}
 */
const methodDescriptor_NotifsService_Get = new grpc.web.MethodDescriptor(
  '/v1.NotifsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.NotifRef,
  proto.v1.Notif,
  /**
   * @param {!proto.v1.NotifRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Notif.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.NotifRef,
 *   !proto.v1.Notif>}
 */
const methodInfo_NotifsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Notif,
  /**
   * @param {!proto.v1.NotifRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Notif.deserializeBinary
);


/**
 * @param {!proto.v1.NotifRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Notif)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Notif>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.NotifsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.NotifsService/Get',
      request,
      metadata || {},
      methodDescriptor_NotifsService_Get,
      callback);
};


/**
 * @param {!proto.v1.NotifRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Notif>}
 *     Promise that resolves to the response
 */
proto.v1.NotifsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.NotifsService/Get',
      request,
      metadata || {},
      methodDescriptor_NotifsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Notif,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_NotifsService_Add = new grpc.web.MethodDescriptor(
  '/v1.NotifsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Notif,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Notif} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Notif,
 *   !proto.v1.Empty>}
 */
const methodInfo_NotifsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Notif} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Notif} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.NotifsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.NotifsService/Add',
      request,
      metadata || {},
      methodDescriptor_NotifsService_Add,
      callback);
};


/**
 * @param {!proto.v1.Notif} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.NotifsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.NotifsService/Add',
      request,
      metadata || {},
      methodDescriptor_NotifsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.NotifRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_NotifsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.NotifsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.NotifRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.NotifRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.NotifRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_NotifsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.NotifRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.NotifRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.NotifsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.NotifsService/Delete',
      request,
      metadata || {},
      methodDescriptor_NotifsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.NotifRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.NotifsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.NotifsService/Delete',
      request,
      metadata || {},
      methodDescriptor_NotifsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EmergenciesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EmergenciesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.GPSPoint,
 *   !proto.v1.EmergencySummaryList>}
 */
const methodDescriptor_Emergencies_AroundMe = new grpc.web.MethodDescriptor(
  '/v1.Emergencies/AroundMe',
  grpc.web.MethodType.UNARY,
  proto.v1.GPSPoint,
  proto.v1.EmergencySummaryList,
  /**
   * @param {!proto.v1.GPSPoint} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EmergencySummaryList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.GPSPoint,
 *   !proto.v1.EmergencySummaryList>}
 */
const methodInfo_Emergencies_AroundMe = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.EmergencySummaryList,
  /**
   * @param {!proto.v1.GPSPoint} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EmergencySummaryList.deserializeBinary
);


/**
 * @param {!proto.v1.GPSPoint} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.EmergencySummaryList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.EmergencySummaryList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EmergenciesClient.prototype.aroundMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Emergencies/AroundMe',
      request,
      metadata || {},
      methodDescriptor_Emergencies_AroundMe,
      callback);
};


/**
 * @param {!proto.v1.GPSPoint} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.EmergencySummaryList>}
 *     Promise that resolves to the response
 */
proto.v1.EmergenciesPromiseClient.prototype.aroundMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Emergencies/AroundMe',
      request,
      metadata || {},
      methodDescriptor_Emergencies_AroundMe);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.MapWatchClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.MapWatchPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.NextReq,
 *   !proto.v1.MapElementChange>}
 */
const methodDescriptor_MapWatch_WatchElements = new grpc.web.MethodDescriptor(
  '/v1.MapWatch/WatchElements',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.v1.NextReq,
  proto.v1.MapElementChange,
  /**
   * @param {!proto.v1.NextReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapElementChange.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.NextReq,
 *   !proto.v1.MapElementChange>}
 */
const methodInfo_MapWatch_WatchElements = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.MapElementChange,
  /**
   * @param {!proto.v1.NextReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapElementChange.deserializeBinary
);


/**
 * @param {!proto.v1.NextReq} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.v1.MapElementChange>}
 *     The XHR Node Readable Stream
 */
proto.v1.MapWatchClient.prototype.watchElements =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/v1.MapWatch/WatchElements',
      request,
      metadata || {},
      methodDescriptor_MapWatch_WatchElements);
};


/**
 * @param {!proto.v1.NextReq} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.v1.MapElementChange>}
 *     The XHR Node Readable Stream
 */
proto.v1.MapWatchPromiseClient.prototype.watchElements =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/v1.MapWatch/WatchElements',
      request,
      metadata || {},
      methodDescriptor_MapWatch_WatchElements);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.TrackMeClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.TrackMePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapElementRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TrackMe_Start = new grpc.web.MethodDescriptor(
  '/v1.TrackMe/Start',
  grpc.web.MethodType.UNARY,
  proto.v1.MapElementRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapElementRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapElementRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_TrackMe_Start = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapElementRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.MapElementRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TrackMeClient.prototype.start =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TrackMe/Start',
      request,
      metadata || {},
      methodDescriptor_TrackMe_Start,
      callback);
};


/**
 * @param {!proto.v1.MapElementRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TrackMePromiseClient.prototype.start =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TrackMe/Start',
      request,
      metadata || {},
      methodDescriptor_TrackMe_Start);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.GPSPoint,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TrackMe_SetPosition = new grpc.web.MethodDescriptor(
  '/v1.TrackMe/SetPosition',
  grpc.web.MethodType.UNARY,
  proto.v1.GPSPoint,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.GPSPoint} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.GPSPoint,
 *   !proto.v1.Empty>}
 */
const methodInfo_TrackMe_SetPosition = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.GPSPoint} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.GPSPoint} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TrackMeClient.prototype.setPosition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TrackMe/SetPosition',
      request,
      metadata || {},
      methodDescriptor_TrackMe_SetPosition,
      callback);
};


/**
 * @param {!proto.v1.GPSPoint} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TrackMePromiseClient.prototype.setPosition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TrackMe/SetPosition',
      request,
      metadata || {},
      methodDescriptor_TrackMe_SetPosition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapIconRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TrackMe_SetIcon = new grpc.web.MethodDescriptor(
  '/v1.TrackMe/SetIcon',
  grpc.web.MethodType.UNARY,
  proto.v1.MapIconRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapIconRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapIconRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_TrackMe_SetIcon = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapIconRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.MapIconRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TrackMeClient.prototype.setIcon =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TrackMe/SetIcon',
      request,
      metadata || {},
      methodDescriptor_TrackMe_SetIcon,
      callback);
};


/**
 * @param {!proto.v1.MapIconRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TrackMePromiseClient.prototype.setIcon =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TrackMe/SetIcon',
      request,
      metadata || {},
      methodDescriptor_TrackMe_SetIcon);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TrackMe_Stop = new grpc.web.MethodDescriptor(
  '/v1.TrackMe/Stop',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.Empty>}
 */
const methodInfo_TrackMe_Stop = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TrackMeClient.prototype.stop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TrackMe/Stop',
      request,
      metadata || {},
      methodDescriptor_TrackMe_Stop,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TrackMePromiseClient.prototype.stop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TrackMe/Stop',
      request,
      metadata || {},
      methodDescriptor_TrackMe_Stop);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.MiscClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.MiscPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.DashboardReq,
 *   !proto.v1.Dashboard>}
 */
const methodDescriptor_Misc_GetDashboard = new grpc.web.MethodDescriptor(
  '/v1.Misc/GetDashboard',
  grpc.web.MethodType.UNARY,
  proto.v1.DashboardReq,
  proto.v1.Dashboard,
  /**
   * @param {!proto.v1.DashboardReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Dashboard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.DashboardReq,
 *   !proto.v1.Dashboard>}
 */
const methodInfo_Misc_GetDashboard = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Dashboard,
  /**
   * @param {!proto.v1.DashboardReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Dashboard.deserializeBinary
);


/**
 * @param {!proto.v1.DashboardReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Dashboard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Dashboard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MiscClient.prototype.getDashboard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Misc/GetDashboard',
      request,
      metadata || {},
      methodDescriptor_Misc_GetDashboard,
      callback);
};


/**
 * @param {!proto.v1.DashboardReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Dashboard>}
 *     Promise that resolves to the response
 */
proto.v1.MiscPromiseClient.prototype.getDashboard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Misc/GetDashboard',
      request,
      metadata || {},
      methodDescriptor_Misc_GetDashboard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.TodoList>}
 */
const methodDescriptor_Misc_CurrentTodoList = new grpc.web.MethodDescriptor(
  '/v1.Misc/CurrentTodoList',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.TodoList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.TodoList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.TodoList>}
 */
const methodInfo_Misc_CurrentTodoList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.TodoList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.TodoList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.TodoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.TodoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MiscClient.prototype.currentTodoList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Misc/CurrentTodoList',
      request,
      metadata || {},
      methodDescriptor_Misc_CurrentTodoList,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.TodoList>}
 *     Promise that resolves to the response
 */
proto.v1.MiscPromiseClient.prototype.currentTodoList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Misc/CurrentTodoList',
      request,
      metadata || {},
      methodDescriptor_Misc_CurrentTodoList);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.GroupsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.GroupsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.GroupList>}
 */
const methodDescriptor_Groups_List = new grpc.web.MethodDescriptor(
  '/v1.Groups/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.GroupList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.GroupList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.GroupList>}
 */
const methodInfo_Groups_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.GroupList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.GroupList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.GroupList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.GroupList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.GroupsClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Groups/List',
      request,
      metadata || {},
      methodDescriptor_Groups_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.GroupList>}
 *     Promise that resolves to the response
 */
proto.v1.GroupsPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Groups/List',
      request,
      metadata || {},
      methodDescriptor_Groups_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.GroupRef,
 *   !proto.v1.Group>}
 */
const methodDescriptor_Groups_Get = new grpc.web.MethodDescriptor(
  '/v1.Groups/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.GroupRef,
  proto.v1.Group,
  /**
   * @param {!proto.v1.GroupRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Group.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.GroupRef,
 *   !proto.v1.Group>}
 */
const methodInfo_Groups_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Group,
  /**
   * @param {!proto.v1.GroupRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Group.deserializeBinary
);


/**
 * @param {!proto.v1.GroupRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Group)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Group>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.GroupsClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Groups/Get',
      request,
      metadata || {},
      methodDescriptor_Groups_Get,
      callback);
};


/**
 * @param {!proto.v1.GroupRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Group>}
 *     Promise that resolves to the response
 */
proto.v1.GroupsPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Groups/Get',
      request,
      metadata || {},
      methodDescriptor_Groups_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Group,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Groups_Set = new grpc.web.MethodDescriptor(
  '/v1.Groups/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Group,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Group} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Group,
 *   !proto.v1.Empty>}
 */
const methodInfo_Groups_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Group} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Group} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.GroupsClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Groups/Set',
      request,
      metadata || {},
      methodDescriptor_Groups_Set,
      callback);
};


/**
 * @param {!proto.v1.Group} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.GroupsPromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Groups/Set',
      request,
      metadata || {},
      methodDescriptor_Groups_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Group,
 *   !proto.v1.GroupRef>}
 */
const methodDescriptor_Groups_Add = new grpc.web.MethodDescriptor(
  '/v1.Groups/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Group,
  proto.v1.GroupRef,
  /**
   * @param {!proto.v1.Group} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.GroupRef.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Group,
 *   !proto.v1.GroupRef>}
 */
const methodInfo_Groups_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.GroupRef,
  /**
   * @param {!proto.v1.Group} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.GroupRef.deserializeBinary
);


/**
 * @param {!proto.v1.Group} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.GroupRef)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.GroupRef>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.GroupsClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Groups/Add',
      request,
      metadata || {},
      methodDescriptor_Groups_Add,
      callback);
};


/**
 * @param {!proto.v1.Group} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.GroupRef>}
 *     Promise that resolves to the response
 */
proto.v1.GroupsPromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Groups/Add',
      request,
      metadata || {},
      methodDescriptor_Groups_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.GroupRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Groups_Delete = new grpc.web.MethodDescriptor(
  '/v1.Groups/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.GroupRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.GroupRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.GroupRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_Groups_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.GroupRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.GroupRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.GroupsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Groups/Delete',
      request,
      metadata || {},
      methodDescriptor_Groups_Delete,
      callback);
};


/**
 * @param {!proto.v1.GroupRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.GroupsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Groups/Delete',
      request,
      metadata || {},
      methodDescriptor_Groups_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.ChatClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.ChatPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ChatReq,
 *   !proto.v1.ChatMessage>}
 */
const methodDescriptor_Chat_Stream = new grpc.web.MethodDescriptor(
  '/v1.Chat/Stream',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.v1.ChatReq,
  proto.v1.ChatMessage,
  /**
   * @param {!proto.v1.ChatReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ChatMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ChatReq,
 *   !proto.v1.ChatMessage>}
 */
const methodInfo_Chat_Stream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.ChatMessage,
  /**
   * @param {!proto.v1.ChatReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ChatMessage.deserializeBinary
);


/**
 * @param {!proto.v1.ChatReq} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.v1.ChatMessage>}
 *     The XHR Node Readable Stream
 */
proto.v1.ChatClient.prototype.stream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/v1.Chat/Stream',
      request,
      metadata || {},
      methodDescriptor_Chat_Stream);
};


/**
 * @param {!proto.v1.ChatReq} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.v1.ChatMessage>}
 *     The XHR Node Readable Stream
 */
proto.v1.ChatPromiseClient.prototype.stream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/v1.Chat/Stream',
      request,
      metadata || {},
      methodDescriptor_Chat_Stream);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ChatReq,
 *   !proto.v1.ChatList>}
 */
const methodDescriptor_Chat_List = new grpc.web.MethodDescriptor(
  '/v1.Chat/List',
  grpc.web.MethodType.UNARY,
  proto.v1.ChatReq,
  proto.v1.ChatList,
  /**
   * @param {!proto.v1.ChatReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ChatList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ChatReq,
 *   !proto.v1.ChatList>}
 */
const methodInfo_Chat_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.ChatList,
  /**
   * @param {!proto.v1.ChatReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ChatList.deserializeBinary
);


/**
 * @param {!proto.v1.ChatReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.ChatList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.ChatList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ChatClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Chat/List',
      request,
      metadata || {},
      methodDescriptor_Chat_List,
      callback);
};


/**
 * @param {!proto.v1.ChatReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.ChatList>}
 *     Promise that resolves to the response
 */
proto.v1.ChatPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Chat/List',
      request,
      metadata || {},
      methodDescriptor_Chat_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ChatAddReq,
 *   !proto.v1.ChatMessageRef>}
 */
const methodDescriptor_Chat_Add = new grpc.web.MethodDescriptor(
  '/v1.Chat/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.ChatAddReq,
  proto.v1.ChatMessageRef,
  /**
   * @param {!proto.v1.ChatAddReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ChatMessageRef.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ChatAddReq,
 *   !proto.v1.ChatMessageRef>}
 */
const methodInfo_Chat_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.ChatMessageRef,
  /**
   * @param {!proto.v1.ChatAddReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ChatMessageRef.deserializeBinary
);


/**
 * @param {!proto.v1.ChatAddReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.ChatMessageRef)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.ChatMessageRef>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ChatClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Chat/Add',
      request,
      metadata || {},
      methodDescriptor_Chat_Add,
      callback);
};


/**
 * @param {!proto.v1.ChatAddReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.ChatMessageRef>}
 *     Promise that resolves to the response
 */
proto.v1.ChatPromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Chat/Add',
      request,
      metadata || {},
      methodDescriptor_Chat_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ChatEditReq,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Chat_Edit = new grpc.web.MethodDescriptor(
  '/v1.Chat/Edit',
  grpc.web.MethodType.UNARY,
  proto.v1.ChatEditReq,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ChatEditReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ChatEditReq,
 *   !proto.v1.Empty>}
 */
const methodInfo_Chat_Edit = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ChatEditReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.ChatEditReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ChatClient.prototype.edit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Chat/Edit',
      request,
      metadata || {},
      methodDescriptor_Chat_Edit,
      callback);
};


/**
 * @param {!proto.v1.ChatEditReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.ChatPromiseClient.prototype.edit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Chat/Edit',
      request,
      metadata || {},
      methodDescriptor_Chat_Edit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ChatMessageRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_Chat_Delete = new grpc.web.MethodDescriptor(
  '/v1.Chat/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.ChatMessageRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ChatMessageRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ChatMessageRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_Chat_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ChatMessageRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.ChatMessageRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ChatClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.Chat/Delete',
      request,
      metadata || {},
      methodDescriptor_Chat_Delete,
      callback);
};


/**
 * @param {!proto.v1.ChatMessageRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.ChatPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.Chat/Delete',
      request,
      metadata || {},
      methodDescriptor_Chat_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.WhiteLabelClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.WhiteLabelPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AnonymousAnomaly,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_WhiteLabel_AddAnonymousAnomaly = new grpc.web.MethodDescriptor(
  '/v1.WhiteLabel/AddAnonymousAnomaly',
  grpc.web.MethodType.UNARY,
  proto.v1.AnonymousAnomaly,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AnonymousAnomaly} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AnonymousAnomaly,
 *   !proto.v1.Empty>}
 */
const methodInfo_WhiteLabel_AddAnonymousAnomaly = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AnonymousAnomaly} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.AnonymousAnomaly} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.WhiteLabelClient.prototype.addAnonymousAnomaly =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.WhiteLabel/AddAnonymousAnomaly',
      request,
      metadata || {},
      methodDescriptor_WhiteLabel_AddAnonymousAnomaly,
      callback);
};


/**
 * @param {!proto.v1.AnonymousAnomaly} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.WhiteLabelPromiseClient.prototype.addAnonymousAnomaly =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.WhiteLabel/AddAnonymousAnomaly',
      request,
      metadata || {},
      methodDescriptor_WhiteLabel_AddAnonymousAnomaly);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AdminOrgsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AdminOrgsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.AdminOrgList>}
 */
const methodDescriptor_AdminOrgsService_List = new grpc.web.MethodDescriptor(
  '/v1.AdminOrgsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.AdminOrgList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AdminOrgList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.AdminOrgList>}
 */
const methodInfo_AdminOrgsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.AdminOrgList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AdminOrgList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.AdminOrgList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.AdminOrgList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminOrgsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AdminOrgsService/List',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.AdminOrgList>}
 *     Promise that resolves to the response
 */
proto.v1.AdminOrgsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AdminOrgsService/List',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AdminOrgRef,
 *   !proto.v1.AdminOrg>}
 */
const methodDescriptor_AdminOrgsService_Get = new grpc.web.MethodDescriptor(
  '/v1.AdminOrgsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.AdminOrgRef,
  proto.v1.AdminOrg,
  /**
   * @param {!proto.v1.AdminOrgRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AdminOrg.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AdminOrgRef,
 *   !proto.v1.AdminOrg>}
 */
const methodInfo_AdminOrgsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.AdminOrg,
  /**
   * @param {!proto.v1.AdminOrgRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AdminOrg.deserializeBinary
);


/**
 * @param {!proto.v1.AdminOrgRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.AdminOrg)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.AdminOrg>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminOrgsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AdminOrgsService/Get',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_Get,
      callback);
};


/**
 * @param {!proto.v1.AdminOrgRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.AdminOrg>}
 *     Promise that resolves to the response
 */
proto.v1.AdminOrgsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AdminOrgsService/Get',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AdminOrg,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AdminOrgsService_Set = new grpc.web.MethodDescriptor(
  '/v1.AdminOrgsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.AdminOrg,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AdminOrg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AdminOrg,
 *   !proto.v1.Empty>}
 */
const methodInfo_AdminOrgsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AdminOrg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.AdminOrg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminOrgsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AdminOrgsService/Set',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_Set,
      callback);
};


/**
 * @param {!proto.v1.AdminOrg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AdminOrgsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AdminOrgsService/Set',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AdminOrg,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AdminOrgsService_Add = new grpc.web.MethodDescriptor(
  '/v1.AdminOrgsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.AdminOrg,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AdminOrg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AdminOrg,
 *   !proto.v1.Empty>}
 */
const methodInfo_AdminOrgsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AdminOrg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.AdminOrg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminOrgsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AdminOrgsService/Add',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_Add,
      callback);
};


/**
 * @param {!proto.v1.AdminOrg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AdminOrgsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AdminOrgsService/Add',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AdminOrgRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AdminOrgsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.AdminOrgsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.AdminOrgRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AdminOrgRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AdminOrgRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_AdminOrgsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AdminOrgRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.AdminOrgRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AdminOrgsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AdminOrgsService/Delete',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.AdminOrgRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AdminOrgsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AdminOrgsService/Delete',
      request,
      metadata || {},
      methodDescriptor_AdminOrgsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.OrgUsersServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.OrgUsersServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.OrgUserList>}
 */
const methodDescriptor_OrgUsersService_List = new grpc.web.MethodDescriptor(
  '/v1.OrgUsersService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.OrgUserList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.OrgUserList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.OrgUserList>}
 */
const methodInfo_OrgUsersService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.OrgUserList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.OrgUserList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.OrgUserList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.OrgUserList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgUsersServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgUsersService/List',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.OrgUserList>}
 *     Promise that resolves to the response
 */
proto.v1.OrgUsersServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgUsersService/List',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.OrgUserRef,
 *   !proto.v1.OrgUser>}
 */
const methodDescriptor_OrgUsersService_Get = new grpc.web.MethodDescriptor(
  '/v1.OrgUsersService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.OrgUserRef,
  proto.v1.OrgUser,
  /**
   * @param {!proto.v1.OrgUserRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.OrgUser.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.OrgUserRef,
 *   !proto.v1.OrgUser>}
 */
const methodInfo_OrgUsersService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.OrgUser,
  /**
   * @param {!proto.v1.OrgUserRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.OrgUser.deserializeBinary
);


/**
 * @param {!proto.v1.OrgUserRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.OrgUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.OrgUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgUsersServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgUsersService/Get',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_Get,
      callback);
};


/**
 * @param {!proto.v1.OrgUserRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.OrgUser>}
 *     Promise that resolves to the response
 */
proto.v1.OrgUsersServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgUsersService/Get',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.OrgUser,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_OrgUsersService_Set = new grpc.web.MethodDescriptor(
  '/v1.OrgUsersService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.OrgUser,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.OrgUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.OrgUser,
 *   !proto.v1.Empty>}
 */
const methodInfo_OrgUsersService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.OrgUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.OrgUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgUsersServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgUsersService/Set',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_Set,
      callback);
};


/**
 * @param {!proto.v1.OrgUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.OrgUsersServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgUsersService/Set',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.OrgUser,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_OrgUsersService_Add = new grpc.web.MethodDescriptor(
  '/v1.OrgUsersService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.OrgUser,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.OrgUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.OrgUser,
 *   !proto.v1.Empty>}
 */
const methodInfo_OrgUsersService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.OrgUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.OrgUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgUsersServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgUsersService/Add',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_Add,
      callback);
};


/**
 * @param {!proto.v1.OrgUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.OrgUsersServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgUsersService/Add',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.OrgUserRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_OrgUsersService_Delete = new grpc.web.MethodDescriptor(
  '/v1.OrgUsersService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.OrgUserRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.OrgUserRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.OrgUserRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_OrgUsersService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.OrgUserRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.OrgUserRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgUsersServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgUsersService/Delete',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_Delete,
      callback);
};


/**
 * @param {!proto.v1.OrgUserRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.OrgUsersServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgUsersService/Delete',
      request,
      metadata || {},
      methodDescriptor_OrgUsersService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.OrgsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.OrgsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.OrgList>}
 */
const methodDescriptor_OrgsService_List = new grpc.web.MethodDescriptor(
  '/v1.OrgsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.OrgList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.OrgList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.OrgList>}
 */
const methodInfo_OrgsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.OrgList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.OrgList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.OrgList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.OrgList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgsService/List',
      request,
      metadata || {},
      methodDescriptor_OrgsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.OrgList>}
 *     Promise that resolves to the response
 */
proto.v1.OrgsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgsService/List',
      request,
      metadata || {},
      methodDescriptor_OrgsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.OrgRef,
 *   !proto.v1.Org>}
 */
const methodDescriptor_OrgsService_Get = new grpc.web.MethodDescriptor(
  '/v1.OrgsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.OrgRef,
  proto.v1.Org,
  /**
   * @param {!proto.v1.OrgRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Org.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.OrgRef,
 *   !proto.v1.Org>}
 */
const methodInfo_OrgsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Org,
  /**
   * @param {!proto.v1.OrgRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Org.deserializeBinary
);


/**
 * @param {!proto.v1.OrgRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Org)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Org>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgsService/Get',
      request,
      metadata || {},
      methodDescriptor_OrgsService_Get,
      callback);
};


/**
 * @param {!proto.v1.OrgRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Org>}
 *     Promise that resolves to the response
 */
proto.v1.OrgsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgsService/Get',
      request,
      metadata || {},
      methodDescriptor_OrgsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Org,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_OrgsService_Set = new grpc.web.MethodDescriptor(
  '/v1.OrgsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Org,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Org} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Org,
 *   !proto.v1.Empty>}
 */
const methodInfo_OrgsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Org} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Org} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgsService/Set',
      request,
      metadata || {},
      methodDescriptor_OrgsService_Set,
      callback);
};


/**
 * @param {!proto.v1.Org} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.OrgsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgsService/Set',
      request,
      metadata || {},
      methodDescriptor_OrgsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Org,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_OrgsService_Add = new grpc.web.MethodDescriptor(
  '/v1.OrgsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Org,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Org} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Org,
 *   !proto.v1.Empty>}
 */
const methodInfo_OrgsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Org} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Org} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgsService/Add',
      request,
      metadata || {},
      methodDescriptor_OrgsService_Add,
      callback);
};


/**
 * @param {!proto.v1.Org} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.OrgsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgsService/Add',
      request,
      metadata || {},
      methodDescriptor_OrgsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.OrgRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_OrgsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.OrgsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.OrgRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.OrgRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.OrgRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_OrgsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.OrgRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.OrgRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.OrgsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.OrgsService/Delete',
      request,
      metadata || {},
      methodDescriptor_OrgsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.OrgRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.OrgsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.OrgsService/Delete',
      request,
      metadata || {},
      methodDescriptor_OrgsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.HandlingsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.HandlingsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.HandlingList>}
 */
const methodDescriptor_HandlingsService_List = new grpc.web.MethodDescriptor(
  '/v1.HandlingsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.HandlingList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.HandlingList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.HandlingList>}
 */
const methodInfo_HandlingsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.HandlingList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.HandlingList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.HandlingList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.HandlingList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.HandlingsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.HandlingsService/List',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.HandlingList>}
 *     Promise that resolves to the response
 */
proto.v1.HandlingsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.HandlingsService/List',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.HandlingRef,
 *   !proto.v1.Handling>}
 */
const methodDescriptor_HandlingsService_Get = new grpc.web.MethodDescriptor(
  '/v1.HandlingsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.HandlingRef,
  proto.v1.Handling,
  /**
   * @param {!proto.v1.HandlingRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Handling.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.HandlingRef,
 *   !proto.v1.Handling>}
 */
const methodInfo_HandlingsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Handling,
  /**
   * @param {!proto.v1.HandlingRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Handling.deserializeBinary
);


/**
 * @param {!proto.v1.HandlingRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Handling)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Handling>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.HandlingsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.HandlingsService/Get',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_Get,
      callback);
};


/**
 * @param {!proto.v1.HandlingRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Handling>}
 *     Promise that resolves to the response
 */
proto.v1.HandlingsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.HandlingsService/Get',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Handling,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_HandlingsService_Set = new grpc.web.MethodDescriptor(
  '/v1.HandlingsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Handling,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Handling} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Handling,
 *   !proto.v1.Empty>}
 */
const methodInfo_HandlingsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Handling} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Handling} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.HandlingsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.HandlingsService/Set',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_Set,
      callback);
};


/**
 * @param {!proto.v1.Handling} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.HandlingsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.HandlingsService/Set',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Handling,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_HandlingsService_Add = new grpc.web.MethodDescriptor(
  '/v1.HandlingsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Handling,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Handling} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Handling,
 *   !proto.v1.Empty>}
 */
const methodInfo_HandlingsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Handling} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Handling} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.HandlingsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.HandlingsService/Add',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_Add,
      callback);
};


/**
 * @param {!proto.v1.Handling} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.HandlingsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.HandlingsService/Add',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.HandlingRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_HandlingsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.HandlingsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.HandlingRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.HandlingRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.HandlingRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_HandlingsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.HandlingRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.HandlingRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.HandlingsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.HandlingsService/Delete',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.HandlingRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.HandlingsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.HandlingsService/Delete',
      request,
      metadata || {},
      methodDescriptor_HandlingsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.CategoriesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.CategoriesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.CategoryList>}
 */
const methodDescriptor_CategoriesService_List = new grpc.web.MethodDescriptor(
  '/v1.CategoriesService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.CategoryList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CategoryList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.CategoryList>}
 */
const methodInfo_CategoriesService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.CategoryList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CategoryList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.CategoryList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.CategoryList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CategoriesServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CategoriesService/List',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.CategoryList>}
 *     Promise that resolves to the response
 */
proto.v1.CategoriesServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CategoriesService/List',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.CategoryRef,
 *   !proto.v1.Category>}
 */
const methodDescriptor_CategoriesService_Get = new grpc.web.MethodDescriptor(
  '/v1.CategoriesService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.CategoryRef,
  proto.v1.Category,
  /**
   * @param {!proto.v1.CategoryRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Category.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.CategoryRef,
 *   !proto.v1.Category>}
 */
const methodInfo_CategoriesService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Category,
  /**
   * @param {!proto.v1.CategoryRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Category.deserializeBinary
);


/**
 * @param {!proto.v1.CategoryRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Category)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Category>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CategoriesServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CategoriesService/Get',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_Get,
      callback);
};


/**
 * @param {!proto.v1.CategoryRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Category>}
 *     Promise that resolves to the response
 */
proto.v1.CategoriesServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CategoriesService/Get',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Category,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_CategoriesService_Set = new grpc.web.MethodDescriptor(
  '/v1.CategoriesService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Category,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Category} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Category,
 *   !proto.v1.Empty>}
 */
const methodInfo_CategoriesService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Category} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Category} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CategoriesServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CategoriesService/Set',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_Set,
      callback);
};


/**
 * @param {!proto.v1.Category} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.CategoriesServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CategoriesService/Set',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Category,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_CategoriesService_Add = new grpc.web.MethodDescriptor(
  '/v1.CategoriesService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Category,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Category} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Category,
 *   !proto.v1.Empty>}
 */
const methodInfo_CategoriesService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Category} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Category} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CategoriesServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CategoriesService/Add',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_Add,
      callback);
};


/**
 * @param {!proto.v1.Category} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.CategoriesServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CategoriesService/Add',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.CategoryRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_CategoriesService_Delete = new grpc.web.MethodDescriptor(
  '/v1.CategoriesService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.CategoryRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.CategoryRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.CategoryRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_CategoriesService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.CategoryRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.CategoryRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CategoriesServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CategoriesService/Delete',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_Delete,
      callback);
};


/**
 * @param {!proto.v1.CategoryRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.CategoriesServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CategoriesService/Delete',
      request,
      metadata || {},
      methodDescriptor_CategoriesService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EquipmentTypesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EquipmentTypesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.EquipmentTypeList>}
 */
const methodDescriptor_EquipmentTypesService_List = new grpc.web.MethodDescriptor(
  '/v1.EquipmentTypesService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.EquipmentTypeList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EquipmentTypeList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.EquipmentTypeList>}
 */
const methodInfo_EquipmentTypesService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.EquipmentTypeList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EquipmentTypeList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.EquipmentTypeList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.EquipmentTypeList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentTypesServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentTypesService/List',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.EquipmentTypeList>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentTypesServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentTypesService/List',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.EquipmentTypeRef,
 *   !proto.v1.EquipmentType>}
 */
const methodDescriptor_EquipmentTypesService_Get = new grpc.web.MethodDescriptor(
  '/v1.EquipmentTypesService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.EquipmentTypeRef,
  proto.v1.EquipmentType,
  /**
   * @param {!proto.v1.EquipmentTypeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EquipmentType.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.EquipmentTypeRef,
 *   !proto.v1.EquipmentType>}
 */
const methodInfo_EquipmentTypesService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.EquipmentType,
  /**
   * @param {!proto.v1.EquipmentTypeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EquipmentType.deserializeBinary
);


/**
 * @param {!proto.v1.EquipmentTypeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.EquipmentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.EquipmentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentTypesServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentTypesService/Get',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_Get,
      callback);
};


/**
 * @param {!proto.v1.EquipmentTypeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.EquipmentType>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentTypesServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentTypesService/Get',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.EquipmentType,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EquipmentTypesService_Set = new grpc.web.MethodDescriptor(
  '/v1.EquipmentTypesService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.EquipmentType,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EquipmentType} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.EquipmentType,
 *   !proto.v1.Empty>}
 */
const methodInfo_EquipmentTypesService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EquipmentType} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.EquipmentType} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentTypesServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentTypesService/Set',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_Set,
      callback);
};


/**
 * @param {!proto.v1.EquipmentType} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentTypesServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentTypesService/Set',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.EquipmentType,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EquipmentTypesService_Add = new grpc.web.MethodDescriptor(
  '/v1.EquipmentTypesService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.EquipmentType,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EquipmentType} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.EquipmentType,
 *   !proto.v1.Empty>}
 */
const methodInfo_EquipmentTypesService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EquipmentType} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.EquipmentType} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentTypesServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentTypesService/Add',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_Add,
      callback);
};


/**
 * @param {!proto.v1.EquipmentType} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentTypesServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentTypesService/Add',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.EquipmentTypeRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EquipmentTypesService_Delete = new grpc.web.MethodDescriptor(
  '/v1.EquipmentTypesService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.EquipmentTypeRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EquipmentTypeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.EquipmentTypeRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_EquipmentTypesService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EquipmentTypeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.EquipmentTypeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentTypesServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentTypesService/Delete',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_Delete,
      callback);
};


/**
 * @param {!proto.v1.EquipmentTypeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentTypesServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentTypesService/Delete',
      request,
      metadata || {},
      methodDescriptor_EquipmentTypesService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EquipmentsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EquipmentsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.EquipmentList>}
 */
const methodDescriptor_EquipmentsService_List = new grpc.web.MethodDescriptor(
  '/v1.EquipmentsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.EquipmentList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EquipmentList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.EquipmentList>}
 */
const methodInfo_EquipmentsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.EquipmentList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EquipmentList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.EquipmentList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.EquipmentList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentsService/List',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.EquipmentList>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentsService/List',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.EquipmentRef,
 *   !proto.v1.Equipment>}
 */
const methodDescriptor_EquipmentsService_Get = new grpc.web.MethodDescriptor(
  '/v1.EquipmentsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.EquipmentRef,
  proto.v1.Equipment,
  /**
   * @param {!proto.v1.EquipmentRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Equipment.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.EquipmentRef,
 *   !proto.v1.Equipment>}
 */
const methodInfo_EquipmentsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Equipment,
  /**
   * @param {!proto.v1.EquipmentRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Equipment.deserializeBinary
);


/**
 * @param {!proto.v1.EquipmentRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Equipment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Equipment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentsService/Get',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_Get,
      callback);
};


/**
 * @param {!proto.v1.EquipmentRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Equipment>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentsService/Get',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Equipment,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EquipmentsService_Set = new grpc.web.MethodDescriptor(
  '/v1.EquipmentsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Equipment,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Equipment} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Equipment,
 *   !proto.v1.Empty>}
 */
const methodInfo_EquipmentsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Equipment} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Equipment} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentsService/Set',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_Set,
      callback);
};


/**
 * @param {!proto.v1.Equipment} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentsService/Set',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Equipment,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EquipmentsService_Add = new grpc.web.MethodDescriptor(
  '/v1.EquipmentsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Equipment,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Equipment} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Equipment,
 *   !proto.v1.Empty>}
 */
const methodInfo_EquipmentsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Equipment} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Equipment} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentsService/Add',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_Add,
      callback);
};


/**
 * @param {!proto.v1.Equipment} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentsService/Add',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.EquipmentRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EquipmentsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.EquipmentsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.EquipmentRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EquipmentRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.EquipmentRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_EquipmentsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EquipmentRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.EquipmentRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EquipmentsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EquipmentsService/Delete',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.EquipmentRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EquipmentsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EquipmentsService/Delete',
      request,
      metadata || {},
      methodDescriptor_EquipmentsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.CheckListsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.CheckListsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.CheckListList>}
 */
const methodDescriptor_CheckListsService_List = new grpc.web.MethodDescriptor(
  '/v1.CheckListsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.CheckListList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CheckListList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.CheckListList>}
 */
const methodInfo_CheckListsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.CheckListList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CheckListList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.CheckListList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.CheckListList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CheckListsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CheckListsService/List',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.CheckListList>}
 *     Promise that resolves to the response
 */
proto.v1.CheckListsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CheckListsService/List',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.CheckListRef,
 *   !proto.v1.CheckList>}
 */
const methodDescriptor_CheckListsService_Get = new grpc.web.MethodDescriptor(
  '/v1.CheckListsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.CheckListRef,
  proto.v1.CheckList,
  /**
   * @param {!proto.v1.CheckListRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CheckList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.CheckListRef,
 *   !proto.v1.CheckList>}
 */
const methodInfo_CheckListsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.CheckList,
  /**
   * @param {!proto.v1.CheckListRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.CheckList.deserializeBinary
);


/**
 * @param {!proto.v1.CheckListRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.CheckList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.CheckList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CheckListsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CheckListsService/Get',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_Get,
      callback);
};


/**
 * @param {!proto.v1.CheckListRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.CheckList>}
 *     Promise that resolves to the response
 */
proto.v1.CheckListsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CheckListsService/Get',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.CheckList,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_CheckListsService_Set = new grpc.web.MethodDescriptor(
  '/v1.CheckListsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.CheckList,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.CheckList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.CheckList,
 *   !proto.v1.Empty>}
 */
const methodInfo_CheckListsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.CheckList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.CheckList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CheckListsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CheckListsService/Set',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_Set,
      callback);
};


/**
 * @param {!proto.v1.CheckList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.CheckListsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CheckListsService/Set',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.CheckList,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_CheckListsService_Add = new grpc.web.MethodDescriptor(
  '/v1.CheckListsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.CheckList,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.CheckList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.CheckList,
 *   !proto.v1.Empty>}
 */
const methodInfo_CheckListsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.CheckList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.CheckList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CheckListsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CheckListsService/Add',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_Add,
      callback);
};


/**
 * @param {!proto.v1.CheckList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.CheckListsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CheckListsService/Add',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.CheckListRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_CheckListsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.CheckListsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.CheckListRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.CheckListRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.CheckListRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_CheckListsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.CheckListRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.CheckListRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.CheckListsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.CheckListsService/Delete',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.CheckListRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.CheckListsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.CheckListsService/Delete',
      request,
      metadata || {},
      methodDescriptor_CheckListsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.RondesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.RondesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.RondeList>}
 */
const methodDescriptor_RondesService_List = new grpc.web.MethodDescriptor(
  '/v1.RondesService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.RondeList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RondeList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.RondeList>}
 */
const methodInfo_RondesService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.RondeList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RondeList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.RondeList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.RondeList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondesServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondesService/List',
      request,
      metadata || {},
      methodDescriptor_RondesService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.RondeList>}
 *     Promise that resolves to the response
 */
proto.v1.RondesServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondesService/List',
      request,
      metadata || {},
      methodDescriptor_RondesService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RondeRef,
 *   !proto.v1.Ronde>}
 */
const methodDescriptor_RondesService_Get = new grpc.web.MethodDescriptor(
  '/v1.RondesService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.RondeRef,
  proto.v1.Ronde,
  /**
   * @param {!proto.v1.RondeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Ronde.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RondeRef,
 *   !proto.v1.Ronde>}
 */
const methodInfo_RondesService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Ronde,
  /**
   * @param {!proto.v1.RondeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Ronde.deserializeBinary
);


/**
 * @param {!proto.v1.RondeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Ronde)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Ronde>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondesServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondesService/Get',
      request,
      metadata || {},
      methodDescriptor_RondesService_Get,
      callback);
};


/**
 * @param {!proto.v1.RondeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Ronde>}
 *     Promise that resolves to the response
 */
proto.v1.RondesServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondesService/Get',
      request,
      metadata || {},
      methodDescriptor_RondesService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Ronde,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RondesService_Set = new grpc.web.MethodDescriptor(
  '/v1.RondesService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Ronde,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Ronde} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Ronde,
 *   !proto.v1.Empty>}
 */
const methodInfo_RondesService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Ronde} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Ronde} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondesServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondesService/Set',
      request,
      metadata || {},
      methodDescriptor_RondesService_Set,
      callback);
};


/**
 * @param {!proto.v1.Ronde} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RondesServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondesService/Set',
      request,
      metadata || {},
      methodDescriptor_RondesService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Ronde,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RondesService_Add = new grpc.web.MethodDescriptor(
  '/v1.RondesService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Ronde,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Ronde} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Ronde,
 *   !proto.v1.Empty>}
 */
const methodInfo_RondesService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Ronde} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Ronde} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondesServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondesService/Add',
      request,
      metadata || {},
      methodDescriptor_RondesService_Add,
      callback);
};


/**
 * @param {!proto.v1.Ronde} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RondesServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondesService/Add',
      request,
      metadata || {},
      methodDescriptor_RondesService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RondeRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RondesService_Delete = new grpc.web.MethodDescriptor(
  '/v1.RondesService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.RondeRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RondeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RondeRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_RondesService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RondeRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.RondeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondesServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondesService/Delete',
      request,
      metadata || {},
      methodDescriptor_RondesService_Delete,
      callback);
};


/**
 * @param {!proto.v1.RondeRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RondesServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondesService/Delete',
      request,
      metadata || {},
      methodDescriptor_RondesService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.RondeExecsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.RondeExecsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.RondeExecList>}
 */
const methodDescriptor_RondeExecsService_List = new grpc.web.MethodDescriptor(
  '/v1.RondeExecsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.RondeExecList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RondeExecList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.RondeExecList>}
 */
const methodInfo_RondeExecsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.RondeExecList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RondeExecList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.RondeExecList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.RondeExecList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondeExecsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondeExecsService/List',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.RondeExecList>}
 *     Promise that resolves to the response
 */
proto.v1.RondeExecsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondeExecsService/List',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RondeExecRef,
 *   !proto.v1.RondeExec>}
 */
const methodDescriptor_RondeExecsService_Get = new grpc.web.MethodDescriptor(
  '/v1.RondeExecsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.RondeExecRef,
  proto.v1.RondeExec,
  /**
   * @param {!proto.v1.RondeExecRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RondeExec.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RondeExecRef,
 *   !proto.v1.RondeExec>}
 */
const methodInfo_RondeExecsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.RondeExec,
  /**
   * @param {!proto.v1.RondeExecRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RondeExec.deserializeBinary
);


/**
 * @param {!proto.v1.RondeExecRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.RondeExec)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.RondeExec>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondeExecsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondeExecsService/Get',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_Get,
      callback);
};


/**
 * @param {!proto.v1.RondeExecRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.RondeExec>}
 *     Promise that resolves to the response
 */
proto.v1.RondeExecsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondeExecsService/Get',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RondeExec,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RondeExecsService_Set = new grpc.web.MethodDescriptor(
  '/v1.RondeExecsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.RondeExec,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RondeExec} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RondeExec,
 *   !proto.v1.Empty>}
 */
const methodInfo_RondeExecsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RondeExec} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.RondeExec} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondeExecsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondeExecsService/Set',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_Set,
      callback);
};


/**
 * @param {!proto.v1.RondeExec} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RondeExecsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondeExecsService/Set',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RondeExec,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RondeExecsService_Add = new grpc.web.MethodDescriptor(
  '/v1.RondeExecsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.RondeExec,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RondeExec} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RondeExec,
 *   !proto.v1.Empty>}
 */
const methodInfo_RondeExecsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RondeExec} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.RondeExec} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondeExecsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondeExecsService/Add',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_Add,
      callback);
};


/**
 * @param {!proto.v1.RondeExec} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RondeExecsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondeExecsService/Add',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RondeExecRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RondeExecsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.RondeExecsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.RondeExecRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RondeExecRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RondeExecRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_RondeExecsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RondeExecRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.RondeExecRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RondeExecsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RondeExecsService/Delete',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.RondeExecRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RondeExecsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RondeExecsService/Delete',
      request,
      metadata || {},
      methodDescriptor_RondeExecsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AlertsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.AlertsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.AlertList>}
 */
const methodDescriptor_AlertsService_List = new grpc.web.MethodDescriptor(
  '/v1.AlertsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.AlertList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AlertList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.AlertList>}
 */
const methodInfo_AlertsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.AlertList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.AlertList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.AlertList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.AlertList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AlertsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AlertsService/List',
      request,
      metadata || {},
      methodDescriptor_AlertsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.AlertList>}
 *     Promise that resolves to the response
 */
proto.v1.AlertsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AlertsService/List',
      request,
      metadata || {},
      methodDescriptor_AlertsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AlertRef,
 *   !proto.v1.Alert>}
 */
const methodDescriptor_AlertsService_Get = new grpc.web.MethodDescriptor(
  '/v1.AlertsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.AlertRef,
  proto.v1.Alert,
  /**
   * @param {!proto.v1.AlertRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Alert.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AlertRef,
 *   !proto.v1.Alert>}
 */
const methodInfo_AlertsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Alert,
  /**
   * @param {!proto.v1.AlertRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Alert.deserializeBinary
);


/**
 * @param {!proto.v1.AlertRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Alert)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Alert>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AlertsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AlertsService/Get',
      request,
      metadata || {},
      methodDescriptor_AlertsService_Get,
      callback);
};


/**
 * @param {!proto.v1.AlertRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Alert>}
 *     Promise that resolves to the response
 */
proto.v1.AlertsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AlertsService/Get',
      request,
      metadata || {},
      methodDescriptor_AlertsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Alert,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AlertsService_Set = new grpc.web.MethodDescriptor(
  '/v1.AlertsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Alert,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Alert} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Alert,
 *   !proto.v1.Empty>}
 */
const methodInfo_AlertsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Alert} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Alert} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AlertsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AlertsService/Set',
      request,
      metadata || {},
      methodDescriptor_AlertsService_Set,
      callback);
};


/**
 * @param {!proto.v1.Alert} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AlertsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AlertsService/Set',
      request,
      metadata || {},
      methodDescriptor_AlertsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Alert,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AlertsService_Add = new grpc.web.MethodDescriptor(
  '/v1.AlertsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Alert,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Alert} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Alert,
 *   !proto.v1.Empty>}
 */
const methodInfo_AlertsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Alert} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Alert} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AlertsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AlertsService/Add',
      request,
      metadata || {},
      methodDescriptor_AlertsService_Add,
      callback);
};


/**
 * @param {!proto.v1.Alert} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AlertsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AlertsService/Add',
      request,
      metadata || {},
      methodDescriptor_AlertsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.AlertRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_AlertsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.AlertsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.AlertRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AlertRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.AlertRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_AlertsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.AlertRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.AlertRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.AlertsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.AlertsService/Delete',
      request,
      metadata || {},
      methodDescriptor_AlertsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.AlertRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.AlertsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.AlertsService/Delete',
      request,
      metadata || {},
      methodDescriptor_AlertsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.RulesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.RulesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.RuleList>}
 */
const methodDescriptor_RulesService_List = new grpc.web.MethodDescriptor(
  '/v1.RulesService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.RuleList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RuleList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.RuleList>}
 */
const methodInfo_RulesService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.RuleList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.RuleList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.RuleList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.RuleList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RulesServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RulesService/List',
      request,
      metadata || {},
      methodDescriptor_RulesService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.RuleList>}
 *     Promise that resolves to the response
 */
proto.v1.RulesServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RulesService/List',
      request,
      metadata || {},
      methodDescriptor_RulesService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RuleRef,
 *   !proto.v1.Rule>}
 */
const methodDescriptor_RulesService_Get = new grpc.web.MethodDescriptor(
  '/v1.RulesService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.RuleRef,
  proto.v1.Rule,
  /**
   * @param {!proto.v1.RuleRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Rule.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RuleRef,
 *   !proto.v1.Rule>}
 */
const methodInfo_RulesService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Rule,
  /**
   * @param {!proto.v1.RuleRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Rule.deserializeBinary
);


/**
 * @param {!proto.v1.RuleRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Rule)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Rule>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RulesServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RulesService/Get',
      request,
      metadata || {},
      methodDescriptor_RulesService_Get,
      callback);
};


/**
 * @param {!proto.v1.RuleRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Rule>}
 *     Promise that resolves to the response
 */
proto.v1.RulesServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RulesService/Get',
      request,
      metadata || {},
      methodDescriptor_RulesService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Rule,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RulesService_Set = new grpc.web.MethodDescriptor(
  '/v1.RulesService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Rule,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Rule} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Rule,
 *   !proto.v1.Empty>}
 */
const methodInfo_RulesService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Rule} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Rule} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RulesServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RulesService/Set',
      request,
      metadata || {},
      methodDescriptor_RulesService_Set,
      callback);
};


/**
 * @param {!proto.v1.Rule} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RulesServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RulesService/Set',
      request,
      metadata || {},
      methodDescriptor_RulesService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Rule,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RulesService_Add = new grpc.web.MethodDescriptor(
  '/v1.RulesService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Rule,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Rule} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Rule,
 *   !proto.v1.Empty>}
 */
const methodInfo_RulesService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Rule} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Rule} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RulesServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RulesService/Add',
      request,
      metadata || {},
      methodDescriptor_RulesService_Add,
      callback);
};


/**
 * @param {!proto.v1.Rule} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RulesServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RulesService/Add',
      request,
      metadata || {},
      methodDescriptor_RulesService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.RuleRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_RulesService_Delete = new grpc.web.MethodDescriptor(
  '/v1.RulesService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.RuleRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RuleRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.RuleRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_RulesService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.RuleRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.RuleRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.RulesServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.RulesService/Delete',
      request,
      metadata || {},
      methodDescriptor_RulesService_Delete,
      callback);
};


/**
 * @param {!proto.v1.RuleRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.RulesServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.RulesService/Delete',
      request,
      metadata || {},
      methodDescriptor_RulesService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EmergenciesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.EmergenciesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.EmergencyList>}
 */
const methodDescriptor_EmergenciesService_List = new grpc.web.MethodDescriptor(
  '/v1.EmergenciesService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.EmergencyList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EmergencyList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.EmergencyList>}
 */
const methodInfo_EmergenciesService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.EmergencyList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.EmergencyList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.EmergencyList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.EmergencyList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EmergenciesServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EmergenciesService/List',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.EmergencyList>}
 *     Promise that resolves to the response
 */
proto.v1.EmergenciesServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EmergenciesService/List',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.EmergencyRef,
 *   !proto.v1.Emergency>}
 */
const methodDescriptor_EmergenciesService_Get = new grpc.web.MethodDescriptor(
  '/v1.EmergenciesService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.EmergencyRef,
  proto.v1.Emergency,
  /**
   * @param {!proto.v1.EmergencyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Emergency.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.EmergencyRef,
 *   !proto.v1.Emergency>}
 */
const methodInfo_EmergenciesService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Emergency,
  /**
   * @param {!proto.v1.EmergencyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Emergency.deserializeBinary
);


/**
 * @param {!proto.v1.EmergencyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Emergency)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Emergency>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EmergenciesServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EmergenciesService/Get',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_Get,
      callback);
};


/**
 * @param {!proto.v1.EmergencyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Emergency>}
 *     Promise that resolves to the response
 */
proto.v1.EmergenciesServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EmergenciesService/Get',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Emergency,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EmergenciesService_Set = new grpc.web.MethodDescriptor(
  '/v1.EmergenciesService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Emergency,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Emergency} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Emergency,
 *   !proto.v1.Empty>}
 */
const methodInfo_EmergenciesService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Emergency} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Emergency} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EmergenciesServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EmergenciesService/Set',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_Set,
      callback);
};


/**
 * @param {!proto.v1.Emergency} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EmergenciesServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EmergenciesService/Set',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Emergency,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EmergenciesService_Add = new grpc.web.MethodDescriptor(
  '/v1.EmergenciesService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Emergency,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Emergency} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Emergency,
 *   !proto.v1.Empty>}
 */
const methodInfo_EmergenciesService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Emergency} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Emergency} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EmergenciesServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EmergenciesService/Add',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_Add,
      callback);
};


/**
 * @param {!proto.v1.Emergency} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EmergenciesServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EmergenciesService/Add',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.EmergencyRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_EmergenciesService_Delete = new grpc.web.MethodDescriptor(
  '/v1.EmergenciesService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.EmergencyRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EmergencyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.EmergencyRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_EmergenciesService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.EmergencyRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.EmergencyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.EmergenciesServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.EmergenciesService/Delete',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_Delete,
      callback);
};


/**
 * @param {!proto.v1.EmergencyRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.EmergenciesServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.EmergenciesService/Delete',
      request,
      metadata || {},
      methodDescriptor_EmergenciesService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.MapElementsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.MapElementsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.MapElementList>}
 */
const methodDescriptor_MapElementsService_List = new grpc.web.MethodDescriptor(
  '/v1.MapElementsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.MapElementList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapElementList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.MapElementList>}
 */
const methodInfo_MapElementsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.MapElementList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapElementList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.MapElementList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.MapElementList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapElementsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapElementsService/List',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.MapElementList>}
 *     Promise that resolves to the response
 */
proto.v1.MapElementsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapElementsService/List',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapElementRef,
 *   !proto.v1.MapElement>}
 */
const methodDescriptor_MapElementsService_Get = new grpc.web.MethodDescriptor(
  '/v1.MapElementsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.MapElementRef,
  proto.v1.MapElement,
  /**
   * @param {!proto.v1.MapElementRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapElement.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapElementRef,
 *   !proto.v1.MapElement>}
 */
const methodInfo_MapElementsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.MapElement,
  /**
   * @param {!proto.v1.MapElementRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapElement.deserializeBinary
);


/**
 * @param {!proto.v1.MapElementRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.MapElement)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.MapElement>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapElementsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapElementsService/Get',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_Get,
      callback);
};


/**
 * @param {!proto.v1.MapElementRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.MapElement>}
 *     Promise that resolves to the response
 */
proto.v1.MapElementsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapElementsService/Get',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapElement,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_MapElementsService_Set = new grpc.web.MethodDescriptor(
  '/v1.MapElementsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.MapElement,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapElement} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapElement,
 *   !proto.v1.Empty>}
 */
const methodInfo_MapElementsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapElement} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.MapElement} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapElementsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapElementsService/Set',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_Set,
      callback);
};


/**
 * @param {!proto.v1.MapElement} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.MapElementsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapElementsService/Set',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapElement,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_MapElementsService_Add = new grpc.web.MethodDescriptor(
  '/v1.MapElementsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.MapElement,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapElement} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapElement,
 *   !proto.v1.Empty>}
 */
const methodInfo_MapElementsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapElement} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.MapElement} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapElementsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapElementsService/Add',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_Add,
      callback);
};


/**
 * @param {!proto.v1.MapElement} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.MapElementsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapElementsService/Add',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapElementRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_MapElementsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.MapElementsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.MapElementRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapElementRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapElementRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_MapElementsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapElementRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.MapElementRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapElementsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapElementsService/Delete',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.MapElementRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.MapElementsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapElementsService/Delete',
      request,
      metadata || {},
      methodDescriptor_MapElementsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.MapIconsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.MapIconsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.MapIconList>}
 */
const methodDescriptor_MapIconsService_List = new grpc.web.MethodDescriptor(
  '/v1.MapIconsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.MapIconList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapIconList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.MapIconList>}
 */
const methodInfo_MapIconsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.MapIconList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapIconList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.MapIconList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.MapIconList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapIconsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapIconsService/List',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.MapIconList>}
 *     Promise that resolves to the response
 */
proto.v1.MapIconsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapIconsService/List',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapIconRef,
 *   !proto.v1.MapIcon>}
 */
const methodDescriptor_MapIconsService_Get = new grpc.web.MethodDescriptor(
  '/v1.MapIconsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.MapIconRef,
  proto.v1.MapIcon,
  /**
   * @param {!proto.v1.MapIconRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapIcon.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapIconRef,
 *   !proto.v1.MapIcon>}
 */
const methodInfo_MapIconsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.MapIcon,
  /**
   * @param {!proto.v1.MapIconRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.MapIcon.deserializeBinary
);


/**
 * @param {!proto.v1.MapIconRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.MapIcon)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.MapIcon>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapIconsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapIconsService/Get',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_Get,
      callback);
};


/**
 * @param {!proto.v1.MapIconRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.MapIcon>}
 *     Promise that resolves to the response
 */
proto.v1.MapIconsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapIconsService/Get',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapIcon,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_MapIconsService_Set = new grpc.web.MethodDescriptor(
  '/v1.MapIconsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.MapIcon,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapIcon} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapIcon,
 *   !proto.v1.Empty>}
 */
const methodInfo_MapIconsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapIcon} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.MapIcon} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapIconsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapIconsService/Set',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_Set,
      callback);
};


/**
 * @param {!proto.v1.MapIcon} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.MapIconsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapIconsService/Set',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapIcon,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_MapIconsService_Add = new grpc.web.MethodDescriptor(
  '/v1.MapIconsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.MapIcon,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapIcon} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapIcon,
 *   !proto.v1.Empty>}
 */
const methodInfo_MapIconsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapIcon} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.MapIcon} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapIconsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapIconsService/Add',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_Add,
      callback);
};


/**
 * @param {!proto.v1.MapIcon} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.MapIconsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapIconsService/Add',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.MapIconRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_MapIconsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.MapIconsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.MapIconRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapIconRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.MapIconRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_MapIconsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.MapIconRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.MapIconRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.MapIconsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.MapIconsService/Delete',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.MapIconRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.MapIconsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.MapIconsService/Delete',
      request,
      metadata || {},
      methodDescriptor_MapIconsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.TodoListsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.TodoListsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.TodoListList>}
 */
const methodDescriptor_TodoListsService_List = new grpc.web.MethodDescriptor(
  '/v1.TodoListsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.TodoListList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.TodoListList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.TodoListList>}
 */
const methodInfo_TodoListsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.TodoListList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.TodoListList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.TodoListList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.TodoListList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TodoListsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TodoListsService/List',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.TodoListList>}
 *     Promise that resolves to the response
 */
proto.v1.TodoListsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TodoListsService/List',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.TodoListRef,
 *   !proto.v1.TodoList>}
 */
const methodDescriptor_TodoListsService_Get = new grpc.web.MethodDescriptor(
  '/v1.TodoListsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.TodoListRef,
  proto.v1.TodoList,
  /**
   * @param {!proto.v1.TodoListRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.TodoList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.TodoListRef,
 *   !proto.v1.TodoList>}
 */
const methodInfo_TodoListsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.TodoList,
  /**
   * @param {!proto.v1.TodoListRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.TodoList.deserializeBinary
);


/**
 * @param {!proto.v1.TodoListRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.TodoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.TodoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TodoListsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TodoListsService/Get',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_Get,
      callback);
};


/**
 * @param {!proto.v1.TodoListRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.TodoList>}
 *     Promise that resolves to the response
 */
proto.v1.TodoListsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TodoListsService/Get',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.TodoList,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TodoListsService_Set = new grpc.web.MethodDescriptor(
  '/v1.TodoListsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.TodoList,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.TodoList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.TodoList,
 *   !proto.v1.Empty>}
 */
const methodInfo_TodoListsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.TodoList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.TodoList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TodoListsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TodoListsService/Set',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_Set,
      callback);
};


/**
 * @param {!proto.v1.TodoList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TodoListsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TodoListsService/Set',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.TodoList,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TodoListsService_Add = new grpc.web.MethodDescriptor(
  '/v1.TodoListsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.TodoList,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.TodoList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.TodoList,
 *   !proto.v1.Empty>}
 */
const methodInfo_TodoListsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.TodoList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.TodoList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TodoListsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TodoListsService/Add',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_Add,
      callback);
};


/**
 * @param {!proto.v1.TodoList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TodoListsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TodoListsService/Add',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.TodoListRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TodoListsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.TodoListsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.TodoListRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.TodoListRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.TodoListRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_TodoListsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.TodoListRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.TodoListRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TodoListsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TodoListsService/Delete',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.TodoListRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TodoListsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TodoListsService/Delete',
      request,
      metadata || {},
      methodDescriptor_TodoListsService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.ImageOverlaysServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.ImageOverlaysServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.ImageOverlayList>}
 */
const methodDescriptor_ImageOverlaysService_List = new grpc.web.MethodDescriptor(
  '/v1.ImageOverlaysService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.ImageOverlayList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ImageOverlayList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.ImageOverlayList>}
 */
const methodInfo_ImageOverlaysService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.ImageOverlayList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ImageOverlayList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.ImageOverlayList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.ImageOverlayList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ImageOverlaysServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ImageOverlaysService/List',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.ImageOverlayList>}
 *     Promise that resolves to the response
 */
proto.v1.ImageOverlaysServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ImageOverlaysService/List',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ImageOverlayRef,
 *   !proto.v1.ImageOverlay>}
 */
const methodDescriptor_ImageOverlaysService_Get = new grpc.web.MethodDescriptor(
  '/v1.ImageOverlaysService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.ImageOverlayRef,
  proto.v1.ImageOverlay,
  /**
   * @param {!proto.v1.ImageOverlayRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ImageOverlay.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ImageOverlayRef,
 *   !proto.v1.ImageOverlay>}
 */
const methodInfo_ImageOverlaysService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.ImageOverlay,
  /**
   * @param {!proto.v1.ImageOverlayRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.ImageOverlay.deserializeBinary
);


/**
 * @param {!proto.v1.ImageOverlayRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.ImageOverlay)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.ImageOverlay>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ImageOverlaysServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ImageOverlaysService/Get',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_Get,
      callback);
};


/**
 * @param {!proto.v1.ImageOverlayRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.ImageOverlay>}
 *     Promise that resolves to the response
 */
proto.v1.ImageOverlaysServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ImageOverlaysService/Get',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ImageOverlay,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_ImageOverlaysService_Set = new grpc.web.MethodDescriptor(
  '/v1.ImageOverlaysService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.ImageOverlay,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ImageOverlay} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ImageOverlay,
 *   !proto.v1.Empty>}
 */
const methodInfo_ImageOverlaysService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ImageOverlay} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.ImageOverlay} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ImageOverlaysServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ImageOverlaysService/Set',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_Set,
      callback);
};


/**
 * @param {!proto.v1.ImageOverlay} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.ImageOverlaysServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ImageOverlaysService/Set',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ImageOverlay,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_ImageOverlaysService_Add = new grpc.web.MethodDescriptor(
  '/v1.ImageOverlaysService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.ImageOverlay,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ImageOverlay} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ImageOverlay,
 *   !proto.v1.Empty>}
 */
const methodInfo_ImageOverlaysService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ImageOverlay} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.ImageOverlay} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ImageOverlaysServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ImageOverlaysService/Add',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_Add,
      callback);
};


/**
 * @param {!proto.v1.ImageOverlay} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.ImageOverlaysServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ImageOverlaysService/Add',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.ImageOverlayRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_ImageOverlaysService_Delete = new grpc.web.MethodDescriptor(
  '/v1.ImageOverlaysService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.ImageOverlayRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ImageOverlayRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.ImageOverlayRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_ImageOverlaysService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.ImageOverlayRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.ImageOverlayRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.ImageOverlaysServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.ImageOverlaysService/Delete',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_Delete,
      callback);
};


/**
 * @param {!proto.v1.ImageOverlayRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.ImageOverlaysServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.ImageOverlaysService/Delete',
      request,
      metadata || {},
      methodDescriptor_ImageOverlaysService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.TeamsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.v1.TeamsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Empty,
 *   !proto.v1.TeamList>}
 */
const methodDescriptor_TeamsService_List = new grpc.web.MethodDescriptor(
  '/v1.TeamsService/List',
  grpc.web.MethodType.UNARY,
  proto.v1.Empty,
  proto.v1.TeamList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.TeamList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Empty,
 *   !proto.v1.TeamList>}
 */
const methodInfo_TeamsService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.TeamList,
  /**
   * @param {!proto.v1.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.TeamList.deserializeBinary
);


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.TeamList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.TeamList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TeamsServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TeamsService/List',
      request,
      metadata || {},
      methodDescriptor_TeamsService_List,
      callback);
};


/**
 * @param {!proto.v1.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.TeamList>}
 *     Promise that resolves to the response
 */
proto.v1.TeamsServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TeamsService/List',
      request,
      metadata || {},
      methodDescriptor_TeamsService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.TeamRef,
 *   !proto.v1.Team>}
 */
const methodDescriptor_TeamsService_Get = new grpc.web.MethodDescriptor(
  '/v1.TeamsService/Get',
  grpc.web.MethodType.UNARY,
  proto.v1.TeamRef,
  proto.v1.Team,
  /**
   * @param {!proto.v1.TeamRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Team.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.TeamRef,
 *   !proto.v1.Team>}
 */
const methodInfo_TeamsService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Team,
  /**
   * @param {!proto.v1.TeamRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Team.deserializeBinary
);


/**
 * @param {!proto.v1.TeamRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Team)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Team>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TeamsServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TeamsService/Get',
      request,
      metadata || {},
      methodDescriptor_TeamsService_Get,
      callback);
};


/**
 * @param {!proto.v1.TeamRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Team>}
 *     Promise that resolves to the response
 */
proto.v1.TeamsServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TeamsService/Get',
      request,
      metadata || {},
      methodDescriptor_TeamsService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Team,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TeamsService_Set = new grpc.web.MethodDescriptor(
  '/v1.TeamsService/Set',
  grpc.web.MethodType.UNARY,
  proto.v1.Team,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Team,
 *   !proto.v1.Empty>}
 */
const methodInfo_TeamsService_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TeamsServiceClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TeamsService/Set',
      request,
      metadata || {},
      methodDescriptor_TeamsService_Set,
      callback);
};


/**
 * @param {!proto.v1.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TeamsServicePromiseClient.prototype.set =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TeamsService/Set',
      request,
      metadata || {},
      methodDescriptor_TeamsService_Set);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.Team,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TeamsService_Add = new grpc.web.MethodDescriptor(
  '/v1.TeamsService/Add',
  grpc.web.MethodType.UNARY,
  proto.v1.Team,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.Team,
 *   !proto.v1.Empty>}
 */
const methodInfo_TeamsService_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TeamsServiceClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TeamsService/Add',
      request,
      metadata || {},
      methodDescriptor_TeamsService_Add,
      callback);
};


/**
 * @param {!proto.v1.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TeamsServicePromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TeamsService/Add',
      request,
      metadata || {},
      methodDescriptor_TeamsService_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.v1.TeamRef,
 *   !proto.v1.Empty>}
 */
const methodDescriptor_TeamsService_Delete = new grpc.web.MethodDescriptor(
  '/v1.TeamsService/Delete',
  grpc.web.MethodType.UNARY,
  proto.v1.TeamRef,
  proto.v1.Empty,
  /**
   * @param {!proto.v1.TeamRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.v1.TeamRef,
 *   !proto.v1.Empty>}
 */
const methodInfo_TeamsService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.v1.Empty,
  /**
   * @param {!proto.v1.TeamRef} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.v1.Empty.deserializeBinary
);


/**
 * @param {!proto.v1.TeamRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.v1.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.v1.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.v1.TeamsServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/v1.TeamsService/Delete',
      request,
      metadata || {},
      methodDescriptor_TeamsService_Delete,
      callback);
};


/**
 * @param {!proto.v1.TeamRef} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.v1.Empty>}
 *     Promise that resolves to the response
 */
proto.v1.TeamsServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/v1.TeamsService/Delete',
      request,
      metadata || {},
      methodDescriptor_TeamsService_Delete);
};


module.exports = proto.v1;

