import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';

// API items

import { RondesServiceClient, RondesClient, RondeExecsServiceClient, SearchClient } from 'api/v1/v1_grpc_web_pb';
import {
    CheckListRef,
    EquipmentRef,
    RondeRef,
    Ronde,
    UserRef,
    OrgRef,
    Analyse,
    RondeExecRef,
    ScheduleDaily,
    ScheduleWeekly,
    ScheduleMonthly,
    ScheduleYearly,
    ScheduleItem,
    CheckFilter,
} from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';
import { getUserDetails } from './userSlice';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    rounds: [],
    loading: true,
};

// =================================================================
// Round slice
// =================================================================

const roundSlice = createSlice({
    name: 'round',
    initialState,
    reducers: {
        setRounds(state, { payload }) {
            state.rounds = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Rounds actions
// ===================================================================

export const { setLoading, setRounds } = roundSlice.actions;

// ===================================================================
// Round selector
// ===================================================================

export const roundSelector = (state) => state.round;

// ===================================================================
// Round reducer
// ===================================================================

export default roundSlice.reducer;

// ====================================================================
// Fetch Rounds
// ====================================================================

let prevId;
export const fetchRounds = () => {
    const currentId = uuidv4();
    prevId = currentId;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            // Fetch the rounds and executions of rounds
            const [rounds, executions] = await Promise.all([getRounds(), getRoundsExecutions()]);
            if (currentId !== prevId) return false;

            // Find and Attach latest execution with corresponding round
            const roundsWithExecutions = rounds.map((round) => {
                round.execution =
                    executions.find((execution) => execution.latest && execution.roundId === round.id) || {}; // If there is no available execution then attach empty obj
                return round;
            });

            dispatch(setRounds(roundsWithExecutions));
        } catch (error) {
            if (currentId !== prevId) return false;
            throw error;
        } finally {
            if (currentId === prevId) {
                dispatch(setLoading(false));
            }
        }
    };
};

// ======================================================================
// Add Rounds
// ======================================================================

export const addRound = (roundData) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();

    //New Round
    const round = new Ronde();
    round.setId(uuidv4());

    const result = await authRequestHandler(RondesServiceClient, 'add', generateRound(roundData, round, activeOrg));
    dispatch(fetchRounds());
    return result;
};

// ======================================================================
// Update  Rounds
// ======================================================================

export const updateRound = (updatedRoundData) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();

    //New Round
    const updatedRound = await getRoundDetails(updatedRoundData.id, false);

    const result = await authRequestHandler(
        RondesServiceClient,
        'set',
        generateRound(updatedRoundData, updatedRound, activeOrg),
    );

    dispatch(fetchRounds());
    return result;
};

// ======================================================================
// Delete  Round
// ======================================================================

export const deleteRound = (deletedRoundId) => async (dispatch, getState) => {
    // Request to the API
    const result = await authRequestHandler(RondesServiceClient, 'delete', ref(RondeRef, deletedRoundId));
    const updatedRounds = copyObj(getState().round.rounds).filter((round) => round.id !== deletedRoundId);

    dispatch(setRounds(updatedRounds));
    return result;
};

// ======================================================================
// Start Round
// ======================================================================

export const startRound = (roundId) => async (dispatch) => {
    // Request to the API
    const result = await authRequestHandler(RondesClient, 'start', ref(RondeRef, roundId));
    await dispatch(fetchRounds());
    return result;
};

// ======================================================================
// Stop  Round
// ======================================================================

export const stopRound = (executionId) => async (dispatch) => {
    const execution = await getRoundExecution(executionId, false);
    execution.setCancelled(true);
    const result = await authRequestHandler(RondeExecsServiceClient, 'set', execution);
    await dispatch(fetchRounds());
    return result;
};

// ======================================================================
// Active auto launch
// ======================================================================

export const triggerLaunch = (roundId, value) => async (dispatch) => {
    const round = await getRoundDetails(roundId, false);
    round.setSuspended(value);
    const result = await authRequestHandler(RondesServiceClient, 'set', round);
    await dispatch(fetchRounds());
    return result;
};

// ====================================================================
// Get Rounds
// ====================================================================

export const getRounds = async (extracted = true) => {
    //Retrieving checklists
    const result = await authRequestHandler(RondesServiceClient, 'list');
    if (extracted) {
        const rounds = result.getItemsList().map((round) => {
            const scheduleList = round.getScheduleList();

            const daily = scheduleList.reduce((result, schedule) => {
                const dailySchedule = schedule.getDaily();
                if (dailySchedule) {
                    const hourMinute = dailySchedule.getHourmin();
                    return result.concat({
                        id: uuidv4(),
                        hour: Math.floor(hourMinute / 60),
                        minute: hourMinute % 60,
                    });
                }
                return result;
            }, []);

            const weekly = scheduleList.reduce((result, schedule) => {
                const weeklySchedule = schedule.getWeekly();
                if (weeklySchedule) {
                    const hourMinute = weeklySchedule.getHourmin();
                    return result.concat({
                        id: uuidv4(),
                        hour: Math.floor(hourMinute / 60),
                        minute: hourMinute % 60,
                        day: weeklySchedule.getDayofweek(),
                    });
                }
                return result;
            }, []);

            const monthly = scheduleList.reduce((result, schedule) => {
                const monthlySchedule = schedule.getMonthly();

                if (monthlySchedule) {
                    const hourMinute = monthlySchedule.getHourmin();
                    return result.concat({
                        id: uuidv4(),
                        hour: Math.floor(hourMinute / 60),
                        minute: hourMinute % 60,
                        date: monthlySchedule.getDay(),
                    });
                }
                return result;
            }, []);

            const yearly = scheduleList.reduce((result, schedule) => {
                const yearlySchedule = schedule.getYearly();

                if (yearlySchedule) {
                    const hourMinute = yearlySchedule.getHourmin();
                    return result.concat({
                        id: uuidv4(),
                        hour: Math.floor(hourMinute / 60),
                        minute: hourMinute % 60,
                        date: yearlySchedule.getDay(),
                        month: yearlySchedule.getMonth(),
                    });
                }
                return result;
            }, []);

            return {
                id: round.getId(),
                name: round.getName(),
                suspended: round.getSuspended(),
                affectedTo: round.getDefaultuser()?.getId(),
                todos: round.getTodoList().map((todo) => ({
                    checklistId: todo.getChecklist().getId(),
                    equipmentId: todo.getEquipment().getId(),
                })),
                frequency: { daily, weekly, monthly, yearly },
            };
        });
        return rounds;
    }
    return result;
};
// ====================================================================
// Get  round details
// ====================================================================

export const getRoundDetails = async (roundId, extracted = true) => {
    const round = new Ronde();
    round.setId(roundId);
    const result = await authRequestHandler(RondesServiceClient, 'get', round);
    if (extracted) {
        // Get the details of the  user if user not found then add null
        const affectedTo = await getUserDetails(result.getDefaultuser()?.getId()).catch(() => null);

        return {
            id: result.getId(),
            name: result.getName(),
            affectedTo,
            todos: result.getTodoList().map((todo) => ({
                checklistId: todo.getChecklist().getId(),
                equipmentId: todo.getEquipment().getId(),
            })),
        };
    }
    return result;
};

// ======================================================================
// Get Round Executions
// ======================================================================

export const getRoundsExecutions = async (extracted = true) => {
    const result = await authRequestHandler(RondeExecsServiceClient, 'list');
    if (extracted) {
        const executions = result.getItemsList().map((exe) => ({
            id: exe.getId(),
            latest: exe.getLatest(),
            roundId: exe.getRonde().getId(),
            userId: exe.getUser()?.getId(),
            analysisDone: exe.getAnalysesdone(),
            analysisCount: exe.getAnalysescount(),
            createdAt: exe.getCreatedat(),
            isCanceled: exe.getCancelled(),
        }));
        return executions;
    }
    return result;
};
// ======================================================================
// Get single Round Execution
// ======================================================================

export const getRoundExecution = async (id, extracted = true) => {
    const result = await authRequestHandler(RondeExecsServiceClient, 'get', ref(RondeExecRef, id));
    if (extracted) {
        const executions = {
            latest: result.getLatest(),
            roundId: result.getRonde().getId(),
            userId: result.getUser().getId(),
            analysisDone: result.getAnalysesdone(),
            analysisCount: result.getAnalysescount(),
            createdAt: result.getCreatedat(),
            isCanceled: result.getCancelled(),
        };

        return executions;
    }
    return result;
};

// ======================================================================
// Fetch Dashboard data
// ======================================================================

export const downloadRoundHistory = (roundId) => async () => {
    const checkFilter = new CheckFilter();
    checkFilter.setRonde(ref(RondeRef, roundId));

    const result = await authRequestHandler(SearchClient, 'checksCSV', checkFilter);

    const content = result.getContent();
    const a = document.createElement('a');
    const file = new Blob([content], { type: 'text/csv' });

    a.href = URL.createObjectURL(file);
    a.download = 'roundHistory.csv';
    a.click();

    URL.revokeObjectURL(a.href);
};

// =================================================================
// ================================ Utils ==========================
// =================================================================

const generateRound = (roundData, round, activeOrg) => {
    const { name, todos, affectedTo, frequency } = roundData;

    round.setOrg(ref(OrgRef, activeOrg));
    round.setName(name);

    // set default users
    round.setDefaultuser(ref(UserRef, affectedTo.userId));

    // Set to do list
    round.setTodoList(
        todos.map((todo) => {
            const newAnalyse = new Analyse();
            newAnalyse.setOrg(ref(OrgRef, activeOrg));
            newAnalyse.setChecklist(ref(CheckListRef, todo.checklist.id));
            newAnalyse.setEquipment(ref(EquipmentRef, todo.equipment.id));

            return newAnalyse;
        }),
    );

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const dailyFrequencies = frequency.daily.map((singleFreq) => {
        const scheduleDaily = new ScheduleDaily();
        scheduleDaily.setHourmin(singleFreq.hour * 60 + singleFreq.minute);

        const scheduleItem = new ScheduleItem();
        scheduleItem.setDaily(scheduleDaily);
        scheduleItem.setTimezone(timeZone);

        return scheduleItem;
    });

    const weeklyFrequencies = frequency.weekly.map((singleFreq) => {
        const scheduleWeekly = new ScheduleWeekly();
        scheduleWeekly.setHourmin(singleFreq.hour * 60 + singleFreq.minute);
        scheduleWeekly.setDayofweek(singleFreq.day);

        const scheduleItem = new ScheduleItem();
        scheduleItem.setWeekly(scheduleWeekly);
        scheduleItem.setTimezone(timeZone);
        scheduleItem.setTimezone(timeZone);

        return scheduleItem;
    });

    const monthlyFrequencies = frequency.monthly.map((singleFreq) => {
        const scheduleMonthly = new ScheduleMonthly();
        scheduleMonthly.setHourmin(singleFreq.hour * 60 + singleFreq.minute);
        scheduleMonthly.setDay(singleFreq.date);

        const scheduleItem = new ScheduleItem();
        scheduleItem.setMonthly(scheduleMonthly);
        scheduleItem.setTimezone(timeZone);

        return scheduleItem;
    });

    const yearlyFrequencies = frequency.yearly.map((singleFreq) => {
        const scheduleYearly = new ScheduleYearly();
        scheduleYearly.setHourmin(singleFreq.hour * 60 + singleFreq.minute);
        scheduleYearly.setDay(singleFreq.date);
        scheduleYearly.setMonth(singleFreq.month);

        const scheduleItem = new ScheduleItem();
        scheduleItem.setYearly(scheduleYearly);
        scheduleItem.setTimezone(timeZone);

        return scheduleItem;
    });
    round.setScheduleList([...dailyFrequencies, ...weeklyFrequencies, ...monthlyFrequencies, ...yearlyFrequencies]);

    if (
        dailyFrequencies.length > 0 ||
        weeklyFrequencies.length > 0 ||
        monthlyFrequencies.length > 0 ||
        yearlyFrequencies.length > 0
    ) {
        round.setSuspended(false);
    } else {
        round.setSuspended(true);
    }

    return round;
};
