import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import cx from 'classnames';

interface FixedPluginProps {
    rtlActive?: boolean;
    handlerCloseIcon?: React.ReactNode;
    handlerOpenIcon?: React.ReactNode;
}

const FixedPlugin: React.FC<FixedPluginProps> = ({
    handlerOpenIcon = <i className="fas fa-filter fa-2x" />,
    handlerCloseIcon = <i className="fas fa-times fa-2x" />,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => setIsOpen((prevState) => !prevState), []);
    const handleClickAway = useCallback(() => setIsOpen(false), []);

    const classes = useStyles();
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={cx(classes.fixedPlugin, { [classes.fixedPluginOpen]: isOpen })}>
                <h3 className={classes.title}>
                    <FormattedMessage defaultMessage="Filters" />
                </h3>
                <div onClick={handleClick} className={classes.fixedPluginTrigger}>
                    {isOpen ? handlerCloseIcon : handlerOpenIcon}
                </div>

                <div className={classes.fixedPluginContent}>{children}</div>
            </div>
        </ClickAwayListener>
    );
};

export default FixedPlugin;

const useStyles = makeStyles((theme) => ({
    title: {
        padding: '15px 10px',
        borderBottom: '1px solid #ddd',
        margin: 0,
        marginBottom: 1,
        boxShadow: '0 2px 7px 2px #dddddd38',
    },
    fixedPlugin: {
        position: 'fixed',
        top: 100,
        right: 20,
        zIndex: 999,
        textAlign: 'center',
        width: '65px',
        maxHeight: '65px',
        fontSize: 14,
        listStyle: 'none',
        backgroundColor: '#fff',
        WebkitBackgroundClip: 'padding-box',
        backgroundClip: 'padding-box',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: 10,
        WebkitBoxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
        overflow: 'hidden',
        transition: '0.3s',
    },
    fixedPluginOpen: {
        width: '330px',
        maxHeight: 'calc(100vh - 200px)',
    },
    fixedPluginTrigger: {
        position: 'absolute',
        right: 0,
        top: 0,
        cursor: 'pointer',
        backgroundColor: '#fff',
        transition: '0.3s',
        borderRadius: '5px 0 0 5px',
        width: '65px',
        height: '65px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1111,
        '&:hover': {
            color: theme.palette.primary.main,
        },
        '& i': {
            // color: '#ffffff',
            padding: 10,
            borderRadius: '0 0 6px 6px',
            width: 'auto',
        },
    },
    fixedPluginContent: {
        width: '330px',
        minWidth: 160,
        padding: '0 10px 20px 20px',
        maxHeight: 'calc(100vh - 268px)',
        overflowY: 'scroll',
    },
}));
