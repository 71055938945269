import { lazy, useMemo } from 'react';
import { useIntl } from 'react-intl';
// Icons
import Place from '@material-ui/icons/Place';
import PeopleAlt from '@material-ui/icons/People';
import Notifs from '@material-ui/icons/Notifications';
import ViewQuilt from '@material-ui/icons/ViewQuilt';
import InsertChart from '@material-ui/icons/InsertChart';
import BuildIcon from '@material-ui/icons/Build';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import WidgetsIcon from '@material-ui/icons/Widgets';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import MapIcon from '@material-ui/icons/Map';
import LayersIcon from '@material-ui/icons/Layers';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import ClassIcon from '@material-ui/icons/Class';

// ============================================
// Views
// ============================================

// Auth Views
const LoginPage = lazy(() => import('views/Auth/LoginPage'));
const RegisterPage = lazy(() => import('views/Auth/RegisterPage'));

// Dashboard views
const Dashboard = lazy(() => import('views/Dashboard/Dashboard'));
const Overview = lazy(() => import('views/Overview/Overview'));
const AddOrganization = lazy(() => import('views/AddOrganization/AddOrganization'));
const Zones = lazy(() => import('views/Zones/Zones'));
const Rules = lazy(() => import('views/Rules/Rules'));
const OrgUsers = lazy(() => import('views/OrgUsers/OrgUsers'));
const Categories = lazy(() => import('views/Categories/Categories'));
const EquipmentList = lazy(() => import('views/Equipment/EquipmentList'));
const EquipmentTypes = lazy(() => import('views/Equipment/EquipmentType/EquipmentTypes'));
const EquipmentMap = lazy(() => import('views/Equipment/EquipmentMap'));
const Rounds = lazy(() => import('views/Rounds/Rounds'));
const CheckLists = lazy(() => import('views/CheckLists/CheckLists'));
const UserProfile = lazy(() => import('views/UserProfile/UserProfile'));
const ChangePassword = lazy(() => import('views/ChangePassword/ChangePassword'));
const Layers = lazy(() => import('views/Layers/Layers'));
const Teams = lazy(() => import('views/Teams/Teams'));

// Public views
const PublicAnomalyForm = lazy(() => import('views/PublicAnomaly/PublicAnomalyForm'));

var useRoutes = () => {
    const intl = useIntl();
    return useMemo(
        () => [
            // Public views
            {
                path: '/eq/:eqId',
                // name: intl.formatMessage({  defaultMessage:"Dashboard" }),
                // icon: InsertChart,
                component: PublicAnomalyForm,
                layout: '/',
                invisible: true,
                adminPrivilege: true,
            },
            {
                path: '/dashboard',
                name: intl.formatMessage({ defaultMessage: 'Dashboard' }),
                icon: InsertChart,
                component: Dashboard,
                layout: '/admin',
                adminPrivilege: true,
            },
            {
                path: '/overview',
                name: intl.formatMessage({ defaultMessage: 'Overview' }),
                icon: Place,
                component: Overview,
                layout: '/admin',
                adminPrivilege: false,
            },
            {
                path: '/profile',
                name: intl.formatMessage({ defaultMessage: 'User profile' }),
                component: UserProfile,
                layout: '/admin',
                invisible: true,
                adminPrivilege: true,
            },
            {
                path: '/change-password',
                name: intl.formatMessage({ defaultMessage: 'Change password' }),
                component: ChangePassword,
                layout: '/admin',
                invisible: true,
                adminPrivilege: true,
            },
            {
                path: '/add-organization',
                name: intl.formatMessage({ defaultMessage: 'Add Organization' }),
                icon: Place,
                component: AddOrganization,
                layout: '/admin',
                invisible: true,
                adminPrivilege: false,
            },

            {
                path: '/zones',
                name: intl.formatMessage({ defaultMessage: 'Zones' }),
                icon: ViewQuilt,
                component: Zones,
                layout: '/admin',
                adminPrivilege: true,
            },
            {
                path: '/layers',
                name: intl.formatMessage({ defaultMessage: 'Layers' }),
                icon: LayersIcon,
                component: Layers,
                layout: '/admin',
                adminPrivilege: true,
            },
            {
                path: '/categories',
                name: intl.formatMessage({ defaultMessage: 'Categories' }),
                icon: WidgetsIcon,
                component: Categories,
                layout: '/admin',
                adminPrivilege: true,
            },
            {
                path: '/users',
                name: intl.formatMessage({ defaultMessage: 'Users' }),
                icon: PeopleAlt,
                component: OrgUsers,
                layout: '/admin',
                adminPrivilege: true,
            },
            {
                path: '/teams',
                name: intl.formatMessage({ defaultMessage: 'Teams' }),
                icon: GroupWorkIcon,
                component: Teams,
                layout: '/admin',
                adminPrivilege: true,
            },

            {
                path: '/rules',
                name: intl.formatMessage({ defaultMessage: 'Rules' }),
                icon: Notifs,
                component: Rules,
                layout: '/admin',
                adminPrivilege: true,
            },

            {
                collapse: true,
                name: intl.formatMessage({ defaultMessage: 'Equipment' }),
                icon: BuildOutlinedIcon,
                state: 'equipmentPages',
                adminPrivilege: true,
                views: [
                    {
                        path: '/equipment/types',
                        name: intl.formatMessage({ defaultMessage: 'Equipment Types' }),
                        icon: ClassIcon,
                        component: EquipmentTypes,
                        layout: '/admin',
                        adminPrivilege: true,
                    },
                    {
                        path: '/equipment/lists',
                        name: intl.formatMessage({ defaultMessage: 'Equipments Lists' }),
                        icon: BuildIcon,
                        component: EquipmentList,
                        layout: '/admin',
                        adminPrivilege: true,
                    },
                    {
                        path: '/equipment/map',
                        name: intl.formatMessage({ defaultMessage: 'Equipments Map' }),
                        icon: MapIcon,
                        component: EquipmentMap,
                        layout: '/admin',
                        adminPrivilege: true,
                        // invisible: true,
                    },
                ],
            },

            {
                path: '/checklists',
                name: intl.formatMessage({ defaultMessage: 'Checklists' }),
                icon: DoneAllIcon,
                component: CheckLists,
                layout: '/admin',
                adminPrivilege: true,
            },
            {
                path: '/rounds',
                name: intl.formatMessage({ defaultMessage: 'Rounds' }),
                icon: Rotate90DegreesCcwIcon,
                component: Rounds,
                layout: '/admin',
                adminPrivilege: true,
            },
            {
                path: '/login',
                name: 'Login',

                component: LoginPage,
                layout: '/auth',
                invisible: true,
            },
            {
                path: '/register',
                name: intl.formatMessage({ defaultMessage: 'Register' }),

                component: RegisterPage,
                layout: '/auth',
                invisible: true,
            },
        ],
        [],
    );
};
export default useRoutes;
