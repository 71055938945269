import { createSelector } from 'reselect';
import { authSelector } from 'store/slices/authSlice';

export const selectIsAuthenticated = createSelector(authSelector, (auth) => auth.isAuthenticated);

export const selectAuthUser = createSelector(authSelector, (auth) => auth.authUser);

export const selectActiveOrg = createSelector(authSelector, (auth) => auth.activeOrg);
export const selectActiveOrgDetails = createSelector(selectAuthUser, selectActiveOrg, (authUser, activeOrg) =>
    authUser?.orgs.find((currentOrg) => currentOrg.id === activeOrg),
);

export const selectAuthLoading = createSelector(authSelector, (auth) => auth.loading);
