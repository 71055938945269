import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash';

// API items
import { UserNotifsClient, NotifsServiceClient } from 'api/v1/v1_grpc_web_pb';
import { NextReq, NotifRef } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    notificationsUpdated: false,
    notifications: [],
    loading: true,
    error: null,
};

// =================================================================
// Notification slice
// =================================================================

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotifications(state, { payload }) {
            state.notifications = [...payload];
            state.notificationsUpdated = true;
        },
        setNotificationsUpdated(state) {
            state.notificationsUpdated = false;
        },
        deleteNotification(state, { payload }) {
            state.notifications = state.notifications.filter((notification) => notification.id !== payload);
        },

        deleteAllNotifications(state, { payload }) {
            state.notifications = state.notifications.filter(
                (notification) => !payload.some((deletedNotification) => deletedNotification.id === notification.id),
            );
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
        setError(state, { payload }) {
            state.error = payload;
        },
    },
});

// ===================================================================
// Notification actions
// ===================================================================

export const {
    setLoading,
    setError,
    setNotifications,
    setNotificationsUpdated,
    deleteNotification,
    deleteAllNotifications,
} = notificationSlice.actions;

// ====================================================================
// Notification selector
// ====================================================================

export const notificationSelector = (state) => state.notification;

// =====================================================================
// Notification reducer
// =====================================================================

export default notificationSlice.reducer;

// ======================================================================
// Fetch notifications
// ======================================================================

let prevFetchId;
export const fetchNotification = () => (dispatch, getState) => {
    let counter = new NextReq();
    counter.setRev(0);
    dispatch(setLoading(true));
    dispatch(setError(null));

    const fetchNotificationController = async () => {
        try {
            const currentFetchId = uuidv4();
            prevFetchId = currentFetchId;

            const result = await authRequestHandler(UserNotifsClient, 'next', counter);
            if (currentFetchId !== prevFetchId) return false;

            const notifications = result
                .getList()
                .getItemsList()
                .map((item) => ({
                    id: item.getId(),
                    owner: item.getOwner().getId(),
                    label: item.getLabel(),
                    anomaly: item.getAnomaly()?.getId(),
                    createdAt: item.getCreatedat(),
                }))
                .sort(function (a, b) {
                    return b.createdAt - a.createdAt;
                });

            if (!isEqual(getState().notification.notifications, notifications)) {
                // Removed checklists notification for temporary
                dispatch(setNotifications(notifications.filter((notif) => notif.anomaly !== undefined)));
            }
            counter = result.getNextreq();

            fetchNotificationController();
        } catch (error) {
            dispatch(setError(true));
        } finally {
            dispatch(setLoading(false));
        }
    };

    return fetchNotificationController();
};

// ======================================================================
// Delete notification
// ======================================================================

export const closeNotification = (id) => async (dispatch) => {
    const result = await notificationDeleteHandler(id);
    dispatch(deleteNotification(id));
    return result;
};

// ======================================================================
// Delete notification
// ======================================================================

export const closeAllNotification = (notifications) => async (dispatch) => {
    const promises = [];
    notifications.map((notification) => promises.push(notificationDeleteHandler(notification.id)));
    const result = await Promise.all(promises);

    dispatch(deleteAllNotifications(notifications));
    return result;
};

// ======================================================================
//  Notification delete handler
// ======================================================================

export const notificationDeleteHandler = async (id) => {
    const result = await authRequestHandler(NotifsServiceClient, 'delete', ref(NotifRef, id));
    return result;
};
