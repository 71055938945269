import axios from 'axios';
import { getAPI_URL } from 'utils/auth';
import { UploadsClient } from 'api/v1/v1_grpc_web_pb';
import { UploadReq } from 'api/v1/v1_pb';
import authRequestHandler from 'api/handlers/apiHandler';

import formatBytes from 'utils/formatBytes';

const uploadHandler = async (file, options = {}) => {
    const { onStart, onProgress } = options;
    const source = axios.CancelToken.source();
    const timeStarted = Date.now();
    try {
        const newUploadReq = new UploadReq();
        newUploadReq.setContenttype(file.type);
        const response = await authRequestHandler(UploadsClient, 'add');

        if (onStart && typeof onStart === 'function') {
            onStart({ source });
        }

        const onUploadProgress = (e) => {
            const { loaded, total } = e;
            const progress = Math.round((loaded / total) * 100);
            const timeElapsed = new Date() - timeStarted;
            const uploadSpeed = loaded / (timeElapsed / 1000); // per second
            const timeRemaining = (total - loaded) / uploadSpeed;
            if (onProgress && typeof onProgress === 'function') {
                onProgress({
                    percentage: progress < 99 ? progress : 99,
                    total: formatBytes(total),
                    loaded: formatBytes(loaded),
                    uploadSpeed: formatBytes(uploadSpeed) + '/s',
                    timeRemaining,
                });
            }
        };

        await axios.post(getAPI_URL() + '/uploads', file, {
            headers: {
                'Content-type': 'application/x-octet-stream',
                Authorization: response.getToken(),
            },
            cancelToken: source.token,
            onUploadProgress,
        });
        return response.getId();
    } catch (error) {
        if (axios.isCancel(error)) {
            error.type = 'cancelled';
        }
        throw error;
    }
};
export default uploadHandler;
