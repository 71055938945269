import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// API items
import { SearchClient } from 'api/v1/v1_grpc_web_pb';
import { AnomalyFilter, GeoBox, Point } from 'api/v1/v1_pb';
import authRequestHandler from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    analyses: [],
    loading: true,
};

// =================================================================
// analysis slice
// =================================================================

const analysisSlice = createSlice({
    name: 'analysis',
    initialState,
    reducers: {
        setAnalyses(state, { payload }) {
            state.analyses = payload;
        },

        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// analysis actions
// ===================================================================

export const { setAnalyses, setLoading } = analysisSlice.actions;

// ====================================================================
// analysis selector
// ====================================================================

export const analysisSelector = (state) => state.analysis;

// =====================================================================
// analysis reducer
// =====================================================================

export default analysisSlice.reducer;

// ======================================================================
// Fetch Analysis
// ======================================================================

let lastRequest;
export const fetchAnalysis = (bounds) => {
    const currentRequest = uuidv4();
    lastRequest = currentRequest;

    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const newAnalysisFilter = new AnomalyFilter();

            const newGeoBox = new GeoBox(); //part of the map we can see
            const southWest = new Point();

            southWest.setLat(bounds?._southWest?.lat);
            southWest.setLong(bounds?._southWest?.lng);

            const northEast = new Point();
            northEast.setLat(bounds?._northEast?.lat);
            northEast.setLong(bounds?._northEast?.lng);
            newGeoBox.setMin(southWest);
            newGeoBox.setMax(northEast);
            newAnalysisFilter.setBox(newGeoBox);

            // Request to the api
            const result = await authRequestHandler(SearchClient, 'analysisStatuses', newAnalysisFilter);
            // If the current request is not the last request then cancel the execution
            if (currentRequest !== lastRequest) return false;

            if (result) {
                const analyses = result.getItemsList().map((analysis) => {
                    return {
                        latLng: [analysis.getLocation().getLat(), analysis.getLocation().getLong()],
                        checkListName: analysis.getLatestexec()?.getChecklistname(),
                        equipmentName: analysis.getLatestexec()?.getEquipmentname(),
                        createdAt: analysis.getLatestexec()?.getCreatedat() || 0,
                        id: uuidv4(), //TODO: Have remove hardcoded id
                        isCompleted:
                            analysis.getLatestexec()?.getCompleted() === undefined
                                ? null
                                : analysis.getLatestexec()?.getCompleted(),
                    };
                });

                dispatch(setAnalyses(analyses));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };
};
