import { createTheme } from '@material-ui/core/styles';
export default ({ isRTLEnabled }) =>
    createTheme({
        palette: {
            direction: isRTLEnabled ? 'rtl' : 'ltr',
            type: 'light',
            primary: {
                main: '#29ABE2',
            },
            text: {
                primary: '#3C4858',
                secondary: 'rgba(0, 0, 0, 0.87)',
            },
            whiteColor: '#FFF',
            blackColor: '#000',
            grayColor: [
                '#999',
                '#777',
                '#3C4858',
                '#AAAAAA',
                '#D2D2D2',
                '#DDD',
                '#555555',
                '#333',
                '#eee',
                '#ccc',
                '#e4e4e4',
                '#E5E5E5',
                '#f9f9f9',
                '#f5f5f5',
                '#495057',
                '#e7e7e7',
                '#212121',
                '#c8c8c8',
                '#505050',
                '#eeeeee',
                '#bdbdbd',
            ],

            primaryColor: ['#29ABE2', '#26c6da', '#00acc1', '#00d3ee', '#0097a7', '#c4e3f3', '#d9edf7'],
            warningColor: ['#ff9800', '#ffa726', '#fb8c00', '#ffa21a', '#f57c00', '#faf2cc', '#fcf8e3'],
            dangerColor: ['#f44336', '#ef5350', '#e53935', '#f55a4e', '#d32f2f', '#ebcccc', '#f2dede'],
            successColor: ['#4caf50', '#66bb6a', '#43a047', '#5cb860', '#388e3c', '#d0e9c6', '#dff0d8'],
            infoColor: ['#9c27b0', '#ab47bc', '#8e24aa', '#af2cc5', '#7b1fa2'],
            roseColor: ['#e91e63', '#ec407a', '#d81b60', '#eb3573', '#c2185b'],
            yellowColor: ['#f5e653', '#eeee00', '#f0ff00', '#f4f776', '#ffff7e'],
        },
        typography: {
            h1: { fontWeight: 300 },
            h2: { fontWeight: 300 },
            h3: { fontWeight: 300 },
            h4: { fontWeight: 300 },
            h5: { fontWeight: 300 },
            h6: { fontWeight: 300 },
            body1: { fontWeight: 300, lineHeight: '1.5em' }, //default font
            body2: { fontWeight: 300 },
        },
        overrides: {
            MuiChip: {
                colorPrimary: { color: '#fff ', fontWeight: 600 },
                deleteIconColorPrimary: { color: '#fff !important', '&:hover': { color: '#ffffff90 !important' } },
            },
        },
    });
