import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

// nodejs library to set properties for components
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

// core components
import AdminNavbarLinks from './AdminNavbarLinks';
import Button from 'components/CustomButtons/Button';

import useRoutes from 'routes/routes';
import { containerFluid, defaultFont, defaultBoxShadow } from 'assets/jss/material-dashboard-pro-react';

const AdminNavbar = ({ color, rtlActive, handleDrawerToggle, miniActive, sidebarMinimizeHandler, pathname }) => {
    const routes = useRoutes();
    const getActiveRoute = (routes) => {
        let activeRoute = '';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    const classes = useStyles();
    const appBarClasses = cx({
        [' ' + classes[color]]: color,
    });

    const sidebarMinimize =
        classes.sidebarMinimize +
        ' ' +
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive,
        });

    return (
        <AppBar className={classes.appBar + appBarClasses} id="mainHeader">
            <Toolbar className={classes.container}>
                <Hidden smDown implementation="css">
                    <div className={sidebarMinimize}>
                        <Button justIcon round color="white" onClick={sidebarMinimizeHandler}>
                            {miniActive ? (
                                <ViewList className={classes.sidebarMiniIcon} />
                            ) : (
                                <MoreVert className={classes.sidebarMiniIcon} />
                            )}
                        </Button>
                    </div>
                </Hidden>
                <Hidden mdUp implementation="css">
                    <Button
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                    <Button href="#" className={classes.title} color="transparent">
                        {getActiveRoute(routes)}
                    </Button>
                </div>

                <AdminNavbarLinks rtlActive={rtlActive} />
            </Toolbar>
        </AppBar>
    );
};

AdminNavbar.propTypes = {
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    rtlActive: PropTypes.bool,
    pathname: PropTypes.string,
    miniActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    sidebarMinimizeHandler: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        position: 'absolute',
        width: '100%',
        paddingTop: '10px',
        zIndex: '1029',
        color: theme.palette.grayColor[6],
        border: '0',
        borderRadius: '3px',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
    },
    container: {
        ...containerFluid,
        minHeight: '50px',
    },
    flex: {
        flex: 1,
    },
    title: {
        ...defaultFont,
        lineHeight: '30px',
        fontSize: '18px',
        borderRadius: '3px',
        textTransform: 'none',
        color: 'inherit',
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
        },
        margin: '0 !important',
        letterSpacing: 'unset',
        '&:hover,&:focus': {
            background: 'transparent',
        },
    },
    primary: {
        backgroundColor: theme.palette.primaryColor[0],
        color: theme.palette.whiteColor,
        ...defaultBoxShadow,
    },
    info: {
        backgroundColor: theme.palette.infoColor[0],
        color: theme.palette.whiteColor,
        ...defaultBoxShadow,
    },
    success: {
        backgroundColor: theme.palette.successColor[0],
        color: theme.palette.whiteColor,
        ...defaultBoxShadow,
    },
    warning: {
        backgroundColor: theme.palette.warningColor[0],
        color: theme.palette.whiteColor,
        ...defaultBoxShadow,
    },
    danger: {
        backgroundColor: theme.palette.dangerColor[0],
        color: theme.palette.whiteColor,
        ...defaultBoxShadow,
    },
    sidebarMinimize: {
        float: 'left',
        padding: '0 0 0 15px',
        display: 'block',
        color: theme.palette.grayColor[6],
    },
    sidebarMinimizeRTL: {
        padding: '0 15px 0 0 !important',
    },
    sidebarMiniIcon: {
        width: '20px',
        height: '17px',
    },
}));

export default memo(AdminNavbar, isEqual);
