/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
// API items
import { MiscClient, SearchClient } from 'api/v1/v1_grpc_web_pb';
import { AnomalyFilter, DashboardReq, UserRef, TeamRef } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';
// =================================================================
// Initial state
// =================================================================

export const initialState = {
    checksInProgress: 0,
    newAnomalies: 0,
    confirmedAnomalies: 0,
    anomaliesInProgress: 0,
    newAnomalyWeekLists: [],
    completedWeekLists: [],
    rejectedWeekLists: [],
    latestAnomalyList: [],
    weekSLA: {
        min: 0,
        avg: 0,
        max: 0,
        historyGramList: [],
    },

    loading: true,
};

// =================================================================
// Dashboard slice
// =================================================================

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardItems(state, { payload }) {
            const {
                checksInProgress,
                newAnomalies,
                confirmedAnomalies,
                anomaliesInProgress,
                newAnomalyWeekLists,
                completedWeekLists,
                rejectedWeekLists,
                latestAnomalyList,
                weekSLA,
            } = payload;
            state.checksInProgress = checksInProgress;
            state.newAnomalies = newAnomalies;
            state.confirmedAnomalies = confirmedAnomalies;
            state.anomaliesInProgress = anomaliesInProgress;
            state.newAnomalyWeekLists = newAnomalyWeekLists;
            state.completedWeekLists = completedWeekLists;
            state.rejectedWeekLists = rejectedWeekLists;
            state.latestAnomalyList = latestAnomalyList;
            state.weekSLA = weekSLA;

            state.loading = false;
        },

        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Dashboard actions
// ===================================================================

export const { setLoading, setDashboardItems } = dashboardSlice.actions;

// ====================================================================
// Dashboard selector
// ====================================================================

export const dashboardSelector = (state) => state.dashboard;

// =====================================================================
// Dashboard reducer
// =====================================================================

export default dashboardSlice.reducer;

// ======================================================================
// Fetch Dashboard data
// ======================================================================
const roundedHours = (seconds) => (seconds / 3600).toFixed(1);
export const fetchDashboardData =
    ({ filteredTeam, filteredMember, fromDate, toDate }) =>
    async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const dashboardReq = new DashboardReq();

            if (filteredTeam) {
                dashboardReq.setTeam(ref(TeamRef, filteredTeam.id));
            }
            if (filteredMember) {
                dashboardReq.setUser(ref(UserRef, filteredMember.userId));
            }
            if (fromDate) {
                dashboardReq.setStart(fromDate / 1000);
            }
            if (toDate) {
                dashboardReq.setEnd(toDate / 1000);
            }

            const result = await authRequestHandler(MiscClient, 'getDashboard', dashboardReq);

            dispatch(
                setDashboardItems({
                    // Blocks data
                    checksInProgress: result.getAnalyseexecsopen(),
                    newAnomalies: result.getAnomaliescurrentstateList()[0],
                    confirmedAnomalies: result.getAnomaliescurrentstateList()[1],
                    anomaliesInProgress: result.getAnomaliescurrentstateList()[3],
                    // Chart data
                    newAnomalyWeekLists: result.getHandlingsweekList()[1].getCountsList(),
                    completedWeekLists: result.getHandlingsweekList()[4].getCountsList(),
                    rejectedWeekLists: result.getHandlingsweekList()[2].getCountsList(),
                    latestAnomalyList: result.getLatestanomaliesList().map((list) => list.getId()),
                    // SLAs data
                    weekSLA: {
                        min: result.getResolution().getWeek().getMin() || 0,
                        avg: result.getResolution().getWeek().getAvg() || 0,
                        max: result.getResolution().getWeek().getMax() || 0,
                        historyGramList: result
                            .getResolution()
                            .getWeek()
                            .getHistogramList()
                            .map((hour) => roundedHours(hour)),
                    },
                }),
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

// ======================================================================
// Fetch Dashboard data
// ======================================================================

export const downloadHistory = () => async () => {
    const newAnomalyFilter = new AnomalyFilter();

    const result = await authRequestHandler(SearchClient, 'anomaliesCSV', newAnomalyFilter);

    const content = result.getContent();
    const a = document.createElement('a');
    const file = new Blob([content], { type: 'text/csv' });

    a.href = URL.createObjectURL(file);
    a.download = 'history.csv';
    a.click();

    URL.revokeObjectURL(a.href);
};
