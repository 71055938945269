import { useCallback } from 'react';
import useCatchError from 'hooks/useCatchError';

export interface UseCatchErrorCallback {
    (callback: (...args: any[]) => Promise<void> | void, dependencies: any[]): (...args: any[]) => void;
}

const useCatchErrorCallback: UseCatchErrorCallback = (callback, dependencies) => {
    const catchError = useCatchError();

    return useCallback((...args) => catchError(() => callback(...args)), dependencies);
};

export default useCatchErrorCallback;
