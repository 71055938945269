import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';

import useRoutes from 'routes/routes';

import { drawerWidth, drawerMiniWidth, transition } from 'assets/jss/material-dashboard-pro-react';

import beewooMiniLogo from 'assets/img/beewooMini.png';
import beewooLogo from 'assets/img/beewoo.png';
import AdminContent from './AdminContent';
import { selectIsAuthenticated, selectAuthUser, selectActiveOrg } from 'store/selectors/authSelectors';
import { selectOrgUsers } from 'store/selectors/orgUserSelectors';

import { fetchOrgUserLists } from 'store/slices/orgUserSlice';

var ps;

//Change default name Dashboard to AdminLayout
const AdminLayout = (props) => {
    const { ...rest } = props;
    const routes = useRoutes();
    const dispatch = useDispatch();
    // Get Redux Store data
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const activeOrg = useSelector(selectActiveOrg);
    const authUser = useSelector(selectAuthUser);
    const orgUsers = useSelector(selectOrgUsers);
    // states and functions
    const [mobileOpen, setMobileOpen] = useState(false);
    const [miniActive, setMiniActive] = useState(false);

    // styles
    const classes = useStyles();
    const mainPanelClasses =
        classes.mainPanel +
        ' ' +
        cx({
            [classes.mainPanelSidebarMini]: miniActive,
            [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
        });
    // ref for main panel div
    const mainPanel = React.createRef();
    // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
    React.useEffect(() => {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
            document.body.style.overflow = 'hidden';
        }
        window.addEventListener('resize', resizeFunction);

        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf('Win') > -1) {
                ps.destroy();
            }
            window.removeEventListener('resize', resizeFunction);
        };
    });

    const handleDrawerToggle = useCallback(() => {
        setMobileOpen((prevState) => !prevState);
    }, []);

    const sidebarMinimizeHandler = useCallback(() => {
        setMiniActive((prevState) => !prevState);
    }, []);

    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    // Get org users according to active org
    useEffect(() => {
        dispatch(fetchOrgUserLists());
    }, [activeOrg]);

    // If user don't set his/her name
    useEffect(() => {
        if (isAuthenticated && !authUser?.name) {
            rest.history.push('/admin/profile');
        }
    }, [authUser, isAuthenticated]);

    // Check use is admin or not
    const isAdmin = useMemo(
        () => orgUsers.some((orgUser) => orgUser.isAdmin && orgUser.userId === authUser?.id),
        [orgUsers, authUser],
    );

    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                logoMini={beewooMiniLogo}
                logo={beewooLogo}
                // image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color="blue"
                bgColor="white"
                miniActive={miniActive}
                isAdmin={isAdmin}
                {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
                <AdminNavbar
                    sidebarMinimizeHandler={sidebarMinimizeHandler}
                    miniActive={miniActive}
                    handleDrawerToggle={handleDrawerToggle}
                    pathname={props.location.pathname}
                    // {...rest}
                />
                <AdminContent isAdmin={isAdmin} />
            </div>
        </div>
    );
};
AdminLayout.propTypes = {
    location: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        top: '0',
        height: '100vh',
        '&:after': {
            display: 'table',
            clear: 'both',
            content: '" "',
        },
    },
    mainPanel: {
        transitionProperty: 'top, bottom, width',
        transitionDuration: '.2s, .2s, .35s',
        transitionTimingFunction: 'linear, linear, ease',
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        overflow: 'auto',
        position: 'relative',
        float: 'right',
        ...transition,
        maxHeight: '100%',
        width: '100%',
        overflowScrolling: 'touch',
    },

    mainPanelSidebarMini: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerMiniWidth}px)`,
        },
    },
    mainPanelWithPerfectScrollbar: {
        overflow: 'hidden !important',
    },
}));

export default AdminLayout;
