import { createSelector } from 'reselect';
import { notificationSelector } from 'store/slices/notificationSlice';

export const selectNotificationLoading = createSelector(notificationSelector, (notification) => notification.loading);

export const selectNotificationUpdated = createSelector(
    notificationSelector,
    (notification) => notification.notificationsUpdated,
);
export const selectNotifications = createSelector(notificationSelector, (notification) => notification.notifications);
export const selectNotificationsError = createSelector(notificationSelector, (notification) => notification.error);
