import React, { memo } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';

const NavbarDropDown = ({ buttonIcon, buttonProps, rtlActive, className, ...rest }) => {
    const classes = useStyles();
    const buttonIconClass =
        classes.headerLinksSvg + ' ' + (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links);

    return (
        <CustomDropdown
            className={`${className} ${classes.managerClasses}`}
            buttonIcon={
                React.isValidElement(buttonIcon)
                    ? React.cloneElement(buttonIcon, {
                          className: buttonIconClass,
                      })
                    : null
            }
            dropPlacement="top"
            caret={false}
            buttonProps={{
                color: 'transparent',
                justIcon: true,
                className: rtlActive ? classes.buttonLinkRTL : classes.buttonLink,
                muiClasses: {
                    label: rtlActive ? classes.labelRTL : '',
                },
                ...buttonProps,
            }}
            {...rest}
        />
    );
};

NavbarDropDown.propTypes = {
    buttonIcon: PropTypes.node,
    buttonProps: PropTypes.object,
    rtlActive: PropTypes.bool,
    className: PropTypes.string,
};

export default memo(NavbarDropDown, isEqual);
const useStyles = makeStyles(() => ({
    linkText: {
        zIndex: '4',
        // ...defaultFont,
        fontSize: '14px',
        margin: '0!important',
        textTransform: 'none',
    },
    buttonLink: {
        // [theme.breakpoints.down('sm')]: {
        //     display: 'flex',
        //     margin: '5px 15px 0',
        //     width: 'auto',
        //     height: 'auto',
        //     '& svg': {
        //         width: '30px',
        //         height: '24px',
        //         marginRight: '19px',
        //         marginLeft: '3px',
        //     },
        //     '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        //         width: '30px',
        //         fontSize: '24px',
        //         lineHeight: '30px',
        //         marginRight: '19px',
        //         marginLeft: '3px',
        //     },
        // },
    },
    searchButton: {
        // [theme.breakpoints.down('sm')]: {
        //     top: '-50px !important',
        //     marginRight: '38px',
        //     float: 'right',
        // },
    },
    top: {
        zIndex: '4',
    },
    searchIcon: {
        width: '17px',
        zIndex: '4',
    },
    links: {
        width: '20px',
        height: '20px',
        zIndex: '4',

        // [theme.breakpoints.down('sm')]: {
        //     display: 'block',
        //     width: '30px',
        //     height: '30px',
        //     color: 'inherit',
        //     opacity: '0.8',
        //     marginRight: '16px',
        //     marginLeft: '-5px',
        // },
    },

    buttonLinkRTL: {
        // [theme.breakpoints.down('sm')]: {
        //     alignItems: 'center',
        //     justifyContent: 'flex-end',
        //     width: '-webkit-fill-available',
        //     margin: '10px 15px 0',
        //     padding: '10px 15px',
        //     display: 'block',
        //     position: 'relative',
        // },
    },
    labelRTL: {
        // [theme.breakpoints.down('sm')]: {
        //     flexDirection: 'row-reverse',
        //     justifyContent: 'initial',
        //     display: 'flex',
        // },
    },
    linksRTL: {
        // [theme.breakpoints.down('sm')]: {
        //     marginRight: '-5px !important',
        //     marginLeft: '16px !important',
        // },
    },
    managerClasses: {
        // [theme.breakpoints.up('md')]: {
        display: 'inline-block',
        // },
    },
    headerLinksSvg: {
        width: '20px !important',
        height: '20px !important',
    },
}));
