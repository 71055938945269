import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// API items

import { ZonesServiceClient, SearchClient } from 'api/v1/v1_grpc_web_pb';
import { Zone, Point, OrgRef, Category, GPSPoint } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================
export const initialState = {
    zones: [],
    loading: true,
};

// =================================================================
// Zone slice
// =================================================================

const zoneSlice = createSlice({
    name: 'zone',
    initialState,
    reducers: {
        setZones(state, { payload }) {
            state.zones = payload;
        },

        addZone(state, { payload }) {
            state.zones.push(payload);
        },

        removeZone(state, { payload }) {
            state.zones = state.zones.filter((zone) => zone.id !== payload);
        },

        updateZone(state, { payload }) {
            state.zones = state.zones.map((zone) => {
                if (zone.id === payload.id) {
                    return payload;
                }
                return zone;
            });
        },

        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Zone actions
// ===================================================================

export const { addZone, removeZone, updateZone, setZones, setLoading } = zoneSlice.actions;

// ====================================================================
// Zone selector
// ====================================================================
export const zoneSelector = (state) => state.zone;

// =====================================================================
// Zone reducer
// =====================================================================

export default zoneSlice.reducer;

// ======================================================================
// Fetch zones
// ======================================================================

export const fetchZones = () => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        //Retrieving zones
        const result = await authRequestHandler(ZonesServiceClient, 'list');
        const zones = result.getItemsList().map((zone) => {
            return {
                id: zone.getId(),
                name: zone.getName(),
                isPublic: zone.getIspublic(),
                ownerId: zone.getOrg().getId(),
                floors: zone.getFloorsList(),
                polygons: zone.getPolygonList().map((singlePoint) => [singlePoint.getLat(), singlePoint.getLong()]),
                activeCategories: zone.getAllowedcategoriesList().map((category) => ({
                    id: category.getId(),
                    name: category.getName(),
                    logo: category.getLogo(),
                    isActive: category.getIsactive(),
                })),
            };
        });
        dispatch(setZones(zones));
        return result;
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Create zone
// ======================================================================

export const createZone = (zone) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();

    zone.id = uuidv4();
    const { name, isPublic, floors, polygons, id, activeCategories } = zone;

    // New zone
    const newZone = new Zone();
    newZone.setId(id);
    newZone.setName(name);
    newZone.setIspublic(isPublic);

    //New Org
    const newOrg = new OrgRef();
    newOrg.setId(activeOrg);
    newZone.setOrg(newOrg);

    // Set Polygon list
    const polygonList = polygons.map((latLng) => {
        const point = new Point();
        point.setLat(latLng[0]);
        point.setLong(latLng[1]);
        return point;
    });

    newZone.setPolygonList(polygonList);
    newZone.setFloorsList(floors);

    // Set active category lists
    const activeCategoryLists = activeCategories.map(({ id, name, logo, isActive }) => {
        const category = new Category();
        category.setId(id);
        category.setName(name);
        category.setLogo(logo);
        category.setIsactive(isActive);

        return category;
    });
    newZone.setAllowedcategoriesList(activeCategoryLists);

    // Request to the API
    const result = await authRequestHandler(ZonesServiceClient, 'add', newZone);
    dispatch(addZone(zone));

    return result;
};

// ======================================================================
// Edit zone
// ======================================================================

export const editZone = (zone) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();
    const { id, name, isPublic, floors, polygons, activeCategories } = zone;

    // New zone
    const updated = await getZoneDetails(id, false);

    updated.setName(name);
    updated.setIspublic(isPublic);

    //New Org
    const newOrg = new OrgRef();
    newOrg.setId(activeOrg);
    updated.setOrg(newOrg);

    // Set Polygon list
    const polygonList = polygons.map((latLng) => {
        const point = new Point();
        point.setLat(latLng[0]);
        point.setLong(latLng[1]);
        return point;
    });

    updated.setPolygonList(polygonList);
    updated.setFloorsList(floors);

    // Set active category lists
    const activeCategoryLists = activeCategories.map(({ id, name, logo, isActive }) => {
        const category = new Category();
        category.setId(id);
        category.setName(name);
        category.setLogo(logo);
        category.setIsactive(isActive);

        return category;
    });
    updated.setAllowedcategoriesList(activeCategoryLists);

    // Request to the API
    const result = await authRequestHandler(ZonesServiceClient, 'set', updated);
    dispatch(updateZone(zone));

    return result;
};

// ======================================================================
// Delete zone
// ======================================================================

export const deleteZone = (zoneId) => async (dispatch) => {
    // Request to the API
    const result = await authRequestHandler(ZonesServiceClient, 'delete', ref(Zone, zoneId));
    dispatch(removeZone(zoneId));

    return result;
};
// ======================================================================
// Get single zone
// ======================================================================

export const getZoneDetails = async (zoneId, extracted = true) => {
    // Request to the API
    const result = await authRequestHandler(ZonesServiceClient, 'get', ref(Zone, zoneId));
    if (extracted) {
        return {
            id: result.getId(),
            name: result.getName(),
            isPublic: result.getIspublic(),
            ownerId: result.getOrg().getId(),
            floors: result.getFloorsList(),
            polygons: result.getPolygonList().map((singlePoint) => [singlePoint.getLat(), singlePoint.getLong()]),
        };
    }
    return result;
};

// Get zone list of specific area
export const getZoneListByPosition = async (latlng) => {
    const point = new Point();
    point.setLat(latlng[0]);
    point.setLong(latlng[1]);
    const gpsPoint = new GPSPoint();
    gpsPoint.setPoint(point);

    const result = await authRequestHandler(SearchClient, 'zones', gpsPoint);

    return result.getItemsList().map((zone) => {
        return {
            id: zone.getId(),
            name: zone.getName(),
            isPublic: zone.getIspublic(),
            orgId: zone.getOrg().getId(),
            ownerId: zone.getOrg().getId(),
            floors: zone.getFloorsList(),
            polygons: zone.getPolygonList().map((singlePoint) => [singlePoint.getLat(), singlePoint.getLong()]),
            activeCategories: zone.getAllowedcategoriesList().map((category) => ({
                id: category.getId(),
                name: category.getName(),
                logo: category.getLogo(),
                isActive: category.getIsactive(),
            })),
        };
    });
};
