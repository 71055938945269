import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { useIntl } from 'react-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// @material-ui/icons
// import Dashboard from '@material-ui/icons/Dashboard';
import Menu from '@material-ui/icons/Menu';
import PersonAdd from '@material-ui/icons/PersonAdd';
import InfoIcon from '@material-ui/icons/Info';
import Fingerprint from '@material-ui/icons/Fingerprint';
import LocaleSwitcher from 'components/Navbars/LocaleSwitcher';
// import LockOpen from '@material-ui/icons/LockOpen';
// import MonetizationOn from '@material-ui/icons/MonetizationOn';
import beewooLogo from 'assets/img/beewoo.png';
// core components
import Button from 'components/CustomButtons/Button';
import {
    container,
    defaultFont,
    primaryColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    boxShadow,
    drawerWidth,
    transition,
    whiteColor,
    grayColor,
    blackColor,
    hexToRgb,
} from 'assets/jss/material-dashboard-pro-react';

export default function AuthNavbar(props) {
    const intl = useIntl();
    const [open, setOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    const classes = useStyles();
    const { color } = props;

    const appBarClasses = cx({
        [' ' + classes[color]]: color,
    });

    var list = (
        <List className={classes.list}>
            <ListItem className={cx(classes.listItem, classes.languageSwitcher)}>
                <LocaleSwitcher dropPlacement="top-start" />
            </ListItem>
            <ListItem className={classes.listItem}>
                <a
                    href="https://about.beewoo.app/"
                    className={cx(classes.navLink, {
                        [classes.navLinkActive]: activeRoute('https://about.beewoo.app/'),
                    })}
                >
                    <InfoIcon className={classes.listItemIcon} />
                    <ListItemText
                        primary={intl.formatMessage({ defaultMessage: 'About' })}
                        disableTypography={true}
                        className={classes.listItemText}
                    />
                </a>
            </ListItem>
            <ListItem className={classes.listItem}>
                <NavLink
                    to={'/auth/register'}
                    className={cx(classes.navLink, {
                        [classes.navLinkActive]: activeRoute('/auth/register'),
                    })}
                >
                    <PersonAdd className={classes.listItemIcon} />
                    <ListItemText
                        primary={intl.formatMessage({ defaultMessage: 'Register' })}
                        disableTypography={true}
                        className={classes.listItemText}
                    />
                </NavLink>
            </ListItem>
            <ListItem className={classes.listItem}>
                <NavLink
                    to={'/auth/login'}
                    className={cx(classes.navLink, {
                        [classes.navLinkActive]: activeRoute('/auth/login'),
                    })}
                >
                    <Fingerprint className={classes.listItemIcon} />
                    <ListItemText
                        primary={intl.formatMessage({ defaultMessage: 'Login' })}
                        disableTypography={true}
                        className={classes.listItemText}
                    />
                </NavLink>
            </ListItem>
        </List>
    );
    return (
        <AppBar position="static" className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    <Button href="#" className={classes.title} color="transparent">
                        <img src={beewooLogo} alt="Beewoo" />
                    </Button>
                </div>

                <Hidden smDown>{list}</Hidden>
                <Hidden mdUp>
                    <Button
                        className={classes.sidebarButton}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
                <Hidden mdUp>
                    <Hidden mdUp>
                        <Drawer
                            variant="temporary"
                            anchor={'right'}
                            open={open}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {list}
                        </Drawer>
                    </Hidden>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    brandText: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        position: 'absolute',
        width: '100%',
        paddingTop: '10px',
        zIndex: '1029',
        color: grayColor[6],
        border: '0',
        borderRadius: '3px',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
    },
    container: {
        ...container,
        minHeight: '50px',
    },
    flex: {
        flex: 1,
    },
    title: {
        ...defaultFont,
        lineHeight: '30px',
        fontSize: '18px',
        borderRadius: '3px',
        textTransform: 'none',
        color: whiteColor,
        letterSpacing: 'unset',
        '&:hover,&:focus': {
            background: 'transparent',
            color: whiteColor,
        },
    },
    appResponsive: {
        top: '8px',
    },
    primary: {
        backgroundColor: primaryColor[0],
        color: whiteColor,
        ...defaultBoxShadow,
    },
    info: {
        backgroundColor: infoColor[0],
        color: whiteColor,
        ...defaultBoxShadow,
    },
    success: {
        backgroundColor: successColor[0],
        color: whiteColor,
        ...defaultBoxShadow,
    },
    warning: {
        backgroundColor: warningColor[0],
        color: whiteColor,
        ...defaultBoxShadow,
    },
    danger: {
        backgroundColor: dangerColor[0],
        color: whiteColor,
        ...defaultBoxShadow,
    },
    list: {
        ...defaultFont,
        fontSize: '14px',
        margin: 0,
        marginRight: '-15px',
        paddingLeft: '0',
        listStyle: 'none',
        color: whiteColor,
        paddingTop: '0',
        paddingBottom: '0',
        [theme.breakpoints.up('md')]: {
            color: grayColor[2],
        },
    },
    listItem: {
        float: 'left',
        position: 'relative',
        display: 'block',
        width: 'auto',
        margin: '0',
        padding: '0',
        [theme.breakpoints.down('sm')]: {
            zIndex: '999',
            width: '100%',
            paddingRight: '15px',
        },
    },
    languageSwitcher: {
        '& button': {
            margin: '0 !important',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 15px',
            zIndex: 9999,
        },
    },
    navLink: {
        color: whiteColor,
        margin: '0 5px',
        fontWeight: '500',
        fontSize: '12px',
        textTransform: 'uppercase',
        borderRadius: '3px',
        lineHeight: '20px',
        position: 'relative',
        display: 'block',
        padding: '10px 15px',
        textDecoration: 'none',

        '&:hover,&:focus': {
            color: whiteColor,
            background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.3)',
        },

        [theme.breakpoints.up('md')]: {
            color: grayColor[2],
            '&:hover,&:focus': {
                color: grayColor[2],
                background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.3)',
            },
        },
    },
    listItemIcon: {
        marginTop: '-3px',
        top: '0px',
        position: 'relative',
        marginRight: '3px',
        width: '20px',
        height: '20px',
        verticalAlign: 'middle',
        color: 'inherit',
        display: 'inline-block',
    },
    listItemText: {
        flex: 'none',
        padding: '0',
        minWidth: '0',
        margin: 0,
        display: 'inline-block',
        position: 'relative',
        whiteSpace: 'nowrap',
    },
    navLinkActive: {
        backgroundColor: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
    },
    drawerPaper: {
        border: 'none',
        bottom: '0',
        transitionProperty: 'top, bottom, width',
        transitionDuration: '.2s, .2s, .35s',
        transitionTimingFunction: 'linear, linear, ease',
        ...boxShadow,
        width: drawerWidth,
        ...boxShadow,
        position: 'fixed',
        display: 'block',
        top: '0',
        height: '100vh',
        right: '0',
        left: 'auto',
        visibility: 'visible',
        overflowY: 'visible',
        borderTop: 'none',
        textAlign: 'left',
        paddingRight: '0px',
        paddingLeft: '0',
        ...transition,
        '&:before,&:after': {
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            content: '""',
            display: 'block',
            top: '0',
        },
        '&:after': {
            background: blackColor,
            opacity: '.8',
        },
    },
    sidebarButton: {
        color: grayColor[2] + '!important',
        '&,&:hover,&:focus': {
            color: whiteColor,
        },
        top: '-2px',
    },
}));
