import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';

// API items

import { EquipmentTypesServiceClient } from 'api/v1/v1_grpc_web_pb';
import { EquipmentType, DocRef, OrgRef, EquipmentTypeRef } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    equipmentTypes: [],
    loading: true,
};

// =================================================================
// Equipment type slice
// =================================================================

const equipmentTypeSlice = createSlice({
    name: 'equipmentType',
    initialState,
    reducers: {
        setEquipmentTypes(state, { payload }) {
            state.equipmentTypes = payload;
        },

        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Equipment type actions
// ===================================================================

export const { setEquipmentTypes, setLoading } = equipmentTypeSlice.actions;

// ====================================================================
// Equipment type selector
// ====================================================================

export const equipmentTypeSelector = (state) => state.equipmentType;

// =====================================================================
// Equipment type reducer
// =====================================================================

export default equipmentTypeSlice.reducer;

// ======================================================================
// Fetch Equipment types
// ======================================================================

export const fetchEquipmentTypes = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const result = await authRequestHandler(EquipmentTypesServiceClient, 'list');
        const equipmentTypes = result.getItemsList().map((equipmentType) => ({
            id: equipmentType.getId(),
            name: equipmentType.getName(),
            publicNote: equipmentType.getPublicnote(),
            note: equipmentType.getNote(),
            metrics: equipmentType.getMetricsList(),
            documents: equipmentType
                .getDocsList()
                .map((doc) => ({ url: doc.getUrl(), title: doc.getTitle()?.trim(), tempId: uuidv4() })),
        }));
        dispatch(setEquipmentTypes(equipmentTypes));
        return equipmentTypes;
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Add new equipment type
// ======================================================================

export const addEquipmentType =
    ({ name, publicNote, note, metrics, documents }) =>
    async (dispatch, getState) => {
        const {
            auth: { activeOrg },
        } = getState();

        //New Equipment type
        const newEquipmentType = new EquipmentType();
        newEquipmentType.setId(uuidv4());
        newEquipmentType.setName(name);
        newEquipmentType.setPublicnote(publicNote);
        newEquipmentType.setNote(note);

        //New Org
        const newOrg = new OrgRef();
        newOrg.setId(activeOrg);
        newEquipmentType.setOrg(newOrg);

        // Metrics
        newEquipmentType.setMetricsList(metrics);
        // set docs list
        newEquipmentType.setDocsList(
            documents.map((doc) => {
                const newDoc = new DocRef();
                newDoc.setUrl(doc.url);
                newDoc.setTitle(doc.title);
                return newDoc;
            }),
        );

        //Adding Equipment
        const result = await authRequestHandler(EquipmentTypesServiceClient, 'add', newEquipmentType);
        dispatch(fetchEquipmentTypes());
        return result;
    };

// ======================================================================
// Update equipment type
// ======================================================================

export const updateEquipmentType = (updatedEquipmentType) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();
    const { id, name, publicNote, note, metrics, documents } = updatedEquipmentType;

    //New Equipment type
    const newEquipmentType = new EquipmentType();
    newEquipmentType.setId(id);
    newEquipmentType.setName(name);
    newEquipmentType.setPublicnote(publicNote);
    newEquipmentType.setNote(note);
    //New Org
    const newOrg = new OrgRef();
    newOrg.setId(activeOrg);
    newEquipmentType.setOrg(newOrg);

    // Metrics
    newEquipmentType.setMetricsList(metrics);
    // set docs list
    newEquipmentType.setDocsList(
        documents.map((doc) => {
            const newDoc = new DocRef();
            newDoc.setUrl(doc.url);
            newDoc.setTitle(doc.title);
            return newDoc;
        }),
    );

    //Request to the API
    const result = await authRequestHandler(EquipmentTypesServiceClient, 'set', newEquipmentType);
    // Update store
    const updatedEquipmentTypes = copyObj(getState().equipmentType.equipmentTypes).map((equipmentType) => {
        if (equipmentType.id === updatedEquipmentType.id) {
            return updatedEquipmentType;
        }
        return equipmentType;
    });

    dispatch(setEquipmentTypes(updatedEquipmentTypes));
    return result;
};

// ======================================================================
// Delete equipment type
// ======================================================================
export const deleteEquipmentType = (deleteEquipmentTypeId) => async (dispatch, getState) => {
    // Request to the API

    const result = await authRequestHandler(
        EquipmentTypesServiceClient,
        'delete',
        ref(EquipmentTypeRef, deleteEquipmentTypeId),
    );
    const updatedUsers = copyObj(getState().equipmentType.equipmentTypes).filter(
        (equipmentType) => equipmentType.id !== deleteEquipmentTypeId,
    );

    dispatch(setEquipmentTypes(updatedUsers));
    return result;
};

// ======================================================================
// Get a equipment type details
// ======================================================================

export const getEquipmentTypeDetails = async (equipmentTypeId, extracted = true) => {
    const equipmentType = new EquipmentType();
    equipmentType.setId(equipmentTypeId);
    // Request to the API
    const result = await authRequestHandler(EquipmentTypesServiceClient, 'get', equipmentType);
    if (extracted) {
        return {
            id: result.getId(),
            name: result.getName(),
            publicNote: result.getPublicnote(),
            note: result.getNote(),
            metrics: result.getMetricsList(),
            documents: result
                .getDocsList()
                .map((doc) => ({ url: doc.getUrl(), title: doc.getTitle()?.trim(), tempId: uuidv4() })),
        };
    }
    return result;
};
