import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { getMasterToken } from 'utils/auth';
import CustomError from 'utils/customError';
// API items
import { UsersServiceClient, AuthClient, OrgsServiceClient, Org } from 'api/v1/v1_grpc_web_pb';
import { LoginReq, OrgRef, UserRef, RegisterReq, User } from 'api/v1/v1_pb';
import authRequestHandler, { fetchToken, requestHandler, ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    isAuthenticated: false,
    authUser: null,
    activeOrg: null,
    loading: false,
};

// =================================================================
// Auth slice
// =================================================================

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthUser(state, { payload }) {
            state.authUser = payload;
        },
        setAuth(state) {
            state.isAuthenticated = true;
        },
        removeAuth(state) {
            state.isAuthenticated = false;
            state.authUser = null;
            state.activeOrg = null;
        },
        setActiveOrg(state, { payload }) {
            state.activeOrg = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Auth actions
// ===================================================================
export const { setAuth, removeAuth, setAuthUser, setActiveOrg, setLoading } = authSlice.actions;

// ====================================================================
// Auth selector
// ====================================================================

export const authSelector = (state) => state.auth;

// =====================================================================
// Auth reducer
// =====================================================================

export default authSlice.reducer;

// LOGIN req to get master token
const fetchMasterToken = async (user) => {
    const newLoginReq = new LoginReq();
    newLoginReq.setUsername(user.username);
    newLoginReq.setPassword(user.password);
    const result = await requestHandler(AuthClient, 'login', newLoginReq, {});
    localStorage.setItem('mToken', result.getToken());
    return result.getId();
};

// Fetch  organizations details
const fetchOrgDetails = async (orgId) => {
    const result = await requestHandler(OrgsServiceClient, 'get', ref(OrgRef, orgId), getMasterToken());

    return {
        id: result.getId(),
        name: result.getName(),
        logo: result.getLogo(),
        isValid: result.getIsvalid(),
    };
};

// get auth User without the org details
export const getAuthUser = (userId) => async (dispatch) => {
    const result = await requestHandler(UsersServiceClient, 'get', ref(UserRef, userId), getMasterToken());

    const authUser = {
        id: result.getId(),
        name: result.getName() || '',
        email: result.getEmail(),

        avatar: result.getAvatar(),
        inviteCode: result.getInvitecode(),
        orgs: await Promise.all(result.getRolesList().map((orgId) => fetchOrgDetails(orgId.getOrgid()))),
    };
    localStorage.setItem('authUser', JSON.stringify(authUser));
    dispatch(setAuthUser(authUser));
    return authUser;
};

// =====================================================================
// Login handler
// =====================================================================

export const loginHandler = (user) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const userId = await fetchMasterToken(user);
        const authUser = await dispatch(getAuthUser(userId));

        const storedActiveOrg = localStorage.getItem('activeOrg');
        let activeOrg = null;

        if (storedActiveOrg && authUser.orgs.length > 0 && authUser.orgs.some((org) => org.id === storedActiveOrg)) {
            activeOrg = storedActiveOrg;
        } else {
            activeOrg = authUser.orgs[0]?.id || null;
        }

        const tokenType = activeOrg ? 'orgToken' : 'publicToken';
        localStorage.setItem('activeOrg', activeOrg);
        dispatch(setActiveOrg(activeOrg));
        await fetchToken(tokenType);
        dispatch(setAuth());
    } finally {
        dispatch(setLoading(false));
    }
};

// =====================================================================
// Register handler
// =====================================================================

export const registerHandler =
    ({ email, password, name }) =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const newRegisterReq = new RegisterReq();
            newRegisterReq.setUsername(email);
            newRegisterReq.setPassword(password);
            newRegisterReq.setEmail(email);

            const result = await requestHandler(AuthClient, 'register', newRegisterReq);
            const adminUser = new User();
            adminUser.setId(result.getId());
            adminUser.setName(name);
            adminUser.setEmail(email);

            await requestHandler(UsersServiceClient, 'set', adminUser, { authn: result.getToken() });
        } finally {
            dispatch(setLoading(false));
        }
    };

// =====================================================================
// setAuthentication only for initial load
// =====================================================================

export const setAuthentication = () => async (dispatch) => {
    try {
        // Get the master and org token
        const masterToken = getMasterToken().authn;
        if (!masterToken) return;

        const decoded = jwtDecode(masterToken);
        if (decoded.exp <= new Date().getTime() / 1000)
            throw new CustomError({ name: 'Invalid token', message: 'Token is expired' });

        // Get the auth user
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        if (!authUser) return;

        // Set the auth user into the store
        dispatch(setAuthUser(authUser));
        const storedActiveOrg = localStorage.getItem('activeOrg');
        let activeOrg = null;

        if (storedActiveOrg && authUser.orgs.length > 0 && authUser.orgs.some((org) => org.id === storedActiveOrg)) {
            activeOrg = storedActiveOrg;
        } else {
            activeOrg = authUser.orgs[0]?.id || null;
        }

        localStorage.setItem('activeOrg', activeOrg);
        const tokenType = activeOrg ? 'orgToken' : 'publicToken';
        dispatch(setActiveOrg(activeOrg));
        dispatch(setAuth());

        await dispatch(getAuthUser(authUser.id));
        await fetchToken(tokenType);
    } catch (error) {
        dispatch(logOutHandler());
    }
};

// =====================================================================
// Log out handler
// =====================================================================

export const logOutHandler = () => (dispatch) => {
    localStorage.removeItem('mToken');
    localStorage.removeItem('orgToken');
    localStorage.removeItem('publicToken');
    localStorage.removeItem('authUser');
    dispatch(removeAuth());
};

// ======================================================================
// Change active org
// ======================================================================
export const changeOrg = (orgId) => async (dispatch) => {
    localStorage.setItem('activeOrg', orgId);
    await fetchToken();
    dispatch(setActiveOrg(orgId));
};

// ======================================================================
// Create org
// ======================================================================

export const createOrg = (org) => async (dispatch, getState) => {
    const authUserId = getState().auth.authUser.id;
    const newOrgId = uuidv4();
    const newOrg = new Org();
    newOrg.setId(newOrgId);
    newOrg.setName(org.name);
    newOrg.setLogo(org.logo);
    const adminRef = new User();
    adminRef.setId(authUserId);
    newOrg.setOwner(adminRef);
    newOrg.setType(org.type);
    newOrg.setIsvalid(false);

    await authRequestHandler(OrgsServiceClient, 'add', newOrg);

    const authUser = await dispatch(getAuthUser(authUserId));

    // If the updated authUser has the newly created org Id then set this org id as active org and fetch new org token
    if (authUser.orgs.some((org) => org.id === newOrgId)) {
        localStorage.setItem('activeOrg', newOrgId);
        await fetchToken();
        dispatch(setActiveOrg(newOrgId));
    }
};
