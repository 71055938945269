import React, { createContext, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useIntl } from 'react-intl';
import ToastCloseBtn from 'components/ToastCloseBtn/ToastCloseBtn';
import { apiURL } from 'config';

export interface CatchErrorFunc {
    (callback: () => Promise<void> | void): void;
}

export const CatchErrorContext = createContext({} as CatchErrorFunc);

const CatchErrorProvider: React.FC = ({ children }) => {
    const intl = useIntl();
    const catchError: CatchErrorFunc = useCallback(async (callback) => {
        try {
            return await callback();
        } catch (error: any) {
            const { code } = error;

            if (code === 4) {
                toast.error(
                    intl.formatMessage({
                        defaultMessage: 'Looks like data is loading slowly. Please try again after some time.',
                    }),
                    { toastId: 'code-4-error' },
                );
            } else if (code === 16 || code === 7) {
                toast.error(intl.formatMessage({ defaultMessage: 'You are not permitted to doing this action' }), {
                    toastId: 'custom-16-7-error',
                });
            } else {
                toast.error(
                    intl.formatMessage({
                        defaultMessage: 'Oops, something went wrong. please try again few moments later...',
                    }),
                    {
                        toastId: 'custom-error',
                    },
                );
            }

            if (apiURL?.indexOf('alpha') !== -1 || apiURL?.indexOf('beta') !== -1||!!localStorage.getItem('debug')) {
                console.dir(error);

            }
        }
    }, []);

    return (
        <CatchErrorContext.Provider value={catchError}>
            {children}
            <ToastContainer
                position="bottom-center"
                autoClose={4000}
                newestOnTop
                hideProgressBar
                closeOnClick
                rtl={false}
                // pauseOnVisibilityChange
                draggable={false}
                className="toast-container"
                toastClassName="material-toast"
                closeButton={<ToastCloseBtn />}
            />
        </CatchErrorContext.Provider>
    );
};

export default CatchErrorProvider;
