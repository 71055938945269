/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0';
import './app.scss';
import PrivateRoute from 'routes/PrivateRoute';
import AuthLayout from 'layouts/Auth';
import RtlLayout from 'layouts/RTL';
import AdminLayout from 'layouts/Admin';

import { selectIsRTLActive } from 'store/selectors/localizationSelectors';
import createTheme from './theme';
import CatchErrorProvider from 'contexts/CatchError/CatchError';
import useTitle from 'hooks/useTitle';

const App = () => {
    useTitle();
    const isRtlEnabled = useSelector(selectIsRTLActive);
    const theme = useMemo(() => createTheme({ isRtlEnabled }), [isRtlEnabled]);

    return (
        <ThemeProvider theme={theme}>
            <CatchErrorProvider>
                <Switch>
                    <Route path="/rtl" component={RtlLayout} />
                    <Route path="/auth" component={AuthLayout} />
                    <PrivateRoute path="/admin" component={AdminLayout} />
                    <Redirect from="/" to="/admin" />
                </Switch>
            </CatchErrorProvider>
        </ThemeProvider>
    );
};

export default App;
