import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { selectIsAuthenticated } from 'store/selectors/authSelectors';

const PrivateRoute = ({ component, ...options }) => {
    const isAuthenticated = useSelector(selectIsAuthenticated);

    return isAuthenticated ? <Route {...options} component={component} /> : <Redirect to="/auth/login" />;
};

PrivateRoute.propTypes = { component: PropTypes.elementType.isRequired };

export default PrivateRoute;
