import { createSlice } from '@reduxjs/toolkit';
import { supportedLangs, rtlSupportedLangs } from 'config';

// =================================================================
// Initial state
// =================================================================

const cachedLocale = localStorage.getItem('locale') || 'en';
// if cached available in supported langs then add it otherwise add en
const initialLocale = supportedLangs[cachedLocale] ? cachedLocale : 'en';
export const initialState = {
    locale: initialLocale,
    isRTLActive: rtlSupportedLangs.includes(initialLocale),
};

// =================================================================
// Notification slice
// =================================================================

const localizationSlice = createSlice({
    name: 'localization',
    initialState,
    reducers: {
        setLocale(state, { payload }) {
            state.locale = payload.locale;
            state.isRTLActive = payload.isRTLActive || false;
        },
    },
});

// ===================================================================
// Notification actions
// ===================================================================

export const { setLocale } = localizationSlice.actions;

// ====================================================================
// Notification selector
// ====================================================================

export const localizationSelector = (state) => state.localization;

// =====================================================================
// Notification reducer
// =====================================================================

export default localizationSlice.reducer;

// ======================================================================
// Fetch localization
// ======================================================================

export const switchLocal = (locale) => (dispatch) => {
    localStorage.setItem('locale', locale);
    dispatch(setLocale({ locale, isRTLActive: rtlSupportedLangs.includes(locale) }));
};
