import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// API items
import { ImageOverlaysServiceClient } from 'api/v1/v1_grpc_web_pb';
import { ImageOverlay, OrgRef, GeoBox, Point } from 'api/v1/v1_pb';
import authRequestHandler from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    layers: [],
    filteredItems: [],
    loading: true,
};

// =================================================================
// Layer slice
// =================================================================

const layersSlice = createSlice({
    name: 'layer',
    initialState,
    reducers: {
        setLayers(state, { payload }) {
            state.layers = payload;
        },
        setFilteredItems(state, { payload }) {
            state.filteredItems = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Layer actions
// ===================================================================

export const { setLoading, setLayers, setFilteredItems } = layersSlice.actions;

// ====================================================================
// Layer selector
// ====================================================================

export const layerSelector = (state) => state.layer;

// =====================================================================
// Layer reducer
// =====================================================================

export default layersSlice.reducer;

// ======================================================================
// Fetch Layers
// ======================================================================

export const fetchLayerLists = () => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        //Retrieving Layers
        const result = await authRequestHandler(ImageOverlaysServiceClient, 'list');

        const layers = result.getItemsList().map((layer) => {
            return {
                orgId: layer.getOrg().getId(),
                name: layer.getName(),
                id: layer.getId(),
                overlayImage: layer.getUrl(),
                overlayBounds: [
                    [layer.getBox().getMin().getLat(), layer.getBox().getMin().getLong()],
                    [layer.getBox().getMax().getLat(), layer.getBox().getMax().getLong()],
                ],
            };
        });

        dispatch(setLayers(layers));
        dispatch(setFilteredItems(layers.map((layer) => layer.id)));

        return layers;
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Add Layer
// ======================================================================

export const addLayer = (layerData) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();
    const { name, overlayImage, overlayBounds } = layerData;

    const newImageOverlay = new ImageOverlay();
    newImageOverlay.setId(uuidv4()); //random
    newImageOverlay.setName(name);
    newImageOverlay.setUrl(overlayImage);

    const orgRef = new OrgRef();
    orgRef.setId(activeOrg); // active Org
    newImageOverlay.setOrg(orgRef);

    const newGeoBox = new GeoBox();

    const southWest = new Point();
    southWest.setLat(overlayBounds[0][0]);
    southWest.setLong(overlayBounds[0][1]);
    newGeoBox.setMin(southWest);

    const northEast = new Point();
    northEast.setLat(overlayBounds[1][0]);
    northEast.setLong(overlayBounds[1][1]);
    newGeoBox.setMax(northEast);

    newImageOverlay.setBox(newGeoBox);

    // Request to the API
    const result = await authRequestHandler(ImageOverlaysServiceClient, 'add', newImageOverlay);
    dispatch(fetchLayerLists());
    return result;
};

// ======================================================================
// Update a Layer
// ======================================================================

export const updateLayer = (layerData) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();
    const { name, overlayImage, overlayBounds, id } = layerData;

    const newImageOverlay = new ImageOverlay();
    newImageOverlay.setId(id);
    newImageOverlay.setName(name);
    newImageOverlay.setUrl(overlayImage);

    const orgRef = new OrgRef();
    orgRef.setId(activeOrg); // active Org
    newImageOverlay.setOrg(orgRef);

    const newGeoBox = new GeoBox();

    const southWest = new Point();
    southWest.setLat(overlayBounds[0][0]);
    southWest.setLong(overlayBounds[0][1]);
    newGeoBox.setMin(southWest);

    const northEast = new Point();
    northEast.setLat(overlayBounds[1][0]);
    northEast.setLong(overlayBounds[1][1]);
    newGeoBox.setMax(northEast);

    newImageOverlay.setBox(newGeoBox);

    // Request to the API
    const result = await authRequestHandler(ImageOverlaysServiceClient, 'set', newImageOverlay);
    dispatch(fetchLayerLists());
    return result;
};

// ======================================================================
// Delete a Layer
// ======================================================================

export const deleteLayer = (layerId) => async (dispatch) => {
    const newImageOverlay = new ImageOverlay();
    newImageOverlay.setId(layerId);

    // Request to the API
    const result = await authRequestHandler(ImageOverlaysServiceClient, 'delete', newImageOverlay);
    dispatch(fetchLayerLists());
    return result;
};

// ======================================================================
// Filter Layers
// ======================================================================

export const filterLayer = (currentLayerId) => async (dispatch, getState) => {
    const filterItems = getState().layer.filteredItems;
    const isFiltered = filterItems.includes(currentLayerId);

    let updateFilteredItems = [];

    if (isFiltered) {
        updateFilteredItems = filterItems.filter((layerId) => layerId !== currentLayerId);
    } else {
        updateFilteredItems = [...filterItems, currentLayerId];
    }

    dispatch(setFilteredItems(updateFilteredItems));
};
