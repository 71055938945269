import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader/Loader';

const LazyLoader = ({ children }) => {
    return <Suspense fallback={<Loader loading />}>{children}</Suspense>;
};

LazyLoader.propTypes = { children: PropTypes.node.isRequired };

export default LazyLoader;
