/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, FormattedRelativeTime } from 'react-intl';

const useStyles = makeStyles({
    root: {
        marginTop: '5px',
        display: 'inline-block',
        fontWeight: '600',
    },
});
const TimeAgo = ({ seconds, className }) => {
    const intl = useIntl();
    const dateInitiate = new Date(seconds);

    const secondsAgo = dateInitiate - new Date();
    const formattedDate = intl.formatDate(dateInitiate, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });

    const classes = useStyles();
    return (
        <time dateTime={dateInitiate} title={formattedDate} className={classes.root + ' ' + className}>
            <FormattedRelativeTime value={secondsAgo / 1000} numeric="auto" updateIntervalInSeconds={1} />
        </time>
    );
};
TimeAgo.propTypes = { seconds: PropTypes.number.isRequired, className: PropTypes.string };

export default TimeAgo;
