import jwtDecode from 'jwt-decode';

// API items
import { AuthClient } from 'api/v1/v1_grpc_web_pb';
import { OrgRef, Empty } from 'api/v1/v1_pb';
import { getAPI_URL } from 'utils/auth';
import { logOutHandler } from 'store/slices/authSlice';
import CustomError from 'utils/customError';

// Ref creator
export const ref = (type, id) => {
    var v = new type();
    v.setId(id);
    return v;
};

// Request handler
export const requestHandler = async (clientFunc, rpc, request, metadata) => {
    const client = new clientFunc(getAPI_URL());

    return new Promise((resolve, reject) =>
        client[rpc](request || new Empty(), metadata || {}, (error, response) => {
            if (error) {
                const codeText = {
                    0: ['OK', 'Success'],
                    1: ['CANCELLED', 'The operation was cancelled'],
                    2: ['UNKNOWN', 'Unknown error'],
                    3: ['INVALID_ARGUMENT', 'The client specified an invalid argument'],
                    4: ['DEADLINE_EXCEEDED', 'The deadline expired before the operation could complete'],
                    5: ['NOT_FOUND', 'Some requested entity was not found'],
                    6: ['ALREADY_EXISTS', 'The entity that a client attempted to create already exists'],
                    7: ['PERMISSION_DENIED', 'The caller does not have permission to execute the specified operation'],
                    8: ['RESOURCE_EXHAUSTED', 'Some resource has been exhausted'],
                    9: [
                        'FAILED_PRECONDITION',
                        "The operation was rejected because the system is not in a state required for the operation's execution",
                    ],
                    10: ['ABORTED', 'The operation was aborted'],
                    11: ['OUT_OF_RANGE', 'The operation was attempted past the valid range'],
                    12: [
                        'UNIMPLEMENTED',
                        'The operation is not implemented or is not supported/enabled in this service',
                    ],
                    13: [
                        'INTERNAL',
                        'Internal errors. This means that some invariants expected by the underlying system have been broken',
                    ],
                    14: ['UNAVAILABLE', 'The service is currently unavailable'],
                    15: ['DATA_LOSS', 'Unrecoverable data loss or corruption'],
                    16: [
                        'UNAUTHENTICATED',
                        'The request does not have valid authentication credentials for the operation',
                    ],
                }[error.code];

                const { message, code, metadata } = error;
                let requestObj ={}

                if(typeof request?.toObject ==='function'){
                    requestObj=request.toObject()
                }

                return reject(new CustomError({ name: 'Api error', message, code, metadata, codeText, request:requestObj}));
            }

            return resolve(response);
        }),
    );
};

// Org and public token fetcher
export const fetchToken = async (tokenType = 'orgToken') => {
    try {
        const masterToken = localStorage.getItem('mToken');
        // If masterToken not found then immediately logout the user
        if (!masterToken) throw new CustomError({ name: 'Invalid token', message: 'Token not found' });

        const decoded = jwtDecode(masterToken);
        // If masterToken is expired then immediately logout the user
        if (decoded.exp <= new Date().getTime() / 1000) {
            throw new CustomError({ name: 'Invalid token', message: 'Token is expired' });
        }

        // Fetch public token
        if (tokenType === 'publicToken') {
            const result = await requestHandler(AuthClient, 'publicLogin', null, {
                authn: masterToken,
            });
            localStorage.setItem('publicToken', result.getToken());
            return result;
        }

        // Fetch org token
        const activeOrg = localStorage.getItem('activeOrg');
        const result = await requestHandler(AuthClient, 'orgLogin', ref(OrgRef, activeOrg), { authn: masterToken });
        localStorage.setItem('orgToken', result.getToken());
        return result;
    } catch (error) {
        if (
            error?.code === 16 ||
            error?.message === 'Token is expired' ||
            error?.message.indexOf('Invalid token specified') === -1 ||
            error?.name === 'InvalidToken'
        ) {
            if (window?.store) {
                await window.store.dispatch(logOutHandler());
            }
        }
        throw error;
    }
};

// Metadata generator
const getMetaData = async (tokenType) => {
    // Get token form the local storage
    const token = localStorage.getItem(tokenType);
    // if  token not found then fetch it immediately
    if (!token) await fetchToken(tokenType);

    const decoded = jwtDecode(token);
    const currentTime = new Date().getTime() / 1000;
    // If token already expired then fetch new token and wait until new token fetch successfully
    if (decoded && decoded.exp <= currentTime) {
        await fetchToken(tokenType);
        // If token isn't expired but the expire time is less than 10 minutes then fetch new token in that case don't wait for the new token
    } else if ((decoded.exp - currentTime) / 60 < 10) {
        await fetchToken(tokenType).catch(() => null);
    }
    return { authn: localStorage.getItem(tokenType) };
};

// Authentic request handler
const authRequestHandler = async (clientFunc, rpc, request, tokenType) => {
    // select token type
    let defaultTokenType = null;
    if (tokenType) {
        defaultTokenType = tokenType;
    } else {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        defaultTokenType = authUser?.orgs.length > 0 ? 'orgToken' : 'publicToken';
    }

    const metadata = await getMetaData(defaultTokenType);
    return await requestHandler(clientFunc, rpc, request, metadata);
};

export default authRequestHandler;
