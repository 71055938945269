import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Person from '@material-ui/icons/Person';
import NavbarDropDown from 'components/Navbars/NavbarDropDown';
import { logOutHandler } from 'store/slices/authSlice';
import { useAppDispatch } from 'store';
import useCatchErrorCallback from 'hooks/useCatchErrorCallback';

const AdminProfileDropDown = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const handleLogOut = useCatchErrorCallback(() => dispatch(logOutHandler()), []);

    return (
        <NavbarDropDown
            buttonIcon={<Person />}
            dropdownList={[
                {
                    content: intl.formatMessage({ defaultMessage: 'Profile' }),
                    menuItemProps: { component: Link, to: '/admin/profile' },
                },
                {
                    content: intl.formatMessage({ defaultMessage: 'Change password' }),
                    menuItemProps: { component: Link, to: '/admin/change-password' },
                },
                {
                    content: intl.formatMessage({ defaultMessage: 'Log out' }),
                    onClick: handleLogOut,
                },
            ]}
        />
    );
};

AdminProfileDropDown.propTypes = {};

export default AdminProfileDropDown;
