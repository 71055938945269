import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';
import { fetchEquipmentTypes } from './equipmentTypeSlice';
// API items

import { getZoneDetails, fetchZones } from 'store/slices/zoneSlice';
import { EquipmentsServiceClient, SearchClient, EquipmentMetricsClient } from 'api/v1/v1_grpc_web_pb';
import {
    Equipment,
    FloorRef,
    Point,
    EquipmentTypeRef,
    EquipmentRef,
    DocRef,
    GPSPoint,
    OrgRef,
    MetricsQuery,
} from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    availableZones: [],
    zonesLoading: false,
    equipments: [],
    loading: true,
};

// =================================================================
// Equipment slice
// =================================================================

const equipmentSlice = createSlice({
    name: 'equipment',
    initialState,
    reducers: {
        setAvailableZones(state, { payload }) {
            state.availableZones = payload;
        },
        setEquipments(state, { payload }) {
            state.equipments = payload;
        },

        setLoading(state, { payload }) {
            state.loading = payload;
        },
        setZonesLoading(state, { payload }) {
            state.zonesLoading = payload;
        },
    },
});

// ===================================================================
// Equipment actions
// ===================================================================

export const { setAvailableZones, setEquipments, setLoading, setZonesLoading } = equipmentSlice.actions;

// ====================================================================
// Equipment selector
// ====================================================================

export const equipmentSelector = (state) => state.equipment;

// =====================================================================
// Equipment reducer
// =====================================================================

export default equipmentSlice.reducer;

// ======================================================================
// Fetch Equipments
// ======================================================================

export const fetchEquipments = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    await dispatch(fetchEquipmentTypes());
    await dispatch(fetchZones());

    const equipmentTypes = getState().equipmentType.equipmentTypes;
    const zones = [...getState().zone.zones];

    try {
        const result = await authRequestHandler(EquipmentsServiceClient, 'list');
        const equipments = result.getItemsList().map(async (equipment) => {
            const equipmentTypeId = equipment.getType()?.getId();

            // If equipment type ref exists then find the equipment type from the equipment types
            let equipmentType = equipmentTypeId
                ? equipmentTypes.find((currentItem) => currentItem.id === equipmentTypeId)
                : null;

            // If equipment type ref exists but the the equipment type doesn't exist in DB then just add the equipment type if
            if (equipmentTypeId && !equipmentType) {
                equipmentType = { id: equipmentTypeId };
            }

            const zoneId = equipment?.getFloor()?.getZoneid() || '';
            let zone = null;
            if (zoneId) {
                zone = zones.find((zone) => zone.id === zoneId);
            }

            if (zoneId && !zone) {
                zone = await getZoneDetails(equipment?.getFloor()?.getZoneid()).catch(() => null);
                if (zone) zones.push(zone);
            }
            return {
                id: equipment.getId(),
                name: equipment.getName(),
                localId: equipment.getLocalid(),
                note: equipment.getNote(),
                publicNote: equipment.getPublicnote(),
                latLng: [equipment.getLocation().getLat(), equipment.getLocation().getLong()],
                zoneId,
                zone,
                floor: equipment?.getFloor()?.getFloorname() || '',
                equipmentType,
                documents: equipment
                    .getDocsList()
                    .map((doc) => ({ url: doc.getUrl(), title: doc.getTitle()?.trim(), tempId: uuidv4() })),
            };
        });

        dispatch(setEquipments(await Promise.all(equipments)));
        return equipments;
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Fetch Available zones according to equipment position
// ======================================================================
let currentZonesExecuteId = '';
export const fetchAvailableZones = (latLng) => {
    const localExecuteId = uuidv4();
    currentZonesExecuteId = localExecuteId;

    return async (dispatch, getState) => {
        try {
            // New GPS point
            const newGPSPoint = new GPSPoint();
            const newPoint = new Point();

            newPoint.setLat(latLng.lat);
            newPoint.setLong(latLng.lng);
            newGPSPoint.setPoint(newPoint);
            newGPSPoint.setPrecision(1);

            dispatch(setZonesLoading(true));

            //Searching zones
            const result = await authRequestHandler(SearchClient, 'zones', newGPSPoint);
            if (currentZonesExecuteId !== localExecuteId) return false;
            const availableZones = result
                .getItemsList()
                .map((zone) => {
                    return {
                        id: zone.getId(),
                        name: zone.getName(),
                        floors: zone.getFloorsList(),
                        orgId: zone.getOrg().getId(),
                    };
                })
                .filter((zone) => zone.orgId === getState().auth.activeOrg); // Only store current org zones
            dispatch(setAvailableZones(availableZones));
            return availableZones;
        } finally {
            dispatch(setZonesLoading(false));
        }
    };
};

// ======================================================================
// Add new equipment
// ======================================================================

export const addEquipment =
    ({ name, note, publicNote, localId, lat, lng, zoneId, floor, equipmentType, documents }) =>
    async (dispatch, getState) => {
        const {
            auth: { activeOrg },
        } = getState();

        //New Equipment
        const newEquipment = new Equipment();
        newEquipment.setId(uuidv4());
        newEquipment.setName(name);
        newEquipment.setPublicnote(publicNote);
        newEquipment.setNote(note);

        if (equipmentType) {
            const newEquipmentTypeRef = new EquipmentTypeRef();
            newEquipmentTypeRef.setId(equipmentType.id);
            newEquipment.setType(newEquipmentTypeRef);
        }
        newEquipment.setLocalid(localId);

        // New location
        const newLocation = new Point();
        newLocation.setLat(lat);
        newLocation.setLong(lng);
        newEquipment.setLocation(newLocation);

        const newFloorRef = new FloorRef();

        newFloorRef.setZoneid(zoneId);

        newFloorRef.setFloorname(floor);

        if (zoneId) {
            newEquipment.setFloor(newFloorRef);
        }

        //New Org
        const newOrg = new OrgRef();
        newOrg.setId(activeOrg);
        newEquipment.setOrg(newOrg);

        // set docs list
        newEquipment.setDocsList(
            documents.map((doc) => {
                const newDoc = new DocRef();
                newDoc.setUrl(doc.url);
                newDoc.setTitle(doc.title);
                return newDoc;
            }),
        );

        //Adding Equipment
        const result = await authRequestHandler(EquipmentsServiceClient, 'add', newEquipment);
        dispatch(fetchEquipments());
        return result;
    };

// ======================================================================
// Update equipment
// ======================================================================

export const updateEquipment = (updatedEquipment) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();

    const { id, name, publicNote, note, localId, lat, lng, zoneId, floor, documents, equipmentType } = updatedEquipment;

    // New equipment
    const newEquipment = await getEquipmentDetails(id, false);

    newEquipment.setName(name);
    newEquipment.setPublicnote(publicNote);
    newEquipment.setNote(note);

    if (equipmentType) {
        const newEquipmentTypeRef = new EquipmentTypeRef();
        newEquipmentTypeRef.setId(equipmentType.id);
        newEquipment.setType(newEquipmentTypeRef);
    }

    newEquipment.setLocalid(localId);

    // New location
    const newLocation = new Point();
    newLocation.setLat(lat);
    newLocation.setLong(lng);
    newEquipment.setLocation(newLocation);
    const newFloorRef = new FloorRef();
    newFloorRef.setZoneid(zoneId);
    newFloorRef.setFloorname(floor);
    newEquipment.setFloor(newFloorRef);

    //New Org
    const newOrg = new OrgRef();
    newOrg.setId(activeOrg);
    newEquipment.setOrg(newOrg);

    // Set docs
    newEquipment.setDocsList(
        documents.map((doc) => {
            const newDoc = new DocRef();
            newDoc.setUrl(doc.url);
            newDoc.setTitle(doc.title);
            return newDoc;
        }),
    );

    //Request to the API
    const result = await authRequestHandler(EquipmentsServiceClient, 'set', newEquipment);

    await dispatch(fetchEquipments());
    return result;
};

// ======================================================================
// Get an equipment metric value
// ======================================================================

export const getEquipmentMetricValue = async (equipment) => {
    const result = await Promise.all(
        equipment.equipmentType.metrics.map((metric) => {
            const metricsQuery = new MetricsQuery();
            metricsQuery.setEquipment(ref(EquipmentRef, equipment.id));
            metricsQuery.setMetric(metric);
            return authRequestHandler(EquipmentMetricsClient, 'get', metricsQuery);
        }),
    );

    return result.map((singleResult) => singleResult.toObject());
};

// ======================================================================
// Delete equipment
// ======================================================================
export const deleteEquipment = (deleteEquipmentId) => async (dispatch, getState) => {
    // Request to the API

    const result = await authRequestHandler(EquipmentsServiceClient, 'delete', ref(EquipmentRef, deleteEquipmentId));
    const updatedUsers = copyObj(getState().equipment.equipments).filter(
        (equipment) => equipment.id !== deleteEquipmentId,
    );

    dispatch(setEquipments(updatedUsers));
    return result;
};

// ======================================================================
// Get a Category details
// ======================================================================

export const getEquipmentDetails = async (equipmentId, extracted = true) => {
    var equipment = new Equipment();
    equipment.setId(equipmentId);
    // Request to the API
    const result = await authRequestHandler(EquipmentsServiceClient, 'get', equipment);
    if (extracted) {
        return {
            id: result.getId(),
            name: result.getName(),
            localId: result.getLocalid(),
            note: result.getNote(),
            publicNote: result.getPublicnote(),
            latLng: [result.getLocation().getLat(), result.getLocation().getLong()],
            zoneId: result?.getFloor()?.getZoneid() || '',
            floor: result?.getFloor()?.getFloorname() || '',
            documents: result.getDocsList().map((doc) => doc.getUrl()),
        };
    }
    return result;
};
