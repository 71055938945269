import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useRoutes from 'routes/routes';
import { selectNotifications } from 'store/selectors/notificationSelectors';

// reformating all routes
const getPathTitles = (routes: any[]): any[] => {
    const pathTitles: any[] = [];
    const getTitle = (routes: any[]): any => {
        routes.forEach((prop) => {
            if (prop.collapse) {
                //If routes has a collapse
                return getTitle(prop.views);
            }

            pathTitles.push({ name: prop.name, path: prop.layout + prop.path });
        });
    };
    getTitle(routes);

    return pathTitles;
};

const useTitle = (): void => {
    const routes = useRoutes();
    const location = useLocation();
    const pathTitles = getPathTitles(routes);
    const notifications = useSelector(selectNotifications);
    const [title, setTitle] = useState('BeeWoo');

    // Change title according to the route
    useEffect(() => {
        const currentTitle = pathTitles.find((title) => title.path.indexOf(location.pathname) !== -1);
        const title = currentTitle && currentTitle.name ? 'BeeWoo | ' + currentTitle.name : 'BeeWoo';
        setTitle(title);
    }, [location.pathname]);

    // Add title
    useEffect(() => {
        document.title = notifications.length > 0 ? `(${notifications.length}) ${title}` : title;
    }, [title, notifications]);
};

export default useTitle;
