import React, { useState, useEffect, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import Popper from '@material-ui/core/Popper';

import { default as MuiButton } from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import NotificationsIcon from '@material-ui/icons/Notifications';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdminNotificationListItem from './AdminNotificationListItem';
import { fetchNotification, closeAllNotification } from 'store/slices/notificationSlice';
import { useAppDispatch } from 'store';
import useCatchErrorCallback from 'hooks/useCatchErrorCallback';
import useDesktopNotification from 'hooks/useDesktopNotification';

import {
    selectNotificationLoading,
    selectNotifications,
    selectNotificationsError,
} from 'store/selectors/notificationSelectors';

const AdminNotification = ({ rtlActive }) => {
    useDesktopNotification();
    const dispatch = useAppDispatch();
    const loading = useSelector(selectNotificationLoading);
    const notifications = useSelector(selectNotifications);
    const error = useSelector(selectNotificationsError);

    const [openNotification, setOpenNotification] = useState(null);
    const [disabledClose, setDisabledClose] = useState(false);

    const handleClickNotification = useCallback(
        (event) => {
            if (openNotification && openNotification.contains(event.target)) {
                setOpenNotification(null);
            } else {
                setOpenNotification(event.currentTarget);
            }
        },
        [openNotification],
    );

    const handleCloseNotification = useCallback(() => {
        setOpenNotification(null);
    }, []);

    const handleOutsideClick = useCallback(
        (event) => {
            if (openNotification && !openNotification.contains(event.target)) {
                setOpenNotification(null);
            }
        },
        [openNotification],
    );

    const closeAllClickHandler = useCatchErrorCallback(async () => {
        try {
            setDisabledClose(true);

            await dispatch(closeAllNotification([...notifications])).catch(() => null);
        } finally {
            setDisabledClose(false);
        }
    }, [notifications]);

    const getNotification = useCatchErrorCallback(async () => {
        await dispatch(fetchNotification());
    }, []);

    useEffect(() => {
        getNotification();
    }, []);

    const classes = useStyles();
    const managerClasses = classNames({
        [classes.managerClasses]: true,
    });

    const notificationsLength = notifications.length;
    return (
        <div className={managerClasses}>
            <Button
                color="transparent"
                justIcon
                aria-label="Notifications"
                aria-owns={openNotification ? 'notification-menu-list' : null}
                aria-haspopup="true"
                onClick={handleClickNotification}
                className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                muiClasses={{
                    label: rtlActive ? classes.labelRTL : '',
                }}
            >
                <NotificationsIcon
                    className={
                        classes.headerLinksSvg +
                        ' ' +
                        (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links)
                    }
                />
                {notificationsLength > 0 && (
                    <span className={classes.notifications}>
                        {notificationsLength > 9 ? '9+' : notificationsLength}
                    </span>
                )}
            </Button>
            <Popper
                open={Boolean(openNotification)}
                anchorEl={openNotification}
                transition
                disablePortal
                placement="bottom"
                role="menu"
                className={classNames({
                    [classes.popperClose]: !openNotification,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: true,
                })}
                keepMounted
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} id="notification-menu-list" style={{ transformOrigin: '0 0 0' }}>
                        <Paper classes={{ root: classes.listPaperRoot }}>
                            <ClickAwayListener onClickAway={handleOutsideClick}>
                                <List
                                    className={classes.listRoot}
                                    subheader={
                                        <ListSubheader className={classes.listSubHeader} component="div">
                                            <Typography variant="subtitle2">
                                                <FormattedMessage defaultMessage="Notifications" />
                                            </Typography>
                                            {notificationsLength > 0 && (
                                                <MuiButton
                                                    className={classes.markAsRead}
                                                    size="small"
                                                    onClick={closeAllClickHandler}
                                                    disableRipple
                                                    disabled={disabledClose}
                                                >
                                                    <FormattedMessage defaultMessage="Close all" />
                                                </MuiButton>
                                            )}
                                        </ListSubheader>
                                    }
                                >
                                    {/* Loader */}
                                    {loading && (
                                        <div className={classes.loader}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                    {/* If has an error */}
                                    {!loading && error && (
                                        <div className={classes.messageContainer}>
                                            <Typography variant="h5">
                                                <FormattedMessage defaultMessage="Oops something went wrong while fetching notification..." />
                                            </Typography>
                                            <Button color="primary" onClick={getNotification}>
                                                <FormattedMessage defaultMessage="Try again" />
                                            </Button>
                                        </div>
                                    )}

                                    {/* If notification array is empty  */}
                                    {!loading && !error && notificationsLength < 1 && (
                                        <div className={classes.messageContainer}>
                                            <Typography variant="h5">
                                                <FormattedMessage defaultMessage="There are no notifications..." />
                                            </Typography>
                                        </div>
                                    )}

                                    {/* Show the notifications */}
                                    {!loading && !error && (
                                        <div className={classes.notificationWrapper}>
                                            <div style={{ overflow: 'hidden' }}>
                                                {notifications.map((notification) => (
                                                    <AdminNotificationListItem
                                                        key={notification.id + notification.anomaly}
                                                        notification={notification}
                                                        onClick={() => handleCloseNotification(null)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

AdminNotification.propTypes = {
    rtlActive: PropTypes.bool,
};

export default memo(AdminNotification, isEqual);
const useStyles = makeStyles((theme) => ({
    popperClose: {
        pointerEvents: 'none',
        display: 'none !important',
    },
    popperResponsive: {
        zIndex: '1200',
        [theme.breakpoints.down('sm')]: {
            zIndex: '1640',
            position: 'static',
            float: 'none',
            width: 'auto',
            marginTop: '0',
            backgroundColor: 'transparent',
            border: '0',
            boxShadow: 'none',
            color: 'black',
        },
    },

    buttonLink: {
        // [theme.breakpoints.down('sm')]: {
        //     display: 'flex',
        //     margin: '5px 15px 0',
        //     width: 'auto',
        //     height: 'auto',
        //     '& svg': {
        //         width: '30px',
        //         height: '24px',
        //         marginRight: '19px',
        //         marginLeft: '3px',
        //     },
        //     '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        //         width: '30px',
        //         fontSize: '24px',
        //         lineHeight: '30px',
        //         marginRight: '19px',
        //         marginLeft: '3px',
        //     },
        // },
    },

    links: {
        width: '20px',
        height: '20px',
        zIndex: '4',
        // [theme.breakpoints.down('sm')]: {
        //     display: 'block',
        //     width: '30px',
        //     height: '30px',
        //     color: 'inherit',
        //     opacity: '0.8',
        //     marginRight: '16px',
        //     marginLeft: '-5px',
        // },
    },
    notifications: {
        zIndex: '4',
        // [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: '1px',
        border: '1px solid ' + theme.palette.whiteColor,
        right: '1px',
        fontSize: '9px',
        background: theme.palette.dangerColor[0],
        color: theme.palette.whiteColor,
        width: '20px',
        height: '20px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     ...defaultFont,
        //     fontSize: '14px',
        //     marginRight: '8px',
        // },
    },

    buttonLinkRTL: {
        // [theme.breakpoints.down('sm')]: {
        //     alignItems: 'center',
        //     justifyContent: 'flex-end',
        //     width: '-webkit-fill-available',
        //     margin: '10px 15px 0',
        //     padding: '10px 15px',
        //     display: 'block',
        //     position: 'relative',
        // },
    },
    labelRTL: {
        // [theme.breakpoints.down('sm')]: {
        //     flexDirection: 'row-reverse',
        //     justifyContent: 'initial',
        //     display: 'flex',
        // },
    },
    linksRTL: {
        // [theme.breakpoints.down('sm')]: {
        //     marginRight: '-5px !important',
        //     marginLeft: '16px !important',
        // },
    },
    managerClasses: {
        // [theme.breakpoints.up('md')]: {
        display: 'inline-block',
        // },
    },
    headerLinksSvg: {
        width: '20px !important',
        height: '20px !important',
    },
    listPaperRoot: {
        overflow: 'hidden',
        marginTop: '2px',
    },
    listRoot: {
        width: 'calc(100vw - 10px)',
        maxWidth: '500px',

        paddingBottom: 0,
    },
    listSubHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '7px',
        paddingBottom: '7px',
        backgroundColor: '#fff',
        borderBottom: '1px solid ' + theme.palette.grayColor[5],
    },
    messageContainer: {
        textAlign: 'center',
        padding: '30px',
        '& button': {
            marginTop: '15px',
        },
    },
    notificationWrapper: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '400px',
    },
    loader: {
        minHeight: '100px',
        overflow: 'hidden',
        display: 'flex',
        '& div': {
            margin: 'auto',
        },
    },
}));
