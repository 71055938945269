import React, { useState, useEffect, memo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { selectLocale } from 'store/selectors/localizationSelectors';
import Loader from 'components/Loader/Loader';
import { switchLocal } from 'store/slices/localizationSlice';
import { supportedLangs } from 'config';
import { useAppDispatch } from 'store';

const loadLocaleData = async (locale) => {
    try {
        const messages = await import(`locale/compiled-lang/${locale}.json`);
        return messages;
    } catch (error) {
        return null;
    }
};

const I18nWrapper = ({ children }) => {
    const locale = useSelector(selectLocale);
    const dispatch = useAppDispatch();
    const isOutSideCalled = useRef(false);
    const [messages, setMessages] = useState(null);
    const [loading, setLoading] = useState(true);

    const getLocaleData = useCallback(
        async (isOutsideCall) => {
            if (isOutsideCall && !isOutSideCalled.current) {
                isOutSideCalled.current = true;
            } else if (isOutsideCall) {
                return;
            }
            setLoading(true);
            const loadedMessages = await loadLocaleData(locale);
            if (!loadedMessages) {
                toast.error(
                    `The ${supportedLangs[locale].name} language is not available right now. We are working on it`,
                );
                dispatch(switchLocal('en'));

                return null;
            }
            setMessages(loadedMessages);
            setLoading(false);
        },
        [locale, dispatch],
    );

    // Immediately load initial locale
    getLocaleData(true);

    useEffect(() => {
        getLocaleData();
    }, [locale, getLocaleData]);

    if (loading) {
        return <Loader loading={loading} />;
    }

    return (
        <IntlProvider messages={messages} locale={locale} defaultLocale="en" key={locale}>
            {children}
        </IntlProvider>
    );
};

I18nWrapper.propTypes = { children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]) };

export default memo(I18nWrapper, isEqual);
