import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';

import I18nWrapper from 'components/I18nWrapper/I18nWrapper';
import App from './App';
import store from 'store';
import { setAuthentication } from 'store/slices/authSlice';

export const history = createBrowserHistory();
store.dispatch(setAuthentication());

const Root = () => {
    useEffect(() => {
        // Remove loader after loading all content
        const initialLoader = document.querySelector('.initial-loader');
        initialLoader?.parentElement?.removeChild(initialLoader);
    }, []);
    return (
        <Provider store={store}>
            <I18nWrapper>
                <Router history={history}>
                    <App />
                </Router>
            </I18nWrapper>
        </Provider>
    );
};

ReactDOM.render(<Root />, document.getElementById('root'));
