import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';

// API items
import { UsersServiceClient, AuthClient } from 'api/v1/v1_grpc_web_pb';
import { User, UserRef, OrgRole, SetPasswordReq } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    users: [],
    loading: true,
};

// =================================================================
// User slice
// =================================================================

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsers(state, { payload }) {
            state.users = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// User actions
// ===================================================================

export const { setLoading, setUsers } = userSlice.actions;

// ====================================================================
// User selector
// ====================================================================

export const userSelector = (state) => state.user;

// =====================================================================
// User reducer
// =====================================================================

export default userSlice.reducer;

// ======================================================================
// Fetch Users
// ======================================================================

export const fetchUserLists = () => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        //Retrieving Users
        const result = await authRequestHandler(UsersServiceClient, 'list');
        const users = result.getItemsList().map((user) => {
            return {
                id: user.getId(),
                name: user.getName(),
                email: user.getEmail(),
                isAdmin: user.getRolesList()[0]?.getIsadmin(),
                isControl: user.getRolesList()[0]?.getIscontrol(),
                isContributor: user.getRolesList()[0]?.getIscontributor(),
                avatar: user.getAvatar(),
            };
        });
        dispatch(setUsers(users));
        return result;
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Add User
// ======================================================================

export const addUser = (userData) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();
    const { firstName, lastName, email, isAdmin, isControl, isContributor, avatar } = userData;
    var newUser = new User();

    newUser.setId(uuidv4());
    newUser.setFirstname(firstName);
    newUser.setLastname(lastName);
    newUser.setEmail(email);
    newUser.setAvatar(avatar);

    // set org role
    const newOrgRole = new OrgRole();
    newOrgRole.setOrgid(activeOrg);
    newOrgRole.setIsadmin(isAdmin);
    newOrgRole.setIscontrol(isControl);
    newOrgRole.setIscontributor(isContributor);

    newUser.setRolesList([newOrgRole]);

    // Request to the API
    const result = await authRequestHandler(UsersServiceClient, 'add', newUser);
    await dispatch(fetchUserLists());
    return result;
};

// ======================================================================
// Update User
// ======================================================================

// export const updateUser = (updatedUser) => async (dispatch, getState) => {
//     const { id, name, email, phone, avatar } = updatedUser;
//     const userRef = await getUserDetails(id, false);

//     userRef.setName(name);
//     userRef.setEmail(email);
//     userRef.setPhone(phone);
//     userRef.setAvatar(avatar);

//     //Request to the API
//     const result = await authRequestHandler(UsersServiceClient, 'set',userRef);
//     const updatedUsers = copyObj(getState().user.users).map((user) => {
//         if (user.id === updatedUser.id) {
//             return updatedUser;
//         }
//         return user;
//     });

//     dispatch(setUsers(updatedUsers));
//     return result;
// };

// ======================================================================
// Update User profile
// ======================================================================

export const updateUserProfile = (updatedUser) => async () => {
    const { id, name, email, avatar } = updatedUser;
    const user = await getUserDetails(id, false);

    user.setName(name);
    user.setEmail(email);
    user.setAvatar(avatar);

    //Request to the API
    const result = await authRequestHandler(UsersServiceClient, 'set', user);

    return result;
};
// ======================================================================
// Change password
// ======================================================================

export const changeUserPassword = (newPassword) => async () => {
    const newPasswordReq = new SetPasswordReq();
    newPasswordReq.setPassword(newPassword);

    //Request to the API
    const result = await authRequestHandler(AuthClient, 'setPassword', newPasswordReq);

    return result;
};

// ======================================================================
// Delete user
// ======================================================================

export const deleteUser = (deleteUserId) => async (dispatch, getState) => {
    // Request to the API
    const result = await authRequestHandler(UsersServiceClient, 'delete', ref(UserRef, deleteUserId));

    const updatedUsers = copyObj(getState().user.users).filter((user) => user.id !== deleteUserId);
    dispatch(setUsers(updatedUsers));
    return result;
};
// ======================================================================
// Get a user details
// ======================================================================

export const getUserDetails = async (userId, extracted = true) => {
    // Request to the API
    const result = await authRequestHandler(UsersServiceClient, 'get', ref(UserRef, userId));

    if (extracted) {
        return {
            id: result.getId(),
            name: result.getName(),
            email: result.getEmail(),
            isAdmin: result.getRolesList()[0]?.getIsadmin(),
            isControl: result.getRolesList()[0]?.getIscontrol(),
            isContributor: result.getRolesList()[0]?.getIscontributor(),
            avatar: result.getAvatar(),
        };
    }

    return result;
};
