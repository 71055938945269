import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';

// API items
import { OrgUsersServiceClient, OrgsServiceClient } from 'api/v1/v1_grpc_web_pb';
import { UserRef, OrgUser, OrgRole, OrgRef } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    orgUsers: [],
    loading: true,
};

// =================================================================
// Org User slice
// =================================================================

const orgUserSlice = createSlice({
    name: 'orgUser',
    initialState,
    reducers: {
        setOrgUsers(state, { payload }) {
            state.orgUsers = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Org User actions
// ===================================================================

export const { setLoading, setOrgUsers } = orgUserSlice.actions;

// ====================================================================
// Org User selector
// ====================================================================

export const orgUserSelector = (state) => state.orgUser;

// =====================================================================
// Org User reducer
// =====================================================================

export default orgUserSlice.reducer;

// ======================================================================
// Fetch Org Users
// ======================================================================

export const fetchOrgUserLists = () => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        //Retrieving Org Users
        const result = await authRequestHandler(OrgUsersServiceClient, 'list');
        const orgUsers = result.getItemsList().map((orgUser) => {
            return {
                userId: orgUser.getUser()?.getId(),
                id: orgUser.getId(),
                name: orgUser.getName(),
                email: orgUser.getEmail(),

                orgId: orgUser.getOrg()?.getId(),
                isAdmin: orgUser.getRole().getIsadmin(),
                isControl: orgUser.getRole().getIscontrol(),
                isContributor: orgUser.getRole().getIscontributor(),
            };
        });

        dispatch(setOrgUsers(orgUsers));
        return orgUsers;
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Add Org User
// ======================================================================

export const addOrgUser = (userData) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();
    const { name, email, isAdmin, isControl, isContributor } = userData;

    const newOrgUser = new OrgUser();
    newOrgUser.setId(uuidv4()); //random
    newOrgUser.setEmail(email);
    newOrgUser.setName(name);
    const orgRef = new OrgRef();
    orgRef.setId(activeOrg); // active Org
    newOrgUser.setOrg(orgRef);
    const newOrgRole = new OrgRole();
    newOrgRole.setOrgid(activeOrg); // active Org
    newOrgRole.setIsadmin(isAdmin);
    newOrgRole.setIscontrol(isControl);
    newOrgRole.setIscontributor(isContributor);
    newOrgUser.setRole(newOrgRole);

    // Request to the API
    const result = await authRequestHandler(OrgUsersServiceClient, 'add', newOrgUser);
    dispatch(fetchOrgUserLists());
    return result;
};

// ======================================================================
// Update a Org User
// ======================================================================

export const updateOrgUser = (updatedOrgUser) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();

    const { id, name, email, isAdmin, isControl, isContributor } = updatedOrgUser;
    const editedOrgUser = await getOrgUserDetails(id, false);

    editedOrgUser.setEmail(email);
    editedOrgUser.setName(name);

    const orgRef = new OrgRef();
    orgRef.setId(activeOrg); // active Org
    editedOrgUser.setOrg(orgRef);

    const newOrgRole = new OrgRole();
    newOrgRole.setOrgid(activeOrg); // active Org
    newOrgRole.setIsadmin(isAdmin);
    newOrgRole.setIscontrol(isControl);
    newOrgRole.setIscontributor(isContributor);
    editedOrgUser.setRole(newOrgRole);

    //Request to the API
    const result = await authRequestHandler(OrgUsersServiceClient, 'set', editedOrgUser);
    const updatedOrgUsers = copyObj(getState().orgUser.orgUsers).map((orgUser) => {
        if (orgUser.id === updatedOrgUser.id) {
            return updatedOrgUser;
        }
        return orgUser;
    });

    dispatch(setOrgUsers(updatedOrgUsers));
    return result;
};

// ======================================================================
// Delete a org user
// ======================================================================

export const deleteOrgUser = (deleteUserId) => async (dispatch, getState) => {
    const newOrgUser = new OrgUser();
    newOrgUser.setId(deleteUserId);

    // Request to the API
    const result = await authRequestHandler(OrgUsersServiceClient, 'delete', newOrgUser);
    const updatedOrgUsers = copyObj(getState().orgUser.orgUsers).filter((orgUser) => orgUser.id !== deleteUserId);

    dispatch(setOrgUsers(updatedOrgUsers));
    return result;
};

// ======================================================================
// Get a Org user details
// ======================================================================

export const getOrgUserDetails = async (userId, extracted = true) => {
    const newUserRef = new UserRef();
    newUserRef.setId(userId);

    // Request to the API
    const result = await authRequestHandler(OrgUsersServiceClient, 'get', ref(UserRef, userId));
    if (extracted) {
        return {
            id: result.getId(),
            userId: result.getUser().getId(),
            name: result.getName(),
            email: result.getEmail(),

            orgId: result.getOrg().getId(),
            isAdmin: result.getRole().getIsadmin(),
            isControl: result.getRole().getIscontrol(),
            isContributor: result.getRole().getIscontributor(),
        };
    }
    return result;
};
// ======================================================================
// Get a Org orgDetails
// ======================================================================

export const getOrgDetails = async (orgId, extracted = true) => {
    // Request to the API
    const result = await authRequestHandler(OrgsServiceClient, 'get', ref(OrgRef, orgId));

    if (extracted) {
        return {
            id: result.getId(),
            name: result.getName(),
            logo: result.getLogo(),
            isValid: result.getIsvalid(),
        };
    }
    return result;
};
