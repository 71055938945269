import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';

// redux Actions
import { fetchZones, getZoneDetails } from './zoneSlice';
import { fetchUserLists, getUserDetails } from './userSlice';
import { fetchCategoryLists, getCategoryDetails } from './categorySlice';

// API items

import { RulesServiceClient } from 'api/v1/v1_grpc_web_pb';
import { CategoryRef, UserRef, ZoneRef, OrgRef, Rule, AlertTarget, AnomalyFilter } from 'api/v1/v1_pb';
import authRequestHandler from 'api/handlers/apiHandler';

// =====================================
// Initial state
// =====================================
export const initialState = {
    rules: [],
    loading: true,
};

// =====================================
// Alert slice
// =====================================
const ruleSlice = createSlice({
    name: 'rule',
    initialState,
    reducers: {
        setRules(state, { payload }) {
            state.rules = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ========================================================
// Alert actions
// ========================================================
export const { setLoading, setRules } = ruleSlice.actions;

// ========================================================
// Alert selector
// ========================================================
export const ruleSelector = (state) => state.rule;

// ========================================================
// Alert reducer
// ========================================================
export default ruleSlice.reducer;

// Modify user like an org User
const getModifiedUser = (id, users) => {
    const user = users.find((user) => user.id === id);
    if (!user) {
        return { userId: id, name: 'Not found' };
    }
    return { userId: id, name: user.name };
};
// ========================================================
// Fetch rule lists
// ========================================================
export const fetchRuleLists = () => async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
        // Fetch user lists, fetch category lists, fetch zones
        await Promise.all([
            await dispatch(fetchUserLists()).catch(() => []),
            await dispatch(fetchCategoryLists()).catch(() => []),
            await dispatch(fetchZones()).catch(() => []),
        ]);

        const {
            user: { users },
            zone: { zones },
            category: { categories },
        } = getState();

        //Retrieving alerts
        const result = await authRequestHandler(RulesServiceClient, 'list');

        const rules = result.getItemsList().map((role) => ({
            id: role.getId(),
            label: role.getLabel(),
            assignTo: role.getAssignto() ? getModifiedUser(role.getAssignto().getId(), users) : null,
            users: role
                .getTargetsList()[0]
                .getUsersList()
                .map((user) => getModifiedUser(user.getId(), users)),
            zones: role
                .getFilter()
                .getZonesList()
                .map((zoneId) => {
                    const id = zoneId.getId();
                    const zone = zones.find((zone) => zone.id === id);
                    if (zone) return zone;
                    return { id };
                }),
            app: role.getTargetsList()[0].getUseapp(),
            email: role.getTargetsList()[0].getUseemail(),
            // sms: role.getTargetsList()[0].getUsesms(),
            categories: role
                .getFilter()
                .getCategoriesList()
                .map((categoryId) => {
                    const id = categoryId.getId();

                    const category = categories.find((category) => category.id === id);
                    if (category) return category;
                    return { id };
                }),
            states: role.getFilter().getStatesList(),
        }));
        dispatch(setRules(rules));
        return result;
    } finally {
        dispatch(setLoading(false));
    }
};

// ========================================================
// Add a new rule
// ========================================================
export const addRule = (rule) => async (dispatch, getState) => {
    const {
        auth: {
            activeOrg,
            authUser: { id },
        },
    } = getState();

    const {
        label,
        categories,
        zones,
        app,
        // sms,
        email,
        users,
        assignTo,
        states,
    } = rule;

    // Creat new rule instance and add data
    const newRule = new Rule();
    newRule.setId(uuidv4());
    newRule.setLabel(label);

    const createdBy = new UserRef();
    createdBy.setId(id);
    newRule.setCreatedby(createdBy);

    const newOrgRef = new OrgRef();
    newOrgRef.setId(activeOrg);
    newRule.setOrg(newOrgRef);

    const newAnomalyFilter = new AnomalyFilter();

    newAnomalyFilter.setCategoriesList(
        categories.map((category) => {
            const categoryRef = new CategoryRef();
            categoryRef.setId(category.id);
            return categoryRef;
        }),
    );

    newAnomalyFilter.setZonesList(
        zones.map((zone) => {
            const zoneRef = new ZoneRef();
            zoneRef.setId(zone.id);
            return zoneRef;
        }),
    );
    newAnomalyFilter.setStatesList(states);

    newRule.setFilter(newAnomalyFilter);

    const newAlertTarget = new AlertTarget();
    newAlertTarget.setUseapp(app);
    newAlertTarget.setUseemail(email);
    // newAlertTarget.setUsesms(sms);

    newAlertTarget.setUsersList(
        users.map((user) => {
            const userRef = new UserRef();
            userRef.setId(user.userId);
            return userRef;
        }),
    );

    newRule.setTargetsList([newAlertTarget]);

    if (assignTo) {
        const assignToRef = new UserRef();
        assignToRef.setId(assignTo.userId);
        newRule.setAssignto(assignToRef);
    }

    const result = await authRequestHandler(RulesServiceClient, 'add', newRule);
    await dispatch(fetchRuleLists()).catch(() => null);
    return result;
};

// =================================================================
// Update a rule
// =================================================================

export const updateRule = (rule) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();

    const {
        label,
        categories,
        zones,
        app,
        // sms,
        email,
        users,
        assignTo,
        states,
        id: ruleId,
    } = rule;

    const newRule = await getRuleDetails(ruleId, false);

    newRule.setLabel(label);

    const newOrgRef = new OrgRef();
    newOrgRef.setId(activeOrg);
    newRule.setOrg(newOrgRef);

    const newAnomalyFilter = new AnomalyFilter();
    newAnomalyFilter.setCategoriesList(
        categories.map((category) => {
            const categoryRef = new CategoryRef();
            categoryRef.setId(category.id);
            return categoryRef;
        }),
    );

    newAnomalyFilter.setZonesList(
        zones.map((zone) => {
            const zoneRef = new ZoneRef();
            zoneRef.setId(zone.id);
            return zoneRef;
        }),
    );

    newAnomalyFilter.setStatesList(states);

    newRule.setFilter(newAnomalyFilter);

    const newAlertTarget = new AlertTarget();
    newAlertTarget.setUseapp(app);
    newAlertTarget.setUseemail(email);
    // newAlertTarget.setUsesms(sms);

    newAlertTarget.setUsersList(
        users.map((user) => {
            const userRef = new UserRef();
            userRef.setId(user.userId);
            return userRef;
        }),
    );

    newRule.setTargetsList([newAlertTarget]);
    // TODO:Have check this assigned to works fine when user don't select any assigned to
    if (assignTo) {
        const assignToRef = new UserRef();
        assignToRef.setId(assignTo.userId);
        newRule.setAssignto(assignToRef);
    }

    //updating  rule
    const result = await authRequestHandler(RulesServiceClient, 'set', newRule);

    await dispatch(fetchRuleLists()).catch(() => null);
    return result;
};

// ==============================================================
// Delete alert
// ==============================================================
export const deleteRule = (deleteRuleId) => async (dispatch, getState) => {
    const newRuleRef = new Rule();
    newRuleRef.setId(deleteRuleId);

    const result = await authRequestHandler(RulesServiceClient, 'delete', newRuleRef);
    const updateRules = copyObj(getState().rule.rules).filter((rule) => rule.id !== deleteRuleId);

    // Only remove the edited rule
    dispatch(setRules(updateRules));
    return result;
};

// ======================================================================
// Get a Checklist details
// ======================================================================

export const getRuleDetails = async (ruleId, extracted = true) => {
    const newRuleRef = new Rule();
    newRuleRef.setId(ruleId);
    // Request to the API
    const result = await authRequestHandler(RulesServiceClient, 'get', newRuleRef);
    if (extracted) {
        return {
            id: result.getId(),
            label: result.getLabel(),
            assignTo: result.getAssignto()
                ? await getUserDetails(result.getAssignto().getId()).catch(() => null)
                : null,
            users: result
                .getTargetsList()[0]
                .getUsersList()
                .map(async (userId) => {
                    const id = userId.getId();
                    const user = await getUserDetails(id).catch(() => null);
                    if (user) return user;
                    return { id };
                }),
            zones: result
                .getFilter()
                .getZonesList()
                .map(async (zoneId) => {
                    const id = zoneId.getId();
                    const zone = await getZoneDetails(id).catch(() => null);
                    if (zone) return zone;
                    return { id };
                }),
            app: result.getTargetsList()[0].getUseapp(),
            email: result.getTargetsList()[0].getUseemail(),
            // sms: result.getTargetsList()[0].getUsesms(),
            categories: result
                .getFilter()
                .getCategoriesList()
                .map(async (categoryId) => {
                    const id = categoryId.getId();

                    const category = await getCategoryDetails(id).catch(() => null);
                    if (category) return category;
                    return { id };
                }),
        };
    }
    return result;
};
