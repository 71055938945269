/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';

// API items
import { TeamsServiceClient } from 'api/v1/v1_grpc_web_pb';
import { Team, OrgRef, UserRef, TeamRef } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';
import { fetchOrgUserLists } from './orgUserSlice';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    teams: [],
    loading: true,
};

// =================================================================
// Team slice
// =================================================================

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeams(state, { payload }) {
            state.teams = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Team actions
// ===================================================================

export const { setLoading, setTeams } = teamSlice.actions;

// ====================================================================
// Team selector
// ====================================================================

export const teamSelector = (state) => state.team;

// =====================================================================
// Team reducer
// =====================================================================

export default teamSlice.reducer;

// ======================================================================
// Fetch Teams
// ======================================================================

export const fetchTeamLists = () => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        // Fetch orgUsers
        await dispatch(fetchOrgUserLists());
        //Retrieving Teams
        const result = await authRequestHandler(TeamsServiceClient, 'list');
        const teams = result.getItemsList().map((team) => {
            return {
                id: team.getId(),
                name: team.getName(),
                members: team.getMembersList().map((member) => ({
                    name: member.getName(),
                    userId: member.getId(),
                })),
            };
        });

        dispatch(setTeams(teams));
        return teams;
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Add Team
// ======================================================================

export const addTeam = (teamData) => async (dispatch, getState) => {
    const newTeam = new Team();
    newTeam.setId(uuidv4()); //random

    // Request to the API
    const result = await authRequestHandler(
        TeamsServiceClient,
        'add',
        createTeam(newTeam, { activeOrg: getState().auth.activeOrg, ...teamData }),
    );
    dispatch(fetchTeamLists());
    return result;
};

// ======================================================================
// Update a Team
// ======================================================================

export const updateTeam = (updatedTeam) => async (dispatch, getState) => {
    const editedTeam = await getTeamDetails(updatedTeam.id, false);

    //Request to the API
    const result = await authRequestHandler(
        TeamsServiceClient,
        'set',
        createTeam(editedTeam, { activeOrg: getState().auth.activeOrg, ...updatedTeam }),
    );

    // Update the state teams
    const updatedTeams = copyObj(getState().team.teams).map((team) => {
        if (team.id === updatedTeam.id) {
            return updatedTeam;
        }
        return team;
    });

    dispatch(setTeams(updatedTeams));
    return result;
};

// ======================================================================
// Delete a Team
// ======================================================================

export const deleteTeam = (deleteTeamId) => async (dispatch, getState) => {
    // Request to the API
    const result = await authRequestHandler(TeamsServiceClient, 'delete', ref(TeamRef, deleteTeamId));

    // Update the state teams
    const updatedTeams = copyObj(getState().team.teams).filter((team) => team.id !== deleteTeamId);

    dispatch(setTeams(updatedTeams));
    return result;
};

// ======================================================================
// Get a Team details
// ======================================================================

export const getTeamDetails = async (teamId, extracted = true) => {
    // Request to the API
    const result = await authRequestHandler(TeamsServiceClient, 'get', ref(TeamRef, teamId));
    if (extracted) {
        return {
            id: result.getId(),
            name: result.getName(),
            members: result.getMembersList().map((member) => ({
                name: member.getName(),
                userId: member.getId(),
            })),
        };
    }
    return result;
};

// ======================================================================
// Create team
// ======================================================================

const createTeam = (team, { activeOrg, name, members }) => {
    team.setOrg(ref(OrgRef, activeOrg));
    team.setName(name);
    team.setMembersList(members.map((member) => ref(UserRef, member.userId).setName(member.name)));
    return team;
};
