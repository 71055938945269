import React from 'react';
import useCatchError from 'hooks/useCatchError';

const withCatchError: (Component: React.ComponentType) => React.ComponentType = (Component) => {
    const WithCatchError: React.FC = (props) => {
        const catchError = useCatchError();
        // @ts-ignore
        return <Component {...props} catchError={catchError} />;
    };

    return WithCatchError;
};
export default withCatchError;
