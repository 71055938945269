/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';
import { getUserDetails } from './userSlice';
import { getCategoryDetails } from './categorySlice';
import { getEquipmentDetails } from './equipmentSlice';
import { getTeamDetails } from './teamSlice';

// API items
import { AnomaliesServiceClient, SearchClient } from 'api/v1/v1_grpc_web_pb';
import {
    OrgRef,
    Handling,
    AnomalyRef,
    Anomaly,
    UserRef,
    CategoryRef,
    AnomalyFilter,
    GeoBox,
    Point,
    TeamRef,
    FloorRef,
    PhotoRef,
    AudioRecordRef,
} from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';
import uploadHandler from 'api/handlers/uploadHandler';
import { getAPI_URL } from 'utils/auth';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    anomalies: [],
    loading: true,
};

// =================================================================
// Anomaly slice
// =================================================================

const anomalySlice = createSlice({
    name: 'anomaly',
    initialState,
    reducers: {
        setAnomalies(state, { payload }) {
            state.anomalies = payload;
        },

        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Anomaly actions
// ===================================================================

export const { setAnomalies, setLoading } = anomalySlice.actions;

// ====================================================================
// Anomaly selector
// ====================================================================

export const anomalySelector = (state) => state.anomaly;

// =====================================================================
// Anomaly reducer
// =====================================================================

export default anomalySlice.reducer;

// ======================================================================
// Fetch Anomalies
// ======================================================================

let lastRequest;
export const fetchAnomalies = ({ categoriesFilter, statusFilter, bounds }) => {
    const currentRequest = uuidv4();
    lastRequest = currentRequest;

    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const newAnomalyFilter = new AnomalyFilter();

            const newGeoBox = new GeoBox(); //part of the map we can see
            const southWest = new Point();

            southWest.setLat(bounds?._southWest?.lat);
            southWest.setLong(bounds?._southWest?.lng);

            const northEast = new Point();
            northEast.setLat(bounds?._northEast?.lat);
            northEast.setLong(bounds?._northEast?.lng);
            newGeoBox.setMin(southWest);
            newGeoBox.setMax(northEast);
            newAnomalyFilter.setBox(newGeoBox);

            //Filter on statuses
            const newStateList = [];
            if (statusFilter.newAdded) {
                newStateList.push('0');
            }
            if (statusFilter.confirmed) {
                newStateList.push('1');
            }

            if (statusFilter.rejected) {
                newStateList.push('2');
            }
            if (statusFilter.inProgress) {
                newStateList.push('3');
            }
            if (statusFilter.corrected) {
                newStateList.push('4');
            }
            if (statusFilter.toBeCheck) {
                newStateList.push('5');
            }
            if (statusFilter.archived) {
                newStateList.push('6');
            }

            newAnomalyFilter.setStatesList(newStateList);

            newAnomalyFilter.setCategoriesList(
                categoriesFilter.map((category) => {
                    const newCategoryRef = new CategoryRef();
                    newCategoryRef.setId(category);

                    return newCategoryRef;
                }),
            );

            // Request to the api
            const result = await authRequestHandler(SearchClient, 'anomalies', newAnomalyFilter);

            // If the current request is not the last request then cancel the execution
            if (currentRequest !== lastRequest) return false;
            if (result) {
                const anomalies = result.getItemsList().map((anomaly) => {
                    return {
                        id: anomaly.getId(),
                        label: anomaly.getLabel(),
                        creator: anomaly.getCreator().getId(),
                        assignTo: anomaly.getAssignedto()?.getId(),
                        createdAt: anomaly.getLatesthandling().getCreatedat(),
                        category: anomaly.getCategory().getId(),
                        kind: anomaly.getCategory().getId(),
                        description: anomaly.getDescription(),
                        latLng: [anomaly.getPoint().getLat(), anomaly.getPoint().getLong()],
                        zoneId: anomaly.getFloor().getZoneid(),
                        floorName: anomaly.getFloor().getFloorname(),
                        photos: anomaly.getPhotosList().map((photo) => photo.getId()),
                        state: anomaly.getLatesthandling()?.getState(),
                        audios: anomaly.getAudiorecordsList().map((audio) => audio.getId()),
                    };
                });
                dispatch(setAnomalies(anomalies));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };
};

// ======================================================================
// Create anomaly
// ======================================================================

export const createAnomaly = (anomaly) => async (dispatch, getState) => {
    const ano = ref(Anomaly, uuidv4());

    // Add user
    ano.setCreator(ref(UserRef, getState().auth.authUser.id));
    // Add category
    ano.setCategory(ref(CategoryRef, anomaly.category.id));
    // Add org
    ano.setOrg(ref(OrgRef, anomaly.company.id));

    ano.setLabel(anomaly.title);
    ano.setDescription(anomaly.description);

    // Add floor
    const floor = new FloorRef();
    floor.setZoneid(anomaly.zone.id);
    floor.setFloorname(anomaly.floor);
    ano.setFloor(floor);

    // Add point
    const point = new Point();
    point.setLat(anomaly.latlng[0]);
    point.setLong(anomaly.latlng[1]);
    ano.setPoint(point);

    // Add images
    ano.setPhotosList(
        await Promise.all(
            anomaly.images.map(async (file) => {
                const id = await uploadHandler(file);
                return ref(PhotoRef, `${getAPI_URL()}/uploads/${id}.${file.name.split('.').pop()}`);
            }),
        ),
    );

    // Add audios
    ano.setAudiorecordsList(
        await Promise.all(
            anomaly.audios.map(async (file) => {
                const id = await uploadHandler(file);
                return ref(AudioRecordRef, `${getAPI_URL()}/uploads/${id}.${file.name.split('.').pop()}`);
            }),
        ),
    );
    await authRequestHandler(AnomaliesServiceClient, 'add', ano);
};

// ======================================================================
// Update anomaly
// ======================================================================
export const updateAnomaly = (anomaly) => async (dispatch, getState) => {
    const anoRef = ref(AnomalyRef, anomaly.id);

    const ano = await authRequestHandler(AnomaliesServiceClient, 'get', anoRef);
    const prevState = ano.getLatesthandling()?.getState();
    const prevCreatedAt = ano.getLatesthandling().getCreatedat();
    // Only set the category if available
    const newCategoryRef = new CategoryRef();
    newCategoryRef.setId(anomaly.category);
    ano.setCategory(newCategoryRef);

    // If user change the state of an anomaly then add new handling
    const isStateChanged = prevState !== anomaly.state;

    const createdAt = new Date().getTime();
    if (isStateChanged) {
        const newHandling = new Handling();
        newHandling.setId(uuidv4());
        newHandling.setAnomaly(anoRef);

        newHandling.setState(anomaly.state);
        newHandling.setMessage(anomaly.stateComment);

        newHandling.setCreatedat(Math.floor(createdAt / 1000));
        ano.addHandlings(newHandling); 
    }

    ano.setAssignedteam(ref(TeamRef, anomaly?.assignedTeam?.id));
    ano.setAssignedto(ref(UserRef, anomaly.assignTo));

    await authRequestHandler(AnomaliesServiceClient, 'set', ano);

    const updateAnomalies = copyObj(getState().anomaly.anomalies).map((oldAnomaly) => {
        if (oldAnomaly.id === anomaly.id) {
            return {
                ...oldAnomaly,
                createdAt: isStateChanged ? createdAt : prevCreatedAt, // If the state changed then add new handling date otherwise keep old one
                category: anomaly.category,
                state: anomaly.state,
                assignTo: anomaly?.assignTo?.id,
            };
        }
        return oldAnomaly;
    });
    dispatch(setAnomalies(updateAnomalies));
};
// ======================================================================
// Send Message
// ======================================================================
export const sendMessage =
    ({ message, anomalyId, state }) =>
    async () => {
        const anoRef = ref(AnomalyRef, anomalyId);

        const ano = await authRequestHandler(AnomaliesServiceClient, 'get', anoRef);

        const createdAt = new Date().getTime();

        const newHandling = new Handling();
        newHandling.setId(uuidv4());
        newHandling.setAnomaly(anoRef);

        newHandling.setState(state);
        newHandling.setMessage(message);

        newHandling.setCreatedat(Math.floor(createdAt / 1000));
        ano.addHandlings(newHandling);

        await authRequestHandler(AnomaliesServiceClient, 'set', ano);
    };

// ======================================================================
// Send Message
// ======================================================================
export const vote =
    ({ anomalyId }) =>
    async () => {
        const ano = await authRequestHandler(AnomaliesServiceClient, 'get', ref(AnomalyRef, anomalyId));
        ano.setUpvotes(ano.getUpvotes() + 1);

        await authRequestHandler(AnomaliesServiceClient, 'set', ano);
        return ano;
    };

// ======================================================================
// Get single anomaly
// ======================================================================
export const getSingleAnomaly = async (anomalyId, extracted = true) => {
    const result = await authRequestHandler(AnomaliesServiceClient, 'get', ref(AnomalyRef, anomalyId));

    if (extracted) {
        return {
            id: result.getId(),
            label: result.getLabel(),
            creator: result.getCreator().getId(),
            assignTo: result.getAssignedto()?.getId(),
            assignedTeam: result.getAssignedteam()?.getId(),
            createdAt: result.getCreatedat(),
            latestStatus: result.getLatesthandling().getCreatedat(),
            category: result.getCategory().getId(),
            kind: result.getCategory().getId(),
            description: result.getDescription(),
            latLng: [result.getPoint().getLat(), result.getPoint().getLong()],
            zoneId: result.getFloor().getZoneid(),
            floorName: result.getFloor().getFloorname(),
            photos: result.getPhotosList().map((photo) => photo.getId()),
            state: result.getLatesthandling()?.getState(),
            audios: result.getAudiorecordsList().map((audio) => audio.getId()),
            upvotes: result.getUpvotes(),
            equipment: result.getEquipment()?.getId(),
            shortTag:result.getShorttag(),
            handlings: result.getHandlingsList().map((handling) => ({
                id: handling.getId(),
                state: handling.getState(),
                createdAt: handling.getCreatedat(),
                createdBy: { id: handling.getCreatedby()?.getId(), name: handling.getCreatedby()?.getName() },
                message: handling.getMessage(),
            })),
        };
    }

    return result;
};

// ======================================================================
// Get single anomaly details
// ======================================================================

export const fetchAnomalyDetails = async (anomalyId) => {
    const anomaly = await getSingleAnomaly(anomalyId);
    const promises = [];
    promises.push(getUserDetails(anomaly.creator));
    promises.push(getCategoryDetails(anomaly.category));
    // TODO: Have to  catch others errors without notfound
    promises.push(anomaly.assignTo ? getUserDetails(anomaly.assignTo).catch(() => null) : null);
    promises.push(anomaly.equipment ? getEquipmentDetails(anomaly.equipment).catch(() => null) : null);
    promises.push(anomaly.assignedTeam ? getTeamDetails(anomaly.assignedTeam).catch(() => null) : null);

    const [creator, category, assignTo, equipment, assignedTeam] = await Promise.all(promises);

    return { ...anomaly, creator, category, assignTo, equipment, assignedTeam };
};
