import { combineReducers } from '@reduxjs/toolkit';

import zoneReducer from './slices/zoneSlice';
import anomalyReducer from './slices/anomalySlice';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import orgUserReducer from './slices/orgUserSlice';
import categoryReducer from './slices/categorySlice';
import equipmentReducer from './slices/equipmentSlice';
import ruleSlice from './slices/ruleSlice';
import checklistReducer from './slices/checklistSlice';
import roundReducer from './slices/roundSlice';
import dashboardReducer from './slices/dashboardSlice';
import notificationSlice from './slices/notificationSlice';
import analysisSlice from './slices/analysisSlice';
import localizationSlice from './slices/localizationSlice';
import layerSlice from './slices/layerSlice';
import equipmentTypeSlice from './slices/equipmentTypeSlice';
import teamSlice from './slices/teamSlice';
import mapElementSlice from './slices/mapElementSlice';

export default combineReducers({
    anomaly: anomalyReducer,
    analysis: analysisSlice,
    auth: authReducer,
    category: categoryReducer,
    checklist: checklistReducer,
    dashboard: dashboardReducer,
    equipment: equipmentReducer,
    equipmentType: equipmentTypeSlice,
    localization: localizationSlice,
    notification: notificationSlice,
    orgUser: orgUserReducer,
    user: userReducer,
    rule: ruleSlice,
    round: roundReducer,
    zone: zoneReducer,
    layer: layerSlice,
    team: teamSlice,
    mapElement: mapElementSlice,
});
