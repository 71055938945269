import React, { useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import AuthNavbar from 'components/Navbars/AuthNavbar';
import Footer from 'components/Footer/Footer';
import authBg from 'assets/img/authBg.jpg';

const CommonLayoutWrapper: React.FC = ({ children }) => {
    // styles
    const classes = useStyles();
    useEffect(() => {
        document.body.style.overflow = 'unset';
        // Specify how to clean up after this effect:
        // return function cleanup() {};
    });

    return (
        <div>
            <AuthNavbar />
            <div className={classes.wrapper}>
                <div className={classes.fullPage} style={{ backgroundImage: 'url(' + authBg + ')' }}>
                    {children}
                    <Footer />
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: 'auto',
        minHeight: '100vh',
        position: 'relative',
        top: '0',
    },
    fullPage: {
        padding: '120px 0',
        position: 'relative',
        minHeight: '100vh',
        display: 'flex!important',
        margin: '0',
        border: '0',
        color: theme.palette.text.primary,
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'fit-content!important',
        },
        '& footer': {
            position: 'absolute',
            bottom: '0',
            width: '100%',
            border: 'none !important',
        },
        '&:before': {
            // backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)"
        },
        '&:before,&:after': {
            display: 'block',
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            zIndex: '2',
        },
    },
}));
export default CommonLayoutWrapper;
