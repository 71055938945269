import { createSlice } from '@reduxjs/toolkit';

// API items

import { CategoriesServiceClient, OrgsServiceClient } from 'api/v1/v1_grpc_web_pb';
import { CategoryRef, OrgRef } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    categories: [],
    defaultCategories: [],
    loading: true,
    defaultCategoryLoading: true,
};

// =================================================================
// Category slice
// =================================================================

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCategories(state, { payload }) {
            state.categories = payload;
        },
        setDefaultCategories(state, { payload }) {
            state.defaultCategories = payload;
        },

        setLoading(state, { payload }) {
            state.loading = payload;
        },
        setDefaultCategoryLoading(state, { payload }) {
            state.defaultCategoryLoading = payload;
        },
    },
});

// ===================================================================
// Category actions
// ===================================================================

export const { setLoading, setCategories, setDefaultCategories, setDefaultCategoryLoading } = categorySlice.actions;

// ====================================================================
// Category selector
// ====================================================================

export const categorySelector = (state) => state.category;

// =====================================================================
// Category reducer
// =====================================================================

export default categorySlice.reducer;

// ======================================================================
// Fetch Categories
// ======================================================================

export const fetchCategoryLists = () => async (dispatch) => {
    dispatch(setLoading(true));

    try {
        //Retrieving Categories
        const result = await authRequestHandler(CategoriesServiceClient, 'list');

        const categories = result
            .getItemsList()
            .filter((category) => category.getIsactive())
            .map((category) => {
                return {
                    id: category.getId(),
                    name: category.getName(),
                    logo: category.getLogo(),
                    isActive: category.getIsactive(),
                };
            });

        dispatch(setCategories(categories));
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Set default categories
// ======================================================================

export const updateDefaultCategories = (categoryId) => async (dispatch, getState) => {
    const defaultCategories = getState().category.defaultCategories;
    const isDefaultCategory = defaultCategories.includes(categoryId);

    let updatedDefaultCategories = [];

    const org = await authRequestHandler(OrgsServiceClient, 'get', ref(OrgRef, getState().auth.activeOrg));

    if (isDefaultCategory) {
        updatedDefaultCategories = defaultCategories.filter((currentItem) => currentItem !== categoryId);
        org.setDefaultcategoriesList(
            org.getDefaultcategoriesList().filter((currentCategory) => currentCategory.getId() !== categoryId),
        );
    } else {
        updatedDefaultCategories = [...defaultCategories, categoryId];
        org.setDefaultcategoriesList([...org.getDefaultcategoriesList(), await getCategoryDetails(categoryId, false)]);
    }

    const result = await authRequestHandler(OrgsServiceClient, 'set', org);
    dispatch(setDefaultCategories(updatedDefaultCategories));
    return result;
};

// ======================================================================
// Get a Category details
// ======================================================================

export const getCategoryDetails = async (categoryId, extracted = true) => {
    // Request to the API
    const result = await authRequestHandler(CategoriesServiceClient, 'get', ref(CategoryRef, categoryId));
    if (extracted) {
        return {
            id: result.getId(),
            name: result.getName(),
            logo: result.getLogo(),
            isActive: result.getIsactive(),
        };
    }
    return result;
};

// ======================================================================
// Fetch default Categories
// ======================================================================

export const fetchDefaultCategories = () => async (dispatch, getState) => {
    dispatch(setDefaultCategoryLoading(true));

    try {
        const result = await authRequestHandler(OrgsServiceClient, 'get', ref(OrgRef, getState().auth.activeOrg));

        const defaultCategories = result.getDefaultcategoriesList().map((category) => category.getId());

        dispatch(setDefaultCategories(defaultCategories));
    } finally {
        dispatch(setDefaultCategoryLoading(false));
    }
};
