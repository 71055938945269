import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LocaleSwitcher from 'components/Navbars/LocaleSwitcher';
import AdminNotification from 'components/Navbars/AdminNotification';
import AdminProfileDropDown from 'components/Navbars/AdminProfileDropDown';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapperRTL: {
        // [theme.breakpoints.up('md')]: {
        paddingLeft: '16px',
        // },
    },
});

export default function HeaderLinks(props) {
    const { rtlActive } = props;
    const classes = useStyles();

    const wrapper = classNames(classes.wrapper, {
        [classes.wrapperRTL]: rtlActive,
    });

    return (
        <div className={wrapper}>
            <LocaleSwitcher />
            <AdminNotification rtlActive={rtlActive} />
            <AdminProfileDropDown />
        </div>
    );
}

HeaderLinks.propTypes = {
    rtlActive: PropTypes.bool,
};
