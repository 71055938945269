import React, { memo, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
// core components
import Button from 'components/CustomButtons/Button';
import {
    defaultFont,
    primaryBoxShadow,
    infoBoxShadow,
    successBoxShadow,
    warningBoxShadow,
    dangerBoxShadow,
    roseBoxShadow,
    whiteColor,
    blackColor,
    hexToRgb,
} from 'assets/jss/material-dashboard-pro-react';

const CustomDropdown = (props) => {
    const {
        className,
        buttonText,
        buttonIcon,
        dropdownList,
        buttonProps,
        dropup,
        dropdownHeader,
        caret,
        hoverColor,
        dropPlacement,
        rtlActive,
        noLiPadding,
        innerDropDown,
        navDropdown,
        onClick,
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const buttonClickHandler = useCallback(
        (event) => {
            if (anchorEl && anchorEl.contains(event.target)) {
                setAnchorEl(null);
            } else {
                setAnchorEl(event.currentTarget);
            }
        },
        [anchorEl],
    );

    const handleOutsideClick = useCallback(
        (event) => {
            if (anchorEl?.contains(event.target)) {
                return;
            }
            setAnchorEl(null);
        },
        [anchorEl],
    );

    const handleMenuItemClick = useCallback(
        (param) => {
            setAnchorEl(null);
            if (onClick) {
                onClick(param);
            }
        },
        [onClick],
    );

    const classes = useStyles();
    const caretClasses = classNames({
        [classes.caret]: true,
        [classes.caretDropup]: dropup && !anchorEl,
        [classes.caretActive]: open && !dropup,
        [classes.caretRTL]: rtlActive,
    });
    const dropdownItem = classNames({
        [classes.dropdownItem]: true,
        [classes[hoverColor + 'Hover']]: true,
        [classes.noLiPadding]: noLiPadding,
        [classes.dropdownItemRTL]: rtlActive,
    });

    const dropDownMenuItems = useMemo(
        () =>
            dropdownList.map((prop, key) => {
                if (prop.divider) {
                    return (
                        <Divider
                            key={key}
                            onClick={() => handleMenuItemClick('divider')}
                            className={classes.dropdownDividerItem}
                        />
                    );
                } else if (prop.props !== undefined && prop.props['data-ref'] === 'multi') {
                    return (
                        <MenuItem
                            key={key}
                            className={dropdownItem}
                            style={{ overflow: 'visible', padding: 0 }}
                            classes={{ selected: classes[hoverColor + 'Selected'] }}
                        >
                            {prop}
                        </MenuItem>
                    );
                }
                return (
                    <MenuItem
                        key={key}
                        onClick={() => {
                            handleMenuItemClick(prop);
                            if (prop && prop.onClick) {
                                prop.onClick();
                            }
                        }}
                        className={dropdownItem}
                        classes={{ selected: classes[hoverColor + 'Selected'] }}
                        {...(prop.menuItemProps || {})}
                    >
                        {typeof prop === 'string' ? prop : prop.content}
                    </MenuItem>
                );
            }),
        [classes, dropdownItem, dropdownList, handleMenuItemClick, hoverColor],
    );

    const dropDownMenu = (
        <MenuList role="menu" className={classes.menuList} id="menu-list" autoFocusItem={open}>
            {dropdownHeader !== undefined ? (
                <MenuItem onClick={() => handleMenuItemClick(dropdownHeader)} className={classes.dropdownHeader}>
                    {dropdownHeader}
                </MenuItem>
            ) : null}
            {dropDownMenuItems}
        </MenuList>
    );

    return (
        <div className={(innerDropDown ? classes.innerManager : classes.manager) + ' ' + className}>
            <div className={buttonText !== undefined ? '' : classes.target}>
                <Button
                    aria-label="Notifications"
                    aria-controls={open ? 'menu-list' : undefined}
                    aria-haspopup="true"
                    {...buttonProps}
                    onClick={buttonClickHandler}
                >
                    {React.isValidElement(buttonIcon)
                        ? React.cloneElement(buttonIcon, {
                              className: classes.buttonIcon + ' ' + buttonIcon.props.className,
                          })
                        : null}
                    {buttonText !== undefined ? buttonText : null}
                    {caret ? <b className={caretClasses} /> : null}
                </Button>
            </div>
            <Popper
                open={open}
                anchorEl={anchorEl}
                transition
                disablePortal
                keepMounted
                placement={dropPlacement}
                className={classNames({
                    [classes.popperClose]: !anchorEl,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: open && navDropdown,
                })}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={dropup ? { transformOrigin: '0 100% 0' } : { transformOrigin: '0 0 0' }}
                    >
                        <Paper
                            className={classes.dropdown}
                            style={{
                                overflow: 'auto',
                                maxHeight: 'calc(100vh - 71px)',
                            }}
                        >
                            {innerDropDown ? (
                                dropDownMenu
                            ) : (
                                <ClickAwayListener onClickAway={handleOutsideClick}>{dropDownMenu}</ClickAwayListener>
                            )}
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

CustomDropdown.defaultProps = {
    className: '',
    caret: true,
    dropup: false,
    hoverColor: 'primary',
};

CustomDropdown.propTypes = {
    className: PropTypes.string,
    hoverColor: PropTypes.oneOf(['dark', 'primary', 'info', 'success', 'warning', 'danger', 'rose']),
    buttonText: PropTypes.node,
    buttonIcon: PropTypes.node,
    dropdownList: PropTypes.array,
    buttonProps: PropTypes.object,
    dropup: PropTypes.bool,
    dropdownHeader: PropTypes.node,
    rtlActive: PropTypes.bool,
    caret: PropTypes.bool,
    dropPlacement: PropTypes.oneOf([
        'bottom',
        'top',
        'right',
        'left',
        'bottom-start',
        'bottom-end',
        'top-start',
        'top-end',
        'right-start',
        'right-end',
        'left-start',
        'left-end',
    ]),
    noLiPadding: PropTypes.bool,
    innerDropDown: PropTypes.bool,
    navDropdown: PropTypes.bool,
    // This is a function that returns the clicked menu item
    onClick: PropTypes.func,
};

export default memo(CustomDropdown, isEqual);

const useStyles = makeStyles((theme) => ({
    popperClose: {
        pointerEvents: 'none',
        display: 'none !important',
    },
    popperNav: {
        [theme.breakpoints.down('sm')]: {
            position: 'static !important',
            left: 'unset !important',
            top: 'unset !important',
            transform: 'none !important',
            willChange: 'unset !important',
            '& > div': {
                boxShadow: 'none !important',
                marginLeft: '0rem',
                marginRight: '0rem',
                transition: 'none !important',
                marginTop: '0px !important',
                marginBottom: '0px !important',
                padding: '0px !important',
                backgroundColor: 'transparent !important',
                '& ul li': {
                    color: theme.palette.whiteColor + ' !important',
                    margin: '10px 15px 0!important',
                    padding: '10px 15px !important',
                    '&:hover': {
                        backgroundColor: 'hsla(0,0%,78%,.2)',
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
    manager: {
        '& > div > button:first-child > span:first-child, & > div > a:first-child > span:first-child': {
            width: '100%',
        },
    },
    innerManager: {
        '& > div > button,& > div > a': {
            margin: '0px !important',
            color: 'inherit !important',
            padding: '10px 20px !important',
            '& > span:first-child': {
                width: '100%',
                justifyContent: 'flex-start',
            },
        },
    },
    target: {
        '& > button:first-child > span:first-child, & > a:first-child > span:first-child': {
            display: 'inline-block',
        },
        '& $caret': {
            marginLeft: '0px',
        },
    },
    dropdown: {
        borderRadius: '3px',
        border: '0',
        boxShadow: '0 2px 5px 0 rgba(' + hexToRgb(blackColor) + ', 0.26)',
        top: '100%',
        zIndex: '1000',
        minWidth: '160px',
        padding: '5px 0',
        margin: '2px 0 0',
        fontSize: '14px',
        textAlign: 'left',
        listStyle: 'none',
        backgroundColor: whiteColor,
        backgroundClip: 'padding-box',
    },
    menuList: {
        padding: '0',
    },
    popperResponsive: {
        zIndex: '1200',
        [theme.breakpoints.down('sm')]: {
            zIndex: '1640',
            position: 'static',
            float: 'none',
            width: 'auto',
            marginTop: '0',
            backgroundColor: 'transparent',
            border: '0',
            boxShadow: 'none',
            color: 'black',
        },
    },
    dropdownItem: {
        ...defaultFont,
        fontSize: '13px',
        padding: '10px 20px',
        margin: '0 5px',
        borderRadius: '2px',
        position: 'relative',
        transition: 'all 150ms linear',
        display: 'block',
        clear: 'both',
        fontWeight: '400',
        height: '100%',
        color: theme.palette.grayColor[7],
        whiteSpace: 'nowrap',
        minHeight: 'unset',
        textTransform: 'capitalize',
        '& a, &:focus': {
            color: 'inherit',
        },
    },
    darkHover: {
        '&:hover': {
            boxShadow:
                '0 4px 20px 0px rgba(' +
                hexToRgb(blackColor) +
                ', 0.14), 0 7px 10px -5px rgba(' +
                hexToRgb(theme.palette.grayColor[16]) +
                ', 0.4)',
            backgroundColor: theme.palette.grayColor[16],
            color: theme.palette.whiteColor,
        },
    },
    primaryHover: {
        '&:hover': {
            backgroundColor: theme.palette.primaryColor[0],
            color: theme.palette.whiteColor,
            ...primaryBoxShadow,
        },
    },
    primarySelected: {
        backgroundColor: 'rgba(' + hexToRgb(theme.palette.primaryColor[0]) + ', 0.8)  !important',
        color: theme.palette.whiteColor,
        ...primaryBoxShadow,
    },
    infoHover: {
        '&:hover': {
            backgroundColor: theme.palette.infoColor[0],
            color: theme.palette.whiteColor,
            ...infoBoxShadow,
        },
    },
    successHover: {
        '&:hover': {
            backgroundColor: theme.palette.successColor[0],
            color: theme.palette.whiteColor,
            ...successBoxShadow,
        },
    },
    warningHover: {
        '&:hover': {
            backgroundColor: theme.palette.warningColor[0],
            color: theme.palette.whiteColor,
            ...warningBoxShadow,
        },
    },
    dangerHover: {
        '&:hover': {
            backgroundColor: theme.palette.dangerColor[0],
            color: theme.palette.whiteColor,
            ...dangerBoxShadow,
        },
    },
    roseHover: {
        '&:hover': {
            backgroundColor: theme.palette.roseColor[0],
            color: whiteColor,
            ...roseBoxShadow,
        },
    },
    dropdownItemRTL: {
        textAlign: 'right',
    },
    dropdownDividerItem: {
        margin: '5px 0',
        backgroundColor: 'rgba(' + hexToRgb(blackColor) + ', 0.12)',
        height: '1px',
        overflow: 'hidden',
    },
    buttonIcon: {
        width: '20px',
        height: '20px',
    },
    caret: {
        transition: 'all 150ms ease-in',
        display: 'inline-block',
        width: '0',
        height: '0',
        marginLeft: '4px',
        verticalAlign: 'middle',
        borderTop: '4px solid',
        borderRight: '4px solid transparent',
        borderLeft: '4px solid transparent',
    },
    caretActive: {
        transform: 'rotate(180deg)',
    },
    caretDropup: {
        transform: 'rotate(180deg)',
    },
    caretRTL: {
        marginRight: '4px',
    },
    dropdownHeader: {
        display: 'block',
        padding: '0.1875rem 1.25rem',
        fontSize: '0.75rem',
        lineHeight: '1.428571',
        color: theme.palette.grayColor[1],
        whiteSpace: 'nowrap',
        fontWeight: 'inherit',
        marginTop: '10px',
        minHeight: 'unset',
        '&:hover,&:focus': {
            backgroundColor: 'transparent',
            cursor: 'auto',
        },
    },
    noLiPadding: {
        padding: '0',
    },
}));
