export default class CustomError extends Error {
    constructor(err) {
        const { message, code, metadata, name = 'CustomError', codeText ,request={}} = err;

        super(message);
        this.name = name;
        this.code = code;
        this.metadata = metadata;
        this.codeText = codeText;
        this.request = request;
        Error?.captureStackTrace?.(this, CustomError);
    }
}
