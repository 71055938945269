import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
// @material-ui/core components

// core components
import LazyLoader from 'components/LazyLoader/LazyLoader';
import CommonLayoutWrapper from 'layouts/CommonLayoutWrapper';
import useRoutes from 'routes/routes';
import { selectIsAuthenticated } from 'store/selectors/authSelectors';

const AuthLayout = ({ history }) => {
    const routes = useRoutes();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === '/auth') {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return null;
            }
        });
    };

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/admin/dashboard');
        }
    }, [isAuthenticated]);

    return (
        <CommonLayoutWrapper>
            <Switch>
                <LazyLoader>{getRoutes(routes)}</LazyLoader>
                <Redirect from="/auth" to="/auth/login" />
            </Switch>
        </CommonLayoutWrapper>
    );
};

AuthLayout.propTypes = {
    history: PropTypes.object.isRequired,
};

export default AuthLayout;
