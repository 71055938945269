import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

const Loader = ({ loading, size, background, whiteBg, color, type }) => {
    let backgroundColor = background;
    if (whiteBg) backgroundColor = 'rgb(238 238 238 / 39%)';

    const classes = useStyles();
    return (
        <div
            className={cx(classes.loaderWrapper, {
                [classes.loaderTopWrapper]: type === 'top',
                [classes.loaderCenterWrapper]: type === 'center',
                [classes.wrapperActive]: loading,
            })}
            style={{ backgroundColor }}
        >
            <div
                className={cx(classes.loader, {
                    [classes.loaderTop]: type === 'top',
                    [classes.loaderCenter]: type === 'center',
                })}
                style={{ padding: size * 0.3 }}
            >
                <CircularProgress color={color} size={size + 'px'} />
            </div>
        </div>
    );
};

Loader.propTypes = {
    loading: PropTypes.bool,
    size: PropTypes.string,
    background: PropTypes.string,
    whiteBg: PropTypes.bool,
    color: PropTypes.string,
    type: PropTypes.string,
};

Loader.defaultProps = {
    loading: false,
    size: '50',
    background: 'transparent',
    color: 'primary',
    type: 'center',
};

const useStyles = makeStyles({
    loaderWrapper: {
        position: 'absolute',

        opacity: 0,
        visibility: 'hidden',
        zIndex: '-1',
        transition: '0.2s',
    },
    loaderTopWrapper: {
        left: '50%',
        top: '50px',
        transform: 'translateX(-50%)',
    },
    loaderCenterWrapper: {
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wrapperActive: { opacity: 1, visibility: 'visible', zIndex: '999' },
    loader: {
        boxShadow:
            '0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
        borderRadius: '50%',
        padding: '15px',
        background: '#fff',
        transition: '0.2s',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default Loader;
