import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import LazyLoader from 'components/LazyLoader/LazyLoader';
import Footer from 'components/Footer/Footer';
import { containerFluid } from 'assets/jss/material-dashboard-pro-react';
import { selectActiveOrg } from 'store/selectors/authSelectors';

const withAdminContainer = (Component, isFullScreen) => {
    const Container = (props) => {
        const activeOrg = useSelector(selectActiveOrg);
        const classes = useStyles();

        const content = (
            <LazyLoader>
                <Component activeOrg={activeOrg} {...props} />
            </LazyLoader>
        );
        //   On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and container classes are present because they have some paddings which would make the map smaller
        return isFullScreen ? (
            <div className={classes.map}>{content}</div>
        ) : (
            <>
                <div className={classes.content}>
                    <div className={classes.container}>{content}</div>
                </div>
                <Footer fluid />
            </>
        );
    };

    return Container;
};

const useStyles = makeStyles({
    content: {
        marginTop: '71px',
        padding: '30px 15px',
        minHeight: 'calc(100vh - 133px)',
        position: 'relative',
    },
    container: { ...containerFluid },
    map: {
        marginTop: '71px',
        position: 'relative',
        height: 'calc(100vh - 71px)',
    },
});
export default withAdminContainer;
