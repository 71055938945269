import React from 'react';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';

const CloseButton = ({ closeToast }) => (
    <button className="toast-btn" onClick={closeToast}>
        <Close />
    </button>
);
CloseButton.propTypes = { closeToast: PropTypes.func };

export default CloseButton;
