// source: api/v1/v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
goog.exportSymbol('proto.v1.AdminOrg', null, global);
goog.exportSymbol('proto.v1.AdminOrgList', null, global);
goog.exportSymbol('proto.v1.AdminOrgRef', null, global);
goog.exportSymbol('proto.v1.Alert', null, global);
goog.exportSymbol('proto.v1.AlertList', null, global);
goog.exportSymbol('proto.v1.AlertRef', null, global);
goog.exportSymbol('proto.v1.AlertTarget', null, global);
goog.exportSymbol('proto.v1.Analyse', null, global);
goog.exportSymbol('proto.v1.AnalyseExec', null, global);
goog.exportSymbol('proto.v1.AnalyseExecRef', null, global);
goog.exportSymbol('proto.v1.AnalyseRef', null, global);
goog.exportSymbol('proto.v1.AnalysisStatus', null, global);
goog.exportSymbol('proto.v1.AnalysisStatusList', null, global);
goog.exportSymbol('proto.v1.Anomaly', null, global);
goog.exportSymbol('proto.v1.AnomalyFilter', null, global);
goog.exportSymbol('proto.v1.AnomalyList', null, global);
goog.exportSymbol('proto.v1.AnomalyRef', null, global);
goog.exportSymbol('proto.v1.AnomalyRefList', null, global);
goog.exportSymbol('proto.v1.AnomalySummary', null, global);
goog.exportSymbol('proto.v1.AnonymousAnomaly', null, global);
goog.exportSymbol('proto.v1.AudioRecordRef', null, global);
goog.exportSymbol('proto.v1.Authorization', null, global);
goog.exportSymbol('proto.v1.Badge', null, global);
goog.exportSymbol('proto.v1.CSV', null, global);
goog.exportSymbol('proto.v1.Category', null, global);
goog.exportSymbol('proto.v1.CategoryList', null, global);
goog.exportSymbol('proto.v1.CategoryRef', null, global);
goog.exportSymbol('proto.v1.ChangeKind', null, global);
goog.exportSymbol('proto.v1.ChatAddReq', null, global);
goog.exportSymbol('proto.v1.ChatEditReq', null, global);
goog.exportSymbol('proto.v1.ChatList', null, global);
goog.exportSymbol('proto.v1.ChatMessage', null, global);
goog.exportSymbol('proto.v1.ChatMessageRef', null, global);
goog.exportSymbol('proto.v1.ChatReq', null, global);
goog.exportSymbol('proto.v1.ChatSubject', null, global);
goog.exportSymbol('proto.v1.ChatSubject.SetRef', null, global);
goog.exportSymbol('proto.v1.Check', null, global);
goog.exportSymbol('proto.v1.CheckExec', null, global);
goog.exportSymbol('proto.v1.CheckFilter', null, global);
goog.exportSymbol('proto.v1.CheckList', null, global);
goog.exportSymbol('proto.v1.CheckListList', null, global);
goog.exportSymbol('proto.v1.CheckListRef', null, global);
goog.exportSymbol('proto.v1.CheckRef', null, global);
goog.exportSymbol('proto.v1.CheckResult', null, global);
goog.exportSymbol('proto.v1.CheckState', null, global);
goog.exportSymbol('proto.v1.Dashboard', null, global);
goog.exportSymbol('proto.v1.DashboardReq', null, global);
goog.exportSymbol('proto.v1.DayCounts', null, global);
goog.exportSymbol('proto.v1.DocRef', null, global);
goog.exportSymbol('proto.v1.Emergency', null, global);
goog.exportSymbol('proto.v1.EmergencyList', null, global);
goog.exportSymbol('proto.v1.EmergencyRef', null, global);
goog.exportSymbol('proto.v1.EmergencySummary', null, global);
goog.exportSymbol('proto.v1.EmergencySummaryList', null, global);
goog.exportSymbol('proto.v1.EmergencyTarget', null, global);
goog.exportSymbol('proto.v1.Empty', null, global);
goog.exportSymbol('proto.v1.Equipment', null, global);
goog.exportSymbol('proto.v1.EquipmentList', null, global);
goog.exportSymbol('proto.v1.EquipmentRef', null, global);
goog.exportSymbol('proto.v1.EquipmentType', null, global);
goog.exportSymbol('proto.v1.EquipmentTypeList', null, global);
goog.exportSymbol('proto.v1.EquipmentTypeRef', null, global);
goog.exportSymbol('proto.v1.FloorRef', null, global);
goog.exportSymbol('proto.v1.GPSPoint', null, global);
goog.exportSymbol('proto.v1.GeoBox', null, global);
goog.exportSymbol('proto.v1.Group', null, global);
goog.exportSymbol('proto.v1.GroupList', null, global);
goog.exportSymbol('proto.v1.GroupRef', null, global);
goog.exportSymbol('proto.v1.Handling', null, global);
goog.exportSymbol('proto.v1.HandlingFilter', null, global);
goog.exportSymbol('proto.v1.HandlingList', null, global);
goog.exportSymbol('proto.v1.HandlingRef', null, global);
goog.exportSymbol('proto.v1.HandlingState', null, global);
goog.exportSymbol('proto.v1.ImageOverlay', null, global);
goog.exportSymbol('proto.v1.ImageOverlayList', null, global);
goog.exportSymbol('proto.v1.ImageOverlayRef', null, global);
goog.exportSymbol('proto.v1.InviteUserReq', null, global);
goog.exportSymbol('proto.v1.Login', null, global);
goog.exportSymbol('proto.v1.LoginReq', null, global);
goog.exportSymbol('proto.v1.MapElement', null, global);
goog.exportSymbol('proto.v1.MapElement.DetailsCase', null, global);
goog.exportSymbol('proto.v1.MapElementChange', null, global);
goog.exportSymbol('proto.v1.MapElementList', null, global);
goog.exportSymbol('proto.v1.MapElementRef', null, global);
goog.exportSymbol('proto.v1.MapIcon', null, global);
goog.exportSymbol('proto.v1.MapIconList', null, global);
goog.exportSymbol('proto.v1.MapIconRef', null, global);
goog.exportSymbol('proto.v1.Meta', null, global);
goog.exportSymbol('proto.v1.MetricValue', null, global);
goog.exportSymbol('proto.v1.MetricValueList', null, global);
goog.exportSymbol('proto.v1.MetricsQuery', null, global);
goog.exportSymbol('proto.v1.NewPassword', null, global);
goog.exportSymbol('proto.v1.NextNotifList', null, global);
goog.exportSymbol('proto.v1.NextReq', null, global);
goog.exportSymbol('proto.v1.Notif', null, global);
goog.exportSymbol('proto.v1.Notif.NotifKind', null, global);
goog.exportSymbol('proto.v1.NotifList', null, global);
goog.exportSymbol('proto.v1.NotifRef', null, global);
goog.exportSymbol('proto.v1.OpLog', null, global);
goog.exportSymbol('proto.v1.OpLogList', null, global);
goog.exportSymbol('proto.v1.OpsLogReq', null, global);
goog.exportSymbol('proto.v1.Org', null, global);
goog.exportSymbol('proto.v1.OrgList', null, global);
goog.exportSymbol('proto.v1.OrgLoginReq', null, global);
goog.exportSymbol('proto.v1.OrgRef', null, global);
goog.exportSymbol('proto.v1.OrgRole', null, global);
goog.exportSymbol('proto.v1.OrgTheme', null, global);
goog.exportSymbol('proto.v1.OrgType', null, global);
goog.exportSymbol('proto.v1.OrgUser', null, global);
goog.exportSymbol('proto.v1.OrgUserList', null, global);
goog.exportSymbol('proto.v1.OrgUserRef', null, global);
goog.exportSymbol('proto.v1.PhotoRef', null, global);
goog.exportSymbol('proto.v1.Point', null, global);
goog.exportSymbol('proto.v1.Ref', null, global);
goog.exportSymbol('proto.v1.RegisterReq', null, global);
goog.exportSymbol('proto.v1.ReorderReq', null, global);
goog.exportSymbol('proto.v1.ReportCheckAnomalyReq', null, global);
goog.exportSymbol('proto.v1.Ronde', null, global);
goog.exportSymbol('proto.v1.RondeExec', null, global);
goog.exportSymbol('proto.v1.RondeExecList', null, global);
goog.exportSymbol('proto.v1.RondeExecRef', null, global);
goog.exportSymbol('proto.v1.RondeList', null, global);
goog.exportSymbol('proto.v1.RondeRef', null, global);
goog.exportSymbol('proto.v1.Rule', null, global);
goog.exportSymbol('proto.v1.RuleList', null, global);
goog.exportSymbol('proto.v1.RuleRef', null, global);
goog.exportSymbol('proto.v1.SLAStats', null, global);
goog.exportSymbol('proto.v1.ScheduleDaily', null, global);
goog.exportSymbol('proto.v1.ScheduleItem', null, global);
goog.exportSymbol('proto.v1.ScheduleItem.FrequencyCase', null, global);
goog.exportSymbol('proto.v1.ScheduleMonthly', null, global);
goog.exportSymbol('proto.v1.ScheduleSingle', null, global);
goog.exportSymbol('proto.v1.ScheduleWeekly', null, global);
goog.exportSymbol('proto.v1.ScheduleYearly', null, global);
goog.exportSymbol('proto.v1.SetPasswordReq', null, global);
goog.exportSymbol('proto.v1.SocialReactions', null, global);
goog.exportSymbol('proto.v1.Stats', null, global);
goog.exportSymbol('proto.v1.TaskFilter', null, global);
goog.exportSymbol('proto.v1.Tasks', null, global);
goog.exportSymbol('proto.v1.Team', null, global);
goog.exportSymbol('proto.v1.TeamList', null, global);
goog.exportSymbol('proto.v1.TeamRef', null, global);
goog.exportSymbol('proto.v1.TodoItem', null, global);
goog.exportSymbol('proto.v1.TodoList', null, global);
goog.exportSymbol('proto.v1.TodoListList', null, global);
goog.exportSymbol('proto.v1.TodoListRef', null, global);
goog.exportSymbol('proto.v1.TodoListSource', null, global);
goog.exportSymbol('proto.v1.UploadReq', null, global);
goog.exportSymbol('proto.v1.User', null, global);
goog.exportSymbol('proto.v1.UserInfo', null, global);
goog.exportSymbol('proto.v1.UserList', null, global);
goog.exportSymbol('proto.v1.UserName', null, global);
goog.exportSymbol('proto.v1.UserProfile', null, global);
goog.exportSymbol('proto.v1.UserRef', null, global);
goog.exportSymbol('proto.v1.WatchReq', null, global);
goog.exportSymbol('proto.v1.Zone', null, global);
goog.exportSymbol('proto.v1.ZoneList', null, global);
goog.exportSymbol('proto.v1.ZoneRef', null, global);
goog.exportSymbol('proto.v1.refTarget', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AdminOrg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AdminOrg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AdminOrg.displayName = 'proto.v1.AdminOrg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.UserName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.UserName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.UserName.displayName = 'proto.v1.UserName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.NewPassword = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.NewPassword, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.NewPassword.displayName = 'proto.v1.NewPassword';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OpsLogReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.OpsLogReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OpsLogReq.displayName = 'proto.v1.OpsLogReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OpLogList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.OpLogList.repeatedFields_, null);
};
goog.inherits(proto.v1.OpLogList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OpLogList.displayName = 'proto.v1.OpLogList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OpLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.OpLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OpLog.displayName = 'proto.v1.OpLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Login = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Login, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Login.displayName = 'proto.v1.Login';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.RegisterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.RegisterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.RegisterReq.displayName = 'proto.v1.RegisterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.LoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.LoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.LoginReq.displayName = 'proto.v1.LoginReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OrgLoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.OrgLoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OrgLoginReq.displayName = 'proto.v1.OrgLoginReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.SetPasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.SetPasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.SetPasswordReq.displayName = 'proto.v1.SetPasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CheckFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.CheckFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CheckFilter.displayName = 'proto.v1.CheckFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ReorderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.ReorderReq.repeatedFields_, null);
};
goog.inherits(proto.v1.ReorderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ReorderReq.displayName = 'proto.v1.ReorderReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ReportCheckAnomalyReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ReportCheckAnomalyReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ReportCheckAnomalyReq.displayName = 'proto.v1.ReportCheckAnomalyReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CheckResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.CheckResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CheckResult.displayName = 'proto.v1.CheckResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CheckRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.CheckRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CheckRef.displayName = 'proto.v1.CheckRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OrgUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.OrgUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OrgUser.displayName = 'proto.v1.OrgUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.UploadReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.UploadReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.UploadReq.displayName = 'proto.v1.UploadReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Authorization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Authorization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Authorization.displayName = 'proto.v1.Authorization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Tasks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Tasks.repeatedFields_, null);
};
goog.inherits(proto.v1.Tasks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Tasks.displayName = 'proto.v1.Tasks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.TaskFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.TaskFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.TaskFilter.displayName = 'proto.v1.TaskFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnomalyFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.AnomalyFilter.repeatedFields_, null);
};
goog.inherits(proto.v1.AnomalyFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnomalyFilter.displayName = 'proto.v1.AnomalyFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnalysisStatusList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.AnalysisStatusList.repeatedFields_, null);
};
goog.inherits(proto.v1.AnalysisStatusList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnalysisStatusList.displayName = 'proto.v1.AnalysisStatusList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnalysisStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AnalysisStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnalysisStatus.displayName = 'proto.v1.AnalysisStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CSV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.CSV.repeatedFields_, null);
};
goog.inherits(proto.v1.CSV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CSV.displayName = 'proto.v1.CSV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Empty.displayName = 'proto.v1.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Org = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Org.repeatedFields_, null);
};
goog.inherits(proto.v1.Org, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Org.displayName = 'proto.v1.Org';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OrgTheme = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.OrgTheme, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OrgTheme.displayName = 'proto.v1.OrgTheme';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Zone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Zone.repeatedFields_, null);
};
goog.inherits(proto.v1.Zone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Zone.displayName = 'proto.v1.Zone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Point.displayName = 'proto.v1.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.GeoBox = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.GeoBox, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.GeoBox.displayName = 'proto.v1.GeoBox';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.GPSPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.GPSPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.GPSPoint.displayName = 'proto.v1.GPSPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.HandlingFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.HandlingFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.HandlingFilter.displayName = 'proto.v1.HandlingFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ZoneRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ZoneRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ZoneRef.displayName = 'proto.v1.ZoneRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ZoneList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.ZoneList.repeatedFields_, null);
};
goog.inherits(proto.v1.ZoneList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ZoneList.displayName = 'proto.v1.ZoneList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Anomaly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Anomaly.repeatedFields_, null);
};
goog.inherits(proto.v1.Anomaly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Anomaly.displayName = 'proto.v1.Anomaly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnomalySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AnomalySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnomalySummary.displayName = 'proto.v1.AnomalySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.FloorRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.FloorRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.FloorRef.displayName = 'proto.v1.FloorRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.PhotoRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.PhotoRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.PhotoRef.displayName = 'proto.v1.PhotoRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AudioRecordRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AudioRecordRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AudioRecordRef.displayName = 'proto.v1.AudioRecordRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnomalyRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AnomalyRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnomalyRef.displayName = 'proto.v1.AnomalyRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnomalyRefList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.AnomalyRefList.repeatedFields_, null);
};
goog.inherits(proto.v1.AnomalyRefList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnomalyRefList.displayName = 'proto.v1.AnomalyRefList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnomalyList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.AnomalyList.repeatedFields_, null);
};
goog.inherits(proto.v1.AnomalyList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnomalyList.displayName = 'proto.v1.AnomalyList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Handling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Handling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Handling.displayName = 'proto.v1.Handling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.User.repeatedFields_, null);
};
goog.inherits(proto.v1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.User.displayName = 'proto.v1.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.UserRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.UserRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.UserRef.displayName = 'proto.v1.UserRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.UserList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.UserList.repeatedFields_, null);
};
goog.inherits(proto.v1.UserList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.UserList.displayName = 'proto.v1.UserList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OrgRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.OrgRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OrgRole.displayName = 'proto.v1.OrgRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.InviteUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.InviteUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.InviteUserReq.displayName = 'proto.v1.InviteUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Category = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Category, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Category.displayName = 'proto.v1.Category';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EquipmentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.EquipmentType.repeatedFields_, null);
};
goog.inherits(proto.v1.EquipmentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EquipmentType.displayName = 'proto.v1.EquipmentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Equipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Equipment.repeatedFields_, null);
};
goog.inherits(proto.v1.Equipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Equipment.displayName = 'proto.v1.Equipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MetricsQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.MetricsQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MetricsQuery.displayName = 'proto.v1.MetricsQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MetricValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.MetricValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MetricValue.displayName = 'proto.v1.MetricValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MetricValueList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.MetricValueList.repeatedFields_, null);
};
goog.inherits(proto.v1.MetricValueList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MetricValueList.displayName = 'proto.v1.MetricValueList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.DocRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.DocRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.DocRef.displayName = 'proto.v1.DocRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CheckList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.CheckList.repeatedFields_, null);
};
goog.inherits(proto.v1.CheckList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CheckList.displayName = 'proto.v1.CheckList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Check = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Check, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Check.displayName = 'proto.v1.Check';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Ronde = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Ronde.repeatedFields_, null);
};
goog.inherits(proto.v1.Ronde, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Ronde.displayName = 'proto.v1.Ronde';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Analyse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Analyse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Analyse.displayName = 'proto.v1.Analyse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnalyseRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AnalyseRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnalyseRef.displayName = 'proto.v1.AnalyseRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.RondeExec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.RondeExec.repeatedFields_, null);
};
goog.inherits(proto.v1.RondeExec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.RondeExec.displayName = 'proto.v1.RondeExec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnalyseExec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.AnalyseExec.repeatedFields_, null);
};
goog.inherits(proto.v1.AnalyseExec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnalyseExec.displayName = 'proto.v1.AnalyseExec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnalyseExecRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AnalyseExecRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnalyseExecRef.displayName = 'proto.v1.AnalyseExecRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CheckExec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.CheckExec.repeatedFields_, null);
};
goog.inherits(proto.v1.CheckExec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CheckExec.displayName = 'proto.v1.CheckExec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ScheduleItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.v1.ScheduleItem.oneofGroups_);
};
goog.inherits(proto.v1.ScheduleItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ScheduleItem.displayName = 'proto.v1.ScheduleItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ScheduleSingle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ScheduleSingle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ScheduleSingle.displayName = 'proto.v1.ScheduleSingle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ScheduleDaily = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ScheduleDaily, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ScheduleDaily.displayName = 'proto.v1.ScheduleDaily';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ScheduleWeekly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ScheduleWeekly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ScheduleWeekly.displayName = 'proto.v1.ScheduleWeekly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ScheduleMonthly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ScheduleMonthly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ScheduleMonthly.displayName = 'proto.v1.ScheduleMonthly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ScheduleYearly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ScheduleYearly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ScheduleYearly.displayName = 'proto.v1.ScheduleYearly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Alert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Alert.repeatedFields_, null);
};
goog.inherits(proto.v1.Alert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Alert.displayName = 'proto.v1.Alert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Rule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Rule.repeatedFields_, null);
};
goog.inherits(proto.v1.Rule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Rule.displayName = 'proto.v1.Rule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AlertTarget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.AlertTarget.repeatedFields_, null);
};
goog.inherits(proto.v1.AlertTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AlertTarget.displayName = 'proto.v1.AlertTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.NextReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.NextReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.NextReq.displayName = 'proto.v1.NextReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.NextNotifList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.NextNotifList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.NextNotifList.displayName = 'proto.v1.NextNotifList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Notif = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Notif, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Notif.displayName = 'proto.v1.Notif';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.NotifRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.NotifRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.NotifRef.displayName = 'proto.v1.NotifRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.NotifList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.NotifList.repeatedFields_, null);
};
goog.inherits(proto.v1.NotifList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.NotifList.displayName = 'proto.v1.NotifList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Emergency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Emergency.repeatedFields_, null);
};
goog.inherits(proto.v1.Emergency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Emergency.displayName = 'proto.v1.Emergency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EmergencyTarget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.EmergencyTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EmergencyTarget.displayName = 'proto.v1.EmergencyTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EmergencySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.EmergencySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EmergencySummary.displayName = 'proto.v1.EmergencySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EmergencySummaryList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.EmergencySummaryList.repeatedFields_, null);
};
goog.inherits(proto.v1.EmergencySummaryList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EmergencySummaryList.displayName = 'proto.v1.EmergencySummaryList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MapElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.MapElement.repeatedFields_, proto.v1.MapElement.oneofGroups_);
};
goog.inherits(proto.v1.MapElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MapElement.displayName = 'proto.v1.MapElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.WatchReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.WatchReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.WatchReq.displayName = 'proto.v1.WatchReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MapElementChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.MapElementChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MapElementChange.displayName = 'proto.v1.MapElementChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MapIcon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.MapIcon.repeatedFields_, null);
};
goog.inherits(proto.v1.MapIcon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MapIcon.displayName = 'proto.v1.MapIcon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.DashboardReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.DashboardReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.DashboardReq.displayName = 'proto.v1.DashboardReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Dashboard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Dashboard.repeatedFields_, null);
};
goog.inherits(proto.v1.Dashboard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Dashboard.displayName = 'proto.v1.Dashboard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.SLAStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.SLAStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.SLAStats.displayName = 'proto.v1.SLAStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.DayCounts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.DayCounts.repeatedFields_, null);
};
goog.inherits(proto.v1.DayCounts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.DayCounts.displayName = 'proto.v1.DayCounts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Stats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Stats.repeatedFields_, null);
};
goog.inherits(proto.v1.Stats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Stats.displayName = 'proto.v1.Stats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.TodoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.TodoList.repeatedFields_, null);
};
goog.inherits(proto.v1.TodoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.TodoList.displayName = 'proto.v1.TodoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.TodoItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.TodoItem.repeatedFields_, null);
};
goog.inherits(proto.v1.TodoItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.TodoItem.displayName = 'proto.v1.TodoItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.TodoListSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.TodoListSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.TodoListSource.displayName = 'proto.v1.TodoListSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Meta.displayName = 'proto.v1.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Ref = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Ref, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Ref.displayName = 'proto.v1.Ref';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.GroupRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.GroupRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.GroupRef.displayName = 'proto.v1.GroupRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Group.repeatedFields_, null);
};
goog.inherits(proto.v1.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Group.displayName = 'proto.v1.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.GroupList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.GroupList.repeatedFields_, null);
};
goog.inherits(proto.v1.GroupList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.GroupList.displayName = 'proto.v1.GroupList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.UserProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.UserProfile.repeatedFields_, null);
};
goog.inherits(proto.v1.UserProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.UserProfile.displayName = 'proto.v1.UserProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.UserInfo.displayName = 'proto.v1.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Badge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.Badge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Badge.displayName = 'proto.v1.Badge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.SocialReactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.SocialReactions.repeatedFields_, null);
};
goog.inherits(proto.v1.SocialReactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.SocialReactions.displayName = 'proto.v1.SocialReactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ChatSubject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ChatSubject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ChatSubject.displayName = 'proto.v1.ChatSubject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ChatMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ChatMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ChatMessage.displayName = 'proto.v1.ChatMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ChatMessageRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ChatMessageRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ChatMessageRef.displayName = 'proto.v1.ChatMessageRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ChatList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.ChatList.repeatedFields_, null);
};
goog.inherits(proto.v1.ChatList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ChatList.displayName = 'proto.v1.ChatList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ChatReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ChatReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ChatReq.displayName = 'proto.v1.ChatReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ChatAddReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ChatAddReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ChatAddReq.displayName = 'proto.v1.ChatAddReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ChatEditReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ChatEditReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ChatEditReq.displayName = 'proto.v1.ChatEditReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ImageOverlay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ImageOverlay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ImageOverlay.displayName = 'proto.v1.ImageOverlay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.Team = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.Team.repeatedFields_, null);
};
goog.inherits(proto.v1.Team, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.Team.displayName = 'proto.v1.Team';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AnonymousAnomaly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AnonymousAnomaly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AnonymousAnomaly.displayName = 'proto.v1.AnonymousAnomaly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AdminOrgRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AdminOrgRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AdminOrgRef.displayName = 'proto.v1.AdminOrgRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AdminOrgList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.AdminOrgList.repeatedFields_, null);
};
goog.inherits(proto.v1.AdminOrgList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AdminOrgList.displayName = 'proto.v1.AdminOrgList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OrgUserRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.OrgUserRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OrgUserRef.displayName = 'proto.v1.OrgUserRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OrgUserList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.OrgUserList.repeatedFields_, null);
};
goog.inherits(proto.v1.OrgUserList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OrgUserList.displayName = 'proto.v1.OrgUserList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OrgRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.OrgRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OrgRef.displayName = 'proto.v1.OrgRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.OrgList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.OrgList.repeatedFields_, null);
};
goog.inherits(proto.v1.OrgList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.OrgList.displayName = 'proto.v1.OrgList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.HandlingRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.HandlingRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.HandlingRef.displayName = 'proto.v1.HandlingRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.HandlingList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.HandlingList.repeatedFields_, null);
};
goog.inherits(proto.v1.HandlingList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.HandlingList.displayName = 'proto.v1.HandlingList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CategoryRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.CategoryRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CategoryRef.displayName = 'proto.v1.CategoryRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CategoryList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.CategoryList.repeatedFields_, null);
};
goog.inherits(proto.v1.CategoryList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CategoryList.displayName = 'proto.v1.CategoryList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EquipmentTypeRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.EquipmentTypeRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EquipmentTypeRef.displayName = 'proto.v1.EquipmentTypeRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EquipmentTypeList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.EquipmentTypeList.repeatedFields_, null);
};
goog.inherits(proto.v1.EquipmentTypeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EquipmentTypeList.displayName = 'proto.v1.EquipmentTypeList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EquipmentRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.EquipmentRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EquipmentRef.displayName = 'proto.v1.EquipmentRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EquipmentList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.EquipmentList.repeatedFields_, null);
};
goog.inherits(proto.v1.EquipmentList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EquipmentList.displayName = 'proto.v1.EquipmentList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CheckListRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.CheckListRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CheckListRef.displayName = 'proto.v1.CheckListRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.CheckListList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.CheckListList.repeatedFields_, null);
};
goog.inherits(proto.v1.CheckListList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.CheckListList.displayName = 'proto.v1.CheckListList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.RondeRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.RondeRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.RondeRef.displayName = 'proto.v1.RondeRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.RondeList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.RondeList.repeatedFields_, null);
};
goog.inherits(proto.v1.RondeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.RondeList.displayName = 'proto.v1.RondeList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.RondeExecRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.RondeExecRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.RondeExecRef.displayName = 'proto.v1.RondeExecRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.RondeExecList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.RondeExecList.repeatedFields_, null);
};
goog.inherits(proto.v1.RondeExecList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.RondeExecList.displayName = 'proto.v1.RondeExecList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AlertRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.AlertRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AlertRef.displayName = 'proto.v1.AlertRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.AlertList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.AlertList.repeatedFields_, null);
};
goog.inherits(proto.v1.AlertList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.AlertList.displayName = 'proto.v1.AlertList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.RuleRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.RuleRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.RuleRef.displayName = 'proto.v1.RuleRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.RuleList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.RuleList.repeatedFields_, null);
};
goog.inherits(proto.v1.RuleList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.RuleList.displayName = 'proto.v1.RuleList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EmergencyRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.EmergencyRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EmergencyRef.displayName = 'proto.v1.EmergencyRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.EmergencyList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.EmergencyList.repeatedFields_, null);
};
goog.inherits(proto.v1.EmergencyList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.EmergencyList.displayName = 'proto.v1.EmergencyList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MapElementRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.MapElementRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MapElementRef.displayName = 'proto.v1.MapElementRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MapElementList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.MapElementList.repeatedFields_, null);
};
goog.inherits(proto.v1.MapElementList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MapElementList.displayName = 'proto.v1.MapElementList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MapIconRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.MapIconRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MapIconRef.displayName = 'proto.v1.MapIconRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.MapIconList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.MapIconList.repeatedFields_, null);
};
goog.inherits(proto.v1.MapIconList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.MapIconList.displayName = 'proto.v1.MapIconList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.TodoListRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.TodoListRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.TodoListRef.displayName = 'proto.v1.TodoListRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.TodoListList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.TodoListList.repeatedFields_, null);
};
goog.inherits(proto.v1.TodoListList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.TodoListList.displayName = 'proto.v1.TodoListList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ImageOverlayRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.ImageOverlayRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ImageOverlayRef.displayName = 'proto.v1.ImageOverlayRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.ImageOverlayList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.ImageOverlayList.repeatedFields_, null);
};
goog.inherits(proto.v1.ImageOverlayList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.ImageOverlayList.displayName = 'proto.v1.ImageOverlayList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.TeamRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.v1.TeamRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.TeamRef.displayName = 'proto.v1.TeamRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.v1.TeamList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.v1.TeamList.repeatedFields_, null);
};
goog.inherits(proto.v1.TeamList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.v1.TeamList.displayName = 'proto.v1.TeamList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AdminOrg.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AdminOrg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AdminOrg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AdminOrg.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apitoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    anonymoussubmissiontoken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    anonuser: (f = msg.getAnonuser()) && proto.v1.UserRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AdminOrg}
 */
proto.v1.AdminOrg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AdminOrg;
  return proto.v1.AdminOrg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AdminOrg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AdminOrg}
 */
proto.v1.AdminOrg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApitoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnonymoussubmissiontoken(value);
      break;
    case 4:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setAnonuser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AdminOrg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AdminOrg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AdminOrg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AdminOrg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApitoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnonymoussubmissiontoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnonuser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.AdminOrg.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AdminOrg} returns this
 */
proto.v1.AdminOrg.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string APIToken = 2;
 * @return {string}
 */
proto.v1.AdminOrg.prototype.getApitoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AdminOrg} returns this
 */
proto.v1.AdminOrg.prototype.setApitoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AnonymousSubmissionToken = 3;
 * @return {string}
 */
proto.v1.AdminOrg.prototype.getAnonymoussubmissiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AdminOrg} returns this
 */
proto.v1.AdminOrg.prototype.setAnonymoussubmissiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserRef AnonUser = 4;
 * @return {?proto.v1.UserRef}
 */
proto.v1.AdminOrg.prototype.getAnonuser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 4));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.AdminOrg} returns this
*/
proto.v1.AdminOrg.prototype.setAnonuser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AdminOrg} returns this
 */
proto.v1.AdminOrg.prototype.clearAnonuser = function() {
  return this.setAnonuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AdminOrg.prototype.hasAnonuser = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.UserName.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.UserName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.UserName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserName.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.UserName}
 */
proto.v1.UserName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.UserName;
  return proto.v1.UserName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.UserName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.UserName}
 */
proto.v1.UserName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.UserName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.UserName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.UserName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string UserName = 1;
 * @return {string}
 */
proto.v1.UserName.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UserName} returns this
 */
proto.v1.UserName.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.NewPassword.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.NewPassword.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.NewPassword} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NewPassword.toObject = function(includeInstance, msg) {
  var f, obj = {
    newpassword: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.NewPassword}
 */
proto.v1.NewPassword.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.NewPassword;
  return proto.v1.NewPassword.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.NewPassword} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.NewPassword}
 */
proto.v1.NewPassword.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.NewPassword.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.NewPassword.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.NewPassword} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NewPassword.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewpassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string NewPassword = 1;
 * @return {string}
 */
proto.v1.NewPassword.prototype.getNewpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.NewPassword} returns this
 */
proto.v1.NewPassword.prototype.setNewpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OpsLogReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OpsLogReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OpsLogReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OpsLogReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    since: jspb.Message.getFieldWithDefault(msg, 1, 0),
    until: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filtermethod: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filterrequest: jspb.Message.getFieldWithDefault(msg, 4, ""),
    filterresponse: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filtererror: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OpsLogReq}
 */
proto.v1.OpsLogReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OpsLogReq;
  return proto.v1.OpsLogReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OpsLogReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OpsLogReq}
 */
proto.v1.OpsLogReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSince(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUntil(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiltermethod(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilterrequest(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilterresponse(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiltererror(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OpsLogReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OpsLogReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OpsLogReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OpsLogReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSince();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUntil();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFiltermethod();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilterrequest();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFilterresponse();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFiltererror();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 Since = 1;
 * @return {number}
 */
proto.v1.OpsLogReq.prototype.getSince = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.OpsLogReq} returns this
 */
proto.v1.OpsLogReq.prototype.setSince = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 Until = 2;
 * @return {number}
 */
proto.v1.OpsLogReq.prototype.getUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.OpsLogReq} returns this
 */
proto.v1.OpsLogReq.prototype.setUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string FilterMethod = 3;
 * @return {string}
 */
proto.v1.OpsLogReq.prototype.getFiltermethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OpsLogReq} returns this
 */
proto.v1.OpsLogReq.prototype.setFiltermethod = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string FilterRequest = 4;
 * @return {string}
 */
proto.v1.OpsLogReq.prototype.getFilterrequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OpsLogReq} returns this
 */
proto.v1.OpsLogReq.prototype.setFilterrequest = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string FilterResponse = 5;
 * @return {string}
 */
proto.v1.OpsLogReq.prototype.getFilterresponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OpsLogReq} returns this
 */
proto.v1.OpsLogReq.prototype.setFilterresponse = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string FilterError = 6;
 * @return {string}
 */
proto.v1.OpsLogReq.prototype.getFiltererror = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OpsLogReq} returns this
 */
proto.v1.OpsLogReq.prototype.setFiltererror = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.OpLogList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OpLogList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OpLogList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OpLogList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OpLogList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.OpLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OpLogList}
 */
proto.v1.OpLogList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OpLogList;
  return proto.v1.OpLogList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OpLogList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OpLogList}
 */
proto.v1.OpLogList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.OpLog;
      reader.readMessage(value,proto.v1.OpLog.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OpLogList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OpLogList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OpLogList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OpLogList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.OpLog.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OpLog Items = 1;
 * @return {!Array<!proto.v1.OpLog>}
 */
proto.v1.OpLogList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.OpLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.OpLog, 1));
};


/**
 * @param {!Array<!proto.v1.OpLog>} value
 * @return {!proto.v1.OpLogList} returns this
*/
proto.v1.OpLogList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.OpLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.OpLog}
 */
proto.v1.OpLogList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.OpLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.OpLogList} returns this
 */
proto.v1.OpLogList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OpLog.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OpLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OpLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OpLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    at: jspb.Message.getFieldWithDefault(msg, 1, 0),
    durationns: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tokenused: msg.getTokenused_asB64(),
    method: jspb.Message.getFieldWithDefault(msg, 3, ""),
    request: msg.getRequest_asB64(),
    response: msg.getResponse_asB64(),
    error: jspb.Message.getFieldWithDefault(msg, 6, ""),
    instance: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OpLog}
 */
proto.v1.OpLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OpLog;
  return proto.v1.OpLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OpLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OpLog}
 */
proto.v1.OpLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationns(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTokenused(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRequest(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setResponse(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OpLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OpLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OpLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OpLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDurationns();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTokenused_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequest_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getResponse_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInstance();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 At = 1;
 * @return {number}
 */
proto.v1.OpLog.prototype.getAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.OpLog} returns this
 */
proto.v1.OpLog.prototype.setAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 DurationNS = 2;
 * @return {number}
 */
proto.v1.OpLog.prototype.getDurationns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.OpLog} returns this
 */
proto.v1.OpLog.prototype.setDurationns = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes TokenUsed = 7;
 * @return {string}
 */
proto.v1.OpLog.prototype.getTokenused = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes TokenUsed = 7;
 * This is a type-conversion wrapper around `getTokenused()`
 * @return {string}
 */
proto.v1.OpLog.prototype.getTokenused_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTokenused()));
};


/**
 * optional bytes TokenUsed = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTokenused()`
 * @return {!Uint8Array}
 */
proto.v1.OpLog.prototype.getTokenused_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTokenused()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.v1.OpLog} returns this
 */
proto.v1.OpLog.prototype.setTokenused = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional string Method = 3;
 * @return {string}
 */
proto.v1.OpLog.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OpLog} returns this
 */
proto.v1.OpLog.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes Request = 4;
 * @return {string}
 */
proto.v1.OpLog.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes Request = 4;
 * This is a type-conversion wrapper around `getRequest()`
 * @return {string}
 */
proto.v1.OpLog.prototype.getRequest_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRequest()));
};


/**
 * optional bytes Request = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRequest()`
 * @return {!Uint8Array}
 */
proto.v1.OpLog.prototype.getRequest_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRequest()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.v1.OpLog} returns this
 */
proto.v1.OpLog.prototype.setRequest = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bytes Response = 5;
 * @return {string}
 */
proto.v1.OpLog.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes Response = 5;
 * This is a type-conversion wrapper around `getResponse()`
 * @return {string}
 */
proto.v1.OpLog.prototype.getResponse_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getResponse()));
};


/**
 * optional bytes Response = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getResponse()`
 * @return {!Uint8Array}
 */
proto.v1.OpLog.prototype.getResponse_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getResponse()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.v1.OpLog} returns this
 */
proto.v1.OpLog.prototype.setResponse = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string Error = 6;
 * @return {string}
 */
proto.v1.OpLog.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OpLog} returns this
 */
proto.v1.OpLog.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Instance = 8;
 * @return {string}
 */
proto.v1.OpLog.prototype.getInstance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OpLog} returns this
 */
proto.v1.OpLog.prototype.setInstance = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Login.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Login.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Login} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Login.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.v1.UserRef.toObject(includeInstance, f),
    passwordhash: msg.getPasswordhash_asB64(),
    passwordsalt: msg.getPasswordsalt_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Login}
 */
proto.v1.Login.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Login;
  return proto.v1.Login.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Login} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Login}
 */
proto.v1.Login.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPasswordhash(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPasswordsalt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Login.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Login.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Login} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Login.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getPasswordhash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getPasswordsalt_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Login.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Login} returns this
 */
proto.v1.Login.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserRef User = 2;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Login.prototype.getUser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 2));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Login} returns this
*/
proto.v1.Login.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Login} returns this
 */
proto.v1.Login.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Login.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes PasswordHash = 3;
 * @return {string}
 */
proto.v1.Login.prototype.getPasswordhash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes PasswordHash = 3;
 * This is a type-conversion wrapper around `getPasswordhash()`
 * @return {string}
 */
proto.v1.Login.prototype.getPasswordhash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPasswordhash()));
};


/**
 * optional bytes PasswordHash = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPasswordhash()`
 * @return {!Uint8Array}
 */
proto.v1.Login.prototype.getPasswordhash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPasswordhash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.v1.Login} returns this
 */
proto.v1.Login.prototype.setPasswordhash = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes PasswordSalt = 4;
 * @return {string}
 */
proto.v1.Login.prototype.getPasswordsalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes PasswordSalt = 4;
 * This is a type-conversion wrapper around `getPasswordsalt()`
 * @return {string}
 */
proto.v1.Login.prototype.getPasswordsalt_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPasswordsalt()));
};


/**
 * optional bytes PasswordSalt = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPasswordsalt()`
 * @return {!Uint8Array}
 */
proto.v1.Login.prototype.getPasswordsalt_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPasswordsalt()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.v1.Login} returns this
 */
proto.v1.Login.prototype.setPasswordsalt = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.RegisterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.RegisterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.RegisterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RegisterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.RegisterReq}
 */
proto.v1.RegisterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.RegisterReq;
  return proto.v1.RegisterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.RegisterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.RegisterReq}
 */
proto.v1.RegisterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.RegisterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.RegisterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.RegisterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RegisterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string UserName = 1;
 * @return {string}
 */
proto.v1.RegisterReq.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.RegisterReq} returns this
 */
proto.v1.RegisterReq.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Password = 2;
 * @return {string}
 */
proto.v1.RegisterReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.RegisterReq} returns this
 */
proto.v1.RegisterReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Email = 3;
 * @return {string}
 */
proto.v1.RegisterReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.RegisterReq} returns this
 */
proto.v1.RegisterReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.LoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.LoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.LoginReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.LoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    superuser: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.LoginReq}
 */
proto.v1.LoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.LoginReq;
  return proto.v1.LoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.LoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.LoginReq}
 */
proto.v1.LoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuperuser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.LoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.LoginReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.LoginReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.LoginReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSuperuser();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string UserName = 1;
 * @return {string}
 */
proto.v1.LoginReq.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.LoginReq} returns this
 */
proto.v1.LoginReq.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Password = 2;
 * @return {string}
 */
proto.v1.LoginReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.LoginReq} returns this
 */
proto.v1.LoginReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool SuperUser = 3;
 * @return {boolean}
 */
proto.v1.LoginReq.prototype.getSuperuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.LoginReq} returns this
 */
proto.v1.LoginReq.prototype.setSuperuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OrgLoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OrgLoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OrgLoginReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgLoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firebasetoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    devicetype: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OrgLoginReq}
 */
proto.v1.OrgLoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OrgLoginReq;
  return proto.v1.OrgLoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OrgLoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OrgLoginReq}
 */
proto.v1.OrgLoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebasetoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OrgLoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OrgLoginReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OrgLoginReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgLoginReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirebasetoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevicetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.OrgLoginReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgLoginReq} returns this
 */
proto.v1.OrgLoginReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FirebaseToken = 2;
 * @return {string}
 */
proto.v1.OrgLoginReq.prototype.getFirebasetoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgLoginReq} returns this
 */
proto.v1.OrgLoginReq.prototype.setFirebasetoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string DeviceType = 3;
 * @return {string}
 */
proto.v1.OrgLoginReq.prototype.getDevicetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgLoginReq} returns this
 */
proto.v1.OrgLoginReq.prototype.setDevicetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.SetPasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.SetPasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.SetPasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.SetPasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.SetPasswordReq}
 */
proto.v1.SetPasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.SetPasswordReq;
  return proto.v1.SetPasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.SetPasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.SetPasswordReq}
 */
proto.v1.SetPasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.SetPasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.SetPasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.SetPasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.SetPasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Password = 1;
 * @return {string}
 */
proto.v1.SetPasswordReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.SetPasswordReq} returns this
 */
proto.v1.SetPasswordReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CheckFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CheckFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CheckFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    rondeexec: (f = msg.getRondeexec()) && proto.v1.RondeExecRef.toObject(includeInstance, f),
    ronde: (f = msg.getRonde()) && proto.v1.RondeRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CheckFilter}
 */
proto.v1.CheckFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CheckFilter;
  return proto.v1.CheckFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CheckFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CheckFilter}
 */
proto.v1.CheckFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.RondeExecRef;
      reader.readMessage(value,proto.v1.RondeExecRef.deserializeBinaryFromReader);
      msg.setRondeexec(value);
      break;
    case 2:
      var value = new proto.v1.RondeRef;
      reader.readMessage(value,proto.v1.RondeRef.deserializeBinaryFromReader);
      msg.setRonde(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CheckFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CheckFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CheckFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRondeexec();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.RondeExecRef.serializeBinaryToWriter
    );
  }
  f = message.getRonde();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.RondeRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional RondeExecRef RondeExec = 1;
 * @return {?proto.v1.RondeExecRef}
 */
proto.v1.CheckFilter.prototype.getRondeexec = function() {
  return /** @type{?proto.v1.RondeExecRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeExecRef, 1));
};


/**
 * @param {?proto.v1.RondeExecRef|undefined} value
 * @return {!proto.v1.CheckFilter} returns this
*/
proto.v1.CheckFilter.prototype.setRondeexec = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckFilter} returns this
 */
proto.v1.CheckFilter.prototype.clearRondeexec = function() {
  return this.setRondeexec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckFilter.prototype.hasRondeexec = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RondeRef Ronde = 2;
 * @return {?proto.v1.RondeRef}
 */
proto.v1.CheckFilter.prototype.getRonde = function() {
  return /** @type{?proto.v1.RondeRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeRef, 2));
};


/**
 * @param {?proto.v1.RondeRef|undefined} value
 * @return {!proto.v1.CheckFilter} returns this
*/
proto.v1.CheckFilter.prototype.setRonde = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckFilter} returns this
 */
proto.v1.CheckFilter.prototype.clearRonde = function() {
  return this.setRonde(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckFilter.prototype.hasRonde = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.ReorderReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ReorderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ReorderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ReorderReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ReorderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    ronde: (f = msg.getRonde()) && proto.v1.RondeRef.toObject(includeInstance, f),
    neworderList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ReorderReq}
 */
proto.v1.ReorderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ReorderReq;
  return proto.v1.ReorderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ReorderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ReorderReq}
 */
proto.v1.ReorderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.RondeRef;
      reader.readMessage(value,proto.v1.RondeRef.deserializeBinaryFromReader);
      msg.setRonde(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNeworder(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ReorderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ReorderReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ReorderReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ReorderReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRonde();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.RondeRef.serializeBinaryToWriter
    );
  }
  f = message.getNeworderList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional RondeRef Ronde = 1;
 * @return {?proto.v1.RondeRef}
 */
proto.v1.ReorderReq.prototype.getRonde = function() {
  return /** @type{?proto.v1.RondeRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeRef, 1));
};


/**
 * @param {?proto.v1.RondeRef|undefined} value
 * @return {!proto.v1.ReorderReq} returns this
*/
proto.v1.ReorderReq.prototype.setRonde = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ReorderReq} returns this
 */
proto.v1.ReorderReq.prototype.clearRonde = function() {
  return this.setRonde(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ReorderReq.prototype.hasRonde = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int32 NewOrder = 2;
 * @return {!Array<number>}
 */
proto.v1.ReorderReq.prototype.getNeworderList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.v1.ReorderReq} returns this
 */
proto.v1.ReorderReq.prototype.setNeworderList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.v1.ReorderReq} returns this
 */
proto.v1.ReorderReq.prototype.addNeworder = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.ReorderReq} returns this
 */
proto.v1.ReorderReq.prototype.clearNeworderList = function() {
  return this.setNeworderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ReportCheckAnomalyReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ReportCheckAnomalyReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ReportCheckAnomalyReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ReportCheckAnomalyReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    check: (f = msg.getCheck()) && proto.v1.CheckRef.toObject(includeInstance, f),
    result: (f = msg.getResult()) && proto.v1.CheckResult.toObject(includeInstance, f),
    anomaly: (f = msg.getAnomaly()) && proto.v1.Anomaly.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ReportCheckAnomalyReq}
 */
proto.v1.ReportCheckAnomalyReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ReportCheckAnomalyReq;
  return proto.v1.ReportCheckAnomalyReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ReportCheckAnomalyReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ReportCheckAnomalyReq}
 */
proto.v1.ReportCheckAnomalyReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.CheckRef;
      reader.readMessage(value,proto.v1.CheckRef.deserializeBinaryFromReader);
      msg.setCheck(value);
      break;
    case 3:
      var value = new proto.v1.CheckResult;
      reader.readMessage(value,proto.v1.CheckResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = new proto.v1.Anomaly;
      reader.readMessage(value,proto.v1.Anomaly.deserializeBinaryFromReader);
      msg.setAnomaly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ReportCheckAnomalyReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ReportCheckAnomalyReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ReportCheckAnomalyReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ReportCheckAnomalyReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheck();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.CheckRef.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.CheckResult.serializeBinaryToWriter
    );
  }
  f = message.getAnomaly();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.Anomaly.serializeBinaryToWriter
    );
  }
};


/**
 * optional CheckRef Check = 1;
 * @return {?proto.v1.CheckRef}
 */
proto.v1.ReportCheckAnomalyReq.prototype.getCheck = function() {
  return /** @type{?proto.v1.CheckRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.CheckRef, 1));
};


/**
 * @param {?proto.v1.CheckRef|undefined} value
 * @return {!proto.v1.ReportCheckAnomalyReq} returns this
*/
proto.v1.ReportCheckAnomalyReq.prototype.setCheck = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ReportCheckAnomalyReq} returns this
 */
proto.v1.ReportCheckAnomalyReq.prototype.clearCheck = function() {
  return this.setCheck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ReportCheckAnomalyReq.prototype.hasCheck = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CheckResult Result = 3;
 * @return {?proto.v1.CheckResult}
 */
proto.v1.ReportCheckAnomalyReq.prototype.getResult = function() {
  return /** @type{?proto.v1.CheckResult} */ (
    jspb.Message.getWrapperField(this, proto.v1.CheckResult, 3));
};


/**
 * @param {?proto.v1.CheckResult|undefined} value
 * @return {!proto.v1.ReportCheckAnomalyReq} returns this
*/
proto.v1.ReportCheckAnomalyReq.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ReportCheckAnomalyReq} returns this
 */
proto.v1.ReportCheckAnomalyReq.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ReportCheckAnomalyReq.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Anomaly Anomaly = 2;
 * @return {?proto.v1.Anomaly}
 */
proto.v1.ReportCheckAnomalyReq.prototype.getAnomaly = function() {
  return /** @type{?proto.v1.Anomaly} */ (
    jspb.Message.getWrapperField(this, proto.v1.Anomaly, 2));
};


/**
 * @param {?proto.v1.Anomaly|undefined} value
 * @return {!proto.v1.ReportCheckAnomalyReq} returns this
*/
proto.v1.ReportCheckAnomalyReq.prototype.setAnomaly = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ReportCheckAnomalyReq} returns this
 */
proto.v1.ReportCheckAnomalyReq.prototype.clearAnomaly = function() {
  return this.setAnomaly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ReportCheckAnomalyReq.prototype.hasAnomaly = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CheckResult.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CheckResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CheckResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metricvalue: (f = msg.getMetricvalue()) && proto.v1.MetricValue.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CheckResult}
 */
proto.v1.CheckResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CheckResult;
  return proto.v1.CheckResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CheckResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CheckResult}
 */
proto.v1.CheckResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.v1.CheckState} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new proto.v1.MetricValue;
      reader.readMessage(value,proto.v1.MetricValue.deserializeBinaryFromReader);
      msg.setMetricvalue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CheckResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CheckResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CheckResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMetricvalue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.MetricValue.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional CheckState Status = 1;
 * @return {!proto.v1.CheckState}
 */
proto.v1.CheckResult.prototype.getStatus = function() {
  return /** @type {!proto.v1.CheckState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.v1.CheckState} value
 * @return {!proto.v1.CheckResult} returns this
 */
proto.v1.CheckResult.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional MetricValue MetricValue = 2;
 * @return {?proto.v1.MetricValue}
 */
proto.v1.CheckResult.prototype.getMetricvalue = function() {
  return /** @type{?proto.v1.MetricValue} */ (
    jspb.Message.getWrapperField(this, proto.v1.MetricValue, 2));
};


/**
 * @param {?proto.v1.MetricValue|undefined} value
 * @return {!proto.v1.CheckResult} returns this
*/
proto.v1.CheckResult.prototype.setMetricvalue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckResult} returns this
 */
proto.v1.CheckResult.prototype.clearMetricvalue = function() {
  return this.setMetricvalue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckResult.prototype.hasMetricvalue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Comment = 3;
 * @return {string}
 */
proto.v1.CheckResult.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.CheckResult} returns this
 */
proto.v1.CheckResult.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CheckRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CheckRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CheckRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    rondeexec: (f = msg.getRondeexec()) && proto.v1.RondeExecRef.toObject(includeInstance, f),
    analyse: (f = msg.getAnalyse()) && proto.v1.AnalyseRef.toObject(includeInstance, f),
    checkindex: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CheckRef}
 */
proto.v1.CheckRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CheckRef;
  return proto.v1.CheckRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CheckRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CheckRef}
 */
proto.v1.CheckRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.RondeExecRef;
      reader.readMessage(value,proto.v1.RondeExecRef.deserializeBinaryFromReader);
      msg.setRondeexec(value);
      break;
    case 2:
      var value = new proto.v1.AnalyseRef;
      reader.readMessage(value,proto.v1.AnalyseRef.deserializeBinaryFromReader);
      msg.setAnalyse(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCheckindex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CheckRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CheckRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CheckRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRondeexec();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.RondeExecRef.serializeBinaryToWriter
    );
  }
  f = message.getAnalyse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.AnalyseRef.serializeBinaryToWriter
    );
  }
  f = message.getCheckindex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional RondeExecRef RondeExec = 1;
 * @return {?proto.v1.RondeExecRef}
 */
proto.v1.CheckRef.prototype.getRondeexec = function() {
  return /** @type{?proto.v1.RondeExecRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeExecRef, 1));
};


/**
 * @param {?proto.v1.RondeExecRef|undefined} value
 * @return {!proto.v1.CheckRef} returns this
*/
proto.v1.CheckRef.prototype.setRondeexec = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckRef} returns this
 */
proto.v1.CheckRef.prototype.clearRondeexec = function() {
  return this.setRondeexec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckRef.prototype.hasRondeexec = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnalyseRef Analyse = 2;
 * @return {?proto.v1.AnalyseRef}
 */
proto.v1.CheckRef.prototype.getAnalyse = function() {
  return /** @type{?proto.v1.AnalyseRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnalyseRef, 2));
};


/**
 * @param {?proto.v1.AnalyseRef|undefined} value
 * @return {!proto.v1.CheckRef} returns this
*/
proto.v1.CheckRef.prototype.setAnalyse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckRef} returns this
 */
proto.v1.CheckRef.prototype.clearAnalyse = function() {
  return this.setAnalyse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckRef.prototype.hasAnalyse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 CheckIndex = 3;
 * @return {number}
 */
proto.v1.CheckRef.prototype.getCheckindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.CheckRef} returns this
 */
proto.v1.CheckRef.prototype.setCheckindex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OrgUser.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OrgUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OrgUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneprefix: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    role: (f = msg.getRole()) && proto.v1.OrgRole.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    user: (f = msg.getUser()) && proto.v1.UserRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OrgUser}
 */
proto.v1.OrgUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OrgUser;
  return proto.v1.OrgUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OrgUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OrgUser}
 */
proto.v1.OrgUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneprefix(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 2:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 3:
      var value = new proto.v1.OrgRole;
      reader.readMessage(value,proto.v1.OrgRole.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OrgUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OrgUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OrgUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneprefix();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.OrgRole.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.OrgUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgUser} returns this
 */
proto.v1.OrgUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Email = 5;
 * @return {string}
 */
proto.v1.OrgUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgUser} returns this
 */
proto.v1.OrgUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string PhonePrefix = 8;
 * @return {string}
 */
proto.v1.OrgUser.prototype.getPhoneprefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgUser} returns this
 */
proto.v1.OrgUser.prototype.setPhoneprefix = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Phone = 6;
 * @return {string}
 */
proto.v1.OrgUser.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgUser} returns this
 */
proto.v1.OrgUser.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional OrgRef Org = 2;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.OrgUser.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 2));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.OrgUser} returns this
*/
proto.v1.OrgUser.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.OrgUser} returns this
 */
proto.v1.OrgUser.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.OrgUser.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OrgRole Role = 3;
 * @return {?proto.v1.OrgRole}
 */
proto.v1.OrgUser.prototype.getRole = function() {
  return /** @type{?proto.v1.OrgRole} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRole, 3));
};


/**
 * @param {?proto.v1.OrgRole|undefined} value
 * @return {!proto.v1.OrgUser} returns this
*/
proto.v1.OrgUser.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.OrgUser} returns this
 */
proto.v1.OrgUser.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.OrgUser.prototype.hasRole = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.v1.OrgUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgUser} returns this
 */
proto.v1.OrgUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional UserRef User = 7;
 * @return {?proto.v1.UserRef}
 */
proto.v1.OrgUser.prototype.getUser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 7));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.OrgUser} returns this
*/
proto.v1.OrgUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.OrgUser} returns this
 */
proto.v1.OrgUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.OrgUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.UploadReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.UploadReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.UploadReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UploadReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contenttype: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.UploadReq}
 */
proto.v1.UploadReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.UploadReq;
  return proto.v1.UploadReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.UploadReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.UploadReq}
 */
proto.v1.UploadReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContenttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.UploadReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.UploadReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.UploadReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UploadReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContenttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ContentType = 1;
 * @return {string}
 */
proto.v1.UploadReq.prototype.getContenttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UploadReq} returns this
 */
proto.v1.UploadReq.prototype.setContenttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Authorization.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Authorization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Authorization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Authorization.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Authorization}
 */
proto.v1.Authorization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Authorization;
  return proto.v1.Authorization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Authorization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Authorization}
 */
proto.v1.Authorization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Authorization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Authorization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Authorization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Authorization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Token = 1;
 * @return {string}
 */
proto.v1.Authorization.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Authorization} returns this
 */
proto.v1.Authorization.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ID = 2;
 * @return {string}
 */
proto.v1.Authorization.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Authorization} returns this
 */
proto.v1.Authorization.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Tasks.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Tasks.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Tasks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Tasks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Tasks.toObject = function(includeInstance, msg) {
  var f, obj = {
    analysesList: jspb.Message.toObjectList(msg.getAnalysesList(),
    proto.v1.AnalyseExec.toObject, includeInstance),
    anomaliesList: jspb.Message.toObjectList(msg.getAnomaliesList(),
    proto.v1.Anomaly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Tasks}
 */
proto.v1.Tasks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Tasks;
  return proto.v1.Tasks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Tasks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Tasks}
 */
proto.v1.Tasks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.AnalyseExec;
      reader.readMessage(value,proto.v1.AnalyseExec.deserializeBinaryFromReader);
      msg.addAnalyses(value);
      break;
    case 2:
      var value = new proto.v1.Anomaly;
      reader.readMessage(value,proto.v1.Anomaly.deserializeBinaryFromReader);
      msg.addAnomalies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Tasks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Tasks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Tasks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Tasks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnalysesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.AnalyseExec.serializeBinaryToWriter
    );
  }
  f = message.getAnomaliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.v1.Anomaly.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnalyseExec Analyses = 1;
 * @return {!Array<!proto.v1.AnalyseExec>}
 */
proto.v1.Tasks.prototype.getAnalysesList = function() {
  return /** @type{!Array<!proto.v1.AnalyseExec>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AnalyseExec, 1));
};


/**
 * @param {!Array<!proto.v1.AnalyseExec>} value
 * @return {!proto.v1.Tasks} returns this
*/
proto.v1.Tasks.prototype.setAnalysesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.AnalyseExec=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AnalyseExec}
 */
proto.v1.Tasks.prototype.addAnalyses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.AnalyseExec, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Tasks} returns this
 */
proto.v1.Tasks.prototype.clearAnalysesList = function() {
  return this.setAnalysesList([]);
};


/**
 * repeated Anomaly Anomalies = 2;
 * @return {!Array<!proto.v1.Anomaly>}
 */
proto.v1.Tasks.prototype.getAnomaliesList = function() {
  return /** @type{!Array<!proto.v1.Anomaly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Anomaly, 2));
};


/**
 * @param {!Array<!proto.v1.Anomaly>} value
 * @return {!proto.v1.Tasks} returns this
*/
proto.v1.Tasks.prototype.setAnomaliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.v1.Anomaly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Anomaly}
 */
proto.v1.Tasks.prototype.addAnomalies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.v1.Anomaly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Tasks} returns this
 */
proto.v1.Tasks.prototype.clearAnomaliesList = function() {
  return this.setAnomaliesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.TaskFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.TaskFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.TaskFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TaskFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    equipment: (f = msg.getEquipment()) && proto.v1.EquipmentRef.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.v1.UserRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.TaskFilter}
 */
proto.v1.TaskFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.TaskFilter;
  return proto.v1.TaskFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.TaskFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.TaskFilter}
 */
proto.v1.TaskFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.EquipmentRef;
      reader.readMessage(value,proto.v1.EquipmentRef.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    case 2:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.TaskFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.TaskFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.TaskFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TaskFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.EquipmentRef.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional EquipmentRef Equipment = 1;
 * @return {?proto.v1.EquipmentRef}
 */
proto.v1.TaskFilter.prototype.getEquipment = function() {
  return /** @type{?proto.v1.EquipmentRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentRef, 1));
};


/**
 * @param {?proto.v1.EquipmentRef|undefined} value
 * @return {!proto.v1.TaskFilter} returns this
*/
proto.v1.TaskFilter.prototype.setEquipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TaskFilter} returns this
 */
proto.v1.TaskFilter.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TaskFilter.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserRef User = 2;
 * @return {?proto.v1.UserRef}
 */
proto.v1.TaskFilter.prototype.getUser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 2));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.TaskFilter} returns this
*/
proto.v1.TaskFilter.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TaskFilter} returns this
 */
proto.v1.TaskFilter.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TaskFilter.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.AnomalyFilter.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnomalyFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnomalyFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnomalyFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalyFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    box: (f = msg.getBox()) && proto.v1.GeoBox.toObject(includeInstance, f),
    byme: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    assignedtome: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    equipment: (f = msg.getEquipment()) && proto.v1.EquipmentRef.toObject(includeInstance, f),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.v1.CategoryRef.toObject, includeInstance),
    zonesList: jspb.Message.toObjectList(msg.getZonesList(),
    proto.v1.ZoneRef.toObject, includeInstance),
    statesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnomalyFilter}
 */
proto.v1.AnomalyFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnomalyFilter;
  return proto.v1.AnomalyFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnomalyFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnomalyFilter}
 */
proto.v1.AnomalyFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.GeoBox;
      reader.readMessage(value,proto.v1.GeoBox.deserializeBinaryFromReader);
      msg.setBox(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setByme(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssignedtome(value);
      break;
    case 4:
      var value = new proto.v1.EquipmentRef;
      reader.readMessage(value,proto.v1.EquipmentRef.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    case 5:
      var value = new proto.v1.CategoryRef;
      reader.readMessage(value,proto.v1.CategoryRef.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 6:
      var value = new proto.v1.ZoneRef;
      reader.readMessage(value,proto.v1.ZoneRef.deserializeBinaryFromReader);
      msg.addZones(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.v1.HandlingState>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStates(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnomalyFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnomalyFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnomalyFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalyFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBox();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.GeoBox.serializeBinaryToWriter
    );
  }
  f = message.getByme();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAssignedtome();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.EquipmentRef.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.v1.CategoryRef.serializeBinaryToWriter
    );
  }
  f = message.getZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.v1.ZoneRef.serializeBinaryToWriter
    );
  }
  f = message.getStatesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
};


/**
 * optional GeoBox Box = 1;
 * @return {?proto.v1.GeoBox}
 */
proto.v1.AnomalyFilter.prototype.getBox = function() {
  return /** @type{?proto.v1.GeoBox} */ (
    jspb.Message.getWrapperField(this, proto.v1.GeoBox, 1));
};


/**
 * @param {?proto.v1.GeoBox|undefined} value
 * @return {!proto.v1.AnomalyFilter} returns this
*/
proto.v1.AnomalyFilter.prototype.setBox = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.clearBox = function() {
  return this.setBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnomalyFilter.prototype.hasBox = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool ByMe = 2;
 * @return {boolean}
 */
proto.v1.AnomalyFilter.prototype.getByme = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.setByme = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool AssignedToMe = 3;
 * @return {boolean}
 */
proto.v1.AnomalyFilter.prototype.getAssignedtome = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.setAssignedtome = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional EquipmentRef Equipment = 4;
 * @return {?proto.v1.EquipmentRef}
 */
proto.v1.AnomalyFilter.prototype.getEquipment = function() {
  return /** @type{?proto.v1.EquipmentRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentRef, 4));
};


/**
 * @param {?proto.v1.EquipmentRef|undefined} value
 * @return {!proto.v1.AnomalyFilter} returns this
*/
proto.v1.AnomalyFilter.prototype.setEquipment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnomalyFilter.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated CategoryRef Categories = 5;
 * @return {!Array<!proto.v1.CategoryRef>}
 */
proto.v1.AnomalyFilter.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.v1.CategoryRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.CategoryRef, 5));
};


/**
 * @param {!Array<!proto.v1.CategoryRef>} value
 * @return {!proto.v1.AnomalyFilter} returns this
*/
proto.v1.AnomalyFilter.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.v1.CategoryRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.CategoryRef}
 */
proto.v1.AnomalyFilter.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.v1.CategoryRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated ZoneRef Zones = 6;
 * @return {!Array<!proto.v1.ZoneRef>}
 */
proto.v1.AnomalyFilter.prototype.getZonesList = function() {
  return /** @type{!Array<!proto.v1.ZoneRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.ZoneRef, 6));
};


/**
 * @param {!Array<!proto.v1.ZoneRef>} value
 * @return {!proto.v1.AnomalyFilter} returns this
*/
proto.v1.AnomalyFilter.prototype.setZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.v1.ZoneRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.ZoneRef}
 */
proto.v1.AnomalyFilter.prototype.addZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.v1.ZoneRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.clearZonesList = function() {
  return this.setZonesList([]);
};


/**
 * repeated HandlingState States = 7;
 * @return {!Array<!proto.v1.HandlingState>}
 */
proto.v1.AnomalyFilter.prototype.getStatesList = function() {
  return /** @type {!Array<!proto.v1.HandlingState>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.v1.HandlingState>} value
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.setStatesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.v1.HandlingState} value
 * @param {number=} opt_index
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.addStates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AnomalyFilter} returns this
 */
proto.v1.AnomalyFilter.prototype.clearStatesList = function() {
  return this.setStatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.AnalysisStatusList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnalysisStatusList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnalysisStatusList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnalysisStatusList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalysisStatusList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.AnalysisStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnalysisStatusList}
 */
proto.v1.AnalysisStatusList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnalysisStatusList;
  return proto.v1.AnalysisStatusList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnalysisStatusList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnalysisStatusList}
 */
proto.v1.AnalysisStatusList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.AnalysisStatus;
      reader.readMessage(value,proto.v1.AnalysisStatus.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnalysisStatusList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnalysisStatusList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnalysisStatusList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalysisStatusList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.AnalysisStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnalysisStatus Items = 1;
 * @return {!Array<!proto.v1.AnalysisStatus>}
 */
proto.v1.AnalysisStatusList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.AnalysisStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AnalysisStatus, 1));
};


/**
 * @param {!Array<!proto.v1.AnalysisStatus>} value
 * @return {!proto.v1.AnalysisStatusList} returns this
*/
proto.v1.AnalysisStatusList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.AnalysisStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AnalysisStatus}
 */
proto.v1.AnalysisStatusList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.AnalysisStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AnalysisStatusList} returns this
 */
proto.v1.AnalysisStatusList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnalysisStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnalysisStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnalysisStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalysisStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: (f = msg.getLocation()) && proto.v1.Point.toObject(includeInstance, f),
    ronde: (f = msg.getRonde()) && proto.v1.RondeRef.toObject(includeInstance, f),
    analyse: (f = msg.getAnalyse()) && proto.v1.Analyse.toObject(includeInstance, f),
    latestexec: (f = msg.getLatestexec()) && proto.v1.AnalyseExec.toObject(includeInstance, f),
    equipment: (f = msg.getEquipment()) && proto.v1.Equipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnalysisStatus}
 */
proto.v1.AnalysisStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnalysisStatus;
  return proto.v1.AnalysisStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnalysisStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnalysisStatus}
 */
proto.v1.AnalysisStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 2:
      var value = new proto.v1.RondeRef;
      reader.readMessage(value,proto.v1.RondeRef.deserializeBinaryFromReader);
      msg.setRonde(value);
      break;
    case 3:
      var value = new proto.v1.Analyse;
      reader.readMessage(value,proto.v1.Analyse.deserializeBinaryFromReader);
      msg.setAnalyse(value);
      break;
    case 4:
      var value = new proto.v1.AnalyseExec;
      reader.readMessage(value,proto.v1.AnalyseExec.deserializeBinaryFromReader);
      msg.setLatestexec(value);
      break;
    case 5:
      var value = new proto.v1.Equipment;
      reader.readMessage(value,proto.v1.Equipment.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnalysisStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnalysisStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnalysisStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalysisStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getRonde();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.RondeRef.serializeBinaryToWriter
    );
  }
  f = message.getAnalyse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.Analyse.serializeBinaryToWriter
    );
  }
  f = message.getLatestexec();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.AnalyseExec.serializeBinaryToWriter
    );
  }
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.Equipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Point Location = 1;
 * @return {?proto.v1.Point}
 */
proto.v1.AnalysisStatus.prototype.getLocation = function() {
  return /** @type{?proto.v1.Point} */ (
    jspb.Message.getWrapperField(this, proto.v1.Point, 1));
};


/**
 * @param {?proto.v1.Point|undefined} value
 * @return {!proto.v1.AnalysisStatus} returns this
*/
proto.v1.AnalysisStatus.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalysisStatus} returns this
 */
proto.v1.AnalysisStatus.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalysisStatus.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RondeRef Ronde = 2;
 * @return {?proto.v1.RondeRef}
 */
proto.v1.AnalysisStatus.prototype.getRonde = function() {
  return /** @type{?proto.v1.RondeRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeRef, 2));
};


/**
 * @param {?proto.v1.RondeRef|undefined} value
 * @return {!proto.v1.AnalysisStatus} returns this
*/
proto.v1.AnalysisStatus.prototype.setRonde = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalysisStatus} returns this
 */
proto.v1.AnalysisStatus.prototype.clearRonde = function() {
  return this.setRonde(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalysisStatus.prototype.hasRonde = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Analyse Analyse = 3;
 * @return {?proto.v1.Analyse}
 */
proto.v1.AnalysisStatus.prototype.getAnalyse = function() {
  return /** @type{?proto.v1.Analyse} */ (
    jspb.Message.getWrapperField(this, proto.v1.Analyse, 3));
};


/**
 * @param {?proto.v1.Analyse|undefined} value
 * @return {!proto.v1.AnalysisStatus} returns this
*/
proto.v1.AnalysisStatus.prototype.setAnalyse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalysisStatus} returns this
 */
proto.v1.AnalysisStatus.prototype.clearAnalyse = function() {
  return this.setAnalyse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalysisStatus.prototype.hasAnalyse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AnalyseExec LatestExec = 4;
 * @return {?proto.v1.AnalyseExec}
 */
proto.v1.AnalysisStatus.prototype.getLatestexec = function() {
  return /** @type{?proto.v1.AnalyseExec} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnalyseExec, 4));
};


/**
 * @param {?proto.v1.AnalyseExec|undefined} value
 * @return {!proto.v1.AnalysisStatus} returns this
*/
proto.v1.AnalysisStatus.prototype.setLatestexec = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalysisStatus} returns this
 */
proto.v1.AnalysisStatus.prototype.clearLatestexec = function() {
  return this.setLatestexec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalysisStatus.prototype.hasLatestexec = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Equipment Equipment = 5;
 * @return {?proto.v1.Equipment}
 */
proto.v1.AnalysisStatus.prototype.getEquipment = function() {
  return /** @type{?proto.v1.Equipment} */ (
    jspb.Message.getWrapperField(this, proto.v1.Equipment, 5));
};


/**
 * @param {?proto.v1.Equipment|undefined} value
 * @return {!proto.v1.AnalysisStatus} returns this
*/
proto.v1.AnalysisStatus.prototype.setEquipment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalysisStatus} returns this
 */
proto.v1.AnalysisStatus.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalysisStatus.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.CSV.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CSV.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CSV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CSV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CSV.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: msg.getContent_asB64(),
    errorsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CSV}
 */
proto.v1.CSV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CSV;
  return proto.v1.CSV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CSV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CSV}
 */
proto.v1.CSV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CSV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CSV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CSV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CSV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional bytes Content = 1;
 * @return {string}
 */
proto.v1.CSV.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes Content = 1;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.v1.CSV.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes Content = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.v1.CSV.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.v1.CSV} returns this
 */
proto.v1.CSV.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * repeated string Errors = 2;
 * @return {!Array<string>}
 */
proto.v1.CSV.prototype.getErrorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.v1.CSV} returns this
 */
proto.v1.CSV.prototype.setErrorsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.v1.CSV} returns this
 */
proto.v1.CSV.prototype.addErrors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.CSV} returns this
 */
proto.v1.CSV.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Empty}
 */
proto.v1.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Empty;
  return proto.v1.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Empty}
 */
proto.v1.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Org.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Org.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Org.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Org} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Org.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    owner: (f = msg.getOwner()) && proto.v1.UserRef.toObject(includeInstance, f),
    isvalid: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    theme: (f = msg.getTheme()) && proto.v1.OrgTheme.toObject(includeInstance, f),
    defaultcategoriesList: jspb.Message.toObjectList(msg.getDefaultcategoriesList(),
    proto.v1.Category.toObject, includeInstance),
    proofsList: jspb.Message.toObjectList(msg.getProofsList(),
    proto.v1.DocRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Org}
 */
proto.v1.Org.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Org;
  return proto.v1.Org.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Org} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Org}
 */
proto.v1.Org.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 4:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsvalid(value);
      break;
    case 6:
      var value = /** @type {!proto.v1.OrgType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = new proto.v1.OrgTheme;
      reader.readMessage(value,proto.v1.OrgTheme.deserializeBinaryFromReader);
      msg.setTheme(value);
      break;
    case 8:
      var value = new proto.v1.Category;
      reader.readMessage(value,proto.v1.Category.deserializeBinaryFromReader);
      msg.addDefaultcategories(value);
      break;
    case 9:
      var value = new proto.v1.DocRef;
      reader.readMessage(value,proto.v1.DocRef.deserializeBinaryFromReader);
      msg.addProofs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Org.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Org.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Org} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Org.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getIsvalid();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTheme();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.v1.OrgTheme.serializeBinaryToWriter
    );
  }
  f = message.getDefaultcategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.v1.Category.serializeBinaryToWriter
    );
  }
  f = message.getProofsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.v1.DocRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Org.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.Org.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Logo = 3;
 * @return {string}
 */
proto.v1.Org.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserRef Owner = 4;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Org.prototype.getOwner = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 4));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Org} returns this
*/
proto.v1.Org.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Org.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool IsValid = 5;
 * @return {boolean}
 */
proto.v1.Org.prototype.getIsvalid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.setIsvalid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional OrgType Type = 6;
 * @return {!proto.v1.OrgType}
 */
proto.v1.Org.prototype.getType = function() {
  return /** @type {!proto.v1.OrgType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.v1.OrgType} value
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional OrgTheme Theme = 7;
 * @return {?proto.v1.OrgTheme}
 */
proto.v1.Org.prototype.getTheme = function() {
  return /** @type{?proto.v1.OrgTheme} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgTheme, 7));
};


/**
 * @param {?proto.v1.OrgTheme|undefined} value
 * @return {!proto.v1.Org} returns this
*/
proto.v1.Org.prototype.setTheme = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.clearTheme = function() {
  return this.setTheme(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Org.prototype.hasTheme = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Category DefaultCategories = 8;
 * @return {!Array<!proto.v1.Category>}
 */
proto.v1.Org.prototype.getDefaultcategoriesList = function() {
  return /** @type{!Array<!proto.v1.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Category, 8));
};


/**
 * @param {!Array<!proto.v1.Category>} value
 * @return {!proto.v1.Org} returns this
*/
proto.v1.Org.prototype.setDefaultcategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.v1.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Category}
 */
proto.v1.Org.prototype.addDefaultcategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.v1.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.clearDefaultcategoriesList = function() {
  return this.setDefaultcategoriesList([]);
};


/**
 * repeated DocRef Proofs = 9;
 * @return {!Array<!proto.v1.DocRef>}
 */
proto.v1.Org.prototype.getProofsList = function() {
  return /** @type{!Array<!proto.v1.DocRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.DocRef, 9));
};


/**
 * @param {!Array<!proto.v1.DocRef>} value
 * @return {!proto.v1.Org} returns this
*/
proto.v1.Org.prototype.setProofsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.v1.DocRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.DocRef}
 */
proto.v1.Org.prototype.addProofs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.v1.DocRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Org} returns this
 */
proto.v1.Org.prototype.clearProofsList = function() {
  return this.setProofsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OrgTheme.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OrgTheme.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OrgTheme} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgTheme.toObject = function(includeInstance, msg) {
  var f, obj = {
    logourl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maincolor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    confirmationtext: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OrgTheme}
 */
proto.v1.OrgTheme.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OrgTheme;
  return proto.v1.OrgTheme.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OrgTheme} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OrgTheme}
 */
proto.v1.OrgTheme.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogourl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaincolor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationtext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OrgTheme.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OrgTheme.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OrgTheme} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgTheme.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogourl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaincolor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfirmationtext();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string LogoURL = 1;
 * @return {string}
 */
proto.v1.OrgTheme.prototype.getLogourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgTheme} returns this
 */
proto.v1.OrgTheme.prototype.setLogourl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string MainColor = 2;
 * @return {string}
 */
proto.v1.OrgTheme.prototype.getMaincolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgTheme} returns this
 */
proto.v1.OrgTheme.prototype.setMaincolor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ConfirmationText = 3;
 * @return {string}
 */
proto.v1.OrgTheme.prototype.getConfirmationtext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgTheme} returns this
 */
proto.v1.OrgTheme.prototype.setConfirmationtext = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Zone.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Zone.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Zone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Zone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Zone.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ispublic: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    polygonList: jspb.Message.toObjectList(msg.getPolygonList(),
    proto.v1.Point.toObject, includeInstance),
    floorsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    allowedcategoriesList: jspb.Message.toObjectList(msg.getAllowedcategoriesList(),
    proto.v1.Category.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Zone}
 */
proto.v1.Zone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Zone;
  return proto.v1.Zone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Zone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Zone}
 */
proto.v1.Zone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspublic(value);
      break;
    case 4:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 5:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.addPolygon(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addFloors(value);
      break;
    case 7:
      var value = new proto.v1.Category;
      reader.readMessage(value,proto.v1.Category.deserializeBinaryFromReader);
      msg.addAllowedcategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Zone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Zone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Zone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Zone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIspublic();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getPolygonList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAllowedcategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.v1.Category.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Zone.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.Zone.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool IsPublic = 3;
 * @return {boolean}
 */
proto.v1.Zone.prototype.getIspublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.setIspublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional OrgRef Org = 4;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Zone.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 4));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Zone} returns this
*/
proto.v1.Zone.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Zone.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Point Polygon = 5;
 * @return {!Array<!proto.v1.Point>}
 */
proto.v1.Zone.prototype.getPolygonList = function() {
  return /** @type{!Array<!proto.v1.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Point, 5));
};


/**
 * @param {!Array<!proto.v1.Point>} value
 * @return {!proto.v1.Zone} returns this
*/
proto.v1.Zone.prototype.setPolygonList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.v1.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Point}
 */
proto.v1.Zone.prototype.addPolygon = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.v1.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.clearPolygonList = function() {
  return this.setPolygonList([]);
};


/**
 * repeated string Floors = 6;
 * @return {!Array<string>}
 */
proto.v1.Zone.prototype.getFloorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.setFloorsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.addFloors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.clearFloorsList = function() {
  return this.setFloorsList([]);
};


/**
 * repeated Category AllowedCategories = 7;
 * @return {!Array<!proto.v1.Category>}
 */
proto.v1.Zone.prototype.getAllowedcategoriesList = function() {
  return /** @type{!Array<!proto.v1.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Category, 7));
};


/**
 * @param {!Array<!proto.v1.Category>} value
 * @return {!proto.v1.Zone} returns this
*/
proto.v1.Zone.prototype.setAllowedcategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.v1.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Category}
 */
proto.v1.Zone.prototype.addAllowedcategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.v1.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Zone} returns this
 */
proto.v1.Zone.prototype.clearAllowedcategoriesList = function() {
  return this.setAllowedcategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_long: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Point}
 */
proto.v1.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Point;
  return proto.v1.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Point}
 */
proto.v1.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLong(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLong();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double Long = 1;
 * @return {number}
 */
proto.v1.Point.prototype.getLong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Point} returns this
 */
proto.v1.Point.prototype.setLong = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double Lat = 2;
 * @return {number}
 */
proto.v1.Point.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Point} returns this
 */
proto.v1.Point.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.GeoBox.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.GeoBox.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.GeoBox} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.GeoBox.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: (f = msg.getMin()) && proto.v1.Point.toObject(includeInstance, f),
    max: (f = msg.getMax()) && proto.v1.Point.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.GeoBox}
 */
proto.v1.GeoBox.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.GeoBox;
  return proto.v1.GeoBox.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.GeoBox} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.GeoBox}
 */
proto.v1.GeoBox.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.setMin(value);
      break;
    case 2:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.GeoBox.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.GeoBox.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.GeoBox} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.GeoBox.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getMax();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
};


/**
 * optional Point Min = 1;
 * @return {?proto.v1.Point}
 */
proto.v1.GeoBox.prototype.getMin = function() {
  return /** @type{?proto.v1.Point} */ (
    jspb.Message.getWrapperField(this, proto.v1.Point, 1));
};


/**
 * @param {?proto.v1.Point|undefined} value
 * @return {!proto.v1.GeoBox} returns this
*/
proto.v1.GeoBox.prototype.setMin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.GeoBox} returns this
 */
proto.v1.GeoBox.prototype.clearMin = function() {
  return this.setMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.GeoBox.prototype.hasMin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Point Max = 2;
 * @return {?proto.v1.Point}
 */
proto.v1.GeoBox.prototype.getMax = function() {
  return /** @type{?proto.v1.Point} */ (
    jspb.Message.getWrapperField(this, proto.v1.Point, 2));
};


/**
 * @param {?proto.v1.Point|undefined} value
 * @return {!proto.v1.GeoBox} returns this
*/
proto.v1.GeoBox.prototype.setMax = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.GeoBox} returns this
 */
proto.v1.GeoBox.prototype.clearMax = function() {
  return this.setMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.GeoBox.prototype.hasMax = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.GPSPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.GPSPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.GPSPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.GPSPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    point: (f = msg.getPoint()) && proto.v1.Point.toObject(includeInstance, f),
    precision: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.GPSPoint}
 */
proto.v1.GPSPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.GPSPoint;
  return proto.v1.GPSPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.GPSPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.GPSPoint}
 */
proto.v1.GPSPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrecision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.GPSPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.GPSPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.GPSPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.GPSPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getPrecision();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional Point Point = 1;
 * @return {?proto.v1.Point}
 */
proto.v1.GPSPoint.prototype.getPoint = function() {
  return /** @type{?proto.v1.Point} */ (
    jspb.Message.getWrapperField(this, proto.v1.Point, 1));
};


/**
 * @param {?proto.v1.Point|undefined} value
 * @return {!proto.v1.GPSPoint} returns this
*/
proto.v1.GPSPoint.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.GPSPoint} returns this
 */
proto.v1.GPSPoint.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.GPSPoint.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float Precision = 2;
 * @return {number}
 */
proto.v1.GPSPoint.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.v1.GPSPoint} returns this
 */
proto.v1.GPSPoint.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.HandlingFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.HandlingFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.HandlingFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.HandlingFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    anomalyid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.HandlingFilter}
 */
proto.v1.HandlingFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.HandlingFilter;
  return proto.v1.HandlingFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.HandlingFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.HandlingFilter}
 */
proto.v1.HandlingFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnomalyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.HandlingFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.HandlingFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.HandlingFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.HandlingFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnomalyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string AnomalyID = 1;
 * @return {string}
 */
proto.v1.HandlingFilter.prototype.getAnomalyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.HandlingFilter} returns this
 */
proto.v1.HandlingFilter.prototype.setAnomalyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ZoneRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ZoneRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ZoneRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ZoneRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ZoneRef}
 */
proto.v1.ZoneRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ZoneRef;
  return proto.v1.ZoneRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ZoneRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ZoneRef}
 */
proto.v1.ZoneRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ZoneRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ZoneRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ZoneRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ZoneRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.ZoneRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ZoneRef} returns this
 */
proto.v1.ZoneRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.ZoneList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ZoneList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ZoneList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ZoneList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ZoneList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Zone.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ZoneList}
 */
proto.v1.ZoneList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ZoneList;
  return proto.v1.ZoneList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ZoneList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ZoneList}
 */
proto.v1.ZoneList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Zone;
      reader.readMessage(value,proto.v1.Zone.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ZoneList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ZoneList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ZoneList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ZoneList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Zone.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Zone Items = 1;
 * @return {!Array<!proto.v1.Zone>}
 */
proto.v1.ZoneList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Zone>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Zone, 1));
};


/**
 * @param {!Array<!proto.v1.Zone>} value
 * @return {!proto.v1.ZoneList} returns this
*/
proto.v1.ZoneList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Zone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Zone}
 */
proto.v1.ZoneList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Zone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.ZoneList} returns this
 */
proto.v1.ZoneList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Anomaly.repeatedFields_ = [12,13,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Anomaly.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Anomaly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Anomaly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Anomaly.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    point: (f = msg.getPoint()) && proto.v1.Point.toObject(includeInstance, f),
    floor: (f = msg.getFloor()) && proto.v1.FloorRef.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && proto.v1.CategoryRef.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    creator: (f = msg.getCreator()) && proto.v1.UserRef.toObject(includeInstance, f),
    latesthandling: (f = msg.getLatesthandling()) && proto.v1.Handling.toObject(includeInstance, f),
    equipment: (f = msg.getEquipment()) && proto.v1.EquipmentRef.toObject(includeInstance, f),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    createdat: jspb.Message.getFieldWithDefault(msg, 14, 0),
    assignedto: (f = msg.getAssignedto()) && proto.v1.UserRef.toObject(includeInstance, f),
    assignedteam: (f = msg.getAssignedteam()) && proto.v1.TeamRef.toObject(includeInstance, f),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    source: jspb.Message.getFieldWithDefault(msg, 20, ""),
    criticite: jspb.Message.getFieldWithDefault(msg, 21, 0),
    shorttag: jspb.Message.getFieldWithDefault(msg, 22, ""),
    upvotes: jspb.Message.getFieldWithDefault(msg, 17, 0),
    photosList: jspb.Message.toObjectList(msg.getPhotosList(),
    proto.v1.PhotoRef.toObject, includeInstance),
    audiorecordsList: jspb.Message.toObjectList(msg.getAudiorecordsList(),
    proto.v1.AudioRecordRef.toObject, includeInstance),
    handlingsList: jspb.Message.toObjectList(msg.getHandlingsList(),
    proto.v1.Handling.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Anomaly}
 */
proto.v1.Anomaly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Anomaly;
  return proto.v1.Anomaly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Anomaly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Anomaly}
 */
proto.v1.Anomaly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 4:
      var value = new proto.v1.FloorRef;
      reader.readMessage(value,proto.v1.FloorRef.deserializeBinaryFromReader);
      msg.setFloor(value);
      break;
    case 5:
      var value = new proto.v1.CategoryRef;
      reader.readMessage(value,proto.v1.CategoryRef.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setCreator(value);
      break;
    case 9:
      var value = new proto.v1.Handling;
      reader.readMessage(value,proto.v1.Handling.deserializeBinaryFromReader);
      msg.setLatesthandling(value);
      break;
    case 10:
      var value = new proto.v1.EquipmentRef;
      reader.readMessage(value,proto.v1.EquipmentRef.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    case 11:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 15:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setAssignedto(value);
      break;
    case 18:
      var value = new proto.v1.TeamRef;
      reader.readMessage(value,proto.v1.TeamRef.deserializeBinaryFromReader);
      msg.setAssignedteam(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCriticite(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setShorttag(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUpvotes(value);
      break;
    case 12:
      var value = new proto.v1.PhotoRef;
      reader.readMessage(value,proto.v1.PhotoRef.deserializeBinaryFromReader);
      msg.addPhotos(value);
      break;
    case 13:
      var value = new proto.v1.AudioRecordRef;
      reader.readMessage(value,proto.v1.AudioRecordRef.deserializeBinaryFromReader);
      msg.addAudiorecords(value);
      break;
    case 16:
      var value = new proto.v1.Handling;
      reader.readMessage(value,proto.v1.Handling.deserializeBinaryFromReader);
      msg.addHandlings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Anomaly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Anomaly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Anomaly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Anomaly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getFloor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.FloorRef.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.CategoryRef.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreator();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getLatesthandling();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.v1.Handling.serializeBinaryToWriter
    );
  }
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.v1.EquipmentRef.serializeBinaryToWriter
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getAssignedto();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getAssignedteam();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.v1.TeamRef.serializeBinaryToWriter
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCriticite();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getShorttag();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getUpvotes();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.v1.PhotoRef.serializeBinaryToWriter
    );
  }
  f = message.getAudiorecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.v1.AudioRecordRef.serializeBinaryToWriter
    );
  }
  f = message.getHandlingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.v1.Handling.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Anomaly.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Point Point = 2;
 * @return {?proto.v1.Point}
 */
proto.v1.Anomaly.prototype.getPoint = function() {
  return /** @type{?proto.v1.Point} */ (
    jspb.Message.getWrapperField(this, proto.v1.Point, 2));
};


/**
 * @param {?proto.v1.Point|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FloorRef Floor = 4;
 * @return {?proto.v1.FloorRef}
 */
proto.v1.Anomaly.prototype.getFloor = function() {
  return /** @type{?proto.v1.FloorRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.FloorRef, 4));
};


/**
 * @param {?proto.v1.FloorRef|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setFloor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearFloor = function() {
  return this.setFloor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasFloor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CategoryRef Category = 5;
 * @return {?proto.v1.CategoryRef}
 */
proto.v1.Anomaly.prototype.getCategory = function() {
  return /** @type{?proto.v1.CategoryRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.CategoryRef, 5));
};


/**
 * @param {?proto.v1.CategoryRef|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string Label = 6;
 * @return {string}
 */
proto.v1.Anomaly.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Description = 7;
 * @return {string}
 */
proto.v1.Anomaly.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional UserRef Creator = 8;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Anomaly.prototype.getCreator = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 8));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setCreator = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearCreator = function() {
  return this.setCreator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasCreator = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Handling LatestHandling = 9;
 * @return {?proto.v1.Handling}
 */
proto.v1.Anomaly.prototype.getLatesthandling = function() {
  return /** @type{?proto.v1.Handling} */ (
    jspb.Message.getWrapperField(this, proto.v1.Handling, 9));
};


/**
 * @param {?proto.v1.Handling|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setLatesthandling = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearLatesthandling = function() {
  return this.setLatesthandling(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasLatesthandling = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional EquipmentRef Equipment = 10;
 * @return {?proto.v1.EquipmentRef}
 */
proto.v1.Anomaly.prototype.getEquipment = function() {
  return /** @type{?proto.v1.EquipmentRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentRef, 10));
};


/**
 * @param {?proto.v1.EquipmentRef|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setEquipment = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OrgRef Org = 11;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Anomaly.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 11));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 CreatedAt = 14;
 * @return {number}
 */
proto.v1.Anomaly.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional UserRef AssignedTo = 15;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Anomaly.prototype.getAssignedto = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 15));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setAssignedto = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearAssignedto = function() {
  return this.setAssignedto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasAssignedto = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional TeamRef AssignedTeam = 18;
 * @return {?proto.v1.TeamRef}
 */
proto.v1.Anomaly.prototype.getAssignedteam = function() {
  return /** @type{?proto.v1.TeamRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.TeamRef, 18));
};


/**
 * @param {?proto.v1.TeamRef|undefined} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setAssignedteam = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearAssignedteam = function() {
  return this.setAssignedteam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.hasAssignedteam = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool Archived = 19;
 * @return {boolean}
 */
proto.v1.Anomaly.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string Source = 20;
 * @return {string}
 */
proto.v1.Anomaly.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int32 Criticite = 21;
 * @return {number}
 */
proto.v1.Anomaly.prototype.getCriticite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setCriticite = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string ShortTag = 22;
 * @return {string}
 */
proto.v1.Anomaly.prototype.getShorttag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setShorttag = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional uint32 UpVotes = 17;
 * @return {number}
 */
proto.v1.Anomaly.prototype.getUpvotes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.setUpvotes = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * repeated PhotoRef Photos = 12;
 * @return {!Array<!proto.v1.PhotoRef>}
 */
proto.v1.Anomaly.prototype.getPhotosList = function() {
  return /** @type{!Array<!proto.v1.PhotoRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.PhotoRef, 12));
};


/**
 * @param {!Array<!proto.v1.PhotoRef>} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.v1.PhotoRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.PhotoRef}
 */
proto.v1.Anomaly.prototype.addPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.v1.PhotoRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearPhotosList = function() {
  return this.setPhotosList([]);
};


/**
 * repeated AudioRecordRef AudioRecords = 13;
 * @return {!Array<!proto.v1.AudioRecordRef>}
 */
proto.v1.Anomaly.prototype.getAudiorecordsList = function() {
  return /** @type{!Array<!proto.v1.AudioRecordRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AudioRecordRef, 13));
};


/**
 * @param {!Array<!proto.v1.AudioRecordRef>} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setAudiorecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.v1.AudioRecordRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AudioRecordRef}
 */
proto.v1.Anomaly.prototype.addAudiorecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.v1.AudioRecordRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearAudiorecordsList = function() {
  return this.setAudiorecordsList([]);
};


/**
 * repeated Handling Handlings = 16;
 * @return {!Array<!proto.v1.Handling>}
 */
proto.v1.Anomaly.prototype.getHandlingsList = function() {
  return /** @type{!Array<!proto.v1.Handling>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Handling, 16));
};


/**
 * @param {!Array<!proto.v1.Handling>} value
 * @return {!proto.v1.Anomaly} returns this
*/
proto.v1.Anomaly.prototype.setHandlingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.v1.Handling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Handling}
 */
proto.v1.Anomaly.prototype.addHandlings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.v1.Handling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Anomaly} returns this
 */
proto.v1.Anomaly.prototype.clearHandlingsList = function() {
  return this.setHandlingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnomalySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnomalySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnomalySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 7, 0),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    point: (f = msg.getPoint()) && proto.v1.Point.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && proto.v1.CategoryRef.toObject(includeInstance, f),
    latesthandling: (f = msg.getLatesthandling()) && proto.v1.Handling.toObject(includeInstance, f),
    mainphoto: (f = msg.getMainphoto()) && proto.v1.PhotoRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnomalySummary}
 */
proto.v1.AnomalySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnomalySummary;
  return proto.v1.AnomalySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnomalySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnomalySummary}
 */
proto.v1.AnomalySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 4:
      var value = new proto.v1.CategoryRef;
      reader.readMessage(value,proto.v1.CategoryRef.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 5:
      var value = new proto.v1.Handling;
      reader.readMessage(value,proto.v1.Handling.deserializeBinaryFromReader);
      msg.setLatesthandling(value);
      break;
    case 6:
      var value = new proto.v1.PhotoRef;
      reader.readMessage(value,proto.v1.PhotoRef.deserializeBinaryFromReader);
      msg.setMainphoto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnomalySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnomalySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnomalySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.CategoryRef.serializeBinaryToWriter
    );
  }
  f = message.getLatesthandling();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.Handling.serializeBinaryToWriter
    );
  }
  f = message.getMainphoto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.v1.PhotoRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.AnomalySummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnomalySummary} returns this
 */
proto.v1.AnomalySummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedAt = 7;
 * @return {number}
 */
proto.v1.AnomalySummary.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.AnomalySummary} returns this
 */
proto.v1.AnomalySummary.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string Label = 2;
 * @return {string}
 */
proto.v1.AnomalySummary.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnomalySummary} returns this
 */
proto.v1.AnomalySummary.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Point Point = 3;
 * @return {?proto.v1.Point}
 */
proto.v1.AnomalySummary.prototype.getPoint = function() {
  return /** @type{?proto.v1.Point} */ (
    jspb.Message.getWrapperField(this, proto.v1.Point, 3));
};


/**
 * @param {?proto.v1.Point|undefined} value
 * @return {!proto.v1.AnomalySummary} returns this
*/
proto.v1.AnomalySummary.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnomalySummary} returns this
 */
proto.v1.AnomalySummary.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnomalySummary.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CategoryRef Category = 4;
 * @return {?proto.v1.CategoryRef}
 */
proto.v1.AnomalySummary.prototype.getCategory = function() {
  return /** @type{?proto.v1.CategoryRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.CategoryRef, 4));
};


/**
 * @param {?proto.v1.CategoryRef|undefined} value
 * @return {!proto.v1.AnomalySummary} returns this
*/
proto.v1.AnomalySummary.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnomalySummary} returns this
 */
proto.v1.AnomalySummary.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnomalySummary.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Handling LatestHandling = 5;
 * @return {?proto.v1.Handling}
 */
proto.v1.AnomalySummary.prototype.getLatesthandling = function() {
  return /** @type{?proto.v1.Handling} */ (
    jspb.Message.getWrapperField(this, proto.v1.Handling, 5));
};


/**
 * @param {?proto.v1.Handling|undefined} value
 * @return {!proto.v1.AnomalySummary} returns this
*/
proto.v1.AnomalySummary.prototype.setLatesthandling = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnomalySummary} returns this
 */
proto.v1.AnomalySummary.prototype.clearLatesthandling = function() {
  return this.setLatesthandling(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnomalySummary.prototype.hasLatesthandling = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PhotoRef MainPhoto = 6;
 * @return {?proto.v1.PhotoRef}
 */
proto.v1.AnomalySummary.prototype.getMainphoto = function() {
  return /** @type{?proto.v1.PhotoRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.PhotoRef, 6));
};


/**
 * @param {?proto.v1.PhotoRef|undefined} value
 * @return {!proto.v1.AnomalySummary} returns this
*/
proto.v1.AnomalySummary.prototype.setMainphoto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnomalySummary} returns this
 */
proto.v1.AnomalySummary.prototype.clearMainphoto = function() {
  return this.setMainphoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnomalySummary.prototype.hasMainphoto = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.FloorRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.FloorRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.FloorRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.FloorRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    floorname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.FloorRef}
 */
proto.v1.FloorRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.FloorRef;
  return proto.v1.FloorRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.FloorRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.FloorRef}
 */
proto.v1.FloorRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloorname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.FloorRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.FloorRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.FloorRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.FloorRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFloorname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ZoneID = 1;
 * @return {string}
 */
proto.v1.FloorRef.prototype.getZoneid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.FloorRef} returns this
 */
proto.v1.FloorRef.prototype.setZoneid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FloorName = 2;
 * @return {string}
 */
proto.v1.FloorRef.prototype.getFloorname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.FloorRef} returns this
 */
proto.v1.FloorRef.prototype.setFloorname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.PhotoRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.PhotoRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.PhotoRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.PhotoRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.PhotoRef}
 */
proto.v1.PhotoRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.PhotoRef;
  return proto.v1.PhotoRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.PhotoRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.PhotoRef}
 */
proto.v1.PhotoRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.PhotoRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.PhotoRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.PhotoRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.PhotoRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.PhotoRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.PhotoRef} returns this
 */
proto.v1.PhotoRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AudioRecordRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AudioRecordRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AudioRecordRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AudioRecordRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AudioRecordRef}
 */
proto.v1.AudioRecordRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AudioRecordRef;
  return proto.v1.AudioRecordRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AudioRecordRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AudioRecordRef}
 */
proto.v1.AudioRecordRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AudioRecordRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AudioRecordRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AudioRecordRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AudioRecordRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.AudioRecordRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AudioRecordRef} returns this
 */
proto.v1.AudioRecordRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnomalyRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnomalyRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnomalyRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalyRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnomalyRef}
 */
proto.v1.AnomalyRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnomalyRef;
  return proto.v1.AnomalyRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnomalyRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnomalyRef}
 */
proto.v1.AnomalyRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnomalyRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnomalyRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnomalyRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalyRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.AnomalyRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnomalyRef} returns this
 */
proto.v1.AnomalyRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.AnomalyRefList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnomalyRefList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnomalyRefList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnomalyRefList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalyRefList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.AnomalyRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnomalyRefList}
 */
proto.v1.AnomalyRefList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnomalyRefList;
  return proto.v1.AnomalyRefList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnomalyRefList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnomalyRefList}
 */
proto.v1.AnomalyRefList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.AnomalyRef;
      reader.readMessage(value,proto.v1.AnomalyRef.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnomalyRefList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnomalyRefList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnomalyRefList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalyRefList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.AnomalyRef.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnomalyRef Items = 1;
 * @return {!Array<!proto.v1.AnomalyRef>}
 */
proto.v1.AnomalyRefList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.AnomalyRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AnomalyRef, 1));
};


/**
 * @param {!Array<!proto.v1.AnomalyRef>} value
 * @return {!proto.v1.AnomalyRefList} returns this
*/
proto.v1.AnomalyRefList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.AnomalyRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AnomalyRef}
 */
proto.v1.AnomalyRefList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.AnomalyRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AnomalyRefList} returns this
 */
proto.v1.AnomalyRefList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.AnomalyList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnomalyList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnomalyList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnomalyList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalyList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Anomaly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnomalyList}
 */
proto.v1.AnomalyList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnomalyList;
  return proto.v1.AnomalyList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnomalyList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnomalyList}
 */
proto.v1.AnomalyList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Anomaly;
      reader.readMessage(value,proto.v1.Anomaly.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnomalyList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnomalyList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnomalyList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnomalyList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Anomaly.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Anomaly Items = 1;
 * @return {!Array<!proto.v1.Anomaly>}
 */
proto.v1.AnomalyList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Anomaly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Anomaly, 1));
};


/**
 * @param {!Array<!proto.v1.Anomaly>} value
 * @return {!proto.v1.AnomalyList} returns this
*/
proto.v1.AnomalyList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Anomaly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Anomaly}
 */
proto.v1.AnomalyList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Anomaly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AnomalyList} returns this
 */
proto.v1.AnomalyList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Handling.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Handling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Handling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Handling.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    anomaly: (f = msg.getAnomaly()) && proto.v1.AnomalyRef.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdat: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdby: (f = msg.getCreatedby()) && proto.v1.UserRef.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Handling}
 */
proto.v1.Handling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Handling;
  return proto.v1.Handling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Handling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Handling}
 */
proto.v1.Handling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.AnomalyRef;
      reader.readMessage(value,proto.v1.AnomalyRef.deserializeBinaryFromReader);
      msg.setAnomaly(value);
      break;
    case 3:
      var value = /** @type {!proto.v1.HandlingState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 6:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Handling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Handling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Handling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Handling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnomaly();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.AnomalyRef.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Handling.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Handling} returns this
 */
proto.v1.Handling.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AnomalyRef Anomaly = 2;
 * @return {?proto.v1.AnomalyRef}
 */
proto.v1.Handling.prototype.getAnomaly = function() {
  return /** @type{?proto.v1.AnomalyRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnomalyRef, 2));
};


/**
 * @param {?proto.v1.AnomalyRef|undefined} value
 * @return {!proto.v1.Handling} returns this
*/
proto.v1.Handling.prototype.setAnomaly = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Handling} returns this
 */
proto.v1.Handling.prototype.clearAnomaly = function() {
  return this.setAnomaly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Handling.prototype.hasAnomaly = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HandlingState State = 3;
 * @return {!proto.v1.HandlingState}
 */
proto.v1.Handling.prototype.getState = function() {
  return /** @type {!proto.v1.HandlingState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.v1.HandlingState} value
 * @return {!proto.v1.Handling} returns this
 */
proto.v1.Handling.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 CreatedAt = 4;
 * @return {number}
 */
proto.v1.Handling.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Handling} returns this
 */
proto.v1.Handling.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional UserRef CreatedBy = 6;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Handling.prototype.getCreatedby = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 6));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Handling} returns this
*/
proto.v1.Handling.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Handling} returns this
 */
proto.v1.Handling.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Handling.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string Message = 5;
 * @return {string}
 */
proto.v1.Handling.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Handling} returns this
 */
proto.v1.Handling.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.User.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneprefix: jspb.Message.getFieldWithDefault(msg, 9, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 5, ""),
    invitecode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.v1.OrgRole.toObject, includeInstance),
    proofsList: jspb.Message.toObjectList(msg.getProofsList(),
    proto.v1.DocRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.User}
 */
proto.v1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.User;
  return proto.v1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.User}
 */
proto.v1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneprefix(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitecode(value);
      break;
    case 6:
      var value = new proto.v1.OrgRole;
      reader.readMessage(value,proto.v1.OrgRole.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 7:
      var value = new proto.v1.DocRef;
      reader.readMessage(value,proto.v1.DocRef.deserializeBinaryFromReader);
      msg.addProofs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneprefix();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInvitecode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.v1.OrgRole.serializeBinaryToWriter
    );
  }
  f = message.getProofsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.v1.DocRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Email = 3;
 * @return {string}
 */
proto.v1.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string PhonePrefix = 9;
 * @return {string}
 */
proto.v1.User.prototype.getPhoneprefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.setPhoneprefix = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string Phone = 4;
 * @return {string}
 */
proto.v1.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Avatar = 5;
 * @return {string}
 */
proto.v1.User.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string InviteCode = 8;
 * @return {string}
 */
proto.v1.User.prototype.getInvitecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.setInvitecode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated OrgRole Roles = 6;
 * @return {!Array<!proto.v1.OrgRole>}
 */
proto.v1.User.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.v1.OrgRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.OrgRole, 6));
};


/**
 * @param {!Array<!proto.v1.OrgRole>} value
 * @return {!proto.v1.User} returns this
*/
proto.v1.User.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.v1.OrgRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.OrgRole}
 */
proto.v1.User.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.v1.OrgRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * repeated DocRef Proofs = 7;
 * @return {!Array<!proto.v1.DocRef>}
 */
proto.v1.User.prototype.getProofsList = function() {
  return /** @type{!Array<!proto.v1.DocRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.DocRef, 7));
};


/**
 * @param {!Array<!proto.v1.DocRef>} value
 * @return {!proto.v1.User} returns this
*/
proto.v1.User.prototype.setProofsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.v1.DocRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.DocRef}
 */
proto.v1.User.prototype.addProofs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.v1.DocRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.User} returns this
 */
proto.v1.User.prototype.clearProofsList = function() {
  return this.setProofsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.UserRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.UserRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.UserRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.UserRef}
 */
proto.v1.UserRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.UserRef;
  return proto.v1.UserRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.UserRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.UserRef}
 */
proto.v1.UserRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.UserRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.UserRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.UserRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.UserRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UserRef} returns this
 */
proto.v1.UserRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.UserRef.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UserRef} returns this
 */
proto.v1.UserRef.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.UserList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.UserList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.UserList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.UserList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.UserList}
 */
proto.v1.UserList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.UserList;
  return proto.v1.UserList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.UserList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.UserList}
 */
proto.v1.UserList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.User;
      reader.readMessage(value,proto.v1.User.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.UserList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.UserList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.UserList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User Items = 1;
 * @return {!Array<!proto.v1.User>}
 */
proto.v1.UserList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.User, 1));
};


/**
 * @param {!Array<!proto.v1.User>} value
 * @return {!proto.v1.UserList} returns this
*/
proto.v1.UserList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.User}
 */
proto.v1.UserList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.UserList} returns this
 */
proto.v1.UserList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OrgRole.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OrgRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OrgRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isadmin: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    iscontrol: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    iscontributor: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OrgRole}
 */
proto.v1.OrgRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OrgRole;
  return proto.v1.OrgRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OrgRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OrgRole}
 */
proto.v1.OrgRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsadmin(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscontrol(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscontributor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OrgRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OrgRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OrgRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsadmin();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIscontrol();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIscontributor();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string OrgID = 1;
 * @return {string}
 */
proto.v1.OrgRole.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgRole} returns this
 */
proto.v1.OrgRole.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool IsAdmin = 2;
 * @return {boolean}
 */
proto.v1.OrgRole.prototype.getIsadmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.OrgRole} returns this
 */
proto.v1.OrgRole.prototype.setIsadmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool IsControl = 3;
 * @return {boolean}
 */
proto.v1.OrgRole.prototype.getIscontrol = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.OrgRole} returns this
 */
proto.v1.OrgRole.prototype.setIscontrol = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool IsContributor = 4;
 * @return {boolean}
 */
proto.v1.OrgRole.prototype.getIscontributor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.OrgRole} returns this
 */
proto.v1.OrgRole.prototype.setIscontributor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.InviteUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.InviteUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.InviteUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.InviteUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    invitecode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.InviteUserReq}
 */
proto.v1.InviteUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.InviteUserReq;
  return proto.v1.InviteUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.InviteUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.InviteUserReq}
 */
proto.v1.InviteUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitecode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.InviteUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.InviteUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.InviteUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.InviteUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvitecode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string InviteCode = 1;
 * @return {string}
 */
proto.v1.InviteUserReq.prototype.getInvitecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.InviteUserReq} returns this
 */
proto.v1.InviteUserReq.prototype.setInvitecode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Category.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Category.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Category} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Category.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Category}
 */
proto.v1.Category.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Category;
  return proto.v1.Category.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Category} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Category}
 */
proto.v1.Category.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 5:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Category.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Category.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Category} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Category.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Category.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Category} returns this
 */
proto.v1.Category.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.Category.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Category} returns this
 */
proto.v1.Category.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Logo = 3;
 * @return {string}
 */
proto.v1.Category.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Category} returns this
 */
proto.v1.Category.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool IsActive = 4;
 * @return {boolean}
 */
proto.v1.Category.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.Category} returns this
 */
proto.v1.Category.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional OrgRef Org = 5;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Category.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 5));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Category} returns this
*/
proto.v1.Category.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Category} returns this
 */
proto.v1.Category.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Category.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.EquipmentType.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EquipmentType.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EquipmentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EquipmentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    publicnote: jspb.Message.getFieldWithDefault(msg, 5, ""),
    docsList: jspb.Message.toObjectList(msg.getDocsList(),
    proto.v1.DocRef.toObject, includeInstance),
    metricsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EquipmentType}
 */
proto.v1.EquipmentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EquipmentType;
  return proto.v1.EquipmentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EquipmentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EquipmentType}
 */
proto.v1.EquipmentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicnote(value);
      break;
    case 4:
      var value = new proto.v1.DocRef;
      reader.readMessage(value,proto.v1.DocRef.deserializeBinaryFromReader);
      msg.addDocs(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EquipmentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EquipmentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EquipmentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPublicnote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDocsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.v1.DocRef.serializeBinaryToWriter
    );
  }
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.EquipmentType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.EquipmentType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrgRef Org = 3;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.EquipmentType.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 3));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.EquipmentType} returns this
*/
proto.v1.EquipmentType.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.EquipmentType.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Note = 7;
 * @return {string}
 */
proto.v1.EquipmentType.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string PublicNote = 5;
 * @return {string}
 */
proto.v1.EquipmentType.prototype.getPublicnote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.setPublicnote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated DocRef Docs = 4;
 * @return {!Array<!proto.v1.DocRef>}
 */
proto.v1.EquipmentType.prototype.getDocsList = function() {
  return /** @type{!Array<!proto.v1.DocRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.DocRef, 4));
};


/**
 * @param {!Array<!proto.v1.DocRef>} value
 * @return {!proto.v1.EquipmentType} returns this
*/
proto.v1.EquipmentType.prototype.setDocsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.v1.DocRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.DocRef}
 */
proto.v1.EquipmentType.prototype.addDocs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.v1.DocRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.clearDocsList = function() {
  return this.setDocsList([]);
};


/**
 * repeated string Metrics = 6;
 * @return {!Array<string>}
 */
proto.v1.EquipmentType.prototype.getMetricsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.setMetricsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.addMetrics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.EquipmentType} returns this
 */
proto.v1.EquipmentType.prototype.clearMetricsList = function() {
  return this.setMetricsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Equipment.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Equipment.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Equipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Equipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Equipment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    type: (f = msg.getType()) && proto.v1.EquipmentTypeRef.toObject(includeInstance, f),
    mobile: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    localid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    floor: (f = msg.getFloor()) && proto.v1.FloorRef.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.v1.Point.toObject(includeInstance, f),
    latestlocation: (f = msg.getLatestlocation()) && proto.v1.Point.toObject(includeInstance, f),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    publicnote: jspb.Message.getFieldWithDefault(msg, 9, ""),
    docsList: jspb.Message.toObjectList(msg.getDocsList(),
    proto.v1.DocRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Equipment}
 */
proto.v1.Equipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Equipment;
  return proto.v1.Equipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Equipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Equipment}
 */
proto.v1.Equipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 8:
      var value = new proto.v1.EquipmentTypeRef;
      reader.readMessage(value,proto.v1.EquipmentTypeRef.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMobile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalid(value);
      break;
    case 5:
      var value = new proto.v1.FloorRef;
      reader.readMessage(value,proto.v1.FloorRef.deserializeBinaryFromReader);
      msg.setFloor(value);
      break;
    case 4:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 12:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.setLatestlocation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicnote(value);
      break;
    case 6:
      var value = new proto.v1.DocRef;
      reader.readMessage(value,proto.v1.DocRef.deserializeBinaryFromReader);
      msg.addDocs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Equipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Equipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Equipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Equipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.v1.EquipmentTypeRef.serializeBinaryToWriter
    );
  }
  f = message.getMobile();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocalid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFloor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.FloorRef.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getLatestlocation();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPublicnote();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDocsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.v1.DocRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Equipment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgRef Org = 7;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Equipment.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 7));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Equipment} returns this
*/
proto.v1.Equipment.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Equipment.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EquipmentTypeRef Type = 8;
 * @return {?proto.v1.EquipmentTypeRef}
 */
proto.v1.Equipment.prototype.getType = function() {
  return /** @type{?proto.v1.EquipmentTypeRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentTypeRef, 8));
};


/**
 * @param {?proto.v1.EquipmentTypeRef|undefined} value
 * @return {!proto.v1.Equipment} returns this
*/
proto.v1.Equipment.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Equipment.prototype.hasType = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool Mobile = 11;
 * @return {boolean}
 */
proto.v1.Equipment.prototype.getMobile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.setMobile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.Equipment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string LocalID = 3;
 * @return {string}
 */
proto.v1.Equipment.prototype.getLocalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.setLocalid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FloorRef Floor = 5;
 * @return {?proto.v1.FloorRef}
 */
proto.v1.Equipment.prototype.getFloor = function() {
  return /** @type{?proto.v1.FloorRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.FloorRef, 5));
};


/**
 * @param {?proto.v1.FloorRef|undefined} value
 * @return {!proto.v1.Equipment} returns this
*/
proto.v1.Equipment.prototype.setFloor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.clearFloor = function() {
  return this.setFloor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Equipment.prototype.hasFloor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Point Location = 4;
 * @return {?proto.v1.Point}
 */
proto.v1.Equipment.prototype.getLocation = function() {
  return /** @type{?proto.v1.Point} */ (
    jspb.Message.getWrapperField(this, proto.v1.Point, 4));
};


/**
 * @param {?proto.v1.Point|undefined} value
 * @return {!proto.v1.Equipment} returns this
*/
proto.v1.Equipment.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Equipment.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Point LatestLocation = 12;
 * @return {?proto.v1.Point}
 */
proto.v1.Equipment.prototype.getLatestlocation = function() {
  return /** @type{?proto.v1.Point} */ (
    jspb.Message.getWrapperField(this, proto.v1.Point, 12));
};


/**
 * @param {?proto.v1.Point|undefined} value
 * @return {!proto.v1.Equipment} returns this
*/
proto.v1.Equipment.prototype.setLatestlocation = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.clearLatestlocation = function() {
  return this.setLatestlocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Equipment.prototype.hasLatestlocation = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string Note = 10;
 * @return {string}
 */
proto.v1.Equipment.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string PublicNote = 9;
 * @return {string}
 */
proto.v1.Equipment.prototype.getPublicnote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.setPublicnote = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated DocRef Docs = 6;
 * @return {!Array<!proto.v1.DocRef>}
 */
proto.v1.Equipment.prototype.getDocsList = function() {
  return /** @type{!Array<!proto.v1.DocRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.DocRef, 6));
};


/**
 * @param {!Array<!proto.v1.DocRef>} value
 * @return {!proto.v1.Equipment} returns this
*/
proto.v1.Equipment.prototype.setDocsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.v1.DocRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.DocRef}
 */
proto.v1.Equipment.prototype.addDocs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.v1.DocRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Equipment} returns this
 */
proto.v1.Equipment.prototype.clearDocsList = function() {
  return this.setDocsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MetricsQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MetricsQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MetricsQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MetricsQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    equipment: (f = msg.getEquipment()) && proto.v1.EquipmentRef.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MetricsQuery}
 */
proto.v1.MetricsQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MetricsQuery;
  return proto.v1.MetricsQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MetricsQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MetricsQuery}
 */
proto.v1.MetricsQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.EquipmentRef;
      reader.readMessage(value,proto.v1.EquipmentRef.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetric(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromtime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MetricsQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MetricsQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MetricsQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MetricsQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.EquipmentRef.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional EquipmentRef Equipment = 1;
 * @return {?proto.v1.EquipmentRef}
 */
proto.v1.MetricsQuery.prototype.getEquipment = function() {
  return /** @type{?proto.v1.EquipmentRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentRef, 1));
};


/**
 * @param {?proto.v1.EquipmentRef|undefined} value
 * @return {!proto.v1.MetricsQuery} returns this
*/
proto.v1.MetricsQuery.prototype.setEquipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MetricsQuery} returns this
 */
proto.v1.MetricsQuery.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MetricsQuery.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Metric = 2;
 * @return {string}
 */
proto.v1.MetricsQuery.prototype.getMetric = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MetricsQuery} returns this
 */
proto.v1.MetricsQuery.prototype.setMetric = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 FromTime = 3;
 * @return {number}
 */
proto.v1.MetricsQuery.prototype.getFromtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.MetricsQuery} returns this
 */
proto.v1.MetricsQuery.prototype.setFromtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 ToTime = 4;
 * @return {number}
 */
proto.v1.MetricsQuery.prototype.getTotime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.MetricsQuery} returns this
 */
proto.v1.MetricsQuery.prototype.setTotime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MetricValue.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MetricValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MetricValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MetricValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    metric: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MetricValue}
 */
proto.v1.MetricValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MetricValue;
  return proto.v1.MetricValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MetricValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MetricValue}
 */
proto.v1.MetricValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetric(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MetricValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MetricValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MetricValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MetricValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetric();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string Metric = 1;
 * @return {string}
 */
proto.v1.MetricValue.prototype.getMetric = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MetricValue} returns this
 */
proto.v1.MetricValue.prototype.setMetric = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedAt = 2;
 * @return {number}
 */
proto.v1.MetricValue.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.MetricValue} returns this
 */
proto.v1.MetricValue.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double Value = 3;
 * @return {number}
 */
proto.v1.MetricValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.v1.MetricValue} returns this
 */
proto.v1.MetricValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.MetricValueList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MetricValueList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MetricValueList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MetricValueList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MetricValueList.toObject = function(includeInstance, msg) {
  var f, obj = {
    metric: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.MetricValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MetricValueList}
 */
proto.v1.MetricValueList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MetricValueList;
  return proto.v1.MetricValueList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MetricValueList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MetricValueList}
 */
proto.v1.MetricValueList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetric(value);
      break;
    case 2:
      var value = new proto.v1.MetricValue;
      reader.readMessage(value,proto.v1.MetricValue.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MetricValueList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MetricValueList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MetricValueList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MetricValueList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetric();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.v1.MetricValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Metric = 1;
 * @return {string}
 */
proto.v1.MetricValueList.prototype.getMetric = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MetricValueList} returns this
 */
proto.v1.MetricValueList.prototype.setMetric = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MetricValue Items = 2;
 * @return {!Array<!proto.v1.MetricValue>}
 */
proto.v1.MetricValueList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.MetricValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.MetricValue, 2));
};


/**
 * @param {!Array<!proto.v1.MetricValue>} value
 * @return {!proto.v1.MetricValueList} returns this
*/
proto.v1.MetricValueList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.v1.MetricValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.MetricValue}
 */
proto.v1.MetricValueList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.v1.MetricValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.MetricValueList} returns this
 */
proto.v1.MetricValueList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.DocRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.DocRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.DocRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.DocRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.DocRef}
 */
proto.v1.DocRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.DocRef;
  return proto.v1.DocRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.DocRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.DocRef}
 */
proto.v1.DocRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.DocRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.DocRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.DocRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.DocRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string URL = 1;
 * @return {string}
 */
proto.v1.DocRef.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.DocRef} returns this
 */
proto.v1.DocRef.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Title = 2;
 * @return {string}
 */
proto.v1.DocRef.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.DocRef} returns this
 */
proto.v1.DocRef.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.CheckList.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CheckList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CheckList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CheckList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckList.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    type: (f = msg.getType()) && proto.v1.EquipmentTypeRef.toObject(includeInstance, f),
    checksList: jspb.Message.toObjectList(msg.getChecksList(),
    proto.v1.Check.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CheckList}
 */
proto.v1.CheckList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CheckList;
  return proto.v1.CheckList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CheckList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CheckList}
 */
proto.v1.CheckList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 5:
      var value = new proto.v1.EquipmentTypeRef;
      reader.readMessage(value,proto.v1.EquipmentTypeRef.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 3:
      var value = new proto.v1.Check;
      reader.readMessage(value,proto.v1.Check.deserializeBinaryFromReader);
      msg.addChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CheckList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CheckList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CheckList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.EquipmentTypeRef.serializeBinaryToWriter
    );
  }
  f = message.getChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.v1.Check.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.CheckList.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.CheckList} returns this
 */
proto.v1.CheckList.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.CheckList.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.CheckList} returns this
 */
proto.v1.CheckList.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrgRef Org = 4;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.CheckList.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 4));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.CheckList} returns this
*/
proto.v1.CheckList.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckList} returns this
 */
proto.v1.CheckList.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckList.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EquipmentTypeRef Type = 5;
 * @return {?proto.v1.EquipmentTypeRef}
 */
proto.v1.CheckList.prototype.getType = function() {
  return /** @type{?proto.v1.EquipmentTypeRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentTypeRef, 5));
};


/**
 * @param {?proto.v1.EquipmentTypeRef|undefined} value
 * @return {!proto.v1.CheckList} returns this
*/
proto.v1.CheckList.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckList} returns this
 */
proto.v1.CheckList.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckList.prototype.hasType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Check Checks = 3;
 * @return {!Array<!proto.v1.Check>}
 */
proto.v1.CheckList.prototype.getChecksList = function() {
  return /** @type{!Array<!proto.v1.Check>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Check, 3));
};


/**
 * @param {!Array<!proto.v1.Check>} value
 * @return {!proto.v1.CheckList} returns this
*/
proto.v1.CheckList.prototype.setChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.v1.Check=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Check}
 */
proto.v1.CheckList.prototype.addChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.v1.Check, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.CheckList} returns this
 */
proto.v1.CheckList.prototype.clearChecksList = function() {
  return this.setChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Check.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Check.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Check} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Check.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metric: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Check}
 */
proto.v1.Check.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Check;
  return proto.v1.Check.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Check} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Check}
 */
proto.v1.Check.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetric(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Check.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Check.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Check} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Check.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetric();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.v1.Check.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Check} returns this
 */
proto.v1.Check.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Metric = 2;
 * @return {string}
 */
proto.v1.Check.prototype.getMetric = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Check} returns this
 */
proto.v1.Check.prototype.setMetric = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Ronde.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Ronde.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Ronde.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Ronde} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Ronde.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    defaultuser: (f = msg.getDefaultuser()) && proto.v1.UserRef.toObject(includeInstance, f),
    todoList: jspb.Message.toObjectList(msg.getTodoList(),
    proto.v1.Analyse.toObject, includeInstance),
    scheduleList: jspb.Message.toObjectList(msg.getScheduleList(),
    proto.v1.ScheduleItem.toObject, includeInstance),
    suspended: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Ronde}
 */
proto.v1.Ronde.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Ronde;
  return proto.v1.Ronde.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Ronde} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Ronde}
 */
proto.v1.Ronde.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 5:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setDefaultuser(value);
      break;
    case 3:
      var value = new proto.v1.Analyse;
      reader.readMessage(value,proto.v1.Analyse.deserializeBinaryFromReader);
      msg.addTodo(value);
      break;
    case 6:
      var value = new proto.v1.ScheduleItem;
      reader.readMessage(value,proto.v1.ScheduleItem.deserializeBinaryFromReader);
      msg.addSchedule(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuspended(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Ronde.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Ronde.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Ronde} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Ronde.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getDefaultuser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getTodoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.v1.Analyse.serializeBinaryToWriter
    );
  }
  f = message.getScheduleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.v1.ScheduleItem.serializeBinaryToWriter
    );
  }
  f = message.getSuspended();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Ronde.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Ronde} returns this
 */
proto.v1.Ronde.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.Ronde.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Ronde} returns this
 */
proto.v1.Ronde.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrgRef Org = 4;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Ronde.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 4));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Ronde} returns this
*/
proto.v1.Ronde.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Ronde} returns this
 */
proto.v1.Ronde.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Ronde.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UserRef DefaultUser = 5;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Ronde.prototype.getDefaultuser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 5));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Ronde} returns this
*/
proto.v1.Ronde.prototype.setDefaultuser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Ronde} returns this
 */
proto.v1.Ronde.prototype.clearDefaultuser = function() {
  return this.setDefaultuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Ronde.prototype.hasDefaultuser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Analyse Todo = 3;
 * @return {!Array<!proto.v1.Analyse>}
 */
proto.v1.Ronde.prototype.getTodoList = function() {
  return /** @type{!Array<!proto.v1.Analyse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Analyse, 3));
};


/**
 * @param {!Array<!proto.v1.Analyse>} value
 * @return {!proto.v1.Ronde} returns this
*/
proto.v1.Ronde.prototype.setTodoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.v1.Analyse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Analyse}
 */
proto.v1.Ronde.prototype.addTodo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.v1.Analyse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Ronde} returns this
 */
proto.v1.Ronde.prototype.clearTodoList = function() {
  return this.setTodoList([]);
};


/**
 * repeated ScheduleItem Schedule = 6;
 * @return {!Array<!proto.v1.ScheduleItem>}
 */
proto.v1.Ronde.prototype.getScheduleList = function() {
  return /** @type{!Array<!proto.v1.ScheduleItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.ScheduleItem, 6));
};


/**
 * @param {!Array<!proto.v1.ScheduleItem>} value
 * @return {!proto.v1.Ronde} returns this
*/
proto.v1.Ronde.prototype.setScheduleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.v1.ScheduleItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.ScheduleItem}
 */
proto.v1.Ronde.prototype.addSchedule = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.v1.ScheduleItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Ronde} returns this
 */
proto.v1.Ronde.prototype.clearScheduleList = function() {
  return this.setScheduleList([]);
};


/**
 * optional bool Suspended = 7;
 * @return {boolean}
 */
proto.v1.Ronde.prototype.getSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.Ronde} returns this
 */
proto.v1.Ronde.prototype.setSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Analyse.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Analyse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Analyse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Analyse.toObject = function(includeInstance, msg) {
  var f, obj = {
    checklist: (f = msg.getChecklist()) && proto.v1.CheckListRef.toObject(includeInstance, f),
    equipment: (f = msg.getEquipment()) && proto.v1.EquipmentRef.toObject(includeInstance, f),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Analyse}
 */
proto.v1.Analyse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Analyse;
  return proto.v1.Analyse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Analyse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Analyse}
 */
proto.v1.Analyse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.CheckListRef;
      reader.readMessage(value,proto.v1.CheckListRef.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    case 2:
      var value = new proto.v1.EquipmentRef;
      reader.readMessage(value,proto.v1.EquipmentRef.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    case 4:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Analyse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Analyse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Analyse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Analyse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.CheckListRef.serializeBinaryToWriter
    );
  }
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.EquipmentRef.serializeBinaryToWriter
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional CheckListRef CheckList = 1;
 * @return {?proto.v1.CheckListRef}
 */
proto.v1.Analyse.prototype.getChecklist = function() {
  return /** @type{?proto.v1.CheckListRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.CheckListRef, 1));
};


/**
 * @param {?proto.v1.CheckListRef|undefined} value
 * @return {!proto.v1.Analyse} returns this
*/
proto.v1.Analyse.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Analyse} returns this
 */
proto.v1.Analyse.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Analyse.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EquipmentRef Equipment = 2;
 * @return {?proto.v1.EquipmentRef}
 */
proto.v1.Analyse.prototype.getEquipment = function() {
  return /** @type{?proto.v1.EquipmentRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentRef, 2));
};


/**
 * @param {?proto.v1.EquipmentRef|undefined} value
 * @return {!proto.v1.Analyse} returns this
*/
proto.v1.Analyse.prototype.setEquipment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Analyse} returns this
 */
proto.v1.Analyse.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Analyse.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OrgRef Org = 4;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Analyse.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 4));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Analyse} returns this
*/
proto.v1.Analyse.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Analyse} returns this
 */
proto.v1.Analyse.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Analyse.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string Label = 3;
 * @return {string}
 */
proto.v1.Analyse.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Analyse} returns this
 */
proto.v1.Analyse.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnalyseRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnalyseRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnalyseRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalyseRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    checklist: (f = msg.getChecklist()) && proto.v1.CheckListRef.toObject(includeInstance, f),
    equipment: (f = msg.getEquipment()) && proto.v1.EquipmentRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnalyseRef}
 */
proto.v1.AnalyseRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnalyseRef;
  return proto.v1.AnalyseRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnalyseRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnalyseRef}
 */
proto.v1.AnalyseRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.CheckListRef;
      reader.readMessage(value,proto.v1.CheckListRef.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    case 2:
      var value = new proto.v1.EquipmentRef;
      reader.readMessage(value,proto.v1.EquipmentRef.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnalyseRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnalyseRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnalyseRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalyseRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.CheckListRef.serializeBinaryToWriter
    );
  }
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.EquipmentRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional CheckListRef CheckList = 1;
 * @return {?proto.v1.CheckListRef}
 */
proto.v1.AnalyseRef.prototype.getChecklist = function() {
  return /** @type{?proto.v1.CheckListRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.CheckListRef, 1));
};


/**
 * @param {?proto.v1.CheckListRef|undefined} value
 * @return {!proto.v1.AnalyseRef} returns this
*/
proto.v1.AnalyseRef.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalyseRef} returns this
 */
proto.v1.AnalyseRef.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalyseRef.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EquipmentRef Equipment = 2;
 * @return {?proto.v1.EquipmentRef}
 */
proto.v1.AnalyseRef.prototype.getEquipment = function() {
  return /** @type{?proto.v1.EquipmentRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentRef, 2));
};


/**
 * @param {?proto.v1.EquipmentRef|undefined} value
 * @return {!proto.v1.AnalyseRef} returns this
*/
proto.v1.AnalyseRef.prototype.setEquipment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalyseRef} returns this
 */
proto.v1.AnalyseRef.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalyseRef.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.RondeExec.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.RondeExec.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.RondeExec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.RondeExec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeExec.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedat: jspb.Message.getFieldWithDefault(msg, 13, 0),
    ronde: (f = msg.getRonde()) && proto.v1.RondeRef.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.v1.UserRef.toObject(includeInstance, f),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    prev: (f = msg.getPrev()) && proto.v1.RondeExecRef.toObject(includeInstance, f),
    analysesdone: jspb.Message.getFieldWithDefault(msg, 8, 0),
    analysescount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    cancelled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    latest: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    analysesList: jspb.Message.toObjectList(msg.getAnalysesList(),
    proto.v1.AnalyseExec.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.RondeExec}
 */
proto.v1.RondeExec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.RondeExec;
  return proto.v1.RondeExec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.RondeExec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.RondeExec}
 */
proto.v1.RondeExec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = new proto.v1.RondeRef;
      reader.readMessage(value,proto.v1.RondeRef.deserializeBinaryFromReader);
      msg.setRonde(value);
      break;
    case 4:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 5:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 12:
      var value = new proto.v1.RondeExecRef;
      reader.readMessage(value,proto.v1.RondeExecRef.deserializeBinaryFromReader);
      msg.setPrev(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnalysesdone(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnalysescount(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCancelled(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLatest(value);
      break;
    case 6:
      var value = new proto.v1.AnalyseExec;
      reader.readMessage(value,proto.v1.AnalyseExec.deserializeBinaryFromReader);
      msg.addAnalyses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.RondeExec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.RondeExec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.RondeExec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeExec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedat();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getRonde();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.RondeRef.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getPrev();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.v1.RondeExecRef.serializeBinaryToWriter
    );
  }
  f = message.getAnalysesdone();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAnalysescount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCancelled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getLatest();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getAnalysesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.v1.AnalyseExec.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.RondeExec.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedAt = 2;
 * @return {number}
 */
proto.v1.RondeExec.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 UpdatedAt = 13;
 * @return {number}
 */
proto.v1.RondeExec.prototype.getUpdatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional RondeRef Ronde = 3;
 * @return {?proto.v1.RondeRef}
 */
proto.v1.RondeExec.prototype.getRonde = function() {
  return /** @type{?proto.v1.RondeRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeRef, 3));
};


/**
 * @param {?proto.v1.RondeRef|undefined} value
 * @return {!proto.v1.RondeExec} returns this
*/
proto.v1.RondeExec.prototype.setRonde = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.clearRonde = function() {
  return this.setRonde(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.RondeExec.prototype.hasRonde = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UserRef User = 4;
 * @return {?proto.v1.UserRef}
 */
proto.v1.RondeExec.prototype.getUser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 4));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.RondeExec} returns this
*/
proto.v1.RondeExec.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.RondeExec.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OrgRef Org = 5;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.RondeExec.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 5));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.RondeExec} returns this
*/
proto.v1.RondeExec.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.RondeExec.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RondeExecRef Prev = 12;
 * @return {?proto.v1.RondeExecRef}
 */
proto.v1.RondeExec.prototype.getPrev = function() {
  return /** @type{?proto.v1.RondeExecRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeExecRef, 12));
};


/**
 * @param {?proto.v1.RondeExecRef|undefined} value
 * @return {!proto.v1.RondeExec} returns this
*/
proto.v1.RondeExec.prototype.setPrev = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.clearPrev = function() {
  return this.setPrev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.RondeExec.prototype.hasPrev = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 AnalysesDone = 8;
 * @return {number}
 */
proto.v1.RondeExec.prototype.getAnalysesdone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.setAnalysesdone = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 AnalysesCount = 9;
 * @return {number}
 */
proto.v1.RondeExec.prototype.getAnalysescount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.setAnalysescount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool Cancelled = 10;
 * @return {boolean}
 */
proto.v1.RondeExec.prototype.getCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.setCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool Latest = 11;
 * @return {boolean}
 */
proto.v1.RondeExec.prototype.getLatest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.setLatest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated AnalyseExec Analyses = 6;
 * @return {!Array<!proto.v1.AnalyseExec>}
 */
proto.v1.RondeExec.prototype.getAnalysesList = function() {
  return /** @type{!Array<!proto.v1.AnalyseExec>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AnalyseExec, 6));
};


/**
 * @param {!Array<!proto.v1.AnalyseExec>} value
 * @return {!proto.v1.RondeExec} returns this
*/
proto.v1.RondeExec.prototype.setAnalysesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.v1.AnalyseExec=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AnalyseExec}
 */
proto.v1.RondeExec.prototype.addAnalyses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.v1.AnalyseExec, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.RondeExec} returns this
 */
proto.v1.RondeExec.prototype.clearAnalysesList = function() {
  return this.setAnalysesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.AnalyseExec.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnalyseExec.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnalyseExec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnalyseExec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalyseExec.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    analyse: (f = msg.getAnalyse()) && proto.v1.AnalyseRef.toObject(includeInstance, f),
    checklistname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    equipmentname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    rondeexec: (f = msg.getRondeexec()) && proto.v1.RondeExecRef.toObject(includeInstance, f),
    checksList: jspb.Message.toObjectList(msg.getChecksList(),
    proto.v1.CheckExec.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnalyseExec}
 */
proto.v1.AnalyseExec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnalyseExec;
  return proto.v1.AnalyseExec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnalyseExec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnalyseExec}
 */
proto.v1.AnalyseExec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 1:
      var value = new proto.v1.AnalyseRef;
      reader.readMessage(value,proto.v1.AnalyseRef.deserializeBinaryFromReader);
      msg.setAnalyse(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecklistname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipmentname(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 6:
      var value = new proto.v1.RondeExecRef;
      reader.readMessage(value,proto.v1.RondeExecRef.deserializeBinaryFromReader);
      msg.setRondeexec(value);
      break;
    case 2:
      var value = new proto.v1.CheckExec;
      reader.readMessage(value,proto.v1.CheckExec.deserializeBinaryFromReader);
      msg.addChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnalyseExec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnalyseExec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnalyseExec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalyseExec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAnalyse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.AnalyseRef.serializeBinaryToWriter
    );
  }
  f = message.getChecklistname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEquipmentname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getRondeexec();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.v1.RondeExecRef.serializeBinaryToWriter
    );
  }
  f = message.getChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.v1.CheckExec.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 CreatedAt = 5;
 * @return {number}
 */
proto.v1.AnalyseExec.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.AnalyseExec} returns this
 */
proto.v1.AnalyseExec.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional AnalyseRef Analyse = 1;
 * @return {?proto.v1.AnalyseRef}
 */
proto.v1.AnalyseExec.prototype.getAnalyse = function() {
  return /** @type{?proto.v1.AnalyseRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnalyseRef, 1));
};


/**
 * @param {?proto.v1.AnalyseRef|undefined} value
 * @return {!proto.v1.AnalyseExec} returns this
*/
proto.v1.AnalyseExec.prototype.setAnalyse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalyseExec} returns this
 */
proto.v1.AnalyseExec.prototype.clearAnalyse = function() {
  return this.setAnalyse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalyseExec.prototype.hasAnalyse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string CheckListName = 3;
 * @return {string}
 */
proto.v1.AnalyseExec.prototype.getChecklistname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnalyseExec} returns this
 */
proto.v1.AnalyseExec.prototype.setChecklistname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string EquipmentName = 4;
 * @return {string}
 */
proto.v1.AnalyseExec.prototype.getEquipmentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnalyseExec} returns this
 */
proto.v1.AnalyseExec.prototype.setEquipmentname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool Completed = 7;
 * @return {boolean}
 */
proto.v1.AnalyseExec.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.AnalyseExec} returns this
 */
proto.v1.AnalyseExec.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional RondeExecRef RondeExec = 6;
 * @return {?proto.v1.RondeExecRef}
 */
proto.v1.AnalyseExec.prototype.getRondeexec = function() {
  return /** @type{?proto.v1.RondeExecRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeExecRef, 6));
};


/**
 * @param {?proto.v1.RondeExecRef|undefined} value
 * @return {!proto.v1.AnalyseExec} returns this
*/
proto.v1.AnalyseExec.prototype.setRondeexec = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalyseExec} returns this
 */
proto.v1.AnalyseExec.prototype.clearRondeexec = function() {
  return this.setRondeexec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalyseExec.prototype.hasRondeexec = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated CheckExec Checks = 2;
 * @return {!Array<!proto.v1.CheckExec>}
 */
proto.v1.AnalyseExec.prototype.getChecksList = function() {
  return /** @type{!Array<!proto.v1.CheckExec>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.CheckExec, 2));
};


/**
 * @param {!Array<!proto.v1.CheckExec>} value
 * @return {!proto.v1.AnalyseExec} returns this
*/
proto.v1.AnalyseExec.prototype.setChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.v1.CheckExec=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.CheckExec}
 */
proto.v1.AnalyseExec.prototype.addChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.v1.CheckExec, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AnalyseExec} returns this
 */
proto.v1.AnalyseExec.prototype.clearChecksList = function() {
  return this.setChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnalyseExecRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnalyseExecRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnalyseExecRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalyseExecRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    rondeexec: (f = msg.getRondeexec()) && proto.v1.RondeExecRef.toObject(includeInstance, f),
    checklistname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    equipmentname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnalyseExecRef}
 */
proto.v1.AnalyseExecRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnalyseExecRef;
  return proto.v1.AnalyseExecRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnalyseExecRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnalyseExecRef}
 */
proto.v1.AnalyseExecRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.RondeExecRef;
      reader.readMessage(value,proto.v1.RondeExecRef.deserializeBinaryFromReader);
      msg.setRondeexec(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecklistname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipmentname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnalyseExecRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnalyseExecRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnalyseExecRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnalyseExecRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRondeexec();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.RondeExecRef.serializeBinaryToWriter
    );
  }
  f = message.getChecklistname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEquipmentname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional RondeExecRef RondeExec = 1;
 * @return {?proto.v1.RondeExecRef}
 */
proto.v1.AnalyseExecRef.prototype.getRondeexec = function() {
  return /** @type{?proto.v1.RondeExecRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeExecRef, 1));
};


/**
 * @param {?proto.v1.RondeExecRef|undefined} value
 * @return {!proto.v1.AnalyseExecRef} returns this
*/
proto.v1.AnalyseExecRef.prototype.setRondeexec = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnalyseExecRef} returns this
 */
proto.v1.AnalyseExecRef.prototype.clearRondeexec = function() {
  return this.setRondeexec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnalyseExecRef.prototype.hasRondeexec = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string CheckListName = 2;
 * @return {string}
 */
proto.v1.AnalyseExecRef.prototype.getChecklistname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnalyseExecRef} returns this
 */
proto.v1.AnalyseExecRef.prototype.setChecklistname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string EquipmentName = 3;
 * @return {string}
 */
proto.v1.AnalyseExecRef.prototype.getEquipmentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnalyseExecRef} returns this
 */
proto.v1.AnalyseExecRef.prototype.setEquipmentname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.CheckExec.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CheckExec.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CheckExec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CheckExec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckExec.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metricvalue: (f = msg.getMetricvalue()) && proto.v1.MetricValue.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 6, ""),
    anomaliesList: jspb.Message.toObjectList(msg.getAnomaliesList(),
    proto.v1.AnomalyRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CheckExec}
 */
proto.v1.CheckExec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CheckExec;
  return proto.v1.CheckExec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CheckExec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CheckExec}
 */
proto.v1.CheckExec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 2:
      var value = /** @type {!proto.v1.CheckState} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.v1.MetricValue;
      reader.readMessage(value,proto.v1.MetricValue.deserializeBinaryFromReader);
      msg.setMetricvalue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = new proto.v1.AnomalyRef;
      reader.readMessage(value,proto.v1.AnomalyRef.deserializeBinaryFromReader);
      msg.addAnomalies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CheckExec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CheckExec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CheckExec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckExec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMetricvalue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.MetricValue.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAnomaliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.v1.AnomalyRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.v1.CheckExec.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.CheckExec} returns this
 */
proto.v1.CheckExec.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgRef Org = 4;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.CheckExec.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 4));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.CheckExec} returns this
*/
proto.v1.CheckExec.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckExec} returns this
 */
proto.v1.CheckExec.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckExec.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CheckState Status = 2;
 * @return {!proto.v1.CheckState}
 */
proto.v1.CheckExec.prototype.getStatus = function() {
  return /** @type {!proto.v1.CheckState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.v1.CheckState} value
 * @return {!proto.v1.CheckExec} returns this
 */
proto.v1.CheckExec.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MetricValue MetricValue = 5;
 * @return {?proto.v1.MetricValue}
 */
proto.v1.CheckExec.prototype.getMetricvalue = function() {
  return /** @type{?proto.v1.MetricValue} */ (
    jspb.Message.getWrapperField(this, proto.v1.MetricValue, 5));
};


/**
 * @param {?proto.v1.MetricValue|undefined} value
 * @return {!proto.v1.CheckExec} returns this
*/
proto.v1.CheckExec.prototype.setMetricvalue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.CheckExec} returns this
 */
proto.v1.CheckExec.prototype.clearMetricvalue = function() {
  return this.setMetricvalue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.CheckExec.prototype.hasMetricvalue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string Comment = 6;
 * @return {string}
 */
proto.v1.CheckExec.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.CheckExec} returns this
 */
proto.v1.CheckExec.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AnomalyRef Anomalies = 3;
 * @return {!Array<!proto.v1.AnomalyRef>}
 */
proto.v1.CheckExec.prototype.getAnomaliesList = function() {
  return /** @type{!Array<!proto.v1.AnomalyRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AnomalyRef, 3));
};


/**
 * @param {!Array<!proto.v1.AnomalyRef>} value
 * @return {!proto.v1.CheckExec} returns this
*/
proto.v1.CheckExec.prototype.setAnomaliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.v1.AnomalyRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AnomalyRef}
 */
proto.v1.CheckExec.prototype.addAnomalies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.v1.AnomalyRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.CheckExec} returns this
 */
proto.v1.CheckExec.prototype.clearAnomaliesList = function() {
  return this.setAnomaliesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.v1.ScheduleItem.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.v1.ScheduleItem.FrequencyCase = {
  FREQUENCY_NOT_SET: 0,
  SINGLE: 1,
  DAILY: 2,
  WEEKLY: 3,
  MONTHLY: 4,
  YEARLY: 5
};

/**
 * @return {proto.v1.ScheduleItem.FrequencyCase}
 */
proto.v1.ScheduleItem.prototype.getFrequencyCase = function() {
  return /** @type {proto.v1.ScheduleItem.FrequencyCase} */(jspb.Message.computeOneofCase(this, proto.v1.ScheduleItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ScheduleItem.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ScheduleItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ScheduleItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    single: (f = msg.getSingle()) && proto.v1.ScheduleSingle.toObject(includeInstance, f),
    daily: (f = msg.getDaily()) && proto.v1.ScheduleDaily.toObject(includeInstance, f),
    weekly: (f = msg.getWeekly()) && proto.v1.ScheduleWeekly.toObject(includeInstance, f),
    monthly: (f = msg.getMonthly()) && proto.v1.ScheduleMonthly.toObject(includeInstance, f),
    yearly: (f = msg.getYearly()) && proto.v1.ScheduleYearly.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ScheduleItem}
 */
proto.v1.ScheduleItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ScheduleItem;
  return proto.v1.ScheduleItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ScheduleItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ScheduleItem}
 */
proto.v1.ScheduleItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.ScheduleSingle;
      reader.readMessage(value,proto.v1.ScheduleSingle.deserializeBinaryFromReader);
      msg.setSingle(value);
      break;
    case 2:
      var value = new proto.v1.ScheduleDaily;
      reader.readMessage(value,proto.v1.ScheduleDaily.deserializeBinaryFromReader);
      msg.setDaily(value);
      break;
    case 3:
      var value = new proto.v1.ScheduleWeekly;
      reader.readMessage(value,proto.v1.ScheduleWeekly.deserializeBinaryFromReader);
      msg.setWeekly(value);
      break;
    case 4:
      var value = new proto.v1.ScheduleMonthly;
      reader.readMessage(value,proto.v1.ScheduleMonthly.deserializeBinaryFromReader);
      msg.setMonthly(value);
      break;
    case 5:
      var value = new proto.v1.ScheduleYearly;
      reader.readMessage(value,proto.v1.ScheduleYearly.deserializeBinaryFromReader);
      msg.setYearly(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ScheduleItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ScheduleItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ScheduleItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSingle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.ScheduleSingle.serializeBinaryToWriter
    );
  }
  f = message.getDaily();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.ScheduleDaily.serializeBinaryToWriter
    );
  }
  f = message.getWeekly();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.ScheduleWeekly.serializeBinaryToWriter
    );
  }
  f = message.getMonthly();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.ScheduleMonthly.serializeBinaryToWriter
    );
  }
  f = message.getYearly();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.ScheduleYearly.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional ScheduleSingle Single = 1;
 * @return {?proto.v1.ScheduleSingle}
 */
proto.v1.ScheduleItem.prototype.getSingle = function() {
  return /** @type{?proto.v1.ScheduleSingle} */ (
    jspb.Message.getWrapperField(this, proto.v1.ScheduleSingle, 1));
};


/**
 * @param {?proto.v1.ScheduleSingle|undefined} value
 * @return {!proto.v1.ScheduleItem} returns this
*/
proto.v1.ScheduleItem.prototype.setSingle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.v1.ScheduleItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ScheduleItem} returns this
 */
proto.v1.ScheduleItem.prototype.clearSingle = function() {
  return this.setSingle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ScheduleItem.prototype.hasSingle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScheduleDaily Daily = 2;
 * @return {?proto.v1.ScheduleDaily}
 */
proto.v1.ScheduleItem.prototype.getDaily = function() {
  return /** @type{?proto.v1.ScheduleDaily} */ (
    jspb.Message.getWrapperField(this, proto.v1.ScheduleDaily, 2));
};


/**
 * @param {?proto.v1.ScheduleDaily|undefined} value
 * @return {!proto.v1.ScheduleItem} returns this
*/
proto.v1.ScheduleItem.prototype.setDaily = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.v1.ScheduleItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ScheduleItem} returns this
 */
proto.v1.ScheduleItem.prototype.clearDaily = function() {
  return this.setDaily(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ScheduleItem.prototype.hasDaily = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScheduleWeekly Weekly = 3;
 * @return {?proto.v1.ScheduleWeekly}
 */
proto.v1.ScheduleItem.prototype.getWeekly = function() {
  return /** @type{?proto.v1.ScheduleWeekly} */ (
    jspb.Message.getWrapperField(this, proto.v1.ScheduleWeekly, 3));
};


/**
 * @param {?proto.v1.ScheduleWeekly|undefined} value
 * @return {!proto.v1.ScheduleItem} returns this
*/
proto.v1.ScheduleItem.prototype.setWeekly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.v1.ScheduleItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ScheduleItem} returns this
 */
proto.v1.ScheduleItem.prototype.clearWeekly = function() {
  return this.setWeekly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ScheduleItem.prototype.hasWeekly = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ScheduleMonthly Monthly = 4;
 * @return {?proto.v1.ScheduleMonthly}
 */
proto.v1.ScheduleItem.prototype.getMonthly = function() {
  return /** @type{?proto.v1.ScheduleMonthly} */ (
    jspb.Message.getWrapperField(this, proto.v1.ScheduleMonthly, 4));
};


/**
 * @param {?proto.v1.ScheduleMonthly|undefined} value
 * @return {!proto.v1.ScheduleItem} returns this
*/
proto.v1.ScheduleItem.prototype.setMonthly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.v1.ScheduleItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ScheduleItem} returns this
 */
proto.v1.ScheduleItem.prototype.clearMonthly = function() {
  return this.setMonthly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ScheduleItem.prototype.hasMonthly = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ScheduleYearly Yearly = 5;
 * @return {?proto.v1.ScheduleYearly}
 */
proto.v1.ScheduleItem.prototype.getYearly = function() {
  return /** @type{?proto.v1.ScheduleYearly} */ (
    jspb.Message.getWrapperField(this, proto.v1.ScheduleYearly, 5));
};


/**
 * @param {?proto.v1.ScheduleYearly|undefined} value
 * @return {!proto.v1.ScheduleItem} returns this
*/
proto.v1.ScheduleItem.prototype.setYearly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.v1.ScheduleItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ScheduleItem} returns this
 */
proto.v1.ScheduleItem.prototype.clearYearly = function() {
  return this.setYearly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ScheduleItem.prototype.hasYearly = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string TimeZone = 6;
 * @return {string}
 */
proto.v1.ScheduleItem.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ScheduleItem} returns this
 */
proto.v1.ScheduleItem.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ScheduleSingle.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ScheduleSingle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ScheduleSingle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleSingle.toObject = function(includeInstance, msg) {
  var f, obj = {
    datetime: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ScheduleSingle}
 */
proto.v1.ScheduleSingle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ScheduleSingle;
  return proto.v1.ScheduleSingle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ScheduleSingle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ScheduleSingle}
 */
proto.v1.ScheduleSingle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ScheduleSingle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ScheduleSingle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ScheduleSingle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleSingle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatetime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 DateTime = 1;
 * @return {number}
 */
proto.v1.ScheduleSingle.prototype.getDatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleSingle} returns this
 */
proto.v1.ScheduleSingle.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ScheduleDaily.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ScheduleDaily.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ScheduleDaily} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleDaily.toObject = function(includeInstance, msg) {
  var f, obj = {
    hourmin: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ScheduleDaily}
 */
proto.v1.ScheduleDaily.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ScheduleDaily;
  return proto.v1.ScheduleDaily.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ScheduleDaily} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ScheduleDaily}
 */
proto.v1.ScheduleDaily.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHourmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ScheduleDaily.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ScheduleDaily.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ScheduleDaily} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleDaily.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHourmin();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 HourMin = 1;
 * @return {number}
 */
proto.v1.ScheduleDaily.prototype.getHourmin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleDaily} returns this
 */
proto.v1.ScheduleDaily.prototype.setHourmin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ScheduleWeekly.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ScheduleWeekly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ScheduleWeekly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleWeekly.toObject = function(includeInstance, msg) {
  var f, obj = {
    hourmin: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dayofweek: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ScheduleWeekly}
 */
proto.v1.ScheduleWeekly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ScheduleWeekly;
  return proto.v1.ScheduleWeekly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ScheduleWeekly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ScheduleWeekly}
 */
proto.v1.ScheduleWeekly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHourmin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofweek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ScheduleWeekly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ScheduleWeekly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ScheduleWeekly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleWeekly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHourmin();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDayofweek();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional uint32 HourMin = 1;
 * @return {number}
 */
proto.v1.ScheduleWeekly.prototype.getHourmin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleWeekly} returns this
 */
proto.v1.ScheduleWeekly.prototype.setHourmin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 DayOfWeek = 2;
 * @return {number}
 */
proto.v1.ScheduleWeekly.prototype.getDayofweek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleWeekly} returns this
 */
proto.v1.ScheduleWeekly.prototype.setDayofweek = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ScheduleMonthly.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ScheduleMonthly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ScheduleMonthly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleMonthly.toObject = function(includeInstance, msg) {
  var f, obj = {
    hourmin: jspb.Message.getFieldWithDefault(msg, 1, 0),
    day: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ScheduleMonthly}
 */
proto.v1.ScheduleMonthly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ScheduleMonthly;
  return proto.v1.ScheduleMonthly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ScheduleMonthly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ScheduleMonthly}
 */
proto.v1.ScheduleMonthly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHourmin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ScheduleMonthly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ScheduleMonthly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ScheduleMonthly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleMonthly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHourmin();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional uint32 HourMin = 1;
 * @return {number}
 */
proto.v1.ScheduleMonthly.prototype.getHourmin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleMonthly} returns this
 */
proto.v1.ScheduleMonthly.prototype.setHourmin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 Day = 2;
 * @return {number}
 */
proto.v1.ScheduleMonthly.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleMonthly} returns this
 */
proto.v1.ScheduleMonthly.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ScheduleYearly.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ScheduleYearly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ScheduleYearly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleYearly.toObject = function(includeInstance, msg) {
  var f, obj = {
    hourmin: jspb.Message.getFieldWithDefault(msg, 1, 0),
    day: jspb.Message.getFieldWithDefault(msg, 2, 0),
    month: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ScheduleYearly}
 */
proto.v1.ScheduleYearly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ScheduleYearly;
  return proto.v1.ScheduleYearly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ScheduleYearly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ScheduleYearly}
 */
proto.v1.ScheduleYearly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHourmin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ScheduleYearly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ScheduleYearly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ScheduleYearly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ScheduleYearly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHourmin();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional uint32 HourMin = 1;
 * @return {number}
 */
proto.v1.ScheduleYearly.prototype.getHourmin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleYearly} returns this
 */
proto.v1.ScheduleYearly.prototype.setHourmin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 Day = 2;
 * @return {number}
 */
proto.v1.ScheduleYearly.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleYearly} returns this
 */
proto.v1.ScheduleYearly.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 Month = 3;
 * @return {number}
 */
proto.v1.ScheduleYearly.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ScheduleYearly} returns this
 */
proto.v1.ScheduleYearly.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Alert.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Alert.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Alert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Alert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Alert.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.v1.UserRef.toObject(includeInstance, f),
    isemail: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isphone: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.v1.CategoryRef.toObject, includeInstance),
    zonesList: jspb.Message.toObjectList(msg.getZonesList(),
    proto.v1.ZoneRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Alert}
 */
proto.v1.Alert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Alert;
  return proto.v1.Alert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Alert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Alert}
 */
proto.v1.Alert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsemail(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsphone(value);
      break;
    case 7:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 3:
      var value = new proto.v1.CategoryRef;
      reader.readMessage(value,proto.v1.CategoryRef.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 4:
      var value = new proto.v1.ZoneRef;
      reader.readMessage(value,proto.v1.ZoneRef.deserializeBinaryFromReader);
      msg.addZones(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Alert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Alert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Alert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Alert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getIsemail();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsphone();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.v1.CategoryRef.serializeBinaryToWriter
    );
  }
  f = message.getZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.v1.ZoneRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Alert.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Alert} returns this
 */
proto.v1.Alert.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserRef User = 2;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Alert.prototype.getUser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 2));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Alert} returns this
*/
proto.v1.Alert.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Alert} returns this
 */
proto.v1.Alert.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Alert.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool IsEmail = 5;
 * @return {boolean}
 */
proto.v1.Alert.prototype.getIsemail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.Alert} returns this
 */
proto.v1.Alert.prototype.setIsemail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool IsPhone = 6;
 * @return {boolean}
 */
proto.v1.Alert.prototype.getIsphone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.Alert} returns this
 */
proto.v1.Alert.prototype.setIsphone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional OrgRef Org = 7;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Alert.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 7));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Alert} returns this
*/
proto.v1.Alert.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Alert} returns this
 */
proto.v1.Alert.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Alert.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated CategoryRef Categories = 3;
 * @return {!Array<!proto.v1.CategoryRef>}
 */
proto.v1.Alert.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.v1.CategoryRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.CategoryRef, 3));
};


/**
 * @param {!Array<!proto.v1.CategoryRef>} value
 * @return {!proto.v1.Alert} returns this
*/
proto.v1.Alert.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.v1.CategoryRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.CategoryRef}
 */
proto.v1.Alert.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.v1.CategoryRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Alert} returns this
 */
proto.v1.Alert.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated ZoneRef Zones = 4;
 * @return {!Array<!proto.v1.ZoneRef>}
 */
proto.v1.Alert.prototype.getZonesList = function() {
  return /** @type{!Array<!proto.v1.ZoneRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.ZoneRef, 4));
};


/**
 * @param {!Array<!proto.v1.ZoneRef>} value
 * @return {!proto.v1.Alert} returns this
*/
proto.v1.Alert.prototype.setZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.v1.ZoneRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.ZoneRef}
 */
proto.v1.Alert.prototype.addZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.v1.ZoneRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Alert} returns this
 */
proto.v1.Alert.prototype.clearZonesList = function() {
  return this.setZonesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Rule.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Rule.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Rule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Rule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Rule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdby: (f = msg.getCreatedby()) && proto.v1.UserRef.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.v1.AnomalyFilter.toObject(includeInstance, f),
    prio: jspb.Message.getFieldWithDefault(msg, 8, 0),
    assignto: (f = msg.getAssignto()) && proto.v1.UserRef.toObject(includeInstance, f),
    assigntoteam: (f = msg.getAssigntoteam()) && proto.v1.TeamRef.toObject(includeInstance, f),
    targetsList: jspb.Message.toObjectList(msg.getTargetsList(),
    proto.v1.AlertTarget.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Rule}
 */
proto.v1.Rule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Rule;
  return proto.v1.Rule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Rule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Rule}
 */
proto.v1.Rule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 5:
      var value = new proto.v1.AnomalyFilter;
      reader.readMessage(value,proto.v1.AnomalyFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrio(value);
      break;
    case 6:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setAssignto(value);
      break;
    case 9:
      var value = new proto.v1.TeamRef;
      reader.readMessage(value,proto.v1.TeamRef.deserializeBinaryFromReader);
      msg.setAssigntoteam(value);
      break;
    case 7:
      var value = new proto.v1.AlertTarget;
      reader.readMessage(value,proto.v1.AlertTarget.deserializeBinaryFromReader);
      msg.addTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Rule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Rule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Rule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Rule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.AnomalyFilter.serializeBinaryToWriter
    );
  }
  f = message.getPrio();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAssignto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getAssigntoteam();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.v1.TeamRef.serializeBinaryToWriter
    );
  }
  f = message.getTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.v1.AlertTarget.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Rule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserRef CreatedBy = 2;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Rule.prototype.getCreatedby = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 2));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Rule} returns this
*/
proto.v1.Rule.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Rule.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Label = 3;
 * @return {string}
 */
proto.v1.Rule.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional OrgRef Org = 4;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Rule.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 4));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Rule} returns this
*/
proto.v1.Rule.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Rule.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AnomalyFilter Filter = 5;
 * @return {?proto.v1.AnomalyFilter}
 */
proto.v1.Rule.prototype.getFilter = function() {
  return /** @type{?proto.v1.AnomalyFilter} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnomalyFilter, 5));
};


/**
 * @param {?proto.v1.AnomalyFilter|undefined} value
 * @return {!proto.v1.Rule} returns this
*/
proto.v1.Rule.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Rule.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 Prio = 8;
 * @return {number}
 */
proto.v1.Rule.prototype.getPrio = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.setPrio = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional UserRef AssignTo = 6;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Rule.prototype.getAssignto = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 6));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Rule} returns this
*/
proto.v1.Rule.prototype.setAssignto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.clearAssignto = function() {
  return this.setAssignto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Rule.prototype.hasAssignto = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TeamRef AssignToTeam = 9;
 * @return {?proto.v1.TeamRef}
 */
proto.v1.Rule.prototype.getAssigntoteam = function() {
  return /** @type{?proto.v1.TeamRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.TeamRef, 9));
};


/**
 * @param {?proto.v1.TeamRef|undefined} value
 * @return {!proto.v1.Rule} returns this
*/
proto.v1.Rule.prototype.setAssigntoteam = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.clearAssigntoteam = function() {
  return this.setAssigntoteam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Rule.prototype.hasAssigntoteam = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated AlertTarget Targets = 7;
 * @return {!Array<!proto.v1.AlertTarget>}
 */
proto.v1.Rule.prototype.getTargetsList = function() {
  return /** @type{!Array<!proto.v1.AlertTarget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AlertTarget, 7));
};


/**
 * @param {!Array<!proto.v1.AlertTarget>} value
 * @return {!proto.v1.Rule} returns this
*/
proto.v1.Rule.prototype.setTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.v1.AlertTarget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AlertTarget}
 */
proto.v1.Rule.prototype.addTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.v1.AlertTarget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Rule} returns this
 */
proto.v1.Rule.prototype.clearTargetsList = function() {
  return this.setTargetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.AlertTarget.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AlertTarget.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AlertTarget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AlertTarget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AlertTarget.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.v1.UserRef.toObject, includeInstance),
    useapp: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    useemail: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AlertTarget}
 */
proto.v1.AlertTarget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AlertTarget;
  return proto.v1.AlertTarget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AlertTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AlertTarget}
 */
proto.v1.AlertTarget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseapp(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseemail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AlertTarget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AlertTarget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AlertTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AlertTarget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getUseapp();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUseemail();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated UserRef Users = 1;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.AlertTarget.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 1));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.AlertTarget} returns this
*/
proto.v1.AlertTarget.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.AlertTarget.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AlertTarget} returns this
 */
proto.v1.AlertTarget.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional bool UseApp = 2;
 * @return {boolean}
 */
proto.v1.AlertTarget.prototype.getUseapp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.AlertTarget} returns this
 */
proto.v1.AlertTarget.prototype.setUseapp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool UseEmail = 3;
 * @return {boolean}
 */
proto.v1.AlertTarget.prototype.getUseemail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.AlertTarget} returns this
 */
proto.v1.AlertTarget.prototype.setUseemail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.NextReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.NextReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.NextReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NextReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    rev: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.NextReq}
 */
proto.v1.NextReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.NextReq;
  return proto.v1.NextReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.NextReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.NextReq}
 */
proto.v1.NextReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRev(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.NextReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.NextReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.NextReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NextReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRev();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 Rev = 1;
 * @return {number}
 */
proto.v1.NextReq.prototype.getRev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.NextReq} returns this
 */
proto.v1.NextReq.prototype.setRev = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.NextNotifList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.NextNotifList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.NextNotifList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NextNotifList.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextreq: (f = msg.getNextreq()) && proto.v1.NextReq.toObject(includeInstance, f),
    list: (f = msg.getList()) && proto.v1.NotifList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.NextNotifList}
 */
proto.v1.NextNotifList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.NextNotifList;
  return proto.v1.NextNotifList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.NextNotifList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.NextNotifList}
 */
proto.v1.NextNotifList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.NextReq;
      reader.readMessage(value,proto.v1.NextReq.deserializeBinaryFromReader);
      msg.setNextreq(value);
      break;
    case 2:
      var value = new proto.v1.NotifList;
      reader.readMessage(value,proto.v1.NotifList.deserializeBinaryFromReader);
      msg.setList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.NextNotifList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.NextNotifList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.NextNotifList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NextNotifList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextreq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.NextReq.serializeBinaryToWriter
    );
  }
  f = message.getList();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.NotifList.serializeBinaryToWriter
    );
  }
};


/**
 * optional NextReq NextReq = 1;
 * @return {?proto.v1.NextReq}
 */
proto.v1.NextNotifList.prototype.getNextreq = function() {
  return /** @type{?proto.v1.NextReq} */ (
    jspb.Message.getWrapperField(this, proto.v1.NextReq, 1));
};


/**
 * @param {?proto.v1.NextReq|undefined} value
 * @return {!proto.v1.NextNotifList} returns this
*/
proto.v1.NextNotifList.prototype.setNextreq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.NextNotifList} returns this
 */
proto.v1.NextNotifList.prototype.clearNextreq = function() {
  return this.setNextreq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.NextNotifList.prototype.hasNextreq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NotifList List = 2;
 * @return {?proto.v1.NotifList}
 */
proto.v1.NextNotifList.prototype.getList = function() {
  return /** @type{?proto.v1.NotifList} */ (
    jspb.Message.getWrapperField(this, proto.v1.NotifList, 2));
};


/**
 * @param {?proto.v1.NotifList|undefined} value
 * @return {!proto.v1.NextNotifList} returns this
*/
proto.v1.NextNotifList.prototype.setList = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.NextNotifList} returns this
 */
proto.v1.NextNotifList.prototype.clearList = function() {
  return this.setList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.NextNotifList.prototype.hasList = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Notif.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Notif.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Notif} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Notif.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    owner: (f = msg.getOwner()) && proto.v1.UserRef.toObject(includeInstance, f),
    createdat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 6, 0),
    count: jspb.Message.getFieldWithDefault(msg, 7, 0),
    anomaly: (f = msg.getAnomaly()) && proto.v1.AnomalySummary.toObject(includeInstance, f),
    analysis: (f = msg.getAnalysis()) && proto.v1.AnalyseExec.toObject(includeInstance, f),
    emergency: (f = msg.getEmergency()) && proto.v1.EmergencySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Notif}
 */
proto.v1.Notif.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Notif;
  return proto.v1.Notif.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Notif} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Notif}
 */
proto.v1.Notif.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 6:
      var value = /** @type {!proto.v1.Notif.NotifKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 4:
      var value = new proto.v1.AnomalySummary;
      reader.readMessage(value,proto.v1.AnomalySummary.deserializeBinaryFromReader);
      msg.setAnomaly(value);
      break;
    case 8:
      var value = new proto.v1.AnalyseExec;
      reader.readMessage(value,proto.v1.AnalyseExec.deserializeBinaryFromReader);
      msg.setAnalysis(value);
      break;
    case 9:
      var value = new proto.v1.EmergencySummary;
      reader.readMessage(value,proto.v1.EmergencySummary.deserializeBinaryFromReader);
      msg.setEmergency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Notif.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Notif.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Notif} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Notif.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAnomaly();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.AnomalySummary.serializeBinaryToWriter
    );
  }
  f = message.getAnalysis();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.v1.AnalyseExec.serializeBinaryToWriter
    );
  }
  f = message.getEmergency();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.v1.EmergencySummary.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.v1.Notif.NotifKind = {
  UNKNOWN: 0,
  ASSIGNED: 1,
  UNASSIGNED: 2,
  CREATORSTATECHANGED: 3,
  CREATORNEWMESSAGE: 4,
  ASSIGNEESTATECHANGED: 5,
  ASSIGNEENEWMESSAGE: 6,
  RULE: 7,
  ANALYSISASSIGNED: 8
};

/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Notif.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserRef Owner = 2;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Notif.prototype.getOwner = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 2));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Notif} returns this
*/
proto.v1.Notif.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Notif.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 CreatedAt = 5;
 * @return {number}
 */
proto.v1.Notif.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string Label = 3;
 * @return {string}
 */
proto.v1.Notif.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NotifKind Kind = 6;
 * @return {!proto.v1.Notif.NotifKind}
 */
proto.v1.Notif.prototype.getKind = function() {
  return /** @type {!proto.v1.Notif.NotifKind} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.v1.Notif.NotifKind} value
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 Count = 7;
 * @return {number}
 */
proto.v1.Notif.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional AnomalySummary Anomaly = 4;
 * @return {?proto.v1.AnomalySummary}
 */
proto.v1.Notif.prototype.getAnomaly = function() {
  return /** @type{?proto.v1.AnomalySummary} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnomalySummary, 4));
};


/**
 * @param {?proto.v1.AnomalySummary|undefined} value
 * @return {!proto.v1.Notif} returns this
*/
proto.v1.Notif.prototype.setAnomaly = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.clearAnomaly = function() {
  return this.setAnomaly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Notif.prototype.hasAnomaly = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AnalyseExec Analysis = 8;
 * @return {?proto.v1.AnalyseExec}
 */
proto.v1.Notif.prototype.getAnalysis = function() {
  return /** @type{?proto.v1.AnalyseExec} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnalyseExec, 8));
};


/**
 * @param {?proto.v1.AnalyseExec|undefined} value
 * @return {!proto.v1.Notif} returns this
*/
proto.v1.Notif.prototype.setAnalysis = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.clearAnalysis = function() {
  return this.setAnalysis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Notif.prototype.hasAnalysis = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EmergencySummary Emergency = 9;
 * @return {?proto.v1.EmergencySummary}
 */
proto.v1.Notif.prototype.getEmergency = function() {
  return /** @type{?proto.v1.EmergencySummary} */ (
    jspb.Message.getWrapperField(this, proto.v1.EmergencySummary, 9));
};


/**
 * @param {?proto.v1.EmergencySummary|undefined} value
 * @return {!proto.v1.Notif} returns this
*/
proto.v1.Notif.prototype.setEmergency = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Notif} returns this
 */
proto.v1.Notif.prototype.clearEmergency = function() {
  return this.setEmergency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Notif.prototype.hasEmergency = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.NotifRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.NotifRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.NotifRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NotifRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.NotifRef}
 */
proto.v1.NotifRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.NotifRef;
  return proto.v1.NotifRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.NotifRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.NotifRef}
 */
proto.v1.NotifRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.NotifRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.NotifRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.NotifRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NotifRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.NotifRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.NotifRef} returns this
 */
proto.v1.NotifRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.NotifList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.NotifList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.NotifList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.NotifList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NotifList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Notif.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.NotifList}
 */
proto.v1.NotifList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.NotifList;
  return proto.v1.NotifList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.NotifList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.NotifList}
 */
proto.v1.NotifList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Notif;
      reader.readMessage(value,proto.v1.Notif.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.NotifList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.NotifList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.NotifList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.NotifList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Notif.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Notif Items = 1;
 * @return {!Array<!proto.v1.Notif>}
 */
proto.v1.NotifList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Notif>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Notif, 1));
};


/**
 * @param {!Array<!proto.v1.Notif>} value
 * @return {!proto.v1.NotifList} returns this
*/
proto.v1.NotifList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Notif=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Notif}
 */
proto.v1.NotifList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Notif, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.NotifList} returns this
 */
proto.v1.NotifList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Emergency.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Emergency.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Emergency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Emergency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Emergency.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && proto.v1.UserRef.toObject(includeInstance, f),
    createdat: jspb.Message.getFieldWithDefault(msg, 4, 0),
    summary: (f = msg.getSummary()) && proto.v1.EmergencySummary.toObject(includeInstance, f),
    targetsList: jspb.Message.toObjectList(msg.getTargetsList(),
    proto.v1.EmergencyTarget.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Emergency}
 */
proto.v1.Emergency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Emergency;
  return proto.v1.Emergency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Emergency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Emergency}
 */
proto.v1.Emergency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 3:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new proto.v1.EmergencySummary;
      reader.readMessage(value,proto.v1.EmergencySummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 6:
      var value = new proto.v1.EmergencyTarget;
      reader.readMessage(value,proto.v1.EmergencyTarget.deserializeBinaryFromReader);
      msg.addTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Emergency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Emergency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Emergency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Emergency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.EmergencySummary.serializeBinaryToWriter
    );
  }
  f = message.getTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.v1.EmergencyTarget.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Emergency.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Emergency} returns this
 */
proto.v1.Emergency.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgRef Org = 2;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Emergency.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 2));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Emergency} returns this
*/
proto.v1.Emergency.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Emergency} returns this
 */
proto.v1.Emergency.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Emergency.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserRef CreatedBy = 3;
 * @return {?proto.v1.UserRef}
 */
proto.v1.Emergency.prototype.getCreatedby = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 3));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.Emergency} returns this
*/
proto.v1.Emergency.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Emergency} returns this
 */
proto.v1.Emergency.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Emergency.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 CreatedAt = 4;
 * @return {number}
 */
proto.v1.Emergency.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Emergency} returns this
 */
proto.v1.Emergency.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional EmergencySummary Summary = 5;
 * @return {?proto.v1.EmergencySummary}
 */
proto.v1.Emergency.prototype.getSummary = function() {
  return /** @type{?proto.v1.EmergencySummary} */ (
    jspb.Message.getWrapperField(this, proto.v1.EmergencySummary, 5));
};


/**
 * @param {?proto.v1.EmergencySummary|undefined} value
 * @return {!proto.v1.Emergency} returns this
*/
proto.v1.Emergency.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Emergency} returns this
 */
proto.v1.Emergency.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Emergency.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated EmergencyTarget Targets = 6;
 * @return {!Array<!proto.v1.EmergencyTarget>}
 */
proto.v1.Emergency.prototype.getTargetsList = function() {
  return /** @type{!Array<!proto.v1.EmergencyTarget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.EmergencyTarget, 6));
};


/**
 * @param {!Array<!proto.v1.EmergencyTarget>} value
 * @return {!proto.v1.Emergency} returns this
*/
proto.v1.Emergency.prototype.setTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.v1.EmergencyTarget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.EmergencyTarget}
 */
proto.v1.Emergency.prototype.addTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.v1.EmergencyTarget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Emergency} returns this
 */
proto.v1.Emergency.prototype.clearTargetsList = function() {
  return this.setTargetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EmergencyTarget.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EmergencyTarget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EmergencyTarget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencyTarget.toObject = function(includeInstance, msg) {
  var f, obj = {
    zone: (f = msg.getZone()) && proto.v1.ZoneRef.toObject(includeInstance, f),
    team: (f = msg.getTeam()) && proto.v1.TeamRef.toObject(includeInstance, f),
    org: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EmergencyTarget}
 */
proto.v1.EmergencyTarget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EmergencyTarget;
  return proto.v1.EmergencyTarget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EmergencyTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EmergencyTarget}
 */
proto.v1.EmergencyTarget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.ZoneRef;
      reader.readMessage(value,proto.v1.ZoneRef.deserializeBinaryFromReader);
      msg.setZone(value);
      break;
    case 2:
      var value = new proto.v1.TeamRef;
      reader.readMessage(value,proto.v1.TeamRef.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EmergencyTarget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EmergencyTarget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EmergencyTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencyTarget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZone();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.ZoneRef.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.TeamRef.serializeBinaryToWriter
    );
  }
  f = message.getOrg();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional ZoneRef Zone = 1;
 * @return {?proto.v1.ZoneRef}
 */
proto.v1.EmergencyTarget.prototype.getZone = function() {
  return /** @type{?proto.v1.ZoneRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.ZoneRef, 1));
};


/**
 * @param {?proto.v1.ZoneRef|undefined} value
 * @return {!proto.v1.EmergencyTarget} returns this
*/
proto.v1.EmergencyTarget.prototype.setZone = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.EmergencyTarget} returns this
 */
proto.v1.EmergencyTarget.prototype.clearZone = function() {
  return this.setZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.EmergencyTarget.prototype.hasZone = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TeamRef Team = 2;
 * @return {?proto.v1.TeamRef}
 */
proto.v1.EmergencyTarget.prototype.getTeam = function() {
  return /** @type{?proto.v1.TeamRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.TeamRef, 2));
};


/**
 * @param {?proto.v1.TeamRef|undefined} value
 * @return {!proto.v1.EmergencyTarget} returns this
*/
proto.v1.EmergencyTarget.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.EmergencyTarget} returns this
 */
proto.v1.EmergencyTarget.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.EmergencyTarget.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool Org = 3;
 * @return {boolean}
 */
proto.v1.EmergencyTarget.prototype.getOrg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.v1.EmergencyTarget} returns this
 */
proto.v1.EmergencyTarget.prototype.setOrg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EmergencySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EmergencySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EmergencySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emitter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    details: jspb.Message.getFieldWithDefault(msg, 4, ""),
    link: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EmergencySummary}
 */
proto.v1.EmergencySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EmergencySummary;
  return proto.v1.EmergencySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EmergencySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EmergencySummary}
 */
proto.v1.EmergencySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmitter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EmergencySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EmergencySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EmergencySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmitter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDetails();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.EmergencySummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EmergencySummary} returns this
 */
proto.v1.EmergencySummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Emitter = 2;
 * @return {string}
 */
proto.v1.EmergencySummary.prototype.getEmitter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EmergencySummary} returns this
 */
proto.v1.EmergencySummary.prototype.setEmitter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Label = 3;
 * @return {string}
 */
proto.v1.EmergencySummary.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EmergencySummary} returns this
 */
proto.v1.EmergencySummary.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Details = 4;
 * @return {string}
 */
proto.v1.EmergencySummary.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EmergencySummary} returns this
 */
proto.v1.EmergencySummary.prototype.setDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Link = 5;
 * @return {string}
 */
proto.v1.EmergencySummary.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EmergencySummary} returns this
 */
proto.v1.EmergencySummary.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.EmergencySummaryList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EmergencySummaryList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EmergencySummaryList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EmergencySummaryList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencySummaryList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.EmergencySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EmergencySummaryList}
 */
proto.v1.EmergencySummaryList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EmergencySummaryList;
  return proto.v1.EmergencySummaryList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EmergencySummaryList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EmergencySummaryList}
 */
proto.v1.EmergencySummaryList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.EmergencySummary;
      reader.readMessage(value,proto.v1.EmergencySummary.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EmergencySummaryList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EmergencySummaryList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EmergencySummaryList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencySummaryList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.EmergencySummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EmergencySummary Items = 1;
 * @return {!Array<!proto.v1.EmergencySummary>}
 */
proto.v1.EmergencySummaryList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.EmergencySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.EmergencySummary, 1));
};


/**
 * @param {!Array<!proto.v1.EmergencySummary>} value
 * @return {!proto.v1.EmergencySummaryList} returns this
*/
proto.v1.EmergencySummaryList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.EmergencySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.EmergencySummary}
 */
proto.v1.EmergencySummaryList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.EmergencySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.EmergencySummaryList} returns this
 */
proto.v1.EmergencySummaryList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.MapElement.repeatedFields_ = [10];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.v1.MapElement.oneofGroups_ = [[8,9,11]];

/**
 * @enum {number}
 */
proto.v1.MapElement.DetailsCase = {
  DETAILS_NOT_SET: 0,
  ANOMALY: 8,
  EQUIPMENT: 9,
  ANALYSEEXEC: 11
};

/**
 * @return {proto.v1.MapElement.DetailsCase}
 */
proto.v1.MapElement.prototype.getDetailsCase = function() {
  return /** @type {proto.v1.MapElement.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.v1.MapElement.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MapElement.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MapElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MapElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && proto.v1.UserRef.toObject(includeInstance, f),
    createdat: jspb.Message.getFieldWithDefault(msg, 4, 0),
    position: (f = msg.getPosition()) && proto.v1.GPSPoint.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 6, ""),
    image: (f = msg.getImage()) && proto.v1.PhotoRef.toObject(includeInstance, f),
    polygonList: jspb.Message.toObjectList(msg.getPolygonList(),
    proto.v1.Point.toObject, includeInstance),
    anomaly: (f = msg.getAnomaly()) && proto.v1.AnomalySummary.toObject(includeInstance, f),
    equipment: (f = msg.getEquipment()) && proto.v1.EquipmentRef.toObject(includeInstance, f),
    analyseexec: (f = msg.getAnalyseexec()) && proto.v1.AnalyseExecRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MapElement}
 */
proto.v1.MapElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MapElement;
  return proto.v1.MapElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MapElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MapElement}
 */
proto.v1.MapElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 3:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new proto.v1.GPSPoint;
      reader.readMessage(value,proto.v1.GPSPoint.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 7:
      var value = new proto.v1.PhotoRef;
      reader.readMessage(value,proto.v1.PhotoRef.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 10:
      var value = new proto.v1.Point;
      reader.readMessage(value,proto.v1.Point.deserializeBinaryFromReader);
      msg.addPolygon(value);
      break;
    case 8:
      var value = new proto.v1.AnomalySummary;
      reader.readMessage(value,proto.v1.AnomalySummary.deserializeBinaryFromReader);
      msg.setAnomaly(value);
      break;
    case 9:
      var value = new proto.v1.EquipmentRef;
      reader.readMessage(value,proto.v1.EquipmentRef.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    case 11:
      var value = new proto.v1.AnalyseExecRef;
      reader.readMessage(value,proto.v1.AnalyseExecRef.deserializeBinaryFromReader);
      msg.setAnalyseexec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MapElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MapElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MapElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.GPSPoint.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.v1.PhotoRef.serializeBinaryToWriter
    );
  }
  f = message.getPolygonList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.v1.Point.serializeBinaryToWriter
    );
  }
  f = message.getAnomaly();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.v1.AnomalySummary.serializeBinaryToWriter
    );
  }
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.v1.EquipmentRef.serializeBinaryToWriter
    );
  }
  f = message.getAnalyseexec();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.v1.AnalyseExecRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.MapElement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgRef Org = 2;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.MapElement.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 2));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.MapElement} returns this
*/
proto.v1.MapElement.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElement.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserRef CreatedBy = 3;
 * @return {?proto.v1.UserRef}
 */
proto.v1.MapElement.prototype.getCreatedby = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 3));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.MapElement} returns this
*/
proto.v1.MapElement.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElement.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 CreatedAt = 4;
 * @return {number}
 */
proto.v1.MapElement.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional GPSPoint Position = 5;
 * @return {?proto.v1.GPSPoint}
 */
proto.v1.MapElement.prototype.getPosition = function() {
  return /** @type{?proto.v1.GPSPoint} */ (
    jspb.Message.getWrapperField(this, proto.v1.GPSPoint, 5));
};


/**
 * @param {?proto.v1.GPSPoint|undefined} value
 * @return {!proto.v1.MapElement} returns this
*/
proto.v1.MapElement.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElement.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string Label = 6;
 * @return {string}
 */
proto.v1.MapElement.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PhotoRef Image = 7;
 * @return {?proto.v1.PhotoRef}
 */
proto.v1.MapElement.prototype.getImage = function() {
  return /** @type{?proto.v1.PhotoRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.PhotoRef, 7));
};


/**
 * @param {?proto.v1.PhotoRef|undefined} value
 * @return {!proto.v1.MapElement} returns this
*/
proto.v1.MapElement.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElement.prototype.hasImage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Point Polygon = 10;
 * @return {!Array<!proto.v1.Point>}
 */
proto.v1.MapElement.prototype.getPolygonList = function() {
  return /** @type{!Array<!proto.v1.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Point, 10));
};


/**
 * @param {!Array<!proto.v1.Point>} value
 * @return {!proto.v1.MapElement} returns this
*/
proto.v1.MapElement.prototype.setPolygonList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.v1.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Point}
 */
proto.v1.MapElement.prototype.addPolygon = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.v1.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.clearPolygonList = function() {
  return this.setPolygonList([]);
};


/**
 * optional AnomalySummary Anomaly = 8;
 * @return {?proto.v1.AnomalySummary}
 */
proto.v1.MapElement.prototype.getAnomaly = function() {
  return /** @type{?proto.v1.AnomalySummary} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnomalySummary, 8));
};


/**
 * @param {?proto.v1.AnomalySummary|undefined} value
 * @return {!proto.v1.MapElement} returns this
*/
proto.v1.MapElement.prototype.setAnomaly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.v1.MapElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.clearAnomaly = function() {
  return this.setAnomaly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElement.prototype.hasAnomaly = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EquipmentRef Equipment = 9;
 * @return {?proto.v1.EquipmentRef}
 */
proto.v1.MapElement.prototype.getEquipment = function() {
  return /** @type{?proto.v1.EquipmentRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.EquipmentRef, 9));
};


/**
 * @param {?proto.v1.EquipmentRef|undefined} value
 * @return {!proto.v1.MapElement} returns this
*/
proto.v1.MapElement.prototype.setEquipment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.v1.MapElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElement.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional AnalyseExecRef AnalyseExec = 11;
 * @return {?proto.v1.AnalyseExecRef}
 */
proto.v1.MapElement.prototype.getAnalyseexec = function() {
  return /** @type{?proto.v1.AnalyseExecRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnalyseExecRef, 11));
};


/**
 * @param {?proto.v1.AnalyseExecRef|undefined} value
 * @return {!proto.v1.MapElement} returns this
*/
proto.v1.MapElement.prototype.setAnalyseexec = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.v1.MapElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElement} returns this
 */
proto.v1.MapElement.prototype.clearAnalyseexec = function() {
  return this.setAnalyseexec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElement.prototype.hasAnalyseexec = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.WatchReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.WatchReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.WatchReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.WatchReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    rev: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.WatchReq}
 */
proto.v1.WatchReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.WatchReq;
  return proto.v1.WatchReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.WatchReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.WatchReq}
 */
proto.v1.WatchReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRev(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.WatchReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.WatchReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.WatchReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.WatchReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRev();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 Rev = 1;
 * @return {number}
 */
proto.v1.WatchReq.prototype.getRev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.WatchReq} returns this
 */
proto.v1.WatchReq.prototype.setRev = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MapElementChange.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MapElementChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MapElementChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapElementChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    next: (f = msg.getNext()) && proto.v1.NextReq.toObject(includeInstance, f),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: (f = msg.getValue()) && proto.v1.MapElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MapElementChange}
 */
proto.v1.MapElementChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MapElementChange;
  return proto.v1.MapElementChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MapElementChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MapElementChange}
 */
proto.v1.MapElementChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.NextReq;
      reader.readMessage(value,proto.v1.NextReq.deserializeBinaryFromReader);
      msg.setNext(value);
      break;
    case 2:
      var value = /** @type {!proto.v1.ChangeKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new proto.v1.MapElement;
      reader.readMessage(value,proto.v1.MapElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MapElementChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MapElementChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MapElementChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapElementChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.NextReq.serializeBinaryToWriter
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.MapElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional NextReq Next = 1;
 * @return {?proto.v1.NextReq}
 */
proto.v1.MapElementChange.prototype.getNext = function() {
  return /** @type{?proto.v1.NextReq} */ (
    jspb.Message.getWrapperField(this, proto.v1.NextReq, 1));
};


/**
 * @param {?proto.v1.NextReq|undefined} value
 * @return {!proto.v1.MapElementChange} returns this
*/
proto.v1.MapElementChange.prototype.setNext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElementChange} returns this
 */
proto.v1.MapElementChange.prototype.clearNext = function() {
  return this.setNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElementChange.prototype.hasNext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChangeKind Kind = 2;
 * @return {!proto.v1.ChangeKind}
 */
proto.v1.MapElementChange.prototype.getKind = function() {
  return /** @type {!proto.v1.ChangeKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.v1.ChangeKind} value
 * @return {!proto.v1.MapElementChange} returns this
 */
proto.v1.MapElementChange.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string ID = 3;
 * @return {string}
 */
proto.v1.MapElementChange.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MapElementChange} returns this
 */
proto.v1.MapElementChange.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MapElement Value = 4;
 * @return {?proto.v1.MapElement}
 */
proto.v1.MapElementChange.prototype.getValue = function() {
  return /** @type{?proto.v1.MapElement} */ (
    jspb.Message.getWrapperField(this, proto.v1.MapElement, 4));
};


/**
 * @param {?proto.v1.MapElement|undefined} value
 * @return {!proto.v1.MapElementChange} returns this
*/
proto.v1.MapElementChange.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapElementChange} returns this
 */
proto.v1.MapElementChange.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapElementChange.prototype.hasValue = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.MapIcon.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MapIcon.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MapIcon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MapIcon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapIcon.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    image: (f = msg.getImage()) && proto.v1.PhotoRef.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MapIcon}
 */
proto.v1.MapIcon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MapIcon;
  return proto.v1.MapIcon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MapIcon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MapIcon}
 */
proto.v1.MapIcon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.v1.PhotoRef;
      reader.readMessage(value,proto.v1.PhotoRef.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MapIcon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MapIcon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MapIcon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapIcon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.PhotoRef.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.MapIcon.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MapIcon} returns this
 */
proto.v1.MapIcon.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.v1.MapIcon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MapIcon} returns this
 */
proto.v1.MapIcon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PhotoRef Image = 3;
 * @return {?proto.v1.PhotoRef}
 */
proto.v1.MapIcon.prototype.getImage = function() {
  return /** @type{?proto.v1.PhotoRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.PhotoRef, 3));
};


/**
 * @param {?proto.v1.PhotoRef|undefined} value
 * @return {!proto.v1.MapIcon} returns this
*/
proto.v1.MapIcon.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.MapIcon} returns this
 */
proto.v1.MapIcon.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.MapIcon.prototype.hasImage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string Tags = 4;
 * @return {!Array<string>}
 */
proto.v1.MapIcon.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.v1.MapIcon} returns this
 */
proto.v1.MapIcon.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.v1.MapIcon} returns this
 */
proto.v1.MapIcon.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.MapIcon} returns this
 */
proto.v1.MapIcon.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.DashboardReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.DashboardReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.DashboardReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.DashboardReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    end: jspb.Message.getFieldWithDefault(msg, 2, 0),
    team: (f = msg.getTeam()) && proto.v1.TeamRef.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.v1.UserRef.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.DashboardReq}
 */
proto.v1.DashboardReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.DashboardReq;
  return proto.v1.DashboardReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.DashboardReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.DashboardReq}
 */
proto.v1.DashboardReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnd(value);
      break;
    case 3:
      var value = new proto.v1.TeamRef;
      reader.readMessage(value,proto.v1.TeamRef.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    case 4:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.DashboardReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.DashboardReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.DashboardReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.DashboardReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.TeamRef.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 Start = 1;
 * @return {number}
 */
proto.v1.DashboardReq.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.DashboardReq} returns this
 */
proto.v1.DashboardReq.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 End = 2;
 * @return {number}
 */
proto.v1.DashboardReq.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.DashboardReq} returns this
 */
proto.v1.DashboardReq.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TeamRef Team = 3;
 * @return {?proto.v1.TeamRef}
 */
proto.v1.DashboardReq.prototype.getTeam = function() {
  return /** @type{?proto.v1.TeamRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.TeamRef, 3));
};


/**
 * @param {?proto.v1.TeamRef|undefined} value
 * @return {!proto.v1.DashboardReq} returns this
*/
proto.v1.DashboardReq.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.DashboardReq} returns this
 */
proto.v1.DashboardReq.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.DashboardReq.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UserRef User = 4;
 * @return {?proto.v1.UserRef}
 */
proto.v1.DashboardReq.prototype.getUser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 4));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.DashboardReq} returns this
*/
proto.v1.DashboardReq.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.DashboardReq} returns this
 */
proto.v1.DashboardReq.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.DashboardReq.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string TimeZone = 5;
 * @return {string}
 */
proto.v1.DashboardReq.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.DashboardReq} returns this
 */
proto.v1.DashboardReq.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Dashboard.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Dashboard.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Dashboard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Dashboard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Dashboard.toObject = function(includeInstance, msg) {
  var f, obj = {
    analyseexecsopen: jspb.Message.getFieldWithDefault(msg, 1, 0),
    latestanomaliesList: jspb.Message.toObjectList(msg.getLatestanomaliesList(),
    proto.v1.AnomalyRef.toObject, includeInstance),
    anomaliescurrentstateList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    handlingsweekList: jspb.Message.toObjectList(msg.getHandlingsweekList(),
    proto.v1.DayCounts.toObject, includeInstance),
    resolution: (f = msg.getResolution()) && proto.v1.SLAStats.toObject(includeInstance, f),
    rangestats: (f = msg.getRangestats()) && proto.v1.Stats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Dashboard}
 */
proto.v1.Dashboard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Dashboard;
  return proto.v1.Dashboard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Dashboard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Dashboard}
 */
proto.v1.Dashboard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnalyseexecsopen(value);
      break;
    case 2:
      var value = new proto.v1.AnomalyRef;
      reader.readMessage(value,proto.v1.AnomalyRef.deserializeBinaryFromReader);
      msg.addLatestanomalies(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAnomaliescurrentstate(values[i]);
      }
      break;
    case 4:
      var value = new proto.v1.DayCounts;
      reader.readMessage(value,proto.v1.DayCounts.deserializeBinaryFromReader);
      msg.addHandlingsweek(value);
      break;
    case 5:
      var value = new proto.v1.SLAStats;
      reader.readMessage(value,proto.v1.SLAStats.deserializeBinaryFromReader);
      msg.setResolution(value);
      break;
    case 6:
      var value = new proto.v1.Stats;
      reader.readMessage(value,proto.v1.Stats.deserializeBinaryFromReader);
      msg.setRangestats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Dashboard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Dashboard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Dashboard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Dashboard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnalyseexecsopen();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLatestanomaliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.v1.AnomalyRef.serializeBinaryToWriter
    );
  }
  f = message.getAnomaliescurrentstateList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getHandlingsweekList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.v1.DayCounts.serializeBinaryToWriter
    );
  }
  f = message.getResolution();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.SLAStats.serializeBinaryToWriter
    );
  }
  f = message.getRangestats();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.v1.Stats.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 AnalyseExecsOpen = 1;
 * @return {number}
 */
proto.v1.Dashboard.prototype.getAnalyseexecsopen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Dashboard} returns this
 */
proto.v1.Dashboard.prototype.setAnalyseexecsopen = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated AnomalyRef LatestAnomalies = 2;
 * @return {!Array<!proto.v1.AnomalyRef>}
 */
proto.v1.Dashboard.prototype.getLatestanomaliesList = function() {
  return /** @type{!Array<!proto.v1.AnomalyRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AnomalyRef, 2));
};


/**
 * @param {!Array<!proto.v1.AnomalyRef>} value
 * @return {!proto.v1.Dashboard} returns this
*/
proto.v1.Dashboard.prototype.setLatestanomaliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.v1.AnomalyRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AnomalyRef}
 */
proto.v1.Dashboard.prototype.addLatestanomalies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.v1.AnomalyRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Dashboard} returns this
 */
proto.v1.Dashboard.prototype.clearLatestanomaliesList = function() {
  return this.setLatestanomaliesList([]);
};


/**
 * repeated int32 AnomaliesCurrentState = 3;
 * @return {!Array<number>}
 */
proto.v1.Dashboard.prototype.getAnomaliescurrentstateList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.v1.Dashboard} returns this
 */
proto.v1.Dashboard.prototype.setAnomaliescurrentstateList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.v1.Dashboard} returns this
 */
proto.v1.Dashboard.prototype.addAnomaliescurrentstate = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Dashboard} returns this
 */
proto.v1.Dashboard.prototype.clearAnomaliescurrentstateList = function() {
  return this.setAnomaliescurrentstateList([]);
};


/**
 * repeated DayCounts HandlingsWeek = 4;
 * @return {!Array<!proto.v1.DayCounts>}
 */
proto.v1.Dashboard.prototype.getHandlingsweekList = function() {
  return /** @type{!Array<!proto.v1.DayCounts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.DayCounts, 4));
};


/**
 * @param {!Array<!proto.v1.DayCounts>} value
 * @return {!proto.v1.Dashboard} returns this
*/
proto.v1.Dashboard.prototype.setHandlingsweekList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.v1.DayCounts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.DayCounts}
 */
proto.v1.Dashboard.prototype.addHandlingsweek = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.v1.DayCounts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Dashboard} returns this
 */
proto.v1.Dashboard.prototype.clearHandlingsweekList = function() {
  return this.setHandlingsweekList([]);
};


/**
 * optional SLAStats Resolution = 5;
 * @return {?proto.v1.SLAStats}
 */
proto.v1.Dashboard.prototype.getResolution = function() {
  return /** @type{?proto.v1.SLAStats} */ (
    jspb.Message.getWrapperField(this, proto.v1.SLAStats, 5));
};


/**
 * @param {?proto.v1.SLAStats|undefined} value
 * @return {!proto.v1.Dashboard} returns this
*/
proto.v1.Dashboard.prototype.setResolution = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Dashboard} returns this
 */
proto.v1.Dashboard.prototype.clearResolution = function() {
  return this.setResolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Dashboard.prototype.hasResolution = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Stats RangeStats = 6;
 * @return {?proto.v1.Stats}
 */
proto.v1.Dashboard.prototype.getRangestats = function() {
  return /** @type{?proto.v1.Stats} */ (
    jspb.Message.getWrapperField(this, proto.v1.Stats, 6));
};


/**
 * @param {?proto.v1.Stats|undefined} value
 * @return {!proto.v1.Dashboard} returns this
*/
proto.v1.Dashboard.prototype.setRangestats = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Dashboard} returns this
 */
proto.v1.Dashboard.prototype.clearRangestats = function() {
  return this.setRangestats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Dashboard.prototype.hasRangestats = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.SLAStats.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.SLAStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.SLAStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.SLAStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    week: (f = msg.getWeek()) && proto.v1.Stats.toObject(includeInstance, f),
    month: (f = msg.getMonth()) && proto.v1.Stats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.SLAStats}
 */
proto.v1.SLAStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.SLAStats;
  return proto.v1.SLAStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.SLAStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.SLAStats}
 */
proto.v1.SLAStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Stats;
      reader.readMessage(value,proto.v1.Stats.deserializeBinaryFromReader);
      msg.setWeek(value);
      break;
    case 2:
      var value = new proto.v1.Stats;
      reader.readMessage(value,proto.v1.Stats.deserializeBinaryFromReader);
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.SLAStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.SLAStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.SLAStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.SLAStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeek();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.Stats.serializeBinaryToWriter
    );
  }
  f = message.getMonth();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.Stats.serializeBinaryToWriter
    );
  }
};


/**
 * optional Stats Week = 1;
 * @return {?proto.v1.Stats}
 */
proto.v1.SLAStats.prototype.getWeek = function() {
  return /** @type{?proto.v1.Stats} */ (
    jspb.Message.getWrapperField(this, proto.v1.Stats, 1));
};


/**
 * @param {?proto.v1.Stats|undefined} value
 * @return {!proto.v1.SLAStats} returns this
*/
proto.v1.SLAStats.prototype.setWeek = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.SLAStats} returns this
 */
proto.v1.SLAStats.prototype.clearWeek = function() {
  return this.setWeek(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.SLAStats.prototype.hasWeek = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Stats Month = 2;
 * @return {?proto.v1.Stats}
 */
proto.v1.SLAStats.prototype.getMonth = function() {
  return /** @type{?proto.v1.Stats} */ (
    jspb.Message.getWrapperField(this, proto.v1.Stats, 2));
};


/**
 * @param {?proto.v1.Stats|undefined} value
 * @return {!proto.v1.SLAStats} returns this
*/
proto.v1.SLAStats.prototype.setMonth = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.SLAStats} returns this
 */
proto.v1.SLAStats.prototype.clearMonth = function() {
  return this.setMonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.SLAStats.prototype.hasMonth = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.DayCounts.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.DayCounts.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.DayCounts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.DayCounts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.DayCounts.toObject = function(includeInstance, msg) {
  var f, obj = {
    countsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.DayCounts}
 */
proto.v1.DayCounts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.DayCounts;
  return proto.v1.DayCounts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.DayCounts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.DayCounts}
 */
proto.v1.DayCounts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCounts(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.DayCounts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.DayCounts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.DayCounts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.DayCounts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 Counts = 1;
 * @return {!Array<number>}
 */
proto.v1.DayCounts.prototype.getCountsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.v1.DayCounts} returns this
 */
proto.v1.DayCounts.prototype.setCountsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.v1.DayCounts} returns this
 */
proto.v1.DayCounts.prototype.addCounts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.DayCounts} returns this
 */
proto.v1.DayCounts.prototype.clearCountsList = function() {
  return this.setCountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Stats.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Stats.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Stats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Stats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Stats.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    avg: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    histogramList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Stats}
 */
proto.v1.Stats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Stats;
  return proto.v1.Stats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Stats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Stats}
 */
proto.v1.Stats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMax(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvg(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHistogram(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Stats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Stats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Stats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Stats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAvg();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHistogramList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
};


/**
 * optional double Min = 1;
 * @return {number}
 */
proto.v1.Stats.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Stats} returns this
 */
proto.v1.Stats.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double Max = 2;
 * @return {number}
 */
proto.v1.Stats.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Stats} returns this
 */
proto.v1.Stats.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double Avg = 3;
 * @return {number}
 */
proto.v1.Stats.prototype.getAvg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.v1.Stats} returns this
 */
proto.v1.Stats.prototype.setAvg = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated double Histogram = 4;
 * @return {!Array<number>}
 */
proto.v1.Stats.prototype.getHistogramList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.v1.Stats} returns this
 */
proto.v1.Stats.prototype.setHistogramList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.v1.Stats} returns this
 */
proto.v1.Stats.prototype.addHistogram = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Stats} returns this
 */
proto.v1.Stats.prototype.clearHistogramList = function() {
  return this.setHistogramList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.TodoList.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.TodoList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.TodoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.TodoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    worker: (f = msg.getWorker()) && proto.v1.UserRef.toObject(includeInstance, f),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && proto.v1.TodoListSource.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.TodoItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.TodoList}
 */
proto.v1.TodoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.TodoList;
  return proto.v1.TodoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.TodoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.TodoList}
 */
proto.v1.TodoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setWorker(value);
      break;
    case 3:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 4:
      var value = new proto.v1.TodoListSource;
      reader.readMessage(value,proto.v1.TodoListSource.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 5:
      var value = new proto.v1.TodoItem;
      reader.readMessage(value,proto.v1.TodoItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.TodoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.TodoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.TodoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.TodoListSource.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.v1.TodoItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.TodoList.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.TodoList} returns this
 */
proto.v1.TodoList.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserRef Worker = 2;
 * @return {?proto.v1.UserRef}
 */
proto.v1.TodoList.prototype.getWorker = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 2));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.TodoList} returns this
*/
proto.v1.TodoList.prototype.setWorker = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TodoList} returns this
 */
proto.v1.TodoList.prototype.clearWorker = function() {
  return this.setWorker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TodoList.prototype.hasWorker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OrgRef Org = 3;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.TodoList.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 3));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.TodoList} returns this
*/
proto.v1.TodoList.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TodoList} returns this
 */
proto.v1.TodoList.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TodoList.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TodoListSource CreatedBy = 4;
 * @return {?proto.v1.TodoListSource}
 */
proto.v1.TodoList.prototype.getCreatedby = function() {
  return /** @type{?proto.v1.TodoListSource} */ (
    jspb.Message.getWrapperField(this, proto.v1.TodoListSource, 4));
};


/**
 * @param {?proto.v1.TodoListSource|undefined} value
 * @return {!proto.v1.TodoList} returns this
*/
proto.v1.TodoList.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TodoList} returns this
 */
proto.v1.TodoList.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TodoList.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated TodoItem Items = 5;
 * @return {!Array<!proto.v1.TodoItem>}
 */
proto.v1.TodoList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.TodoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.TodoItem, 5));
};


/**
 * @param {!Array<!proto.v1.TodoItem>} value
 * @return {!proto.v1.TodoList} returns this
*/
proto.v1.TodoList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.v1.TodoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.TodoItem}
 */
proto.v1.TodoList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.v1.TodoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.TodoList} returns this
 */
proto.v1.TodoList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.TodoItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.TodoItem.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.TodoItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.TodoItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    analyseexec: (f = msg.getAnalyseexec()) && proto.v1.AnalyseExec.toObject(includeInstance, f),
    anomaly: (f = msg.getAnomaly()) && proto.v1.AnomalySummary.toObject(includeInstance, f),
    notesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.TodoItem}
 */
proto.v1.TodoItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.TodoItem;
  return proto.v1.TodoItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.TodoItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.TodoItem}
 */
proto.v1.TodoItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.AnalyseExec;
      reader.readMessage(value,proto.v1.AnalyseExec.deserializeBinaryFromReader);
      msg.setAnalyseexec(value);
      break;
    case 2:
      var value = new proto.v1.AnomalySummary;
      reader.readMessage(value,proto.v1.AnomalySummary.deserializeBinaryFromReader);
      msg.setAnomaly(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.TodoItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.TodoItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.TodoItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnalyseexec();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.AnalyseExec.serializeBinaryToWriter
    );
  }
  f = message.getAnomaly();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.AnomalySummary.serializeBinaryToWriter
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional AnalyseExec AnalyseExec = 1;
 * @return {?proto.v1.AnalyseExec}
 */
proto.v1.TodoItem.prototype.getAnalyseexec = function() {
  return /** @type{?proto.v1.AnalyseExec} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnalyseExec, 1));
};


/**
 * @param {?proto.v1.AnalyseExec|undefined} value
 * @return {!proto.v1.TodoItem} returns this
*/
proto.v1.TodoItem.prototype.setAnalyseexec = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TodoItem} returns this
 */
proto.v1.TodoItem.prototype.clearAnalyseexec = function() {
  return this.setAnalyseexec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TodoItem.prototype.hasAnalyseexec = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnomalySummary Anomaly = 2;
 * @return {?proto.v1.AnomalySummary}
 */
proto.v1.TodoItem.prototype.getAnomaly = function() {
  return /** @type{?proto.v1.AnomalySummary} */ (
    jspb.Message.getWrapperField(this, proto.v1.AnomalySummary, 2));
};


/**
 * @param {?proto.v1.AnomalySummary|undefined} value
 * @return {!proto.v1.TodoItem} returns this
*/
proto.v1.TodoItem.prototype.setAnomaly = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TodoItem} returns this
 */
proto.v1.TodoItem.prototype.clearAnomaly = function() {
  return this.setAnomaly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TodoItem.prototype.hasAnomaly = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string Notes = 3;
 * @return {!Array<string>}
 */
proto.v1.TodoItem.prototype.getNotesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.v1.TodoItem} returns this
 */
proto.v1.TodoItem.prototype.setNotesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.v1.TodoItem} returns this
 */
proto.v1.TodoItem.prototype.addNotes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.TodoItem} returns this
 */
proto.v1.TodoItem.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.TodoListSource.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.TodoListSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.TodoListSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoListSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.v1.UserRef.toObject(includeInstance, f),
    ronde: (f = msg.getRonde()) && proto.v1.RondeRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.TodoListSource}
 */
proto.v1.TodoListSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.TodoListSource;
  return proto.v1.TodoListSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.TodoListSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.TodoListSource}
 */
proto.v1.TodoListSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.v1.RondeRef;
      reader.readMessage(value,proto.v1.RondeRef.deserializeBinaryFromReader);
      msg.setRonde(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.TodoListSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.TodoListSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.TodoListSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoListSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getRonde();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.RondeRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserRef User = 1;
 * @return {?proto.v1.UserRef}
 */
proto.v1.TodoListSource.prototype.getUser = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 1));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.TodoListSource} returns this
*/
proto.v1.TodoListSource.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TodoListSource} returns this
 */
proto.v1.TodoListSource.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TodoListSource.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RondeRef Ronde = 2;
 * @return {?proto.v1.RondeRef}
 */
proto.v1.TodoListSource.prototype.getRonde = function() {
  return /** @type{?proto.v1.RondeRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.RondeRef, 2));
};


/**
 * @param {?proto.v1.RondeRef|undefined} value
 * @return {!proto.v1.TodoListSource} returns this
*/
proto.v1.TodoListSource.prototype.setRonde = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.TodoListSource} returns this
 */
proto.v1.TodoListSource.prototype.clearRonde = function() {
  return this.setRonde(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.TodoListSource.prototype.hasRonde = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    labelsMap: (f = msg.getLabelsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Meta}
 */
proto.v1.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Meta;
  return proto.v1.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Meta}
 */
proto.v1.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = msg.getLabelsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLabelsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string OrgID = 1;
 * @return {string}
 */
proto.v1.Meta.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Meta} returns this
 */
proto.v1.Meta.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ID = 2;
 * @return {string}
 */
proto.v1.Meta.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Meta} returns this
 */
proto.v1.Meta.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.v1.Meta.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Meta} returns this
 */
proto.v1.Meta.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> Labels = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.v1.Meta.prototype.getLabelsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.v1.Meta} returns this
 */
proto.v1.Meta.prototype.clearLabelsMap = function() {
  this.getLabelsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Ref.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Ref.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Ref} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Ref.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Ref}
 */
proto.v1.Ref.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Ref;
  return proto.v1.Ref.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Ref} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Ref}
 */
proto.v1.Ref.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Ref.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Ref.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Ref} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Ref.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string OrgID = 1;
 * @return {string}
 */
proto.v1.Ref.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Ref} returns this
 */
proto.v1.Ref.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ID = 2;
 * @return {string}
 */
proto.v1.Ref.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Ref} returns this
 */
proto.v1.Ref.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.v1.Ref.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Ref} returns this
 */
proto.v1.Ref.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.GroupRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.GroupRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.GroupRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.GroupRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.GroupRef}
 */
proto.v1.GroupRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.GroupRef;
  return proto.v1.GroupRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.GroupRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.GroupRef}
 */
proto.v1.GroupRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.GroupRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.GroupRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.GroupRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.GroupRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string OrgID = 1;
 * @return {string}
 */
proto.v1.GroupRef.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.GroupRef} returns this
 */
proto.v1.GroupRef.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ID = 2;
 * @return {string}
 */
proto.v1.GroupRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.GroupRef} returns this
 */
proto.v1.GroupRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Label = 3;
 * @return {string}
 */
proto.v1.GroupRef.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.GroupRef} returns this
 */
proto.v1.GroupRef.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Group.repeatedFields_ = [4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.v1.Meta.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentsList: jspb.Message.toObjectList(msg.getParentsList(),
    proto.v1.GroupRef.toObject, includeInstance),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.v1.GroupRef.toObject, includeInstance),
    adminsList: jspb.Message.toObjectList(msg.getAdminsList(),
    proto.v1.UserRef.toObject, includeInstance),
    managersList: jspb.Message.toObjectList(msg.getManagersList(),
    proto.v1.UserRef.toObject, includeInstance),
    actorsList: jspb.Message.toObjectList(msg.getActorsList(),
    proto.v1.UserRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Group}
 */
proto.v1.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Group;
  return proto.v1.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Group}
 */
proto.v1.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Meta;
      reader.readMessage(value,proto.v1.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = new proto.v1.GroupRef;
      reader.readMessage(value,proto.v1.GroupRef.deserializeBinaryFromReader);
      msg.addParents(value);
      break;
    case 5:
      var value = new proto.v1.GroupRef;
      reader.readMessage(value,proto.v1.GroupRef.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 6:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addAdmins(value);
      break;
    case 7:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addManagers(value);
      break;
    case 8:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addActors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.Meta.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.v1.GroupRef.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.v1.GroupRef.serializeBinaryToWriter
    );
  }
  f = message.getAdminsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getManagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getActorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta Meta = 1;
 * @return {?proto.v1.Meta}
 */
proto.v1.Group.prototype.getMeta = function() {
  return /** @type{?proto.v1.Meta} */ (
    jspb.Message.getWrapperField(this, proto.v1.Meta, 1));
};


/**
 * @param {?proto.v1.Meta|undefined} value
 * @return {!proto.v1.Group} returns this
*/
proto.v1.Group.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Group} returns this
 */
proto.v1.Group.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Group.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Label = 2;
 * @return {string}
 */
proto.v1.Group.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Group} returns this
 */
proto.v1.Group.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated GroupRef Parents = 4;
 * @return {!Array<!proto.v1.GroupRef>}
 */
proto.v1.Group.prototype.getParentsList = function() {
  return /** @type{!Array<!proto.v1.GroupRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.GroupRef, 4));
};


/**
 * @param {!Array<!proto.v1.GroupRef>} value
 * @return {!proto.v1.Group} returns this
*/
proto.v1.Group.prototype.setParentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.v1.GroupRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.GroupRef}
 */
proto.v1.Group.prototype.addParents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.v1.GroupRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Group} returns this
 */
proto.v1.Group.prototype.clearParentsList = function() {
  return this.setParentsList([]);
};


/**
 * repeated GroupRef Children = 5;
 * @return {!Array<!proto.v1.GroupRef>}
 */
proto.v1.Group.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.v1.GroupRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.GroupRef, 5));
};


/**
 * @param {!Array<!proto.v1.GroupRef>} value
 * @return {!proto.v1.Group} returns this
*/
proto.v1.Group.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.v1.GroupRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.GroupRef}
 */
proto.v1.Group.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.v1.GroupRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Group} returns this
 */
proto.v1.Group.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * repeated UserRef Admins = 6;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.Group.prototype.getAdminsList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 6));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.Group} returns this
*/
proto.v1.Group.prototype.setAdminsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.Group.prototype.addAdmins = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Group} returns this
 */
proto.v1.Group.prototype.clearAdminsList = function() {
  return this.setAdminsList([]);
};


/**
 * repeated UserRef Managers = 7;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.Group.prototype.getManagersList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 7));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.Group} returns this
*/
proto.v1.Group.prototype.setManagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.Group.prototype.addManagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Group} returns this
 */
proto.v1.Group.prototype.clearManagersList = function() {
  return this.setManagersList([]);
};


/**
 * repeated UserRef Actors = 8;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.Group.prototype.getActorsList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 8));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.Group} returns this
*/
proto.v1.Group.prototype.setActorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.Group.prototype.addActors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Group} returns this
 */
proto.v1.Group.prototype.clearActorsList = function() {
  return this.setActorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.GroupList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.GroupList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.GroupList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.GroupList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.GroupList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.GroupList}
 */
proto.v1.GroupList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.GroupList;
  return proto.v1.GroupList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.GroupList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.GroupList}
 */
proto.v1.GroupList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Group;
      reader.readMessage(value,proto.v1.Group.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.GroupList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.GroupList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.GroupList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.GroupList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Group.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Group Items = 1;
 * @return {!Array<!proto.v1.Group>}
 */
proto.v1.GroupList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Group, 1));
};


/**
 * @param {!Array<!proto.v1.Group>} value
 * @return {!proto.v1.GroupList} returns this
*/
proto.v1.GroupList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Group}
 */
proto.v1.GroupList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.GroupList} returns this
 */
proto.v1.GroupList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.UserProfile.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.UserProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.UserProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.UserProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.v1.Meta.toObject(includeInstance, f),
    info: (f = msg.getInfo()) && proto.v1.UserInfo.toObject(includeInstance, f),
    orgsList: jspb.Message.toObjectList(msg.getOrgsList(),
    proto.v1.Org.toObject, includeInstance),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.v1.GroupRef.toObject, includeInstance),
    badgesList: jspb.Message.toObjectList(msg.getBadgesList(),
    proto.v1.Badge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.UserProfile}
 */
proto.v1.UserProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.UserProfile;
  return proto.v1.UserProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.UserProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.UserProfile}
 */
proto.v1.UserProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Meta;
      reader.readMessage(value,proto.v1.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.v1.UserInfo;
      reader.readMessage(value,proto.v1.UserInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 3:
      var value = new proto.v1.Org;
      reader.readMessage(value,proto.v1.Org.deserializeBinaryFromReader);
      msg.addOrgs(value);
      break;
    case 4:
      var value = new proto.v1.GroupRef;
      reader.readMessage(value,proto.v1.GroupRef.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 5:
      var value = new proto.v1.Badge;
      reader.readMessage(value,proto.v1.Badge.deserializeBinaryFromReader);
      msg.addBadges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.UserProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.UserProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.UserProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.Meta.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getOrgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.v1.Org.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.v1.GroupRef.serializeBinaryToWriter
    );
  }
  f = message.getBadgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.v1.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta Meta = 1;
 * @return {?proto.v1.Meta}
 */
proto.v1.UserProfile.prototype.getMeta = function() {
  return /** @type{?proto.v1.Meta} */ (
    jspb.Message.getWrapperField(this, proto.v1.Meta, 1));
};


/**
 * @param {?proto.v1.Meta|undefined} value
 * @return {!proto.v1.UserProfile} returns this
*/
proto.v1.UserProfile.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.UserProfile} returns this
 */
proto.v1.UserProfile.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.UserProfile.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserInfo Info = 2;
 * @return {?proto.v1.UserInfo}
 */
proto.v1.UserProfile.prototype.getInfo = function() {
  return /** @type{?proto.v1.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserInfo, 2));
};


/**
 * @param {?proto.v1.UserInfo|undefined} value
 * @return {!proto.v1.UserProfile} returns this
*/
proto.v1.UserProfile.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.UserProfile} returns this
 */
proto.v1.UserProfile.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.UserProfile.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Org Orgs = 3;
 * @return {!Array<!proto.v1.Org>}
 */
proto.v1.UserProfile.prototype.getOrgsList = function() {
  return /** @type{!Array<!proto.v1.Org>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Org, 3));
};


/**
 * @param {!Array<!proto.v1.Org>} value
 * @return {!proto.v1.UserProfile} returns this
*/
proto.v1.UserProfile.prototype.setOrgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.v1.Org=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Org}
 */
proto.v1.UserProfile.prototype.addOrgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.v1.Org, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.UserProfile} returns this
 */
proto.v1.UserProfile.prototype.clearOrgsList = function() {
  return this.setOrgsList([]);
};


/**
 * repeated GroupRef Groups = 4;
 * @return {!Array<!proto.v1.GroupRef>}
 */
proto.v1.UserProfile.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.v1.GroupRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.GroupRef, 4));
};


/**
 * @param {!Array<!proto.v1.GroupRef>} value
 * @return {!proto.v1.UserProfile} returns this
*/
proto.v1.UserProfile.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.v1.GroupRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.GroupRef}
 */
proto.v1.UserProfile.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.v1.GroupRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.UserProfile} returns this
 */
proto.v1.UserProfile.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * repeated Badge Badges = 5;
 * @return {!Array<!proto.v1.Badge>}
 */
proto.v1.UserProfile.prototype.getBadgesList = function() {
  return /** @type{!Array<!proto.v1.Badge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Badge, 5));
};


/**
 * @param {!Array<!proto.v1.Badge>} value
 * @return {!proto.v1.UserProfile} returns this
*/
proto.v1.UserProfile.prototype.setBadgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.v1.Badge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Badge}
 */
proto.v1.UserProfile.prototype.addBadges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.v1.Badge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.UserProfile} returns this
 */
proto.v1.UserProfile.prototype.clearBadgesList = function() {
  return this.setBadgesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoneprefix: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.UserInfo}
 */
proto.v1.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.UserInfo;
  return proto.v1.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.UserInfo}
 */
proto.v1.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneprefix(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoneprefix();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.v1.UserInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UserInfo} returns this
 */
proto.v1.UserInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Email = 2;
 * @return {string}
 */
proto.v1.UserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UserInfo} returns this
 */
proto.v1.UserInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string PhonePrefix = 5;
 * @return {string}
 */
proto.v1.UserInfo.prototype.getPhoneprefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UserInfo} returns this
 */
proto.v1.UserInfo.prototype.setPhoneprefix = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Phone = 3;
 * @return {string}
 */
proto.v1.UserInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UserInfo} returns this
 */
proto.v1.UserInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Avatar = 4;
 * @return {string}
 */
proto.v1.UserInfo.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.UserInfo} returns this
 */
proto.v1.UserInfo.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Badge.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Badge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Badge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Badge.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.v1.Meta.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Badge}
 */
proto.v1.Badge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Badge;
  return proto.v1.Badge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Badge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Badge}
 */
proto.v1.Badge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Meta;
      reader.readMessage(value,proto.v1.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Badge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Badge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Badge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Badge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.Meta.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Meta Meta = 1;
 * @return {?proto.v1.Meta}
 */
proto.v1.Badge.prototype.getMeta = function() {
  return /** @type{?proto.v1.Meta} */ (
    jspb.Message.getWrapperField(this, proto.v1.Meta, 1));
};


/**
 * @param {?proto.v1.Meta|undefined} value
 * @return {!proto.v1.Badge} returns this
*/
proto.v1.Badge.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Badge} returns this
 */
proto.v1.Badge.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Badge.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Label = 2;
 * @return {string}
 */
proto.v1.Badge.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Badge} returns this
 */
proto.v1.Badge.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.SocialReactions.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.SocialReactions.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.SocialReactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.SocialReactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.SocialReactions.toObject = function(includeInstance, msg) {
  var f, obj = {
    likeList: jspb.Message.toObjectList(msg.getLikeList(),
    proto.v1.UserRef.toObject, includeInstance),
    dislikeList: jspb.Message.toObjectList(msg.getDislikeList(),
    proto.v1.UserRef.toObject, includeInstance),
    heartList: jspb.Message.toObjectList(msg.getHeartList(),
    proto.v1.UserRef.toObject, includeInstance),
    confusedList: jspb.Message.toObjectList(msg.getConfusedList(),
    proto.v1.UserRef.toObject, includeInstance),
    hoorayList: jspb.Message.toObjectList(msg.getHoorayList(),
    proto.v1.UserRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.SocialReactions}
 */
proto.v1.SocialReactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.SocialReactions;
  return proto.v1.SocialReactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.SocialReactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.SocialReactions}
 */
proto.v1.SocialReactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addLike(value);
      break;
    case 2:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addDislike(value);
      break;
    case 3:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addHeart(value);
      break;
    case 4:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addConfused(value);
      break;
    case 5:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addHooray(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.SocialReactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.SocialReactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.SocialReactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.SocialReactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLikeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getDislikeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getHeartList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getConfusedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getHoorayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserRef Like = 1;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.SocialReactions.prototype.getLikeList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 1));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.SocialReactions} returns this
*/
proto.v1.SocialReactions.prototype.setLikeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.SocialReactions.prototype.addLike = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.SocialReactions} returns this
 */
proto.v1.SocialReactions.prototype.clearLikeList = function() {
  return this.setLikeList([]);
};


/**
 * repeated UserRef Dislike = 2;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.SocialReactions.prototype.getDislikeList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 2));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.SocialReactions} returns this
*/
proto.v1.SocialReactions.prototype.setDislikeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.SocialReactions.prototype.addDislike = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.SocialReactions} returns this
 */
proto.v1.SocialReactions.prototype.clearDislikeList = function() {
  return this.setDislikeList([]);
};


/**
 * repeated UserRef Heart = 3;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.SocialReactions.prototype.getHeartList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 3));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.SocialReactions} returns this
*/
proto.v1.SocialReactions.prototype.setHeartList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.SocialReactions.prototype.addHeart = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.SocialReactions} returns this
 */
proto.v1.SocialReactions.prototype.clearHeartList = function() {
  return this.setHeartList([]);
};


/**
 * repeated UserRef Confused = 4;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.SocialReactions.prototype.getConfusedList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 4));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.SocialReactions} returns this
*/
proto.v1.SocialReactions.prototype.setConfusedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.SocialReactions.prototype.addConfused = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.SocialReactions} returns this
 */
proto.v1.SocialReactions.prototype.clearConfusedList = function() {
  return this.setConfusedList([]);
};


/**
 * repeated UserRef Hooray = 5;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.SocialReactions.prototype.getHoorayList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 5));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.SocialReactions} returns this
*/
proto.v1.SocialReactions.prototype.setHoorayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.SocialReactions.prototype.addHooray = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.SocialReactions} returns this
 */
proto.v1.SocialReactions.prototype.clearHoorayList = function() {
  return this.setHoorayList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ChatSubject.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ChatSubject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ChatSubject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatSubject.toObject = function(includeInstance, msg) {
  var f, obj = {
    set: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ChatSubject}
 */
proto.v1.ChatSubject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ChatSubject;
  return proto.v1.ChatSubject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ChatSubject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ChatSubject}
 */
proto.v1.ChatSubject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.v1.ChatSubject.SetRef} */ (reader.readEnum());
      msg.setSet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ChatSubject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ChatSubject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ChatSubject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatSubject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSet();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.v1.ChatSubject.SetRef = {
  TASK: 0
};

/**
 * optional SetRef Set = 1;
 * @return {!proto.v1.ChatSubject.SetRef}
 */
proto.v1.ChatSubject.prototype.getSet = function() {
  return /** @type {!proto.v1.ChatSubject.SetRef} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.v1.ChatSubject.SetRef} value
 * @return {!proto.v1.ChatSubject} returns this
 */
proto.v1.ChatSubject.prototype.setSet = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string ID = 2;
 * @return {string}
 */
proto.v1.ChatSubject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ChatSubject} returns this
 */
proto.v1.ChatSubject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ChatMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ChatMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ChatMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    author: (f = msg.getAuthor()) && proto.v1.UserRef.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reactions: (f = msg.getReactions()) && proto.v1.SocialReactions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ChatMessage}
 */
proto.v1.ChatMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ChatMessage;
  return proto.v1.ChatMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ChatMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ChatMessage}
 */
proto.v1.ChatMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.v1.SocialReactions;
      reader.readMessage(value,proto.v1.SocialReactions.deserializeBinaryFromReader);
      msg.setReactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ChatMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ChatMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ChatMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReactions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.v1.SocialReactions.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 ID = 1;
 * @return {number}
 */
proto.v1.ChatMessage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ChatMessage} returns this
 */
proto.v1.ChatMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UserRef Author = 2;
 * @return {?proto.v1.UserRef}
 */
proto.v1.ChatMessage.prototype.getAuthor = function() {
  return /** @type{?proto.v1.UserRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.UserRef, 2));
};


/**
 * @param {?proto.v1.UserRef|undefined} value
 * @return {!proto.v1.ChatMessage} returns this
*/
proto.v1.ChatMessage.prototype.setAuthor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ChatMessage} returns this
 */
proto.v1.ChatMessage.prototype.clearAuthor = function() {
  return this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ChatMessage.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Message = 3;
 * @return {string}
 */
proto.v1.ChatMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ChatMessage} returns this
 */
proto.v1.ChatMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SocialReactions Reactions = 4;
 * @return {?proto.v1.SocialReactions}
 */
proto.v1.ChatMessage.prototype.getReactions = function() {
  return /** @type{?proto.v1.SocialReactions} */ (
    jspb.Message.getWrapperField(this, proto.v1.SocialReactions, 4));
};


/**
 * @param {?proto.v1.SocialReactions|undefined} value
 * @return {!proto.v1.ChatMessage} returns this
*/
proto.v1.ChatMessage.prototype.setReactions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ChatMessage} returns this
 */
proto.v1.ChatMessage.prototype.clearReactions = function() {
  return this.setReactions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ChatMessage.prototype.hasReactions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ChatMessageRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ChatMessageRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ChatMessageRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatMessageRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: (f = msg.getSubject()) && proto.v1.ChatSubject.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ChatMessageRef}
 */
proto.v1.ChatMessageRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ChatMessageRef;
  return proto.v1.ChatMessageRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ChatMessageRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ChatMessageRef}
 */
proto.v1.ChatMessageRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.ChatSubject;
      reader.readMessage(value,proto.v1.ChatSubject.deserializeBinaryFromReader);
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ChatMessageRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ChatMessageRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ChatMessageRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatMessageRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.ChatSubject.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional ChatSubject Subject = 1;
 * @return {?proto.v1.ChatSubject}
 */
proto.v1.ChatMessageRef.prototype.getSubject = function() {
  return /** @type{?proto.v1.ChatSubject} */ (
    jspb.Message.getWrapperField(this, proto.v1.ChatSubject, 1));
};


/**
 * @param {?proto.v1.ChatSubject|undefined} value
 * @return {!proto.v1.ChatMessageRef} returns this
*/
proto.v1.ChatMessageRef.prototype.setSubject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ChatMessageRef} returns this
 */
proto.v1.ChatMessageRef.prototype.clearSubject = function() {
  return this.setSubject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ChatMessageRef.prototype.hasSubject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 ID = 2;
 * @return {number}
 */
proto.v1.ChatMessageRef.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ChatMessageRef} returns this
 */
proto.v1.ChatMessageRef.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.ChatList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ChatList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ChatList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ChatList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.ChatMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ChatList}
 */
proto.v1.ChatList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ChatList;
  return proto.v1.ChatList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ChatList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ChatList}
 */
proto.v1.ChatList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.ChatMessage;
      reader.readMessage(value,proto.v1.ChatMessage.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ChatList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ChatList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ChatList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.ChatMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChatMessage Items = 1;
 * @return {!Array<!proto.v1.ChatMessage>}
 */
proto.v1.ChatList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.ChatMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.ChatMessage, 1));
};


/**
 * @param {!Array<!proto.v1.ChatMessage>} value
 * @return {!proto.v1.ChatList} returns this
*/
proto.v1.ChatList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.ChatMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.ChatMessage}
 */
proto.v1.ChatList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.ChatMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.ChatList} returns this
 */
proto.v1.ChatList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ChatReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ChatReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ChatReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: (f = msg.getSubject()) && proto.v1.ChatSubject.toObject(includeInstance, f),
    since: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ChatReq}
 */
proto.v1.ChatReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ChatReq;
  return proto.v1.ChatReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ChatReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ChatReq}
 */
proto.v1.ChatReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.ChatSubject;
      reader.readMessage(value,proto.v1.ChatSubject.deserializeBinaryFromReader);
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSince(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ChatReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ChatReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ChatReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.ChatSubject.serializeBinaryToWriter
    );
  }
  f = message.getSince();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional ChatSubject Subject = 1;
 * @return {?proto.v1.ChatSubject}
 */
proto.v1.ChatReq.prototype.getSubject = function() {
  return /** @type{?proto.v1.ChatSubject} */ (
    jspb.Message.getWrapperField(this, proto.v1.ChatSubject, 1));
};


/**
 * @param {?proto.v1.ChatSubject|undefined} value
 * @return {!proto.v1.ChatReq} returns this
*/
proto.v1.ChatReq.prototype.setSubject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ChatReq} returns this
 */
proto.v1.ChatReq.prototype.clearSubject = function() {
  return this.setSubject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ChatReq.prototype.hasSubject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 Since = 2;
 * @return {number}
 */
proto.v1.ChatReq.prototype.getSince = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.v1.ChatReq} returns this
 */
proto.v1.ChatReq.prototype.setSince = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ChatAddReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ChatAddReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ChatAddReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatAddReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: (f = msg.getSubject()) && proto.v1.ChatSubject.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ChatAddReq}
 */
proto.v1.ChatAddReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ChatAddReq;
  return proto.v1.ChatAddReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ChatAddReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ChatAddReq}
 */
proto.v1.ChatAddReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.ChatSubject;
      reader.readMessage(value,proto.v1.ChatSubject.deserializeBinaryFromReader);
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ChatAddReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ChatAddReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ChatAddReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatAddReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.ChatSubject.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChatSubject Subject = 1;
 * @return {?proto.v1.ChatSubject}
 */
proto.v1.ChatAddReq.prototype.getSubject = function() {
  return /** @type{?proto.v1.ChatSubject} */ (
    jspb.Message.getWrapperField(this, proto.v1.ChatSubject, 1));
};


/**
 * @param {?proto.v1.ChatSubject|undefined} value
 * @return {!proto.v1.ChatAddReq} returns this
*/
proto.v1.ChatAddReq.prototype.setSubject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ChatAddReq} returns this
 */
proto.v1.ChatAddReq.prototype.clearSubject = function() {
  return this.setSubject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ChatAddReq.prototype.hasSubject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Message = 2;
 * @return {string}
 */
proto.v1.ChatAddReq.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ChatAddReq} returns this
 */
proto.v1.ChatAddReq.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ChatEditReq.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ChatEditReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ChatEditReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatEditReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    ref: (f = msg.getRef()) && proto.v1.ChatMessageRef.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ChatEditReq}
 */
proto.v1.ChatEditReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ChatEditReq;
  return proto.v1.ChatEditReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ChatEditReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ChatEditReq}
 */
proto.v1.ChatEditReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.ChatMessageRef;
      reader.readMessage(value,proto.v1.ChatMessageRef.deserializeBinaryFromReader);
      msg.setRef(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ChatEditReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ChatEditReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ChatEditReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ChatEditReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRef();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.v1.ChatMessageRef.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChatMessageRef Ref = 1;
 * @return {?proto.v1.ChatMessageRef}
 */
proto.v1.ChatEditReq.prototype.getRef = function() {
  return /** @type{?proto.v1.ChatMessageRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.ChatMessageRef, 1));
};


/**
 * @param {?proto.v1.ChatMessageRef|undefined} value
 * @return {!proto.v1.ChatEditReq} returns this
*/
proto.v1.ChatEditReq.prototype.setRef = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ChatEditReq} returns this
 */
proto.v1.ChatEditReq.prototype.clearRef = function() {
  return this.setRef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ChatEditReq.prototype.hasRef = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Message = 2;
 * @return {string}
 */
proto.v1.ChatEditReq.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ChatEditReq} returns this
 */
proto.v1.ChatEditReq.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ImageOverlay.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ImageOverlay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ImageOverlay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ImageOverlay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    url: jspb.Message.getFieldWithDefault(msg, 4, ""),
    box: (f = msg.getBox()) && proto.v1.GeoBox.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ImageOverlay}
 */
proto.v1.ImageOverlay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ImageOverlay;
  return proto.v1.ImageOverlay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ImageOverlay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ImageOverlay}
 */
proto.v1.ImageOverlay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = new proto.v1.GeoBox;
      reader.readMessage(value,proto.v1.GeoBox.deserializeBinaryFromReader);
      msg.setBox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ImageOverlay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ImageOverlay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ImageOverlay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ImageOverlay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBox();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.v1.GeoBox.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.ImageOverlay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ImageOverlay} returns this
 */
proto.v1.ImageOverlay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgRef Org = 2;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.ImageOverlay.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 2));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.ImageOverlay} returns this
*/
proto.v1.ImageOverlay.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ImageOverlay} returns this
 */
proto.v1.ImageOverlay.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ImageOverlay.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.v1.ImageOverlay.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ImageOverlay} returns this
 */
proto.v1.ImageOverlay.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string URL = 4;
 * @return {string}
 */
proto.v1.ImageOverlay.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ImageOverlay} returns this
 */
proto.v1.ImageOverlay.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GeoBox Box = 5;
 * @return {?proto.v1.GeoBox}
 */
proto.v1.ImageOverlay.prototype.getBox = function() {
  return /** @type{?proto.v1.GeoBox} */ (
    jspb.Message.getWrapperField(this, proto.v1.GeoBox, 5));
};


/**
 * @param {?proto.v1.GeoBox|undefined} value
 * @return {!proto.v1.ImageOverlay} returns this
*/
proto.v1.ImageOverlay.prototype.setBox = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.ImageOverlay} returns this
 */
proto.v1.ImageOverlay.prototype.clearBox = function() {
  return this.setBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.ImageOverlay.prototype.hasBox = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.Team.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.Team.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.Team.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.Team} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Team.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    org: (f = msg.getOrg()) && proto.v1.OrgRef.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    proto.v1.UserRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.Team}
 */
proto.v1.Team.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.Team;
  return proto.v1.Team.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.Team} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.Team}
 */
proto.v1.Team.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.v1.OrgRef;
      reader.readMessage(value,proto.v1.OrgRef.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.v1.UserRef;
      reader.readMessage(value,proto.v1.UserRef.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.Team.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.Team.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.Team} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.Team.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.v1.OrgRef.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.v1.UserRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.Team.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Team} returns this
 */
proto.v1.Team.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgRef Org = 2;
 * @return {?proto.v1.OrgRef}
 */
proto.v1.Team.prototype.getOrg = function() {
  return /** @type{?proto.v1.OrgRef} */ (
    jspb.Message.getWrapperField(this, proto.v1.OrgRef, 2));
};


/**
 * @param {?proto.v1.OrgRef|undefined} value
 * @return {!proto.v1.Team} returns this
*/
proto.v1.Team.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.Team} returns this
 */
proto.v1.Team.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.Team.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.v1.Team.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.Team} returns this
 */
proto.v1.Team.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated UserRef Members = 3;
 * @return {!Array<!proto.v1.UserRef>}
 */
proto.v1.Team.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.v1.UserRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.UserRef, 3));
};


/**
 * @param {!Array<!proto.v1.UserRef>} value
 * @return {!proto.v1.Team} returns this
*/
proto.v1.Team.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.v1.UserRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.UserRef}
 */
proto.v1.Team.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.v1.UserRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.Team} returns this
 */
proto.v1.Team.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AnonymousAnomaly.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AnonymousAnomaly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AnonymousAnomaly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnonymousAnomaly.toObject = function(includeInstance, msg) {
  var f, obj = {
    anonymoustoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    anomaly: (f = msg.getAnomaly()) && proto.v1.Anomaly.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AnonymousAnomaly}
 */
proto.v1.AnonymousAnomaly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AnonymousAnomaly;
  return proto.v1.AnonymousAnomaly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AnonymousAnomaly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AnonymousAnomaly}
 */
proto.v1.AnonymousAnomaly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnonymoustoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 3:
      var value = new proto.v1.Anomaly;
      reader.readMessage(value,proto.v1.Anomaly.deserializeBinaryFromReader);
      msg.setAnomaly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AnonymousAnomaly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AnonymousAnomaly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AnonymousAnomaly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AnonymousAnomaly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnonymoustoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnomaly();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.v1.Anomaly.serializeBinaryToWriter
    );
  }
};


/**
 * optional string AnonymousToken = 1;
 * @return {string}
 */
proto.v1.AnonymousAnomaly.prototype.getAnonymoustoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnonymousAnomaly} returns this
 */
proto.v1.AnonymousAnomaly.prototype.setAnonymoustoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrgID = 2;
 * @return {string}
 */
proto.v1.AnonymousAnomaly.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AnonymousAnomaly} returns this
 */
proto.v1.AnonymousAnomaly.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Anomaly Anomaly = 3;
 * @return {?proto.v1.Anomaly}
 */
proto.v1.AnonymousAnomaly.prototype.getAnomaly = function() {
  return /** @type{?proto.v1.Anomaly} */ (
    jspb.Message.getWrapperField(this, proto.v1.Anomaly, 3));
};


/**
 * @param {?proto.v1.Anomaly|undefined} value
 * @return {!proto.v1.AnonymousAnomaly} returns this
*/
proto.v1.AnonymousAnomaly.prototype.setAnomaly = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.v1.AnonymousAnomaly} returns this
 */
proto.v1.AnonymousAnomaly.prototype.clearAnomaly = function() {
  return this.setAnomaly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.v1.AnonymousAnomaly.prototype.hasAnomaly = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AdminOrgRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AdminOrgRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AdminOrgRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AdminOrgRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AdminOrgRef}
 */
proto.v1.AdminOrgRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AdminOrgRef;
  return proto.v1.AdminOrgRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AdminOrgRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AdminOrgRef}
 */
proto.v1.AdminOrgRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AdminOrgRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AdminOrgRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AdminOrgRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AdminOrgRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.AdminOrgRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AdminOrgRef} returns this
 */
proto.v1.AdminOrgRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.AdminOrgList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AdminOrgList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AdminOrgList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AdminOrgList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AdminOrgList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.AdminOrg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AdminOrgList}
 */
proto.v1.AdminOrgList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AdminOrgList;
  return proto.v1.AdminOrgList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AdminOrgList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AdminOrgList}
 */
proto.v1.AdminOrgList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.AdminOrg;
      reader.readMessage(value,proto.v1.AdminOrg.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AdminOrgList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AdminOrgList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AdminOrgList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AdminOrgList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.AdminOrg.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdminOrg Items = 1;
 * @return {!Array<!proto.v1.AdminOrg>}
 */
proto.v1.AdminOrgList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.AdminOrg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.AdminOrg, 1));
};


/**
 * @param {!Array<!proto.v1.AdminOrg>} value
 * @return {!proto.v1.AdminOrgList} returns this
*/
proto.v1.AdminOrgList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.AdminOrg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.AdminOrg}
 */
proto.v1.AdminOrgList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.AdminOrg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AdminOrgList} returns this
 */
proto.v1.AdminOrgList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OrgUserRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OrgUserRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OrgUserRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgUserRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OrgUserRef}
 */
proto.v1.OrgUserRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OrgUserRef;
  return proto.v1.OrgUserRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OrgUserRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OrgUserRef}
 */
proto.v1.OrgUserRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OrgUserRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OrgUserRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OrgUserRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgUserRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.OrgUserRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgUserRef} returns this
 */
proto.v1.OrgUserRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.OrgUserList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OrgUserList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OrgUserList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OrgUserList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgUserList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.OrgUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OrgUserList}
 */
proto.v1.OrgUserList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OrgUserList;
  return proto.v1.OrgUserList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OrgUserList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OrgUserList}
 */
proto.v1.OrgUserList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.OrgUser;
      reader.readMessage(value,proto.v1.OrgUser.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OrgUserList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OrgUserList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OrgUserList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgUserList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.OrgUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrgUser Items = 1;
 * @return {!Array<!proto.v1.OrgUser>}
 */
proto.v1.OrgUserList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.OrgUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.OrgUser, 1));
};


/**
 * @param {!Array<!proto.v1.OrgUser>} value
 * @return {!proto.v1.OrgUserList} returns this
*/
proto.v1.OrgUserList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.OrgUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.OrgUser}
 */
proto.v1.OrgUserList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.OrgUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.OrgUserList} returns this
 */
proto.v1.OrgUserList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OrgRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OrgRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OrgRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OrgRef}
 */
proto.v1.OrgRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OrgRef;
  return proto.v1.OrgRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OrgRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OrgRef}
 */
proto.v1.OrgRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OrgRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OrgRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OrgRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.OrgRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.OrgRef} returns this
 */
proto.v1.OrgRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.OrgList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.OrgList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.OrgList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.OrgList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Org.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.OrgList}
 */
proto.v1.OrgList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.OrgList;
  return proto.v1.OrgList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.OrgList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.OrgList}
 */
proto.v1.OrgList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Org;
      reader.readMessage(value,proto.v1.Org.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.OrgList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.OrgList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.OrgList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.OrgList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Org.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Org Items = 1;
 * @return {!Array<!proto.v1.Org>}
 */
proto.v1.OrgList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Org>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Org, 1));
};


/**
 * @param {!Array<!proto.v1.Org>} value
 * @return {!proto.v1.OrgList} returns this
*/
proto.v1.OrgList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Org=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Org}
 */
proto.v1.OrgList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Org, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.OrgList} returns this
 */
proto.v1.OrgList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.HandlingRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.HandlingRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.HandlingRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.HandlingRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.HandlingRef}
 */
proto.v1.HandlingRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.HandlingRef;
  return proto.v1.HandlingRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.HandlingRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.HandlingRef}
 */
proto.v1.HandlingRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.HandlingRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.HandlingRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.HandlingRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.HandlingRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.HandlingRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.HandlingRef} returns this
 */
proto.v1.HandlingRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.HandlingList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.HandlingList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.HandlingList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.HandlingList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.HandlingList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Handling.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.HandlingList}
 */
proto.v1.HandlingList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.HandlingList;
  return proto.v1.HandlingList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.HandlingList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.HandlingList}
 */
proto.v1.HandlingList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Handling;
      reader.readMessage(value,proto.v1.Handling.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.HandlingList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.HandlingList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.HandlingList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.HandlingList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Handling.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Handling Items = 1;
 * @return {!Array<!proto.v1.Handling>}
 */
proto.v1.HandlingList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Handling>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Handling, 1));
};


/**
 * @param {!Array<!proto.v1.Handling>} value
 * @return {!proto.v1.HandlingList} returns this
*/
proto.v1.HandlingList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Handling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Handling}
 */
proto.v1.HandlingList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Handling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.HandlingList} returns this
 */
proto.v1.HandlingList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CategoryRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CategoryRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CategoryRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CategoryRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CategoryRef}
 */
proto.v1.CategoryRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CategoryRef;
  return proto.v1.CategoryRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CategoryRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CategoryRef}
 */
proto.v1.CategoryRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CategoryRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CategoryRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CategoryRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CategoryRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.CategoryRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.CategoryRef} returns this
 */
proto.v1.CategoryRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.CategoryList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CategoryList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CategoryList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CategoryList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CategoryList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Category.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CategoryList}
 */
proto.v1.CategoryList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CategoryList;
  return proto.v1.CategoryList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CategoryList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CategoryList}
 */
proto.v1.CategoryList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Category;
      reader.readMessage(value,proto.v1.Category.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CategoryList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CategoryList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CategoryList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CategoryList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Category.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Category Items = 1;
 * @return {!Array<!proto.v1.Category>}
 */
proto.v1.CategoryList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Category, 1));
};


/**
 * @param {!Array<!proto.v1.Category>} value
 * @return {!proto.v1.CategoryList} returns this
*/
proto.v1.CategoryList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Category}
 */
proto.v1.CategoryList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.CategoryList} returns this
 */
proto.v1.CategoryList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EquipmentTypeRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EquipmentTypeRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EquipmentTypeRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentTypeRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EquipmentTypeRef}
 */
proto.v1.EquipmentTypeRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EquipmentTypeRef;
  return proto.v1.EquipmentTypeRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EquipmentTypeRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EquipmentTypeRef}
 */
proto.v1.EquipmentTypeRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EquipmentTypeRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EquipmentTypeRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EquipmentTypeRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentTypeRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.EquipmentTypeRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EquipmentTypeRef} returns this
 */
proto.v1.EquipmentTypeRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.EquipmentTypeList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EquipmentTypeList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EquipmentTypeList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EquipmentTypeList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentTypeList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.EquipmentType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EquipmentTypeList}
 */
proto.v1.EquipmentTypeList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EquipmentTypeList;
  return proto.v1.EquipmentTypeList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EquipmentTypeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EquipmentTypeList}
 */
proto.v1.EquipmentTypeList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.EquipmentType;
      reader.readMessage(value,proto.v1.EquipmentType.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EquipmentTypeList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EquipmentTypeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EquipmentTypeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentTypeList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.EquipmentType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EquipmentType Items = 1;
 * @return {!Array<!proto.v1.EquipmentType>}
 */
proto.v1.EquipmentTypeList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.EquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.EquipmentType, 1));
};


/**
 * @param {!Array<!proto.v1.EquipmentType>} value
 * @return {!proto.v1.EquipmentTypeList} returns this
*/
proto.v1.EquipmentTypeList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.EquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.EquipmentType}
 */
proto.v1.EquipmentTypeList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.EquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.EquipmentTypeList} returns this
 */
proto.v1.EquipmentTypeList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EquipmentRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EquipmentRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EquipmentRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EquipmentRef}
 */
proto.v1.EquipmentRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EquipmentRef;
  return proto.v1.EquipmentRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EquipmentRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EquipmentRef}
 */
proto.v1.EquipmentRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EquipmentRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EquipmentRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EquipmentRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.EquipmentRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EquipmentRef} returns this
 */
proto.v1.EquipmentRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.EquipmentList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EquipmentList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EquipmentList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EquipmentList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Equipment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EquipmentList}
 */
proto.v1.EquipmentList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EquipmentList;
  return proto.v1.EquipmentList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EquipmentList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EquipmentList}
 */
proto.v1.EquipmentList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Equipment;
      reader.readMessage(value,proto.v1.Equipment.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EquipmentList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EquipmentList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EquipmentList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EquipmentList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Equipment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Equipment Items = 1;
 * @return {!Array<!proto.v1.Equipment>}
 */
proto.v1.EquipmentList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Equipment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Equipment, 1));
};


/**
 * @param {!Array<!proto.v1.Equipment>} value
 * @return {!proto.v1.EquipmentList} returns this
*/
proto.v1.EquipmentList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Equipment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Equipment}
 */
proto.v1.EquipmentList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Equipment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.EquipmentList} returns this
 */
proto.v1.EquipmentList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CheckListRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CheckListRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CheckListRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckListRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CheckListRef}
 */
proto.v1.CheckListRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CheckListRef;
  return proto.v1.CheckListRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CheckListRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CheckListRef}
 */
proto.v1.CheckListRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CheckListRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CheckListRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CheckListRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckListRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.CheckListRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.CheckListRef} returns this
 */
proto.v1.CheckListRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.CheckListList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.CheckListList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.CheckListList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.CheckListList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckListList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.CheckList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.CheckListList}
 */
proto.v1.CheckListList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.CheckListList;
  return proto.v1.CheckListList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.CheckListList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.CheckListList}
 */
proto.v1.CheckListList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.CheckList;
      reader.readMessage(value,proto.v1.CheckList.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.CheckListList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.CheckListList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.CheckListList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.CheckListList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.CheckList.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CheckList Items = 1;
 * @return {!Array<!proto.v1.CheckList>}
 */
proto.v1.CheckListList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.CheckList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.CheckList, 1));
};


/**
 * @param {!Array<!proto.v1.CheckList>} value
 * @return {!proto.v1.CheckListList} returns this
*/
proto.v1.CheckListList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.CheckList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.CheckList}
 */
proto.v1.CheckListList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.CheckList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.CheckListList} returns this
 */
proto.v1.CheckListList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.RondeRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.RondeRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.RondeRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.RondeRef}
 */
proto.v1.RondeRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.RondeRef;
  return proto.v1.RondeRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.RondeRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.RondeRef}
 */
proto.v1.RondeRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.RondeRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.RondeRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.RondeRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.RondeRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.RondeRef} returns this
 */
proto.v1.RondeRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.RondeList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.RondeList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.RondeList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.RondeList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Ronde.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.RondeList}
 */
proto.v1.RondeList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.RondeList;
  return proto.v1.RondeList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.RondeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.RondeList}
 */
proto.v1.RondeList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Ronde;
      reader.readMessage(value,proto.v1.Ronde.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.RondeList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.RondeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.RondeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Ronde.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Ronde Items = 1;
 * @return {!Array<!proto.v1.Ronde>}
 */
proto.v1.RondeList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Ronde>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Ronde, 1));
};


/**
 * @param {!Array<!proto.v1.Ronde>} value
 * @return {!proto.v1.RondeList} returns this
*/
proto.v1.RondeList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Ronde=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Ronde}
 */
proto.v1.RondeList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Ronde, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.RondeList} returns this
 */
proto.v1.RondeList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.RondeExecRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.RondeExecRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.RondeExecRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeExecRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.RondeExecRef}
 */
proto.v1.RondeExecRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.RondeExecRef;
  return proto.v1.RondeExecRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.RondeExecRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.RondeExecRef}
 */
proto.v1.RondeExecRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.RondeExecRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.RondeExecRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.RondeExecRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeExecRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.RondeExecRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.RondeExecRef} returns this
 */
proto.v1.RondeExecRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.RondeExecList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.RondeExecList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.RondeExecList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.RondeExecList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeExecList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.RondeExec.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.RondeExecList}
 */
proto.v1.RondeExecList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.RondeExecList;
  return proto.v1.RondeExecList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.RondeExecList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.RondeExecList}
 */
proto.v1.RondeExecList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.RondeExec;
      reader.readMessage(value,proto.v1.RondeExec.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.RondeExecList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.RondeExecList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.RondeExecList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RondeExecList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.RondeExec.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RondeExec Items = 1;
 * @return {!Array<!proto.v1.RondeExec>}
 */
proto.v1.RondeExecList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.RondeExec>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.RondeExec, 1));
};


/**
 * @param {!Array<!proto.v1.RondeExec>} value
 * @return {!proto.v1.RondeExecList} returns this
*/
proto.v1.RondeExecList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.RondeExec=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.RondeExec}
 */
proto.v1.RondeExecList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.RondeExec, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.RondeExecList} returns this
 */
proto.v1.RondeExecList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AlertRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AlertRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AlertRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AlertRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AlertRef}
 */
proto.v1.AlertRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AlertRef;
  return proto.v1.AlertRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AlertRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AlertRef}
 */
proto.v1.AlertRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AlertRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AlertRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AlertRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AlertRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.AlertRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.AlertRef} returns this
 */
proto.v1.AlertRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.AlertList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.AlertList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.AlertList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.AlertList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AlertList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Alert.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.AlertList}
 */
proto.v1.AlertList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.AlertList;
  return proto.v1.AlertList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.AlertList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.AlertList}
 */
proto.v1.AlertList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Alert;
      reader.readMessage(value,proto.v1.Alert.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.AlertList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.AlertList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.AlertList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.AlertList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Alert.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Alert Items = 1;
 * @return {!Array<!proto.v1.Alert>}
 */
proto.v1.AlertList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Alert>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Alert, 1));
};


/**
 * @param {!Array<!proto.v1.Alert>} value
 * @return {!proto.v1.AlertList} returns this
*/
proto.v1.AlertList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Alert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Alert}
 */
proto.v1.AlertList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Alert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.AlertList} returns this
 */
proto.v1.AlertList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.RuleRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.RuleRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.RuleRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RuleRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.RuleRef}
 */
proto.v1.RuleRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.RuleRef;
  return proto.v1.RuleRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.RuleRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.RuleRef}
 */
proto.v1.RuleRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.RuleRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.RuleRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.RuleRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RuleRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.RuleRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.RuleRef} returns this
 */
proto.v1.RuleRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.RuleList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.RuleList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.RuleList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.RuleList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RuleList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Rule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.RuleList}
 */
proto.v1.RuleList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.RuleList;
  return proto.v1.RuleList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.RuleList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.RuleList}
 */
proto.v1.RuleList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Rule;
      reader.readMessage(value,proto.v1.Rule.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.RuleList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.RuleList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.RuleList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.RuleList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Rule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Rule Items = 1;
 * @return {!Array<!proto.v1.Rule>}
 */
proto.v1.RuleList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Rule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Rule, 1));
};


/**
 * @param {!Array<!proto.v1.Rule>} value
 * @return {!proto.v1.RuleList} returns this
*/
proto.v1.RuleList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Rule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Rule}
 */
proto.v1.RuleList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Rule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.RuleList} returns this
 */
proto.v1.RuleList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EmergencyRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EmergencyRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EmergencyRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencyRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EmergencyRef}
 */
proto.v1.EmergencyRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EmergencyRef;
  return proto.v1.EmergencyRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EmergencyRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EmergencyRef}
 */
proto.v1.EmergencyRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EmergencyRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EmergencyRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EmergencyRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencyRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.EmergencyRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.EmergencyRef} returns this
 */
proto.v1.EmergencyRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.EmergencyList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.EmergencyList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.EmergencyList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.EmergencyList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencyList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Emergency.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.EmergencyList}
 */
proto.v1.EmergencyList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.EmergencyList;
  return proto.v1.EmergencyList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.EmergencyList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.EmergencyList}
 */
proto.v1.EmergencyList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Emergency;
      reader.readMessage(value,proto.v1.Emergency.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.EmergencyList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.EmergencyList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.EmergencyList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.EmergencyList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Emergency.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Emergency Items = 1;
 * @return {!Array<!proto.v1.Emergency>}
 */
proto.v1.EmergencyList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Emergency>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Emergency, 1));
};


/**
 * @param {!Array<!proto.v1.Emergency>} value
 * @return {!proto.v1.EmergencyList} returns this
*/
proto.v1.EmergencyList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Emergency=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Emergency}
 */
proto.v1.EmergencyList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Emergency, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.EmergencyList} returns this
 */
proto.v1.EmergencyList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MapElementRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MapElementRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MapElementRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapElementRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MapElementRef}
 */
proto.v1.MapElementRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MapElementRef;
  return proto.v1.MapElementRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MapElementRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MapElementRef}
 */
proto.v1.MapElementRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MapElementRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MapElementRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MapElementRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapElementRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.MapElementRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MapElementRef} returns this
 */
proto.v1.MapElementRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.MapElementList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MapElementList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MapElementList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MapElementList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapElementList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.MapElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MapElementList}
 */
proto.v1.MapElementList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MapElementList;
  return proto.v1.MapElementList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MapElementList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MapElementList}
 */
proto.v1.MapElementList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.MapElement;
      reader.readMessage(value,proto.v1.MapElement.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MapElementList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MapElementList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MapElementList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapElementList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.MapElement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MapElement Items = 1;
 * @return {!Array<!proto.v1.MapElement>}
 */
proto.v1.MapElementList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.MapElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.MapElement, 1));
};


/**
 * @param {!Array<!proto.v1.MapElement>} value
 * @return {!proto.v1.MapElementList} returns this
*/
proto.v1.MapElementList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.MapElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.MapElement}
 */
proto.v1.MapElementList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.MapElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.MapElementList} returns this
 */
proto.v1.MapElementList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MapIconRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MapIconRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MapIconRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapIconRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MapIconRef}
 */
proto.v1.MapIconRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MapIconRef;
  return proto.v1.MapIconRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MapIconRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MapIconRef}
 */
proto.v1.MapIconRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MapIconRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MapIconRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MapIconRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapIconRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.MapIconRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.MapIconRef} returns this
 */
proto.v1.MapIconRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.MapIconList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.MapIconList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.MapIconList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.MapIconList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapIconList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.MapIcon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.MapIconList}
 */
proto.v1.MapIconList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.MapIconList;
  return proto.v1.MapIconList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.MapIconList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.MapIconList}
 */
proto.v1.MapIconList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.MapIcon;
      reader.readMessage(value,proto.v1.MapIcon.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.MapIconList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.MapIconList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.MapIconList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.MapIconList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.MapIcon.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MapIcon Items = 1;
 * @return {!Array<!proto.v1.MapIcon>}
 */
proto.v1.MapIconList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.MapIcon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.MapIcon, 1));
};


/**
 * @param {!Array<!proto.v1.MapIcon>} value
 * @return {!proto.v1.MapIconList} returns this
*/
proto.v1.MapIconList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.MapIcon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.MapIcon}
 */
proto.v1.MapIconList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.MapIcon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.MapIconList} returns this
 */
proto.v1.MapIconList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.TodoListRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.TodoListRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.TodoListRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoListRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.TodoListRef}
 */
proto.v1.TodoListRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.TodoListRef;
  return proto.v1.TodoListRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.TodoListRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.TodoListRef}
 */
proto.v1.TodoListRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.TodoListRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.TodoListRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.TodoListRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoListRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.TodoListRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.TodoListRef} returns this
 */
proto.v1.TodoListRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.TodoListList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.TodoListList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.TodoListList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.TodoListList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoListList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.TodoList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.TodoListList}
 */
proto.v1.TodoListList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.TodoListList;
  return proto.v1.TodoListList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.TodoListList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.TodoListList}
 */
proto.v1.TodoListList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.TodoList;
      reader.readMessage(value,proto.v1.TodoList.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.TodoListList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.TodoListList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.TodoListList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TodoListList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.TodoList.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TodoList Items = 1;
 * @return {!Array<!proto.v1.TodoList>}
 */
proto.v1.TodoListList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.TodoList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.TodoList, 1));
};


/**
 * @param {!Array<!proto.v1.TodoList>} value
 * @return {!proto.v1.TodoListList} returns this
*/
proto.v1.TodoListList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.TodoList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.TodoList}
 */
proto.v1.TodoListList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.TodoList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.TodoListList} returns this
 */
proto.v1.TodoListList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ImageOverlayRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ImageOverlayRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ImageOverlayRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ImageOverlayRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ImageOverlayRef}
 */
proto.v1.ImageOverlayRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ImageOverlayRef;
  return proto.v1.ImageOverlayRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ImageOverlayRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ImageOverlayRef}
 */
proto.v1.ImageOverlayRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ImageOverlayRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ImageOverlayRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ImageOverlayRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ImageOverlayRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.ImageOverlayRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.ImageOverlayRef} returns this
 */
proto.v1.ImageOverlayRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.ImageOverlayList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.ImageOverlayList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.ImageOverlayList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.ImageOverlayList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ImageOverlayList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.ImageOverlay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.ImageOverlayList}
 */
proto.v1.ImageOverlayList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.ImageOverlayList;
  return proto.v1.ImageOverlayList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.ImageOverlayList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.ImageOverlayList}
 */
proto.v1.ImageOverlayList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.ImageOverlay;
      reader.readMessage(value,proto.v1.ImageOverlay.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.ImageOverlayList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.ImageOverlayList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.ImageOverlayList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.ImageOverlayList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.ImageOverlay.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ImageOverlay Items = 1;
 * @return {!Array<!proto.v1.ImageOverlay>}
 */
proto.v1.ImageOverlayList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.ImageOverlay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.ImageOverlay, 1));
};


/**
 * @param {!Array<!proto.v1.ImageOverlay>} value
 * @return {!proto.v1.ImageOverlayList} returns this
*/
proto.v1.ImageOverlayList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.ImageOverlay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.ImageOverlay}
 */
proto.v1.ImageOverlayList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.ImageOverlay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.ImageOverlayList} returns this
 */
proto.v1.ImageOverlayList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.TeamRef.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.TeamRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.TeamRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TeamRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.TeamRef}
 */
proto.v1.TeamRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.TeamRef;
  return proto.v1.TeamRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.TeamRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.TeamRef}
 */
proto.v1.TeamRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.TeamRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.TeamRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.TeamRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TeamRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.v1.TeamRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.v1.TeamRef} returns this
 */
proto.v1.TeamRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.v1.TeamList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.v1.TeamList.prototype.toObject = function(opt_includeInstance) {
  return proto.v1.TeamList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.v1.TeamList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TeamList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.v1.Team.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.v1.TeamList}
 */
proto.v1.TeamList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.v1.TeamList;
  return proto.v1.TeamList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.v1.TeamList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.v1.TeamList}
 */
proto.v1.TeamList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.v1.Team;
      reader.readMessage(value,proto.v1.Team.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.v1.TeamList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.v1.TeamList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.v1.TeamList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.v1.TeamList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.v1.Team.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Team Items = 1;
 * @return {!Array<!proto.v1.Team>}
 */
proto.v1.TeamList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.v1.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.v1.Team, 1));
};


/**
 * @param {!Array<!proto.v1.Team>} value
 * @return {!proto.v1.TeamList} returns this
*/
proto.v1.TeamList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.v1.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.v1.Team}
 */
proto.v1.TeamList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.v1.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.v1.TeamList} returns this
 */
proto.v1.TeamList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * @enum {number}
 */
proto.v1.OrgType = {
  ORGTYPEUNKNOWN: 0,
  PUBLIC: 1,
  PRIVATE: 2
};

/**
 * @enum {number}
 */
proto.v1.HandlingState = {
  HANDLINGNEW: 0,
  HANDLINGCONFIRMED: 1,
  HANDLINGREJECTED: 2,
  HANDLINGINPROGRESS: 3,
  HANDLINGDONE: 4,
  HANDLINGNEEDANALYSIS: 5,
  HANDLINGNEEDINFO: 7,
  HANDLINGWORKCOMPLETE: 8,
  HANDLINGARCHIVED: 6
};

/**
 * @enum {number}
 */
proto.v1.CheckState = {
  CHECKUNKNOWN: 0,
  CHECKOK: 1,
  CHECKKO: 2
};

/**
 * @enum {number}
 */
proto.v1.ChangeKind = {
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2
};


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `refTarget`.
 * @type {!jspb.ExtensionFieldInfo<string>}
 */
proto.v1.refTarget = new jspb.ExtensionFieldInfo(
    42001,
    {refTarget: 0},
    null,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         null),
    0);

google_protobuf_descriptor_pb.FieldOptions.extensionsBinary[42001] = new jspb.ExtensionFieldBinaryInfo(
    proto.v1.refTarget,
    jspb.BinaryReader.prototype.readString,
    jspb.BinaryWriter.prototype.writeString,
    undefined,
    undefined,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FieldOptions.extensions[42001] = proto.v1.refTarget;

goog.object.extend(exports, proto.v1);
