import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// API items
import { MapElementsServiceClient, MapIconsServiceClient } from 'api/v1/v1_grpc_web_pb';
import { MapElement, OrgRef, UserRef, Point, PhotoRef, GPSPoint } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    mapIcons: [],
    mapIconsLoading: true,
    mapElements: [],
    mapElementsLoading: false,
};

// =================================================================
// Map element slice
// =================================================================

const mapElementSlice = createSlice({
    name: 'mapElement',
    initialState,
    reducers: {
        setMapIcons(state, { payload }) {
            state.mapIcons = payload;
        },

        setMapIconsLoading(state, { payload }) {
            state.mapIconsLoading = payload;
        },
        setMapElements(state, { payload }) {
            state.mapElements = payload;
        },
        setMapElementsLoading(state, { payload }) {
            state.mapElementsLoading = payload;
        },
    },
});

// ===================================================================
// Map element actions
// ===================================================================

export const { setMapIcons, setMapIconsLoading, setMapElements, setMapElementsLoading } = mapElementSlice.actions;

// ====================================================================
// Map element selector
// ====================================================================

export const mapElementSelector = (state) => state.mapElement;

// =====================================================================
// Map element reducer
// =====================================================================

export default mapElementSlice.reducer;

// ======================================================================
// List Map elements
// ======================================================================
export const refreshMapElements = (data) => async (dispatch) => {
    dispatch(setMapElements(data));
    dispatch(setMapElementsLoading(false));
};

// ======================================================================
// List Map elements
// ======================================================================
export const fetchMapIcons = () => async (dispatch) => {
    dispatch(setMapIconsLoading(true));

    try {
        const res = await authRequestHandler(MapIconsServiceClient, 'list');
        const mapIcons = res.getItemsList().map((icon) => ({
            id: icon.getId(),
            name: icon.getName(),
            image: icon.getImage().getId(),
            tagLists: icon.getTagsList(),
        }));

        dispatch(setMapIcons(mapIcons));
    } finally {
        dispatch(setMapIconsLoading(false));
    }
};

// ======================================================================
// Add Map element
// ======================================================================
export const addMapElement = (data) => async (dispatch, getState) => {
    const {
        auth: { activeOrg, authUser },
    } = getState();

    const mapElement = new MapElement();
    mapElement.setId(uuidv4());

    mapElement.setLabel(data.label);
    mapElement.setCreatedby(ref(UserRef, authUser.id));
    mapElement.setOrg(ref(OrgRef, activeOrg));

    const point = new Point();
    point.setLat(data.latlng[0]);
    point.setLong(data.latlng[1]);

    const newGPSPoint = new GPSPoint();
    newGPSPoint.setPoint(point);
    newGPSPoint.setPrecision(1);

    mapElement.setPosition(newGPSPoint);

    const photo = new PhotoRef();
    photo.setId(data.image);
    mapElement.setImage(photo);
    mapElement.setCreatedat(parseInt(new Date().getTime() / 1000));

    await authRequestHandler(MapElementsServiceClient, 'add', mapElement);
};
// ======================================================================
// Edit Map elements
// ======================================================================
export const editMapElement = (data) => async () => {
    const mapElement = await getMapElement(data.id);

    mapElement.setLabel(data.label);

    const point = new Point();
    point.setLat(data.latlng[0]);
    point.setLong(data.latlng[1]);

    const newGPSPoint = new GPSPoint();
    newGPSPoint.setPoint(point);
    newGPSPoint.setPrecision(1);

    mapElement.setPosition(newGPSPoint);

    const photo = new PhotoRef();
    photo.setId(data.image);
    mapElement.setImage(photo);

    await authRequestHandler(MapElementsServiceClient, 'set', mapElement);
};
// ======================================================================
// Edit Map elements
// ======================================================================
export const deleteMapElement = (elementId) => async () => {
    await authRequestHandler(MapElementsServiceClient, 'delete', ref(MapElement, elementId));
};

// ======================================================================
// Edit Map elements
// ======================================================================
// eslint-disable-next-line no-unused-vars
export const getMapElement = async (elementId) => {
    const result = await authRequestHandler(MapElementsServiceClient, 'get', ref(MapElement, elementId));

    return result;
};
