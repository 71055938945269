import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import copyObj from 'utils/copyObj';

import { getEquipmentTypeDetails } from 'store/slices/equipmentTypeSlice';

// API items
import { CheckListsServiceClient } from 'api/v1/v1_grpc_web_pb';
import { CheckList, CheckListRef, OrgRef, Check, EquipmentTypeRef } from 'api/v1/v1_pb';
import authRequestHandler, { ref } from 'api/handlers/apiHandler';

// =================================================================
// Initial state
// =================================================================

export const initialState = {
    checklists: [],
    loading: true,
};

// =================================================================
// Checklist slice
// =================================================================

const checklistSlice = createSlice({
    name: 'checklist',
    initialState,
    reducers: {
        setChecklists(state, { payload }) {
            state.checklists = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
    },
});

// ===================================================================
// Checklist actions
// ===================================================================

export const { setLoading, setChecklists } = checklistSlice.actions;

// ====================================================================
// Checklist selector
// ====================================================================

export const checklistSelector = (state) => state.checklist;

// =====================================================================
// Checklist reducer
// =====================================================================

export default checklistSlice.reducer;

// ======================================================================
// Fetch Checklists
// ======================================================================

export const fetchChecklists = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const result = await authRequestHandler(CheckListsServiceClient, 'list');
        const checkLists = await result.getItemsList().map(async (checklist) => {
            const type = checklist.getType()?.getId();

            return {
                id: checklist.getId(),
                name: checklist.getName(),
                type: type ? await getEquipmentTypeDetails(type).catch(() => null) : null,
                checks: checklist
                    ?.getChecksList()
                    .map((checks) => ({ name: checks.getName(), metric: checks.getMetric() || null })),
            };
        });

        dispatch(setChecklists(await Promise.all(checkLists)));
        return checkLists;
    } finally {
        dispatch(setLoading(false));
    }
};

// ======================================================================
// Add Checklists
// ======================================================================

export const addChecklist = (checklistData) => async (dispatch, getState) => {
    const {
        auth: { activeOrg },
    } = getState();

    const { name, checks, type } = checklistData;

    // New checklist
    const newCheckList = new CheckList();
    newCheckList.setId(uuidv4());
    newCheckList.setName(name);

    if (type) {
        const equipmentTypeRef = new EquipmentTypeRef();
        equipmentTypeRef.setId(type.id);
        newCheckList.setType(equipmentTypeRef);
    }

    //New Org
    const newOrg = new OrgRef();
    newOrg.setId(activeOrg);
    newCheckList.setOrg(newOrg);

    // Set checklists
    newCheckList.setChecksList(
        checks.map((check) => {
            const newCheck = new Check();
            newCheck.setName(check.name);
            if (check.metric) {
                newCheck.setMetric(check.metric);
            }
            return newCheck;
        }),
    );

    // Request to the API
    const result = await authRequestHandler(CheckListsServiceClient, 'add', newCheckList);
    dispatch(fetchChecklists());
    return result;
};

// ======================================================================
// Update checklist
// ======================================================================

export const updateChecklist = (updatedChecklistData) => async (dispatch, getState) => {
    // Get auth token
    const {
        auth: { activeOrg },
    } = getState();

    const { name, checks, type } = updatedChecklistData;

    // New checklist
    const newCheckList = await getChecklistDetails(updatedChecklistData.id, false);
    newCheckList.setName(name);

    if (type) {
        const equipmentTypeRef = new EquipmentTypeRef();
        equipmentTypeRef.setId(type.id);
        newCheckList.setType(equipmentTypeRef);
    }

    //New Org
    const newOrg = new OrgRef();
    newOrg.setId(activeOrg);
    newCheckList.setOrg(newOrg);

    // Set checklists
    newCheckList.setChecksList(
        checks.map((check) => {
            const newCheck = new Check();
            newCheck.setName(check.name);
            if (check.metric) {
                newCheck.setMetric(check.metric);
            }
            return newCheck;
        }),
    );

    // Request to the API
    const result = await authRequestHandler(CheckListsServiceClient, 'set', newCheckList);
    const updatedCheckLists = copyObj(getState().checklist.checklists).map((checklist) => {
        if (checklist.id === updatedChecklistData.id) {
            return updatedChecklistData;
        }
        return checklist;
    });

    dispatch(setChecklists(updatedCheckLists));
    return result;
};

// ======================================================================
// Delete Checklist
// ======================================================================

export const deleteChecklist = (deleteChecklistId) => async (dispatch, getState) => {
    // Request to the API
    const result = await authRequestHandler(CheckListsServiceClient, 'delete', ref(CheckListRef, deleteChecklistId));
    const updatedChecklists = copyObj(getState().checklist.checklists).filter(
        (checklist) => checklist.id !== deleteChecklistId,
    );

    dispatch(setChecklists(updatedChecklists));
    return result;
};

// ======================================================================
// Get a Checklist details
// ======================================================================

export const getChecklistDetails = async (checklistId, extracted = true) => {
    // Request to the API
    const result = await authRequestHandler(CheckListsServiceClient, 'get', ref(CheckListRef, checklistId));
    if (extracted) {
        return {
            id: result.getId(),
            name: result.getName(),
            checks: result
                ?.getChecksList()
                .map((checks) => ({ name: checks.getName(), metric: checks.getMetric() || null })),
        };
    }
    return result;
};
